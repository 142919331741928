@import "../mixins/media";
@import "../mixins/projects";

/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [カートページ](http://demo3.ec-cube.net/shopping)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartRole

Styleguide 15.1

*/
.ec-cartRole{
  @include container;
  padding-left:10px;
  padding-right:10px;
  &::before{
    display: none;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  & &__progress{
    margin-bottom:30px;
    width: 100%;
    text-align: center;
  }
  & &__error{
    width: 100%;
    text-align: center;
    .ec-alert-warning {
      max-width: 80%;
      display: inline-block;
    }
  }
  & &__totalText{
    margin-bottom: 0;
    padding: 16px 0 6px;
    width: 100%;
    text-align: center;
    font-weight: normal;
    @include media_desktop {
      margin-bottom: 5px;
      padding: 0;
    }
    strong{
      color:#f5568b;
    }
  }
  & &__cart{
    margin: 0;
    width: 100%;
    @include media_desktop {
      margin: 0 10%;
    }

  }
  & &__actions{
    padding:1em;
    text-align: right;
    width: 100%;
    background: #fbfde5;
    @include media_desktop {
      margin-top :3em;
    }
    p{
      margin:0 auto;
      font-weight:bold;
      font-size:20px;
      text-align:center;
      background: #ff6c00;
      color:#fff;
    }
    @include media_desktop {
      width:  30%;
      margin-right: 10%;
    }
  }
  & &__total{
    padding: 0 0 20px ;
    font-weight: bold;
    font-size: 16px;
  }
  & &__sample,& &__goods{
    padding: 0;
    font-size:14px;
  }
  & &__totalAmount{
    margin-left: 30px;
    color: #de5d50;
    font-size: 16px;
    @include media_desktop {
      font-size: 24px;
    }
  }

  .ec-blockBtn--action {
    margin-bottom: 10px;
  }
}


/*
カート商品表示枠（テーブルヘッダ）

カート内の商品をを表示するテーブル枠です。

ex [カートページ　テーブル部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartTable

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.2
*/
.ec-cartTable{
  display: table;
  border-top: 1px solid #ccc;
  width: 100%;
  @include media_desktop {
    border-top: none;
  }
}


/*
カート商品表示枠（テーブルヘッダ）

カート内の商品を表示するテーブルのヘッダです。
スマホでは非表示となります。

ex [カートページ　カートテーブルヘッダ部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)


Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartHeader

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>



Styleguide 15.1.3
*/
.ec-cartHeader{
  display: none;
  border:1px solid #ccc;
  width: 100%;
  background: #F4F3F0;
  @include media_desktop {
    display: table-row;
    border:1px solid #ccc;
  }
  & &__label{
    display: table-cell;
    border-right:1px solid #ccc;
    border-top:1px solid #ccc;
    border-bottom:1px solid #ccc;
    padding: 16px;
    text-align: center;
    background: url(../img/common/th_bg.gif) 0 0 repeat;
    overflow-x: hidden;
    font-weight: bold;
    &:first-child{
      border-left:1px solid #ccc;
    }
  }
}


#sample-bg{
  padding:5px;
  background:url("../img/common/th_bg_yellow.gif") repeat scroll 0 0;
}
#sample li{
  background:url("../img/common/th_bg_yellow.gif") repeat scroll 0 0 !important;
}
#history-bg{
  padding:5px;
 background:url("../img/common/th_bg.gif") repeat scroll 0 0;
}



.ec-cartCompleteRole {
  @include container;
  /*JNB*/
  .thanks-jnb,.thanks-credit{
    margin:0 auto 2rem;
    text-align:center;
    h3{
      font-size:24px;
      font-size:2.4rem;
      strong{
        color:#003894;
      }
      a{
        text-decoration: underline;
        &:hover{
          text-decoration: none;
        }
      }
    }
    p{
      &.jnb-furikomi{
        max-width:700px;
        margin:8px auto 2rem;
        padding:5px;
        background: #003894;
        color:#fff;
        font-weight:bold;        
      }
      &.btn-url a{
        display: inline-block;
        color: #fff;
        background: #f5568b;
        font-size: 1.3em;
        font-weight: bold;
        border-radius: 20px;
        padding: 10px 15px;
        text-decoration: none;
      }
    }
  }
  dl.pay-txt{
    max-width:700px;
    margin: 1.5rem auto 2rem;
    padding: 10px;
    border: 1px solid #de5d50;
    dt{
      padding-bottom: 0.5rem;
      border-bottom: 1px dashed #ccc;
      font-size: 24px;
      font-size: 2.4rem;
      text-align: center;
      font-weight: bold;
      color: #de5d50;
    }
    dd{
      padding: 15px;
      font-size: 14px;
      font-size: 1.4rem;
      text-align: left;
      span{
        color: #de5d50;
      }     
    }
   }
  }




/*
カート内商品

カート内のアイテムを表示するテーブル行です。
スマホでは非表示となります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRow

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.4
*/
.ec-cartTable{
  margin-bottom:2em;
  font-size:14px;
}
.ec-cartRow{
  border:1px solid #ccc;
  li{
    border-right:1px solid #ccc;

  }
  display: table-row;
  & &__delColumn{
    a{
      width:14px;
      display:inline-block;
      img{
        width:16px;
        max-width:16px;
        display:inline-block;
      }
    }

    border-bottom: 1px solid #ccc;
    text-align: center;
    display: table-cell;
    width: 14%;
    vertical-align: middle;
    @include media_desktop{
      width: 8.3333333%;
    }
    .ec-icon {
      img {
        width: 1.5em;
        height: 1.5em;
        @include media_desktop {
          width: 1em;
          height: 1em;
        }
      }
    }
  }
  & &__contentColumn{
    border-bottom: 1px solid #ccc;
    border-left:1px solid #ccc;
    padding: 10px;
    display: table;
    @include media_desktop {
      display: table-cell;
    }
  }
  & &__img{
    display: table-cell;
    width: 40%;
    vertical-align: middle;
    padding-right: 10px;
    img{
      border:1px solid #ccc;
    }
    
    @include media_desktop {
      display: inline-block;
      min-width: 80px;
      max-width: 100px;
      padding-right: 0;
    }
  }
  & &__summary{
    display: table-cell;
    margin-left: 5px;
    font-weight: bold;
    vertical-align: middle;
    width: 46%;
    @include media_desktop {
      display: inline-block;
      width: calc(100% - 130px);
      margin-left: 20px;
      vertical-align: middle;
    }
    .ec-cartRow__name {
      margin-bottom: 5px;
      a{
        text-decoration:underline;
        &:hover{
          text-decoration:none;
        }
      }
    }
    .ec-cartRow__unitPriceSP{
      display: block;
      font-weight: normal;
      @include media_desktop {
        display: none;
      }     
    }
    .ec-cartRow__sutbtotalSP {
      display: block;
      font-weight: normal;
      @include media_desktop {
        display: none;
      }
    }
  }
  & &__amountColumn{
    display: table-cell;
    border-bottom: 1px solid #ccc;
    vertical-align: middle;
    text-align: center;
    width: 20%;
    @include media_desktop {
      width: 16.66666667%;
    }

    .ec-cartRow__amount {
      display: none;
      margin-bottom: 10px;
      @include media_desktop {
        display: block;
      }
    }
    .ec-cartRow__amountSP {
      display: block;
      margin-bottom: 10px;
      @include media_desktop {
        display: none;
      }
    }

    .ec-cartRow__amountUpDown {
      display: flex;
      justify-content: center;
      @include media_desktop {
        display: block;
      }
    }

    .ec-cartRow__amountUpButton {
      margin: 0 2px;
      display: inline-block;
      border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff;


      .ec-cartRow__amountUpButton__icon {
        img {
          display: block;
          margin-left: -0.4em;
          width: .8em;
          height: .8em;
          position: absolute;
          top: 28%;
          left: 50%;
        }
      }
    }
    .ec-cartRow__amountDownButton {
      margin: 0 2px;
      display: inline-block;
      border: 2px solid #c9c9c9;
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff;

      .ec-cartRow__amountDownButton__icon {
        img {
          display: block;
          margin-left: -0.4em;
          width: .8em;
          height: .8em;
          position: absolute;
          top: 28%;
          left: 50%;
        }
      }
    }

    .ec-cartRow__amountDownButtonDisabled {
      @extend .ec-cartRow__amountDownButton;
      cursor: default;
    }
  }
  & &__subtotalColumn{
    display: none;
    border-bottom: 1px solid #ccc;
    text-align: right;
    padding:0 10px;
    width: 16.66666667%;
    @include media_desktop {
      display: table-cell;
    }
  }
}

/*
カート内商品(商品が１の場合)

商品が１の場合はカート商品を減らす「-」ボタンの無効化状態になります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRowOnly

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.5
*/

.ec-cartRow{
  & &__amountColumn{
    .ec-cartRow__amountDownButtonDisabled {
      @extend .ec-cartRow__amountDownButton;
      cursor: default;
    }
  }
}

/*
アラート

カート内の商品に問題があることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartRole
  .ec-cartRole__cart
    +ec-alert-warning

Styleguide 15.1.6
*/

.ec-alert-warning {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #F99;
  margin-bottom: 20px;


  & &__icon {
    display: inline-block;
    margin-right: 1rem;
    width: 20px;
    height: 20px;
    color: #fff;
    fill: #fff;
    vertical-align: top;
  }
  & &__text {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: relative;
  }
}




/*
アラート(空)

カートが空であることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-off3Grid
        .ec-off3Grid__cell
            +ec-alert-warningEnpty

Styleguide 15.1.7
*/
