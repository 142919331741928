@import "../mixins/media";
@import "../mixins/clearfix";
@import "../mixins/projects";
/*
注文内容確認

カート内 注文内容確認に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/shopping)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderRole




Styleguide 15.2
*/
.ec-orderRole{
  @include container;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  @include media_desktop {
    margin-top: 20px;
    flex-direction: row;
  }
  .ec-inlineBtn  {
    font-weight: normal;
    .mypage & {
      font-weight: bold;
    }
  }
  & &__detail{
    padding: 0;
    width: 100%;
    @include media_desktop {
      padding: 0 16px;
      width: 66.66666%;
    }
  }
  & &__summary{
    width: 100%;
    .ec-inlineBtn {
      display: inline-block;
    }
    @include media_desktop {
      width: 33.33333%;
      padding: 0 16px;
      .ec-inlineBtn {
        display: none;
      }
    }
  }
  .ec-borderedList {
    margin-bottom: 20px;
    border-top: 1px dotted #ccc;
    @include media_desktop {
      border-top: none;
    }
  }

}

.ec-orderOrder,.ec-orderConfirm{
  margin-bottom:30px;
}

.link-question{
  a{
    &:hover{
      opacity: 0.8;
    }
    &::before{
      content: "\f059";
      padding: 0 0 0 5px;		
      font-family: 'Font Awesome 5 Free', 'Font Awesome 5 Brands';
      font-weight: 900; 
      color:#525263;
    }
  }

}
/*
注文履歴詳細 オーダ情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderInfo

Styleguide 15.2.1
*/
.ec-orderOrder{
  margin-bottom: 30px;
  & &__items{
    @include borderBottom;
    @include borderTop;
  }
}

/*
注文履歴詳細 お客様情報

マイページ 注文詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAccount

Styleguide 15.2.2
*/
.ec-orderAccount{
  margin-bottom: 30px;
  p {
    margin-bottom: 0;
  }
  @include clearfix;
  & &__change{
    display: inline-block;
    margin-left: 10px;
    float: right;
  }
  & &__account {
    margin-bottom: 16px;
  }

}


/*
注文詳細 配送情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　配送情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderDelivery

Styleguide 15.2.3
*/
.ec-orderDelivery{
  margin-bottom:30px;
  & &__title{
    padding: 16px 0 17px;
    font-weight: bold;
    font-size: 18px;
    position: relative;
  }
  & &__change{
    display: inline-block;
    position: absolute;
    right: 0;
    top:0;
  }
  & &__items{
    @include borderBottom;
    @include borderTop;
  }

  & &__address{
    margin: 10px 0 18px ;
    p{
      margin:0;
    }
  }
  & &__edit{
    margin-bottom:2rem;
  }

}


  h2.shopping-list{
    padding: 5px;
    font-size:1.6rem;
    background: url("../img/common/th_bg.gif") repeat scroll 0 0;
    @include media_desktop {
      font-size:1.8rem;
    }
    &:before{
      content: "\f10c";
      color: #fc6a72;
      font-family: "FontAwesome";
      padding: 0 7px 0 0;
      font-size: 2rem; 
    }
  }


.delivery-table{
  th,td{
    padding: 10px 5px;
    border-collapse:collapse;
    border:1px solid #ccc;
    font-size:14px;
  }
  th{
    background: url(../img/common/th_bg.gif) 0 0 repeat;
    text-align:center;
    font-weight:bold;
  }
  td{
    a{
      color:#f5568b;
    }
    &.goods-img{
      width:15%;
    }
    &.goods-num{
      width: 10%;
    }
    &.goods-name{
      width:45%;
      a{
        text-decoration:underline;
        font-weight:bold;
        &:hover{
          text-decoration: none;
        }
      }
    }
    &.goods-price{
      width: 15%;
    }
    &.goods-subtotal{
      width:15%;
    }

  }
}


#sample-bg{
  padding:5px;
  background:url("../img/common/th_bg_yellow.gif") repeat scroll 0 0;
}
#sample table.delivery-table th{
  background:url("../img/common/th_bg_yellow.gif") repeat scroll 0 0 !important;
}




.payment-box{
  margin:15px 0 15px 15px !important;
  font-size:14px;
  font-size:1.4rem;
  width:100%;
  ul{
    margin-bottom:2rem;
    display:flex;
    flex-wrap:wrap;
    &.credit{
      li{
        width:40px;
        margin-right:5px;
        img{
          width:100%;
        }
        
      }
    }

  }

  .payment-in{
    width:100%;
    p.fig{
      max-width:400px !important;
    }
    p.link-question{
      text-align:right;
      font-size:15px;
      font-size:1.5rem;
    }
  }
}

/*
注文履歴詳細 支払情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　支払情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderPayment
    .ec-rectHeading
      h2 お支払方法
    p 支払方法： 郵便振替

Styleguide 15.2.4
*/
.ec-orderPayment{
  margin-bottom: 30px;
  .t-area{
    h2{
      background: none !important;
      padding:0 !important;
      padding-left: 50px !important;
      margin-top: 0px !important;
      margin-bottom: 0 !important;     
    }
  }
  label{
    span{
      font-weight: bold;
    }
  }
}

/*
注文履歴詳細 お問い合わせ

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　お問い合わせ(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderConfirm
    .ec-rectHeading
      h2 お問い合わせ
    p 記載なし

Styleguide 15.2.5
*/
.ec-orderConfirm{
  margin-bottom: 30px;
  @include media_desktop {
    margin-bottom: 30px;
  }
  .ec-input {
    textarea {
      height: 96px;
    }
  }

}


/*
お届け先の複数指定

お届け先の複数指定に関するコンポーネントを定義します。

ex [マイページ　お届け先の複数指定](http://demo3.ec-cube.net/shopping/shipping_multiple)
(商品購入画面 → 「お届け先を追加する」を押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAddAddress

Styleguide 15.2.6
*/
.ec-AddAddress  {
  padding: 0 10px;
  @include media_desktop {
    margin: 0 10%;
  }

  & &__info {
    margin-bottom: 32px;
    text-align: center;
    font-size: 16px;
  }
  & &__add {
    border-top: 1px solid #f4f4f4;
    padding-top: 20px;
    margin-bottom: 20px;
  }
  & &__item {
    display: table;
    padding:16px;
    background: #f4f4f4;
    margin-bottom: 16px;
  }
  & &__itemThumb {
    display: table-cell;
    min-width: 160px;
    width: 20%;
    img {
      width: 100%;
    }
  }
  & &__itemtContent {
    display: table-cell;
    vertical-align: middle;
    padding-left: 16px;
    font-size:16px;
    a{
      color:#f5568b;
      text-decoration: underline;
      &:hover{
        text-align: none;
      }
    }
  }
  & &__itemtTitle {
    font-weight: bold;
    margin-bottom: 10px;
  }
  & &__itemtSize {
    margin-bottom: 10px;
  }
  & &__itemtPrice {

  }
  & &__itemtNumber {

  }
  & &__select {
    margin-bottom: 5px;
  }
  & &__selectAddress {
    display: block;
    @include media_desktop {
      display: inline-block;
    }
    label {
      font-size: 16px;
      font-weight: normal;
    }
    select {
      min-width: 100%;
      @include media_desktop {
        min-width: 350px;
      }
    }
  }
  & &__selectNumber {
    display: block;
    @include media_desktop {
      display: inline-block;
      margin-left: 30px;
    }
    label {
      font-size: 16px;
      font-weight: normal;
    }
    input {
      display: inline-block;
      margin-left: 10px;
      width: 80px;
    }
  }
  & &__actions {
    @include media_desktop {
      width: 33.3333%;
      min-width: 250px;
      margin: 0 auto;
    }
    .ec-blockBtn--action {
      margin-bottom: 8px;
    }
  }
  & &__new {
    margin-bottom: 20px;
  }
}
