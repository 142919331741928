@charset "UTF-8";
@import url("../../../../../node_modules/normalize.css/normalize.css");
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import "//maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css";
@import url("https://fonts.googleapis.com/css2?family=Cabin+Sketch:wght@400;700&family=Changa+One:ital@0;1&family=Fredericka+the+Great&display=swap");
:root {
  --bs-blue: #337ab7;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #337ab7;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 51, 122, 183;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.42857142;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #337ab7;
  text-decoration: underline;
}
a:hover {
  color: #296292;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d6e4f1;
  --bs-table-striped-bg: #cbd9e5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1cdd9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6d3df;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c1cdd9;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.42857142;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.42857142;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #99bddb;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(51, 122, 183, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.42857142em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.42857142;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.42857142em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.42857142em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.42857142em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.42857142em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.42857142em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.42857142em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.42857142em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.42857142;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #99bddb;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(51, 122, 183, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-check {
  display: block;
  min-height: 1.42857142rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.21428571em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #99bddb;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(51, 122, 183, 0.25);
}
.form-check-input:checked {
  background-color: #337ab7;
  border-color: #337ab7;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #337ab7;
  border-color: #337ab7;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2399bddb'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(51, 122, 183, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(51, 122, 183, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #337ab7;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #c2d7e9;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #337ab7;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #c2d7e9;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.42857142;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.42857142em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.357142855em + 0.1875rem) center;
  background-size: calc(0.71428571em + 0.375rem) calc(0.71428571em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.42857142em + 0.75rem);
  background-position: top calc(0.357142855em + 0.1875rem) right calc(0.357142855em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.71428571em + 0.375rem) calc(0.71428571em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.42857142em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.357142855em + 0.1875rem) center;
  background-size: calc(0.71428571em + 0.375rem) calc(0.71428571em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.42857142em + 0.75rem);
  background-position: top calc(0.357142855em + 0.1875rem) right calc(0.357142855em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.71428571em + 0.375rem) calc(0.71428571em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.42857142;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(51, 122, 183, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}
.btn-primary:hover {
  color: #fff;
  background-color: #2b689c;
  border-color: #296292;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #2b689c;
  border-color: #296292;
  box-shadow: 0 0 0 0.25rem rgba(82, 142, 194, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #296292;
  border-color: #265c89;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(82, 142, 194, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-primary {
  color: #337ab7;
  border-color: #337ab7;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(51, 122, 183, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(51, 122, 183, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #337ab7;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #212529;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #337ab7;
  text-decoration: underline;
}
.btn-link:hover {
  color: #296292;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #337ab7;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #337ab7;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #296292;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #337ab7;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3214285725rem;
  padding-bottom: 0.3214285725rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
.navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #2e6ea5;
  background-color: #ebf2f8;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232e6ea5'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #99bddb;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(51, 122, 183, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #296292;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #296292;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(51, 122, 183, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #1f496e;
  background-color: #d6e4f1;
  border-color: #c2d7e9;
}
.alert-primary .alert-link {
  color: #193a58;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #337ab7;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #1f496e;
  background-color: #d6e4f1;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #1f496e;
  background-color: #c1cdd9;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #1f496e;
  border-color: #1f496e;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(51, 122, 183, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.42857142;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857142;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857142;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.42857142;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #337ab7;
}
.link-primary:hover, .link-primary:focus {
  color: #296292;
}

.link-secondary {
  color: #6c757d;
}
.link-secondary:hover, .link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #198754;
}
.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #212529;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #337ab7 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.42857142 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
html {
  font-size: 62.5%;
  /* 62.5%を指定すると「1.0 rem = 10px」 */
  overflow-x: hidden;
}

body {
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.4;
  color: #333;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  font-size: 16px;
  font-size: 1.6rem;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-weight: bold !important;
}

a {
  text-decoration: none;
}

a {
  /* color: #ff6c00;*/
  color: #f5568b;
  text-decoration: none;
  cursor: pointer;
}

a,
a img {
  transition: all 0.4s ease;
}

textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
  font-size: 16px;
}

a:hover img,
.btn a:hover {
  opacity: 0.7;
}

a:hover,
a:focus,
a:active {
  /*color: #ff6c00;*/
  color: #f5568b;
  text-decoration: none;
  outline: none;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

pre {
  background-color: transparent;
  border: none;
  padding: 16px 0;
}

dl, dt, dd, li {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

address, em {
  font-style: normal;
}

/*基本table*/
table {
  margin: 0 auto 3rem;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e5dacd;
  font-size: 14px;
  font-size: 1.4rem;
}

th, td {
  padding: 10px 5px;
  border-collapse: collapse;
  border: 1px solid #e5dacd;
}

th {
  background: #ececec;
}

th.head-base {
  background: url(../img/common/th_bg.gif) 0 0 repeat;
  width: 20%;
}

th.head2 {
  background: url(../img/common/th_bg_yellow.gif) 0 0 repeat;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.read {
  margin: 0 auto 1rem;
}

.comment {
  margin: 0 auto 0.5rem;
  font-size: 12px;
  font-size: 1.2rem;
}

.comment a {
  color: #333;
  text-decoration: underline;
}

.caution {
  color: #de5d50;
}

/* svg icon */
#svgicon {
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
}

.cb {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
}

.cb-images {
  width: 1.125em;
}

.icon-bg {
  fill: #d4d4d5;
}

.icon-with-bg {
  fill: #fff;
}

.link-question {
  margin: 0 auto 1.5rem;
  text-align: right;
}

.link-question a {
  font-weight: bold;
}

/*?アイコン*/
.question-circle {
  font-size: 11px;
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  vertical-align: middle;
  background: #525263;
  border-radius: 50%;
  fill: #fff;
}

.question-circle .cb-question {
  fill: #fff;
  color: #fff !important;
}

p {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

.cute {
  font-family: "Cabin Sketch", cursive;
  font-weight: 700;
}
.cute span {
  font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
}

.fig {
  margin: 0 auto 1.5rem;
  text-align: center;
}

.fig img {
  width: 100%;
  max-width: 500px;
}

/* dl_table */
.dl_table {
  margin: 0 auto 3rem;
  border-top: 1px dotted #ff6c00;
  font-size: 14px;
  font-size: 1.4rem;
}
.dl_table h2, .dl_table .h2 {
  padding: 5px;
  width: 100% !important;
  font-size: 18px;
  font-size: 1.8rem;
  border-radius: 5px;
  background: #FFF0F0;
}
.dl_table h2:before, .dl_table .h2:before {
  content: "\f10c";
  color: #f5568b;
  font-family: "FontAwesome";
  padding: 0 7px 0 0;
  font-size: 2rem;
}
.dl_table dl {
  padding: 8px 0;
}
.dl_table dl dt {
  background: #FBFDE5;
  font-weight: bold;
  padding: 1rem 0.5rem;
  margin-bottom: 1rem;
  vertical-align: middle;
}
.dl_table dl dt dd {
  padding-left: 0.5rem;
}
.dl_table dl dt dd .form-group {
  padding-top: 8px;
}
.dl_table dl dt dd .form-group:first-child {
  padding-top: 0;
}
.dl_table .zip-search {
  margin-top: 8px;
}

/*flow-box*/
.flow-box * {
  margin: 0;
  padding: 0;
}

.flow-box {
  margin: 0 auto;
  width: 98%;
  background: #efefef;
}
.flow-box dl {
  margin: 0 auto !important;
  /*padding:25px;*/
  padding: 15px;
}
.flow-box dl dt {
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-weight: bold;
  color: #40bdc1;
  font-size: 18px;
  font-size: 1.8rem;
  border-bottom: 3px solid #40bdc1;
}
.flow-box dl dt span {
  display: block;
  font-size: 45px;
  font-size: 4.5rem;
  height: 50px;
}
.flow-box dl dd p {
  margin-bottom: 1rem;
}
.flow-box dl dd p.fig {
  text-align: center;
}
.flow-box dl dd p.fig img {
  border: 1px solid #ccc;
}
.flow-box dl dd em {
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  display: block;
  background: #de5d50;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
}
.flow-box dl dd .print-data {
  margin: 0 auto 2rem;
  border-radius: 15px;
  border: 3px solid #40bdc1;
  background: #fff;
}
.flow-box dl dd .print-data h3, .flow-box dl dd .print-data .h3 {
  margin-bottom: 15px;
  padding: 5px 0;
  border-radius: 10px 10px 0 0;
  text-align: center;
  background: #40bdc1;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem;
}
.flow-box dl dd .print-data .data-in {
  padding: 0 10px 10px;
  font-size: 13px;
  font-size: 1.3rem;
}
.flow-box dl dd .print-data .data-in img {
  border: none;
}
.flow-box dl dd .flow-detail h3, .flow-box dl dd .flow-detail .h3 {
  margin-bottom: 0.5rem;
  color: #ff6c00;
  font-size: 15px;
  font-size: 1.5rem;
}
.flow-box dl dd .flow-detail p {
  margin-left: 15px;
  font-size: 16px;
  font-size: 1.6rem;
}

.flow-box dl:nth-child(1) {
  background: #fbfbf6;
}

.flow-box dl:nth-child(2n) {
  background: #fbfbf6;
}

.flow-box dl:nth-child(2n+1) {
  background: #fff5f9;
}

/* btn */
.btn {
  margin: 0 auto;
  padding: 0;
  text-align: center;
  padding: 10px 16px;
  font-weight: bold;
  overflow: hidden;
  zoom: 1;
}
.btn a {
  border-radius: 10px;
  color: #f5568b !important;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  padding: 7px 20px;
  text-decoration: none;
}
.btn a::before {
  content: "\f0da";
  font-family: "FontAwesome";
  padding: 0 8px;
  color: #f5568b;
}
.btn a:hover {
  background: #fff;
}
.btn a:active {
  background: none;
  border: none;
  box-shadow: none;
}

/* btn+green */
.btn-green {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: bold;
}
.btn-green a {
  border-radius: 10px;
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  padding: 20px 35px !important;
  text-decoration: none;
  background: #34babe;
}
.btn-green a::before {
  content: "\f0da";
  font-family: "FontAwesome";
  padding: 0 8px;
  color: #fff;
}
.btn-green a:hover {
  background: #66cdd0;
}

/* btn:fax; */
.btn-fax {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}
.btn-fax a {
  border-radius: 10px;
  background: #f5568b;
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  padding: 20px 35px !important;
  text-decoration: none;
}
.btn-fax a::before {
  content: "\f1c1";
  font-family: "FontAwesome";
  padding: 0 8px;
  color: #fff;
}
.btn-fax a:hover {
  background: #f490b2;
}

/*送料変更のお知らせ*/
.postage {
  margin: 2em auto 3em;
  width: 98%;
  position: relative;
}
.postage dl {
  border: 8px solid #f5568b;
  border-radius: 10px;
  background: #FFF;
}
.postage dl dt {
  position: relative;
  padding: 0.5em 0.5em 0.5em 80px;
  background: #f5568b;
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  text-align: left;
}
.postage dl dt p.fig {
  position: absolute;
  left: 5px;
  top: -10px;
  width: 80px;
}
.postage dl dt p.fig img {
  width: 100%;
}
.postage dl dd {
  padding: 1em;
}

/* common_add */
.common-add {
  position: relative;
  margin: 0 auto 2rem;
  padding: 15px !important;
  background: #fff0f0;
  border-radius: 15px;
}
.common-add .common-top, .common-add .common-bottom {
  display: none;
}
.common-add h2, .common-add .h2 {
  margin: 0 auto 1rem;
  color: #f5568b;
  text-align: center;
  font-size: 14px;
  font-size: 1.4rem;
}
.common-add ul {
  margin: 0 auto;
  width: 100%;
}
.common-add ul li {
  margin-bottom: 10px;
  font-size: 1.4rem;
  font-weight: bold;
  vertical-align: top;
}
.common-add ul li em {
  margin: 0 3px !important;
  padding: 3px !important;
  background: #f5568b !important;
  color: #fff;
  font-size: 1rem;
  border-radius: 0;
  color: #fff;
  display: inline !important;
  font-weight: bold;
}
.common-add ul li span {
  display: block;
  font-size: 10px;
  font-size: 1rem;
  text-align: left;
}
.common-add ul li a {
  color: #333;
  text-decoration: underline;
}
.common-add ul li a:hover {
  text-decoration: none;
}
.common-add .row, .common-add .col-md-6 {
  padding: 0 !important;
}

/*notfound*/
#nofound {
  background: url(../img/common/stripe_bg.gif) 0 0 repeat;
}
#nofound #main {
  margin: 5em auto;
  max-width: 640px;
  text-align: center;
}
#nofound #main .notfound-box p.txt {
  margin: 0 auto 2em;
  width: 90%;
  text-align: left;
}
#nofound #main .notfound-box p.btn {
  max-width: 300px;
}

#nofound #contents {
  background: none;
}

#inner {
  margin: 10rem auto 2rem;
}

#inner p {
  margin: 0 auto 1.5rem;
  text-align: center;
}

.notfound-box h2, .notfound-box .h2 {
  color: #F5568B;
}

/****** Effect Setting ********/
/* Overlay */
.overlay {
  /* shadow layer visible when navigation is active */
  position: fixed;
  z-index: 2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s, -webkit-transform 0.3s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s, -moz-transform 0.3s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.3s 0s;
}

.overlay.is-visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s, -webkit-transform 0.3s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s, -moz-transform 0.3s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s, transform 0.3s 0s;
}

/* modal */
.modal-header .close {
  font-size: 22px;
  font-size: 2.2rem;
}

.modal-content {
  background-color: #F3F3F3;
}

.modal-body .form-control {
  width: 100%;
}

.modal-body .quantity {
  width: 50%;
  float: right;
  text-align: right;
  padding: 16px 0;
  font-size: 16px;
  font-size: 1.6rem;
}

.modal-body .quantity dd input {
  text-align: right;
}

.modal-body .classcategory_list li {
  margin-top: 16px;
}

.modal-body .classcategory_list li:first-child {
  margin-top: 0;
}

.modal-body .item_price {
  margin: 0;
  clear: both;
  text-align: right;
  font-size: 16px;
  font-size: 1.6rem;
}

.modal-body .item_price .small {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: normal;
  margin-left: 5px;
}

.modal-footer {
  padding: 16px 0;
  margin: 0 16px;
}

.modal-footer .btn_area {
  padding-bottom: 0;
}

.modal-footer .btn_area li:last-child {
  padding-left: 0;
}

.modal-footer .btn_area button {
  font-size: 16px;
  font-size: 1.6rem;
  height: 56px;
  line-height: 56px;
  vertical-align: middle;
  padding-top: 0;
  padding-bottom: 0;
}

.modal-footer .btn_area .btn_icon .cb {
  display: inline-block;
  font-size: 30px;
  font-size: 3rem;
  height: 54px;
  line-height: 54px;
  vertical-align: middle;
  position: relative;
  top: -2px;
}

/*animatioln*/
/*bounce*/
@keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

/**
 * $keyframes \ gelatine 
 **/
@keyframes gelatine {
  from, to {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.9, 1.1);
    transform: scale(0.9, 1.1);
  }
  50% {
    -webkit-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }
  75% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }
  from, to {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  25% {
    -webkit-transform: scale(0.9, 1.1);
    transform: scale(0.9, 1.1);
  }
  50% {
    -webkit-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }
  75% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }
}
.pickup_item:hover, #checkeditem:hover {
  animation: gelatine 1s;
  /*    animation: bounce 1s;*/
  animation-iteration-count: 1;
  /*アニメーションの繰り返し回数*/
  -webkit-animation: bounce 1s;
  /*Safari & Chrome */
  -webkit-animation-iteration-count: 1;
  /*Safari & Chrome */
}

.modal-body {
  margin-top: 15px;
}

#item_photo_area .wysiwyg {
  margin: 0 auto 3rem;
  font-size: 0.9em;
}

#item_photo_area .wysiwyg ol li {
  margin-left: 15px;
  list-style-type: decimal;
}

#item_photo_area .wysiwyg ul li {
  margin-left: 15px;
  list-style-type: disc;
}

#item_photo_area .wysiwyg em {
  font-style: oblique;
}

/* pagetop
------------------------------------------------*/
@keyframes vertical {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.pagetop {
  animation: vertical 2s ease-in-out infinite alternate;
  -webkit-animation: vertical 2s ease-in-out infinite alternate;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
見出し

ページ内で見出しとして機能する要素のスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.1
*/
/*
見出し

商品紹介等で利用される、一般的な見出しのスタイルです。

ex [商品詳細ページ　商品見出し部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-headingTitle マトリョーシカ

Styleguide 1.1.1
*/
.ec-headingTitle {
  margin: 0 0 8px;
  font-size: 32px;
  font-weight: normal;
  color: #525263;
}

/*
ページヘッダ

各種ページで用いられるページヘッダのデザインです。

ex [利用規約ページ　ページヘッダ部](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-pageHeader
  h1 利用規約

Styleguide 1.1.2
*/
.ec-pageHeader h1, .ec-pageHeader .h1 {
  margin: 0 0 3rem;
  padding: 8px 0 12px;
  font-size: 16px;
  font-weight: bold;
  border-top: none;
  background: url(../img/common/t_line.gif) 0 bottom repeat-x;
}
.ec-pageHeader h1 span, .ec-pageHeader .h1 span {
  display: block;
  text-align: center;
  width: 90px;
  vertical-align: middle;
  margin: 0;
  font-size: 14px;
  font-size: 1.4rem;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: normal;
  background: #f5568b;
  color: #fff;
  padding: 8px;
  padding: 2px 5px;
  border-radius: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-pageHeader h1, .ec-pageHeader .h1 {
    margin: 20px auto 3rem;
    padding: 16px 10px 12px;
    text-align: center;
    font-size: 30px;
    font-size: 3rem;
  }
  .ec-pageHeader h1 span, .ec-pageHeader .h1 span {
    display: inline-block;
    text-align: center;
    width: auto;
    vertical-align: middle;
    margin: 0 15px 0 0;
    font-size: 14px;
    font-size: 1.4rem;
    padding: 8px;
  }
}

.page-heading {
  margin: 0 0 3rem;
  padding: 8px 0 12px;
  font-size: 16px;
  font-weight: bold;
  border-top: none;
  background: url(../img/common/t_line.gif) 0 bottom repeat-x;
}
.page-heading span {
  display: block;
  text-align: center;
  width: 90px;
  vertical-align: middle;
  margin: 0;
  font-size: 14px;
  font-size: 1.4rem;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: normal;
  background: #f5568b;
  color: #fff;
  padding: 8px;
  padding: 2px 5px;
  border-radius: 10px;
}
@media only screen and (min-width: 768px) {
  .page-heading {
    margin: 20px auto 24px;
    padding: 16px 10px 12px;
    text-align: center;
    font-size: 30px;
    font-size: 3rem;
  }
  .page-heading span {
    display: inline-block;
    text-align: center;
    width: auto;
    vertical-align: middle;
    margin: 0 15px 0 0;
    font-size: 14px;
    font-size: 1.4rem;
    padding: 8px;
  }
}

/* heading */
h2, .h2 {
  font-size: 24px;
  font-size: 2.4rem;
}

.heading01 {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}

.heading02,
.heading03 {
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
}

.heading04 {
  clear: both;
  margin-bottom: 5px;
}

.heading05 {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}

@media only screen and (min-width: 768px) {
  .heading01,
.heading03 {
    font-size: 24px;
    font-size: 2.4rem;
    font-weight: bold;
  }

  .heading02 {
    font-size: 24px;
    font-size: 2.4rem;
    font-weight: bold;
  }

  .heading05 {
    font-size: 16px;
    font-size: 1.6rem;
  }
}
#main {
  margin: 0 auto 3rem;
}

/*
サブ見出し

利用規約など、文字主体のページで用いられるサブ見出しです。

ex [利用規約ページ サブ見出し部分](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-heading 第1条 (会員)

Styleguide 1.1.3
*/
.ec-heading {
  margin: 24px 0;
}

/*
サブ見出し(太字)

文字主体のページで用いられるサブ見出しの太字のスタイルです。

ex [プライバシーポリシー サブ見出し部分](http://demo3.ec-cube.net/help/privacy)

Markup:
.ec-heading-bold 個人情報の定義

Styleguide 1.1.4
*/
.ec-heading-bold {
  margin: 16px 0;
  font-size: 16px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-heading-bold {
    font-size: 18px;
  }
}

/*
背景付き見出し

マイページ注文履歴等で用いられる背景付きの見出しです。

ex [ご注文履歴詳細　背景付き見出し部分](http://demo3.ec-cube.net/mypage/history/1063)

Markup:
.ec-rectHeading
  h2 配送情報
.ec-rectHeading
  h2 お支払について

Styleguide 1.1.5
*/
.ec-rectHeading h1, .ec-rectHeading .h1, .ec-rectHeading h2, .ec-rectHeading .h2, .ec-rectHeading h3, .ec-rectHeading .h3,
.ec-rectHeading h4,
.ec-rectHeading .h4, .ec-rectHeading h5, .ec-rectHeading .h5, .ec-rectHeading h6, .ec-rectHeading .h6 {
  background: #F3F3F3;
  padding: 8px 12px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

h2, .h2 {
  font-size: 16px;
  font-size: 1.6rem;
}

/*
メッセージ見出し

ユーザが行った操作に対する、完了報告やエラー表示のページで使用される見出しのスタイルです。

ex [注文完了 ログイン後、カートに商品を入れ注文完了まで行う](http://demo3.ec-cube.net/shopping/)

Markup:
.ec-reportHeading
  h2 ご注文ありがとうございました

Styleguide 1.1.6
*/
.ec-reportHeading {
  width: 100%;
  border-top: 1px dotted #ccc;
  margin: 20px 0 30px;
  padding: 0;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-reportHeading {
    border-top: 0;
    font-size: 32px;
  }
}
.ec-reportHeading h1, .ec-reportHeading .h1, .ec-reportHeading h2, .ec-reportHeading .h2, .ec-reportHeading h3, .ec-reportHeading .h3,
.ec-reportHeading h4,
.ec-reportHeading .h4, .ec-reportHeading h5, .ec-reportHeading .h5, .ec-reportHeading h6, .ec-reportHeading .h6, .ec-reportHeading p {
  font-weight: bold;
  font-size: 24px;
}
@media only screen and (min-width: 768px) {
  .ec-reportHeading h1, .ec-reportHeading .h1, .ec-reportHeading h2, .ec-reportHeading .h2, .ec-reportHeading h3, .ec-reportHeading .h3,
.ec-reportHeading h4,
.ec-reportHeading .h4, .ec-reportHeading h5, .ec-reportHeading .h5, .ec-reportHeading h6, .ec-reportHeading .h6, .ec-reportHeading p {
    font-size: 32px;
  }
}

/* ピンクballoon */
div.balloon {
  position: relative;
  margin: 0 auto 3rem;
  background: #f5568b;
  font-weight: bold;
  color: #fff;
  width: 260px;
  height: 60px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

div.balloon:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 120px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top: 10px solid #f5568b;
}

div.balloon h2, div.balloon .h2 {
  padding-top: 3px;
  text-align: center;
  font-size: 3.5rem;
  line-height: 3rem;
}

div.balloon span {
  display: block;
  font-size: 1.3em;
  font-size: 1.3rem;
}

/*共通タイトル*/
.t-area * {
  margin: 0;
  padding: 0;
}

.t-area:before, .t-area:after {
  content: " ";
  display: table;
}

.t-area:after {
  clear: both;
}

.t-area {
  margin: 0 auto 2rem;
  padding: 7px 0;
  position: relative;
  background: #ffe8e8;
}
.t-area p.icon {
  position: absolute;
  top: -5px;
  left: 10px;
  width: 30px;
}
.t-area h2, .t-area .h2 {
  float: left;
  padding-left: 50px;
  font-size: 1.8px;
  font-size: 1.8rem;
  font-weight: bold;
}

/*背景がリボンの見出し*/
h1.ribbon-heading, .ribbon-heading.h1 {
  margin: 1.5rem auto;
  text-align: center;
  background: url(../img/sample/fig_ribbon.png) 50% 0 no-repeat;
  background-size: 100%;
  color: #fff;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 10px 0 0;
  height: 70px;
  max-width: 324px;
}
@media only screen and (min-width: 768px) {
  h1.ribbon-heading, .ribbon-heading.h1 {
    margin: 1.5rem auto 2rem !important;
    text-align: center;
    background: url(../img/sample/fig_ribbon.png) 50% 0 no-repeat;
    background-size: 100%;
    color: #fff;
    font-size: 24px !important;
    font-size: 2.4rem !important;
    padding: 15px 0 0 !important;
    width: 518px;
    height: 112px !important;
    max-width: 518px !important;
  }
}

/*共通タイトル●*/
.sub-area * {
  margin: 0;
  padding: 0;
}

.sub-area {
  margin: 0 auto 2rem;
  padding: 7px 0;
  border-bottom: 3px solid #f5568b;
}
.sub-area h3, .sub-area .h3 {
  font-size: 1.9px;
  font-size: 1.9rem;
  font-weight: bold;
}
.sub-area h3::before, .sub-area .h3::before {
  color: #f5568b;
  content: "\f10c";
  font-family: "FontAwesome";
  font-size: 2rem;
  padding: 0 7px 0 0;
}

/* topicpath */
#common-topicpath * {
  margin: 0;
  padding: 0;
}

#common-topicpath {
  padding: 10px;
  background: url(../img/common/path_bg.gif) 0 0 repeat;
  font-size: 11px;
  font-size: 1.1rem;
}
#common-topicpath .path-in {
  margin: 0 auto;
  overflow: hidden;
  zoom: 1;
}
@media only screen and (min-width: 768px) {
  #common-topicpath .path-in {
    width: 1060px;
  }
}
#common-topicpath .path-in p.back-list a {
  position: relative;
  float: left;
  display: inline-block;
  margin-right: 10px;
  margin-left: 20px;
  padding: 0 10px 0 5px;
  background: #f5568b;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: 11px;
  color: #fff;
  line-height: 16px;
}
#common-topicpath .path-in p.back-list a::before {
  content: "";
  position: absolute;
  top: 0;
  left: -8px;
  border-right: 8px solid #f5568b;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  transform-origin: right top;
  transform: scaleX(0.7);
}
#common-topicpath .path-in p.back-list a:hover {
  color: #ffb6b6;
}
#common-topicpath .path-in .topicpath a {
  color: #4e290e;
}
#common-topicpath .path-in .topicpath a:hover {
  color: #f5568b;
}
#common-topicpath .path-in .topicpath a span.home::before {
  content: "\f015";
  color: #4e290e;
  font-family: "FontAwesome";
  padding: 0 7px 0 0;
  font-size: 1.2rem;
}

/*ページ内リンク*/
.sublink * {
  margin: 0;
  padding: 0;
}

.sublink {
  margin: 0 auto 3rem;
}
@media only screen and (min-width: 768px) {
  .sublink {
    display: flex;
    justify-content: center;
  }
}
.sublink li {
  letter-spacing: normal;
  display: block;
  text-align: center;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .sublink li {
    width: auto;
    display: inline-block;
    margin: 0 2px;
  }
}
.sublink li a {
  margin: 0 auto;
  display: block;
  background: #FF6C00;
  color: #fff;
  padding: 10px;
  border-radius: none;
  border-bottom: 2px solid #fff;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .sublink li a {
    border-radius: 8px;
    width: auto;
    display: inline-block;
  }
}
.sublink li a::before {
  content: "\f063";
  color: #fff;
  font-family: "FontAwesome";
  padding: 0 7px 0 0;
  font-size: 1.5rem;
}
.sublink li a:hover {
  background: #ffb57e;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
文字装飾

文字装飾をするためのスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.2
*/
/*
テキストリンク

テキストリンクのスタイルです。

Markup:
a(href="#").ec-link さくらのクラウド

Styleguide 1.2.1
*/
.ec-link {
  color: #f5568b;
  text-decoration: none;
  cursor: pointer;
}
.ec-link:hover {
  color: #f5568b;
  text-decoration: none;
  opacity: 0.8;
}

/*
テキスト（太字）

テキストを太くするためのスタイルです。

Markup:
p.ec-font-bold この季節にぴったりな商品をご用意しました

Styleguide 1.2.2
*/
.ec-font-bold {
  font-weight: bold;
}

/*
テキスト（グレー）

テキストをグレーにするためのスタイルです。

Markup:
p.ec-color-grey 青色が美しい職人が仕上げた吹きガラス

Styleguide 1.2.3
*/
.ec-color-grey {
  color: #9a947e;
}

/*
テキスト（赤）

テキストを赤にするためのスタイルです。

Markup:
p.ec-color-red ¥ 2,728 税込
p.ec-color-accent ¥ 2,728 税込

Styleguide 1.2.4
*/
.ec-color-red {
  color: #DE5D50;
}

.ec-color-accent {
  color: #DE5D50;
}

/*
フォントサイズ

フォントサイズを指定するためのスタイルです。

Markup:
.ec-font-size-1 さわやかな日差しが過ごしやすい季節
.ec-font-size-2 さわやかな日差しが過ごしやすい季節
.ec-font-size-3 さわやかな日差しが過ごしやすい季節
.ec-font-size-4 さわやかな日差しが過ごしやすい季節
.ec-font-size-5 さわやかな日差しが過ごしやすい季節
.ec-font-size-6 さわやかな日差しが過ごしやすい季節


Styleguide 1.2.5
*/
.ec-font-size-1 {
  font-size: 12px;
}

.ec-font-size-2 {
  font-size: 14px;
}

.ec-font-size-3 {
  font-size: 16px;
}

.ec-font-size-4 {
  font-size: 20px;
}

.ec-font-size-5 {
  font-size: 32px;
}

.ec-font-size-6 {
  font-size: 40px;
}

/*
テキスト水平位置

テキストをセンタリングするためのスタイルです。

Markup:
p.ec-text-ac さわやかな日差しが過ごしやすい季節

Styleguide 1.2.6
*/
.ec-text-ac {
  text-align: center;
}

/*
価格テキスト

価格を表示するテキストです。

価格文字にスペースを取るほか、税込み等の表示を小さくする効果もあります。

spanを用いたインライン要素として利用します。

Markup:
div(style="color:#DE5D50;font-size:28px")
    span.ec-price
      span.ec-price__unit ¥
      span.ec-price__price 1,280
      span.ec-price__tax 税込

Styleguide 1.2.7
*/
.ec-price .ec-price__unit {
  font-size: 18px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-price .ec-price__unit {
    font-size: 1em;
  }
}
.ec-price .ec-price__price {
  display: inline-block;
  padding: 0 0.3em;
  font-size: 18px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-price .ec-price__price {
    font-size: 1em;
  }
}
.ec-price .ec-price__tax {
  font-size: 12px;
}
@media only screen and (min-width: 768px) {
  .ec-price .ec-price__tax {
    font-size: 0.57em;
  }
}

/*
テキストの位置

テキストや、入れ子にしたインライン要素を
「左揃え」「中央揃え」「右揃え」に設定することができます。

Markup:
h3 左揃え
p.text-left
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?
br
h3 中央揃え
p.text-center
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?
br
h3 右揃え
p.text-right
  | Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt praesentium repellat sapiente suscipit, unde veniam! Doloribus error, expedita id impedit iusto qui sint totam? Aspernatur error facere possimus quam quos?

Styleguide 1.2.8
*/
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/*
メッセージテキスト

ユーザが行った操作に対する、完了報告やエラー表示のページで使用されるテキストのスタイルです。

ex [注文完了 （ログイン後、カートに商品を入れ注文完了まで行う）](http://demo3.ec-cube.net/shopping/)

Markup:
.ec-reportHeading
  h2 ご注文ありがとうございました
p.ec-reportDescription
      | ただいま、ご注文の確認メールをお送りさせていただきました。
      br
      | 万一、ご確認メールが届かない場合は、トラブルの可能性もありますので大変お手数ではございますがもう一度お問い合わせいただくか、お電話にてお問い合わせくださいませ。
      br
      | 今後ともご愛顧賜りますようよろしくお願い申し上げます。


Styleguide 1.2.9
*/
.ec-reportDescription {
  margin-bottom: 32px;
  text-align: left;
  font-size: 16px;
  line-height: 1.4;
}
@media only screen and (min-width: 768px) {
  .ec-reportDescription {
    text-align: center;
  }
}

/*
テキスト下部のスペース

テキストの下に余白を追加することができます。 .ec-para-normalで16pxの余白をつけることができます。

Markup:
p.ec-para-normal 万一、ご確認メールが届かない場合は、トラブルの可能性もありますので大変お手数ではございますがもう一度お問い合わせいただくか、お電話にてお問い合わせくださいませ。
p.ec-para-normal 万一、ご確認メールが届かない場合は、トラブルの可能性もありますので大変お手数ではございますがもう一度お問い合わせいただくか、お電話にてお問い合わせくださいませ。

Styleguide 1.2.10
*/
.ec-para-normal {
  margin-bottom: 16px;
}
.ec-para-normal span {
  color: #f5568b;
  font-weight: bold;
}

h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 500;
}

/*thanks*/
.complete_message {
  text-align: center;
}
.complete_message .heading01 {
  font-size: 24px;
  font-size: 2.4rem;
}
.complete_message .thanks-credit h3, .complete_message .thanks-credit .h3 {
  color: #f5568b;
  font-size: 24px;
  font-size: 2.4rem;
}
.complete_message .thanks-credit dl {
  margin: 0 auto 2rem;
  width: 60%;
  padding: 0;
  border: 3px solid #de5d50;
  font-size: 17px;
  font-size: 1.7rem;
}
.complete_message .thanks-credit dl dt {
  padding: 4px 10px;
  background: #de5d50;
  color: #fff;
}
.complete_message .thanks-credit dl dd {
  padding: 4px 10px;
  color: #de5d50;
  font-weight: bold;
}
.complete_message .thanks-credit dl.pay-txt dt {
  padding-bottom: 0.5rem;
  font-size: 24px;
  font-size: 2.4rem;
  text-align: center;
  font-weight: bold;
  color: #de5d50;
}
.complete_message .thanks-credit dl.pay-txt dt strong {
  margin: 5px;
  padding: 3px;
  background: #de5d50;
  color: #fff;
}
.complete_message .thanks-credit dl.pay-txt dt dd {
  padding: 15px;
  font-size: 14px;
  font-size: 1.4rem;
  text-align: left;
}
.complete_message .thanks-credit dl.pay-txt dt dd p {
  margin: 0 0 10px;
  padding: 0;
}
.complete_message .thanks-credit dl.pay-txt dt dd span {
  color: #de5d50;
}
.complete_message .thanks-jnb {
  margin: 0 auto 2rem;
}
.complete_message .thanks-jnb h3, .complete_message .thanks-jnb .h3 {
  font-size: 24px;
  font-size: 2.4rem;
}
.complete_message .thanks-jnb h3 strong, .complete_message .thanks-jnb .h3 strong {
  color: #003894;
}
.complete_message p.jnb-furikomi {
  margin: 8px auto 2rem;
  padding: 5px;
  background: #003894;
  color: #fff;
  font-weight: bold;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
リスト

シンプルなリストを構成するためのスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.3
*/
/*
水平定義リスト

シンプルな定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　水平定義リスト部分](http://demo3.ec-cube.net/help/about)

Markup:
dl.ec-definitions
    dt 店名
    dd EC-CUBE3 DEMO SHOP
dl.ec-definitions
    dt 会社名
    dd EC-CUBE3
dl.ec-definitions--soft
    dt 所在地
    dd 〒 550-0001

Styleguide 1.3.1
*/
.ec-definitions, .ec-definitions--soft {
  margin: 5px 0;
  display: block;
}
.ec-definitions dt, .ec-definitions--soft dt, .ec-definitions dd, .ec-definitions--soft dd {
  display: inline-block;
  margin: 0;
}
.ec-definitions dt, .ec-definitions--soft dt {
  font-weight: bold;
}

.ec-definitions--soft dt {
  font-weight: normal;
}

/*
下線つき定義リスト

線が添えられた定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

ex [当サイトについて　下線つき定義リスト](http://demo3.ec-cube.net/help/about)

Markup:
.ec-borderedDefs
  dl
    dt 店名
    dd EC-CUBE3 DEMO SHOP
  dl
    dt 会社名
    dd EC-CUBE3
  dl
    dt 所在地
    dd 〒550 - 0001

Styleguide 1.3.2
*/
.ec-borderedDefs {
  width: 100%;
  border-top: 1px dotted #ccc;
  margin-bottom: 16px;
}
.ec-borderedDefs dl {
  display: flex;
  border-bottom: 1px dotted #ccc;
  margin: 0;
  padding: 10px 0 0;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  .ec-borderedDefs dl {
    flex-wrap: nowrap;
    padding: 15px 0 4px;
  }
}
.ec-borderedDefs dt, .ec-borderedDefs dd {
  padding: 0;
}
.ec-borderedDefs dt {
  font-weight: normal;
  width: 100%;
  padding-top: 0;
}
@media only screen and (min-width: 768px) {
  .ec-borderedDefs dt {
    padding-top: 14px;
    width: 30%;
  }
}
.ec-borderedDefs dd {
  padding: 0;
  width: 100%;
  line-height: 2.5;
}
@media only screen and (min-width: 768px) {
  .ec-borderedDefs dd {
    width: 70%;
    line-height: 3;
  }
}
.ec-borderedDefs p {
  line-height: 1.4;
}

.ec-list-chilled {
  display: table-row;
  border: 0 none;
  padding: 8px 0;
}
.ec-list-chilled dt, .ec-list-chilled dd {
  display: table-cell;
  border-bottom: 1px dotted #ccc;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .ec-list-chilled dt, .ec-list-chilled dd {
    padding: 16px 0;
  }
}
.ec-list-chilled dt {
  width: 30%;
}
.ec-list-chilled dd {
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .ec-list-chilled dd {
    padding: 16px;
  }
}

/*
ボーダーリスト

線が添えられたリストを表示します。

ex [当サイトについて　ボーダーリスト](http://demo3.ec-cube.net/help/about)

Markup:
ul.ec-borderedList
  li: p lorem
  li: p lorem
  li: p lorem


Styleguide 1.3.3
*/
.ec-borderedList {
  width: 100%;
  border-top: 0;
  list-style: none;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .ec-borderedList {
    border-top: 1px dotted #ccc;
  }
}
.ec-borderedList li {
  border-bottom: 1px dotted #ccc;
}

.ec-list-chilled {
  display: table-row;
  border: 0 none;
  padding: 8px 0;
}
.ec-list-chilled dt, .ec-list-chilled dd {
  display: table-cell;
  border-bottom: 1px dotted #ccc;
  padding: 16px 0;
}
.ec-list-chilled dt {
  width: 30%;
}
.ec-list-chilled dd {
  padding: 16px;
}

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
ボタンサイズ

ボタンサイズを変更するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.1
*/
/*
通常ボタン

インラインの要素としてボタンを定義出来ます。

ex [トップページ　ボタン部分](http://demo3.ec-cube.net/)

Markup:
.ec-inlineBtn 住所検索
.ec-inlineBtn--primary もっと見る
.ec-inlineBtn--action カートに入れる
.ec-inlineBtn--cancel キャンセル

Styleguide 2.1.1
*/
.ec-inlineBtn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 1.42857142;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #4e5a7d;
  background-color: #F5F7F8;
  border-color: #4e5a7d;
  background: #F5568B;
  color: #fff;
  font-size: 12px;
  border-radius: 10px;
  border: none;
  transition: all 0.4;
}
.ec-inlineBtn:focus, .ec-inlineBtn.focus, .ec-inlineBtn:active:focus, .ec-inlineBtn:active.focus, .ec-inlineBtn.active:focus, .ec-inlineBtn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-inlineBtn:hover, .ec-inlineBtn:focus, .ec-inlineBtn.focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.8;
}
.ec-inlineBtn:active, .ec-inlineBtn.active {
  outline: 0;
  background-image: none;
}
.ec-inlineBtn.disabled, .ec-inlineBtn[disabled], fieldset[disabled] .ec-inlineBtn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
}
.ec-inlineBtn:hover {
  color: #000;
  background-color: #f7f8f9;
  border-color: #606b8a;
}
.btn-check:focus + .ec-inlineBtn, .ec-inlineBtn:focus {
  color: #000;
  background-color: #f7f8f9;
  border-color: #606b8a;
  box-shadow: 0 0 0 0.25rem rgba(78, 90, 125, 0.5);
}
.btn-check:checked + .ec-inlineBtn, .btn-check:active + .ec-inlineBtn, .ec-inlineBtn:active, .ec-inlineBtn.active, .show > .ec-inlineBtn.dropdown-toggle {
  color: #000;
  background-color: #f7f9f9;
  border-color: #606b8a;
}
.btn-check:checked + .ec-inlineBtn:focus, .btn-check:active + .ec-inlineBtn:focus, .ec-inlineBtn:active:focus, .ec-inlineBtn.active:focus, .show > .ec-inlineBtn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(78, 90, 125, 0.5);
}
.ec-inlineBtn:disabled, .ec-inlineBtn.disabled {
  color: #4e5a7d;
  background-color: #F5F7F8;
  border-color: #4e5a7d;
}
.ec-inlineBtn .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}
.ec-inlineBtn:hover {
  color: #fff;
  background: #f4a6c0;
}

.ec-inlineBtn--primary {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 1.42857142;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #5CB1B1;
  border-color: #5CB1B1;
}
.ec-inlineBtn--primary:focus, .ec-inlineBtn--primary.focus, .ec-inlineBtn--primary:active:focus, .ec-inlineBtn--primary:active.focus, .ec-inlineBtn--primary.active:focus, .ec-inlineBtn--primary.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-inlineBtn--primary:hover, .ec-inlineBtn--primary:focus, .ec-inlineBtn--primary.focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.8;
}
.ec-inlineBtn--primary:active, .ec-inlineBtn--primary.active {
  outline: 0;
  background-image: none;
}
.ec-inlineBtn--primary.disabled, .ec-inlineBtn--primary[disabled], fieldset[disabled] .ec-inlineBtn--primary {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
}
.ec-inlineBtn--primary:hover {
  color: #000;
  background-color: #4e9696;
  border-color: #4a8e8e;
}
.btn-check:focus + .ec-inlineBtn--primary, .ec-inlineBtn--primary:focus {
  color: #000;
  background-color: #4e9696;
  border-color: #4a8e8e;
  box-shadow: 0 0 0 0.25rem rgba(116, 189, 189, 0.5);
}
.btn-check:checked + .ec-inlineBtn--primary, .btn-check:active + .ec-inlineBtn--primary, .ec-inlineBtn--primary:active, .ec-inlineBtn--primary.active, .show > .ec-inlineBtn--primary.dropdown-toggle {
  color: #000;
  background-color: #4a8e8e;
  border-color: #458585;
}
.btn-check:checked + .ec-inlineBtn--primary:focus, .btn-check:active + .ec-inlineBtn--primary:focus, .ec-inlineBtn--primary:active:focus, .ec-inlineBtn--primary.active:focus, .show > .ec-inlineBtn--primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(116, 189, 189, 0.5);
}
.ec-inlineBtn--primary:disabled, .ec-inlineBtn--primary.disabled {
  color: #fff;
  background-color: #5CB1B1;
  border-color: #5CB1B1;
}
.ec-inlineBtn--primary .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}

.ec-inlineBtn--action {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 1.42857142;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #f5568b;
  border-color: #f5568b;
}
.ec-inlineBtn--action:focus, .ec-inlineBtn--action.focus, .ec-inlineBtn--action:active:focus, .ec-inlineBtn--action:active.focus, .ec-inlineBtn--action.active:focus, .ec-inlineBtn--action.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-inlineBtn--action:hover, .ec-inlineBtn--action:focus, .ec-inlineBtn--action.focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.8;
}
.ec-inlineBtn--action:active, .ec-inlineBtn--action.active {
  outline: 0;
  background-image: none;
}
.ec-inlineBtn--action.disabled, .ec-inlineBtn--action[disabled], fieldset[disabled] .ec-inlineBtn--action {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
}
.ec-inlineBtn--action:hover {
  color: #000;
  background-color: #d04976;
  border-color: #c4456f;
}
.btn-check:focus + .ec-inlineBtn--action, .ec-inlineBtn--action:focus {
  color: #000;
  background-color: #d04976;
  border-color: #c4456f;
  box-shadow: 0 0 0 0.25rem rgba(247, 111, 156, 0.5);
}
.btn-check:checked + .ec-inlineBtn--action, .btn-check:active + .ec-inlineBtn--action, .ec-inlineBtn--action:active, .ec-inlineBtn--action.active, .show > .ec-inlineBtn--action.dropdown-toggle {
  color: #fff;
  background-color: #c4456f;
  border-color: #b84168;
}
.btn-check:checked + .ec-inlineBtn--action:focus, .btn-check:active + .ec-inlineBtn--action:focus, .ec-inlineBtn--action:active:focus, .ec-inlineBtn--action.active:focus, .show > .ec-inlineBtn--action.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 111, 156, 0.5);
}
.ec-inlineBtn--action:disabled, .ec-inlineBtn--action.disabled {
  color: #fff;
  background-color: #f5568b;
  border-color: #f5568b;
}
.ec-inlineBtn--action .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}
.ec-inlineBtn--action:hover {
  color: #fff !important;
}

.ec-inlineBtn--cancel {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 1.42857142;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #4e5a7d;
  border-color: #4e5a7d;
}
.ec-inlineBtn--cancel:focus, .ec-inlineBtn--cancel.focus, .ec-inlineBtn--cancel:active:focus, .ec-inlineBtn--cancel:active.focus, .ec-inlineBtn--cancel.active:focus, .ec-inlineBtn--cancel.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-inlineBtn--cancel:hover, .ec-inlineBtn--cancel:focus, .ec-inlineBtn--cancel.focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.8;
}
.ec-inlineBtn--cancel:active, .ec-inlineBtn--cancel.active {
  outline: 0;
  background-image: none;
}
.ec-inlineBtn--cancel.disabled, .ec-inlineBtn--cancel[disabled], fieldset[disabled] .ec-inlineBtn--cancel {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
}
.ec-inlineBtn--cancel:hover {
  color: #fff;
  background-color: #424d6a;
  border-color: #3e4864;
}
.btn-check:focus + .ec-inlineBtn--cancel, .ec-inlineBtn--cancel:focus {
  color: #fff;
  background-color: #424d6a;
  border-color: #3e4864;
  box-shadow: 0 0 0 0.25rem rgba(105, 115, 145, 0.5);
}
.btn-check:checked + .ec-inlineBtn--cancel, .btn-check:active + .ec-inlineBtn--cancel, .ec-inlineBtn--cancel:active, .ec-inlineBtn--cancel.active, .show > .ec-inlineBtn--cancel.dropdown-toggle {
  color: #fff;
  background-color: #3e4864;
  border-color: #3b445e;
}
.btn-check:checked + .ec-inlineBtn--cancel:focus, .btn-check:active + .ec-inlineBtn--cancel:focus, .ec-inlineBtn--cancel:active:focus, .ec-inlineBtn--cancel.active:focus, .show > .ec-inlineBtn--cancel.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(105, 115, 145, 0.5);
}
.ec-inlineBtn--cancel:disabled, .ec-inlineBtn--cancel.disabled {
  color: #fff;
  background-color: #4e5a7d;
  border-color: #4e5a7d;
}
.ec-inlineBtn--cancel .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}

/*
ブロックボタン（全幅）

ボタンサイズは em で指定するため、テキストサイズの変更でボタンサイズを変更できます。

ex [商品詳細ページ　カートボタン部分](http://demo3.ec-cube.net/products/detail/30)

Markup:
p: .ec-blockBtn 住所検索
p: .ec-blockBtn--primary もっと見る
p: .ec-blockBtn--action カートに入れる
p: .ec-blockBtn--cancel キャンセル

Styleguide 2.1.2
*/
.ec-blockBtn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 1.42857142;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #4e5a7d;
  background-color: #F5F7F8;
  border-color: #4e5a7d;
  display: block;
  width: 100%;
  height: 42px !important;
  line-height: 42px !important;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.9em !important;
  border-radius: 10px !important;
}
.ec-blockBtn:focus, .ec-blockBtn.focus, .ec-blockBtn:active:focus, .ec-blockBtn:active.focus, .ec-blockBtn.active:focus, .ec-blockBtn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-blockBtn:hover, .ec-blockBtn:focus, .ec-blockBtn.focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.8;
}
.ec-blockBtn:active, .ec-blockBtn.active {
  outline: 0;
  background-image: none;
}
.ec-blockBtn.disabled, .ec-blockBtn[disabled], fieldset[disabled] .ec-blockBtn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
}
.ec-blockBtn:hover {
  color: #000;
  background-color: #f7f8f9;
  border-color: #606b8a;
}
.btn-check:focus + .ec-blockBtn, .ec-blockBtn:focus {
  color: #000;
  background-color: #f7f8f9;
  border-color: #606b8a;
  box-shadow: 0 0 0 0.25rem rgba(78, 90, 125, 0.5);
}
.btn-check:checked + .ec-blockBtn, .btn-check:active + .ec-blockBtn, .ec-blockBtn:active, .ec-blockBtn.active, .show > .ec-blockBtn.dropdown-toggle {
  color: #000;
  background-color: #f7f9f9;
  border-color: #606b8a;
}
.btn-check:checked + .ec-blockBtn:focus, .btn-check:active + .ec-blockBtn:focus, .ec-blockBtn:active:focus, .ec-blockBtn.active:focus, .show > .ec-blockBtn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(78, 90, 125, 0.5);
}
.ec-blockBtn:disabled, .ec-blockBtn.disabled {
  color: #4e5a7d;
  background-color: #F5F7F8;
  border-color: #4e5a7d;
}
.ec-blockBtn .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}
.ec-blockBtn:hover {
  color: #fff !important;
  opacity: 0.8;
}

.ec-blockBtn--primary {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 1.42857142;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #5CB1B1;
  border-color: #5CB1B1;
  display: block;
  width: 100%;
  height: 42px !important;
  line-height: 42px !important;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.9em !important;
  border-radius: 10px !important;
}
.ec-blockBtn--primary:focus, .ec-blockBtn--primary.focus, .ec-blockBtn--primary:active:focus, .ec-blockBtn--primary:active.focus, .ec-blockBtn--primary.active:focus, .ec-blockBtn--primary.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-blockBtn--primary:hover, .ec-blockBtn--primary:focus, .ec-blockBtn--primary.focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.8;
}
.ec-blockBtn--primary:active, .ec-blockBtn--primary.active {
  outline: 0;
  background-image: none;
}
.ec-blockBtn--primary.disabled, .ec-blockBtn--primary[disabled], fieldset[disabled] .ec-blockBtn--primary {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
}
.ec-blockBtn--primary:hover {
  color: #000;
  background-color: #4e9696;
  border-color: #4a8e8e;
}
.btn-check:focus + .ec-blockBtn--primary, .ec-blockBtn--primary:focus {
  color: #000;
  background-color: #4e9696;
  border-color: #4a8e8e;
  box-shadow: 0 0 0 0.25rem rgba(116, 189, 189, 0.5);
}
.btn-check:checked + .ec-blockBtn--primary, .btn-check:active + .ec-blockBtn--primary, .ec-blockBtn--primary:active, .ec-blockBtn--primary.active, .show > .ec-blockBtn--primary.dropdown-toggle {
  color: #000;
  background-color: #4a8e8e;
  border-color: #458585;
}
.btn-check:checked + .ec-blockBtn--primary:focus, .btn-check:active + .ec-blockBtn--primary:focus, .ec-blockBtn--primary:active:focus, .ec-blockBtn--primary.active:focus, .show > .ec-blockBtn--primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(116, 189, 189, 0.5);
}
.ec-blockBtn--primary:disabled, .ec-blockBtn--primary.disabled {
  color: #fff;
  background-color: #5CB1B1;
  border-color: #5CB1B1;
}
.ec-blockBtn--primary .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}
.ec-blockBtn--primary:hover {
  color: #fff !important;
  opacity: 0.8;
}

.ec-blockBtn--action {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 1.42857142;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #f5568b;
  border-color: #f5568b;
  display: block;
  width: 100%;
  height: 42px !important;
  line-height: 42px !important;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.9em !important;
  border-radius: 10px !important;
  transition: all 0.4;
}
.ec-blockBtn--action:focus, .ec-blockBtn--action.focus, .ec-blockBtn--action:active:focus, .ec-blockBtn--action:active.focus, .ec-blockBtn--action.active:focus, .ec-blockBtn--action.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-blockBtn--action:hover, .ec-blockBtn--action:focus, .ec-blockBtn--action.focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.8;
}
.ec-blockBtn--action:active, .ec-blockBtn--action.active {
  outline: 0;
  background-image: none;
}
.ec-blockBtn--action.disabled, .ec-blockBtn--action[disabled], fieldset[disabled] .ec-blockBtn--action {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
}
.ec-blockBtn--action:hover {
  color: #000;
  background-color: #d04976;
  border-color: #c4456f;
}
.btn-check:focus + .ec-blockBtn--action, .ec-blockBtn--action:focus {
  color: #000;
  background-color: #d04976;
  border-color: #c4456f;
  box-shadow: 0 0 0 0.25rem rgba(247, 111, 156, 0.5);
}
.btn-check:checked + .ec-blockBtn--action, .btn-check:active + .ec-blockBtn--action, .ec-blockBtn--action:active, .ec-blockBtn--action.active, .show > .ec-blockBtn--action.dropdown-toggle {
  color: #fff;
  background-color: #c4456f;
  border-color: #b84168;
}
.btn-check:checked + .ec-blockBtn--action:focus, .btn-check:active + .ec-blockBtn--action:focus, .ec-blockBtn--action:active:focus, .ec-blockBtn--action.active:focus, .show > .ec-blockBtn--action.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 111, 156, 0.5);
}
.ec-blockBtn--action:disabled, .ec-blockBtn--action.disabled {
  color: #fff;
  background-color: #f5568b;
  border-color: #f5568b;
}
.ec-blockBtn--action .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}
.ec-blockBtn--action:hover {
  color: #fff !important;
  opacity: 0.8;
}

.ec-blockBtn--cancel {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 1.42857142;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #4e5a7d;
  border-color: #4e5a7d;
  display: block;
  width: 100%;
  height: 42px !important;
  line-height: 42px !important;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.9em !important;
  border-radius: 10px !important;
}
.ec-blockBtn--cancel:focus, .ec-blockBtn--cancel.focus, .ec-blockBtn--cancel:active:focus, .ec-blockBtn--cancel:active.focus, .ec-blockBtn--cancel.active:focus, .ec-blockBtn--cancel.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-blockBtn--cancel:hover, .ec-blockBtn--cancel:focus, .ec-blockBtn--cancel.focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.8;
}
.ec-blockBtn--cancel:active, .ec-blockBtn--cancel.active {
  outline: 0;
  background-image: none;
}
.ec-blockBtn--cancel.disabled, .ec-blockBtn--cancel[disabled], fieldset[disabled] .ec-blockBtn--cancel {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
}
.ec-blockBtn--cancel:hover {
  color: #fff;
  background-color: #424d6a;
  border-color: #3e4864;
}
.btn-check:focus + .ec-blockBtn--cancel, .ec-blockBtn--cancel:focus {
  color: #fff;
  background-color: #424d6a;
  border-color: #3e4864;
  box-shadow: 0 0 0 0.25rem rgba(105, 115, 145, 0.5);
}
.btn-check:checked + .ec-blockBtn--cancel, .btn-check:active + .ec-blockBtn--cancel, .ec-blockBtn--cancel:active, .ec-blockBtn--cancel.active, .show > .ec-blockBtn--cancel.dropdown-toggle {
  color: #fff;
  background-color: #3e4864;
  border-color: #3b445e;
}
.btn-check:checked + .ec-blockBtn--cancel:focus, .btn-check:active + .ec-blockBtn--cancel:focus, .ec-blockBtn--cancel:active:focus, .ec-blockBtn--cancel.active:focus, .show > .ec-blockBtn--cancel.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(105, 115, 145, 0.5);
}
.ec-blockBtn--cancel:disabled, .ec-blockBtn--cancel.disabled {
  color: #fff;
  background-color: #4e5a7d;
  border-color: #4e5a7d;
}
.ec-blockBtn--cancel .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}
.ec-blockBtn--cancel:hover {
  color: #fff !important;
  opacity: 0.8;
}

/*
アイコンボタン

SVGアイコンを用いたアイコンボタンです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 2.2
*/
/*
アイコンボタン

閉じるなどSVGアイコンを用いたボタン装飾で利用します。

ex [ログイン画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/login)

Markup:
a.ec-closeBtn
  .ec-icon
    img(src='/moc/icon/cross.svg', alt='close')

Styleguide 2.2.1
*/
.ec-closeBtn {
  cursor: pointer;
}
.ec-closeBtn .ec-icon img {
  display: inline-block;
  margin-right: 5px;
  width: 1em;
  height: 1em;
  position: relative;
  top: -1px;
  vertical-align: middle;
}

/*
アイコンボタン(○)

閉じるなどSVGアイコンを用いたボタン装飾で利用します。

ex [ログイン画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/login)



ex [お届け先編集画面　☓ボタン部分](http://demo3.ec-cube.net/mypage/delivery)

Markup:
a.ec-closeBtn--circle
  .ec-icon
    img(src='/moc/icon/cross-white.svg', alt='close')

Styleguide 2.2.2
*/
.ec-closeBtn--circle {
  display: block;
  border: 0 none;
  padding: 0;
  margin: 0;
  text-shadow: none;
  box-shadow: none;
  border-radius: 50%;
  background: #B8BEC4;
  cursor: pointer;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  position: relative;
  text-align: center;
}
.ec-closeBtn--circle .ec-icon img {
  display: block;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
その他のボタン

通常のボタンや、アイコンボタン以外のボタンを定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.3
*/
/*
ページトップボタン

ページトップボタンを表示します

ex [商品詳細ページ　カートボタン部分](http://demo3.ec-cube.net/products/detail/30)

Markup:
.ec-blockTopBtn

Styleguide 2.3.1
*/
.ec-blockTopBtn {
  display: none;
  position: fixed;
  right: 0;
  bottom: 10px;
  cursor: pointer;
  opacity: 0.8;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
フォーム部品(テキスト)

テキストや数値の入力項目に関する要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 3.1
*/
/*
フォーム

`.ec-input` 要素は全ての入力項目に関する標準的なコンポーネントクラスです。


ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-input
  input(type="number")
p.ec-input
  textarea(rows="6")

Styleguide 3.1.1
*/
.ec-input input[type=radio], .ec-birth input[type=radio], .ec-select input[type=radio], .ec-telInput input[type=radio], .ec-zipInput input[type=radio], .ec-numberInput input[type=radio], .ec-halfInput input[type=radio],
.ec-input input[type=checkbox],
.ec-birth input[type=checkbox],
.ec-select input[type=checkbox],
.ec-telInput input[type=checkbox],
.ec-zipInput input[type=checkbox],
.ec-numberInput input[type=checkbox],
.ec-halfInput input[type=checkbox] {
  margin: 4px 0 0;
  margin-top: 1px \9 ;
  line-height: normal;
}
.ec-input input[type=file], .ec-birth input[type=file], .ec-select input[type=file], .ec-telInput input[type=file], .ec-zipInput input[type=file], .ec-numberInput input[type=file], .ec-halfInput input[type=file] {
  display: block;
}
.ec-input input[type=range], .ec-birth input[type=range], .ec-select input[type=range], .ec-telInput input[type=range], .ec-zipInput input[type=range], .ec-numberInput input[type=range], .ec-halfInput input[type=range] {
  display: block;
  width: 100%;
}
.ec-input select[multiple], .ec-birth select[multiple], .ec-select select[multiple], .ec-telInput select[multiple], .ec-zipInput select[multiple], .ec-numberInput select[multiple], .ec-halfInput select[multiple],
.ec-input select[size],
.ec-birth select[size],
.ec-select select[size],
.ec-telInput select[size],
.ec-zipInput select[size],
.ec-numberInput select[size],
.ec-halfInput select[size] {
  height: auto;
}
.ec-input input[type=file]:focus, .ec-birth input[type=file]:focus, .ec-select input[type=file]:focus, .ec-telInput input[type=file]:focus, .ec-zipInput input[type=file]:focus, .ec-numberInput input[type=file]:focus, .ec-halfInput input[type=file]:focus,
.ec-input input[type=radio]:focus,
.ec-birth input[type=radio]:focus,
.ec-select input[type=radio]:focus,
.ec-telInput input[type=radio]:focus,
.ec-zipInput input[type=radio]:focus,
.ec-numberInput input[type=radio]:focus,
.ec-halfInput input[type=radio]:focus,
.ec-input input[type=checkbox]:focus,
.ec-birth input[type=checkbox]:focus,
.ec-select input[type=checkbox]:focus,
.ec-telInput input[type=checkbox]:focus,
.ec-zipInput input[type=checkbox]:focus,
.ec-numberInput input[type=checkbox]:focus,
.ec-halfInput input[type=checkbox]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-input input, .ec-birth input, .ec-select input, .ec-telInput input, .ec-zipInput input, .ec-numberInput input, .ec-halfInput input {
  display: block;
  width: 100%;
  height: 1rem px12px2 px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857142;
  color: #212529;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ff9cbd;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px;
}
@media (prefers-reduced-motion: reduce) {
  .ec-input input, .ec-birth input, .ec-select input, .ec-telInput input, .ec-zipInput input, .ec-numberInput input, .ec-halfInput input {
    transition: none;
  }
}
.ec-input input:focus, .ec-birth input:focus, .ec-select input:focus, .ec-telInput input:focus, .ec-zipInput input:focus, .ec-numberInput input:focus, .ec-halfInput input:focus {
  border-color: #66afe9;
  outline: 0;
}
.ec-input input::-moz-placeholder, .ec-birth input::-moz-placeholder, .ec-select input::-moz-placeholder, .ec-telInput input::-moz-placeholder, .ec-zipInput input::-moz-placeholder, .ec-numberInput input::-moz-placeholder, .ec-halfInput input::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.ec-input input:-ms-input-placeholder, .ec-birth input:-ms-input-placeholder, .ec-select input:-ms-input-placeholder, .ec-telInput input:-ms-input-placeholder, .ec-zipInput input:-ms-input-placeholder, .ec-numberInput input:-ms-input-placeholder, .ec-halfInput input:-ms-input-placeholder {
  color: #999;
}
.ec-input input::-webkit-input-placeholder, .ec-birth input::-webkit-input-placeholder, .ec-select input::-webkit-input-placeholder, .ec-telInput input::-webkit-input-placeholder, .ec-zipInput input::-webkit-input-placeholder, .ec-numberInput input::-webkit-input-placeholder, .ec-halfInput input::-webkit-input-placeholder {
  color: #999;
}
.ec-input input::-ms-expand, .ec-birth input::-ms-expand, .ec-select input::-ms-expand, .ec-telInput input::-ms-expand, .ec-zipInput input::-ms-expand, .ec-numberInput input::-ms-expand, .ec-halfInput input::-ms-expand {
  border: 0;
  background-color: transparent;
}
.ec-input input[disabled], .ec-birth input[disabled], .ec-select input[disabled], .ec-telInput input[disabled], .ec-zipInput input[disabled], .ec-numberInput input[disabled], .ec-halfInput input[disabled], .ec-input input[readonly], .ec-birth input[readonly], .ec-select input[readonly], .ec-telInput input[readonly], .ec-zipInput input[readonly], .ec-numberInput input[readonly], .ec-halfInput input[readonly], fieldset[disabled] .ec-input input, fieldset[disabled] .ec-birth input, fieldset[disabled] .ec-select input, fieldset[disabled] .ec-telInput input, fieldset[disabled] .ec-zipInput input, fieldset[disabled] .ec-numberInput input, fieldset[disabled] .ec-halfInput input {
  opacity: 1;
}
.ec-input input[disabled], .ec-birth input[disabled], .ec-select input[disabled], .ec-telInput input[disabled], .ec-zipInput input[disabled], .ec-numberInput input[disabled], .ec-halfInput input[disabled], fieldset[disabled] .ec-input input, fieldset[disabled] .ec-birth input, fieldset[disabled] .ec-select input, fieldset[disabled] .ec-telInput input, fieldset[disabled] .ec-zipInput input, fieldset[disabled] .ec-numberInput input, fieldset[disabled] .ec-halfInput input {
  cursor: not-allowed;
}
@media only screen and (min-width: 768px) {
  .ec-input input, .ec-birth input, .ec-select input, .ec-telInput input, .ec-zipInput input, .ec-numberInput input, .ec-halfInput input {
    font-size: 16px;
  }
}
.ec-input select, .ec-birth select, .ec-select select, .ec-telInput select, .ec-zipInput select, .ec-numberInput select, .ec-halfInput select {
  display: block;
  width: 100%;
  height: 1rem px12px2 px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857142;
  color: #212529;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ff9cbd;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px;
}
@media (prefers-reduced-motion: reduce) {
  .ec-input select, .ec-birth select, .ec-select select, .ec-telInput select, .ec-zipInput select, .ec-numberInput select, .ec-halfInput select {
    transition: none;
  }
}
.ec-input select:focus, .ec-birth select:focus, .ec-select select:focus, .ec-telInput select:focus, .ec-zipInput select:focus, .ec-numberInput select:focus, .ec-halfInput select:focus {
  border-color: #66afe9;
  outline: 0;
}
.ec-input select::-moz-placeholder, .ec-birth select::-moz-placeholder, .ec-select select::-moz-placeholder, .ec-telInput select::-moz-placeholder, .ec-zipInput select::-moz-placeholder, .ec-numberInput select::-moz-placeholder, .ec-halfInput select::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.ec-input select:-ms-input-placeholder, .ec-birth select:-ms-input-placeholder, .ec-select select:-ms-input-placeholder, .ec-telInput select:-ms-input-placeholder, .ec-zipInput select:-ms-input-placeholder, .ec-numberInput select:-ms-input-placeholder, .ec-halfInput select:-ms-input-placeholder {
  color: #999;
}
.ec-input select::-webkit-input-placeholder, .ec-birth select::-webkit-input-placeholder, .ec-select select::-webkit-input-placeholder, .ec-telInput select::-webkit-input-placeholder, .ec-zipInput select::-webkit-input-placeholder, .ec-numberInput select::-webkit-input-placeholder, .ec-halfInput select::-webkit-input-placeholder {
  color: #999;
}
.ec-input select::-ms-expand, .ec-birth select::-ms-expand, .ec-select select::-ms-expand, .ec-telInput select::-ms-expand, .ec-zipInput select::-ms-expand, .ec-numberInput select::-ms-expand, .ec-halfInput select::-ms-expand {
  border: 0;
  background-color: transparent;
}
.ec-input select[disabled], .ec-birth select[disabled], .ec-select select[disabled], .ec-telInput select[disabled], .ec-zipInput select[disabled], .ec-numberInput select[disabled], .ec-halfInput select[disabled], .ec-input select[readonly], .ec-birth select[readonly], .ec-select select[readonly], .ec-telInput select[readonly], .ec-zipInput select[readonly], .ec-numberInput select[readonly], .ec-halfInput select[readonly], fieldset[disabled] .ec-input select, fieldset[disabled] .ec-birth select, fieldset[disabled] .ec-select select, fieldset[disabled] .ec-telInput select, fieldset[disabled] .ec-zipInput select, fieldset[disabled] .ec-numberInput select, fieldset[disabled] .ec-halfInput select {
  opacity: 1;
}
.ec-input select[disabled], .ec-birth select[disabled], .ec-select select[disabled], .ec-telInput select[disabled], .ec-zipInput select[disabled], .ec-numberInput select[disabled], .ec-halfInput select[disabled], fieldset[disabled] .ec-input select, fieldset[disabled] .ec-birth select, fieldset[disabled] .ec-select select, fieldset[disabled] .ec-telInput select, fieldset[disabled] .ec-zipInput select, fieldset[disabled] .ec-numberInput select, fieldset[disabled] .ec-halfInput select {
  cursor: not-allowed;
}
@media only screen and (min-width: 768px) {
  .ec-input select, .ec-birth select, .ec-select select, .ec-telInput select, .ec-zipInput select, .ec-numberInput select, .ec-halfInput select {
    font-size: 16px;
  }
}
.ec-input textarea, .ec-birth textarea, .ec-select textarea, .ec-telInput textarea, .ec-zipInput textarea, .ec-numberInput textarea, .ec-halfInput textarea {
  display: block;
  width: 100%;
  height: 1rem px12px2 px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857142;
  color: #212529;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ff9cbd;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px;
}
@media (prefers-reduced-motion: reduce) {
  .ec-input textarea, .ec-birth textarea, .ec-select textarea, .ec-telInput textarea, .ec-zipInput textarea, .ec-numberInput textarea, .ec-halfInput textarea {
    transition: none;
  }
}
.ec-input textarea:focus, .ec-birth textarea:focus, .ec-select textarea:focus, .ec-telInput textarea:focus, .ec-zipInput textarea:focus, .ec-numberInput textarea:focus, .ec-halfInput textarea:focus {
  border-color: #66afe9;
  outline: 0;
}
.ec-input textarea::-moz-placeholder, .ec-birth textarea::-moz-placeholder, .ec-select textarea::-moz-placeholder, .ec-telInput textarea::-moz-placeholder, .ec-zipInput textarea::-moz-placeholder, .ec-numberInput textarea::-moz-placeholder, .ec-halfInput textarea::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.ec-input textarea:-ms-input-placeholder, .ec-birth textarea:-ms-input-placeholder, .ec-select textarea:-ms-input-placeholder, .ec-telInput textarea:-ms-input-placeholder, .ec-zipInput textarea:-ms-input-placeholder, .ec-numberInput textarea:-ms-input-placeholder, .ec-halfInput textarea:-ms-input-placeholder {
  color: #999;
}
.ec-input textarea::-webkit-input-placeholder, .ec-birth textarea::-webkit-input-placeholder, .ec-select textarea::-webkit-input-placeholder, .ec-telInput textarea::-webkit-input-placeholder, .ec-zipInput textarea::-webkit-input-placeholder, .ec-numberInput textarea::-webkit-input-placeholder, .ec-halfInput textarea::-webkit-input-placeholder {
  color: #999;
}
.ec-input textarea::-ms-expand, .ec-birth textarea::-ms-expand, .ec-select textarea::-ms-expand, .ec-telInput textarea::-ms-expand, .ec-zipInput textarea::-ms-expand, .ec-numberInput textarea::-ms-expand, .ec-halfInput textarea::-ms-expand {
  border: 0;
  background-color: transparent;
}
.ec-input textarea[disabled], .ec-birth textarea[disabled], .ec-select textarea[disabled], .ec-telInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-halfInput textarea[disabled], .ec-input textarea[readonly], .ec-birth textarea[readonly], .ec-select textarea[readonly], .ec-telInput textarea[readonly], .ec-zipInput textarea[readonly], .ec-numberInput textarea[readonly], .ec-halfInput textarea[readonly], fieldset[disabled] .ec-input textarea, fieldset[disabled] .ec-birth textarea, fieldset[disabled] .ec-select textarea, fieldset[disabled] .ec-telInput textarea, fieldset[disabled] .ec-zipInput textarea, fieldset[disabled] .ec-numberInput textarea, fieldset[disabled] .ec-halfInput textarea {
  opacity: 1;
}
.ec-input textarea[disabled], .ec-birth textarea[disabled], .ec-select textarea[disabled], .ec-telInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-halfInput textarea[disabled], fieldset[disabled] .ec-input textarea, fieldset[disabled] .ec-birth textarea, fieldset[disabled] .ec-select textarea, fieldset[disabled] .ec-telInput textarea, fieldset[disabled] .ec-zipInput textarea, fieldset[disabled] .ec-numberInput textarea, fieldset[disabled] .ec-halfInput textarea {
  cursor: not-allowed;
}
@media only screen and (min-width: 768px) {
  .ec-input textarea, .ec-birth textarea, .ec-select textarea, .ec-telInput textarea, .ec-zipInput textarea, .ec-numberInput textarea, .ec-halfInput textarea {
    font-size: 16px;
  }
}
.ec-input input:focus, .ec-birth input:focus, .ec-select input:focus, .ec-telInput input:focus, .ec-zipInput input:focus, .ec-numberInput input:focus, .ec-halfInput input:focus, .ec-input textarea:focus, .ec-birth textarea:focus, .ec-select textarea:focus, .ec-telInput textarea:focus, .ec-zipInput textarea:focus, .ec-numberInput textarea:focus, .ec-halfInput textarea:focus {
  box-shadow: none;
  border-color: #3c8dbc;
}
.ec-input input, .ec-birth input, .ec-select input, .ec-telInput input, .ec-zipInput input, .ec-numberInput input, .ec-halfInput input {
  height: 40px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-input input, .ec-birth input, .ec-select input, .ec-telInput input, .ec-zipInput input, .ec-numberInput input, .ec-halfInput input {
    margin-bottom: 16px;
  }
}
.ec-input textarea, .ec-birth textarea, .ec-select textarea, .ec-telInput textarea, .ec-zipInput textarea, .ec-numberInput textarea, .ec-halfInput textarea {
  height: auto;
  min-height: 100px;
}
.ec-input p, .ec-birth p, .ec-select p, .ec-telInput p, .ec-zipInput p, .ec-numberInput p, .ec-halfInput p {
  line-height: 1.4;
}
.ec-input .ec-errorMessage, .ec-birth .ec-errorMessage, .ec-select .ec-errorMessage, .ec-telInput .ec-errorMessage, .ec-zipInput .ec-errorMessage, .ec-numberInput .ec-errorMessage, .ec-halfInput .ec-errorMessage {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50;
}

.error.ec-input input, .error.ec-birth input, .error.ec-select input, .error.ec-telInput input, .error.ec-zipInput input, .error.ec-numberInput input, .error.ec-halfInput input, .error.ec-input select, .error.ec-birth select, .error.ec-select select, .error.ec-telInput select, .error.ec-zipInput select, .error.ec-numberInput select, .error.ec-halfInput select, .error.ec-input textarea, .error.ec-birth textarea, .error.ec-select textarea, .error.ec-telInput textarea, .error.ec-zipInput textarea, .error.ec-numberInput textarea, .error.ec-halfInput textarea {
  margin-bottom: 5px;
  border-color: #CF3F34;
  background: #FDF1F0;
}

.form-error-box {
  margin: 0 auto 1em;
  padding: 0.5em;
  text-align: center;
  border: 2px solid #CF3F34;
  background: #FDF1F0;
  color: #CF3F34;
}

.ec-checkbox .ec-errorMessage, .ec-radio .ec-errorMessage {
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50;
}

.error.ec-checkbox input, .error.ec-checkbox label, .error.ec-checkbox div, .error.ec-radio input, .error.ec-radio label, .error.ec-radio div {
  border-color: #CF3F34;
  background: #FDF1F0;
}

/*
フォーム（text２つ）

姓名など2つ入力させたい入力項目で使用します。

入力フォームを半分で用意したいときにも利用可能です。

ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-halfInput
  input(type="text")
  input(type="text")
p.ec-halfInput
  input(type="text")

Styleguide 3.1.2
*/
.ec-halfInput input[type=text] {
  display: inline-block;
  width: 47%;
  margin-left: 2%;
}
@media only screen and (min-width: 768px) {
  .ec-halfInput input[type=text] {
    margin-left: 15px;
    width: 45%;
  }
}
.ec-halfInput .ec-errorMessage {
  display: inline-block;
  width: 47%;
}
@media only screen and (min-width: 768px) {
  .ec-halfInput .ec-errorMessage {
    margin-left: 15px;
    width: 45%;
  }
}
.ec-halfInput input[type=text]:first-of-type,
.ec-halfInput .ec-errorMessage:first-of-type {
  margin-left: 0;
}

/*
数量ボタン

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [商品詳細画面　数量ボタン部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-numberInput
  span 数量
  input(type="number",value="0")

Styleguide 3.1.3
*/
.ec-numberInput input[type=number] {
  display: inline-block;
  width: auto;
  max-width: 100px;
  text-align: right;
}

/*
郵便番号フォーム

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　郵便番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-zipInput
  span 〒
  input(type="text")
.ec-zipInputHelp
  a(href="http://www.post.japanpost.jp/zipcode/" target="_blank")
    .ec-zipInputHelp__icon
      .ec-icon
        img(src='/moc/icon/question-white.svg', alt='')
    span 郵便番号検索
.ec-zipAuto
  a.ec-inlineBtn 郵便番号から自動入力

Styleguide 3.1.4
*/
.ec-zipInput {
  display: inline-block;
}
.ec-zipInput input {
  display: inline-block;
  text-align: left;
  width: auto;
  max-width: 5em;
  font-size: 16px;
}
.ec-zipInput span {
  display: inline-block;
  padding: 0 5px 0 3px;
  margin-left: 5px;
}

.ec-zipInputHelp {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 16px;
  vertical-align: baseline;
  line-height: 0;
}
.ec-zipInputHelp .ec-zipInputHelp__icon {
  display: inline-block;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  background: #525263;
  border-radius: 50%;
  font-size: 13px;
  position: relative;
  top: -6px;
}
.ec-zipInputHelp .ec-zipInputHelp__icon .ec-icon {
  display: inline-block;
}
.ec-zipInputHelp .ec-zipInputHelp__icon .ec-icon img {
  width: 1em;
  height: 1em;
  position: relative;
  left: 3px;
  top: 3px;
}
.ec-zipInputHelp span {
  margin-left: 8px;
  display: inline-block;
  color: #fa8fb3;
  vertical-align: 3px;
}

.zip-search a {
  background: #000;
  color: #fff;
  text-align: center;
  border-radius: 10px;
}

.ec-zipAuto {
  margin-bottom: 16px;
}
.ec-zipAuto .ec-inlineBtn {
  font-weight: normal;
}

/*
電話番号ボタン

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　電話番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-telInput
  input(type="text")

Styleguide 3.1.5
*/
.ec-telInput input {
  max-width: 5em;
  text-align: left;
}

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
フォーム部品(テキスト)

テキストや数値の入力項目に関する要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 3.1
*/
/*
フォーム

`.ec-input` 要素は全ての入力項目に関する標準的なコンポーネントクラスです。


ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-input
  input(type="number")
p.ec-input
  textarea(rows="6")

Styleguide 3.1.1
*/
.ec-input input[type=radio], .ec-birth input[type=radio], .ec-select input[type=radio], .ec-halfInput input[type=radio], .ec-numberInput input[type=radio], .ec-zipInput input[type=radio], .ec-telInput input[type=radio],
.ec-input input[type=checkbox],
.ec-birth input[type=checkbox],
.ec-select input[type=checkbox],
.ec-halfInput input[type=checkbox],
.ec-numberInput input[type=checkbox],
.ec-zipInput input[type=checkbox],
.ec-telInput input[type=checkbox] {
  margin: 4px 0 0;
  margin-top: 1px \9 ;
  line-height: normal;
}
.ec-input input[type=file], .ec-birth input[type=file], .ec-select input[type=file], .ec-halfInput input[type=file], .ec-numberInput input[type=file], .ec-zipInput input[type=file], .ec-telInput input[type=file] {
  display: block;
}
.ec-input input[type=range], .ec-birth input[type=range], .ec-select input[type=range], .ec-halfInput input[type=range], .ec-numberInput input[type=range], .ec-zipInput input[type=range], .ec-telInput input[type=range] {
  display: block;
  width: 100%;
}
.ec-input select[multiple], .ec-birth select[multiple], .ec-select select[multiple], .ec-halfInput select[multiple], .ec-numberInput select[multiple], .ec-zipInput select[multiple], .ec-telInput select[multiple],
.ec-input select[size],
.ec-birth select[size],
.ec-select select[size],
.ec-halfInput select[size],
.ec-numberInput select[size],
.ec-zipInput select[size],
.ec-telInput select[size] {
  height: auto;
}
.ec-input input[type=file]:focus, .ec-birth input[type=file]:focus, .ec-select input[type=file]:focus, .ec-halfInput input[type=file]:focus, .ec-numberInput input[type=file]:focus, .ec-zipInput input[type=file]:focus, .ec-telInput input[type=file]:focus,
.ec-input input[type=radio]:focus,
.ec-birth input[type=radio]:focus,
.ec-select input[type=radio]:focus,
.ec-halfInput input[type=radio]:focus,
.ec-numberInput input[type=radio]:focus,
.ec-zipInput input[type=radio]:focus,
.ec-telInput input[type=radio]:focus,
.ec-input input[type=checkbox]:focus,
.ec-birth input[type=checkbox]:focus,
.ec-select input[type=checkbox]:focus,
.ec-halfInput input[type=checkbox]:focus,
.ec-numberInput input[type=checkbox]:focus,
.ec-zipInput input[type=checkbox]:focus,
.ec-telInput input[type=checkbox]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-input input, .ec-birth input, .ec-select input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input {
  display: block;
  width: 100%;
  height: 1rem px12px2 px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857142;
  color: #212529;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ff9cbd;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px;
}
@media (prefers-reduced-motion: reduce) {
  .ec-input input, .ec-birth input, .ec-select input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input {
    transition: none;
  }
}
.ec-input input:focus, .ec-birth input:focus, .ec-select input:focus, .ec-halfInput input:focus, .ec-numberInput input:focus, .ec-zipInput input:focus, .ec-telInput input:focus {
  border-color: #66afe9;
  outline: 0;
}
.ec-input input::-moz-placeholder, .ec-birth input::-moz-placeholder, .ec-select input::-moz-placeholder, .ec-halfInput input::-moz-placeholder, .ec-numberInput input::-moz-placeholder, .ec-zipInput input::-moz-placeholder, .ec-telInput input::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.ec-input input:-ms-input-placeholder, .ec-birth input:-ms-input-placeholder, .ec-select input:-ms-input-placeholder, .ec-halfInput input:-ms-input-placeholder, .ec-numberInput input:-ms-input-placeholder, .ec-zipInput input:-ms-input-placeholder, .ec-telInput input:-ms-input-placeholder {
  color: #999;
}
.ec-input input::-webkit-input-placeholder, .ec-birth input::-webkit-input-placeholder, .ec-select input::-webkit-input-placeholder, .ec-halfInput input::-webkit-input-placeholder, .ec-numberInput input::-webkit-input-placeholder, .ec-zipInput input::-webkit-input-placeholder, .ec-telInput input::-webkit-input-placeholder {
  color: #999;
}
.ec-input input::-ms-expand, .ec-birth input::-ms-expand, .ec-select input::-ms-expand, .ec-halfInput input::-ms-expand, .ec-numberInput input::-ms-expand, .ec-zipInput input::-ms-expand, .ec-telInput input::-ms-expand {
  border: 0;
  background-color: transparent;
}
.ec-input input[disabled], .ec-birth input[disabled], .ec-select input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled], .ec-input input[readonly], .ec-birth input[readonly], .ec-select input[readonly], .ec-halfInput input[readonly], .ec-numberInput input[readonly], .ec-zipInput input[readonly], .ec-telInput input[readonly], fieldset[disabled] .ec-input input, fieldset[disabled] .ec-birth input, fieldset[disabled] .ec-select input, fieldset[disabled] .ec-halfInput input, fieldset[disabled] .ec-numberInput input, fieldset[disabled] .ec-zipInput input, fieldset[disabled] .ec-telInput input {
  opacity: 1;
}
.ec-input input[disabled], .ec-birth input[disabled], .ec-select input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled], fieldset[disabled] .ec-input input, fieldset[disabled] .ec-birth input, fieldset[disabled] .ec-select input, fieldset[disabled] .ec-halfInput input, fieldset[disabled] .ec-numberInput input, fieldset[disabled] .ec-zipInput input, fieldset[disabled] .ec-telInput input {
  cursor: not-allowed;
}
@media only screen and (min-width: 768px) {
  .ec-input input, .ec-birth input, .ec-select input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input {
    font-size: 16px;
  }
}
.ec-input select, .ec-birth select, .ec-select select, .ec-halfInput select, .ec-numberInput select, .ec-zipInput select, .ec-telInput select {
  display: block;
  width: 100%;
  height: 1rem px12px2 px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857142;
  color: #212529;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ff9cbd;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px;
}
@media (prefers-reduced-motion: reduce) {
  .ec-input select, .ec-birth select, .ec-select select, .ec-halfInput select, .ec-numberInput select, .ec-zipInput select, .ec-telInput select {
    transition: none;
  }
}
.ec-input select:focus, .ec-birth select:focus, .ec-select select:focus, .ec-halfInput select:focus, .ec-numberInput select:focus, .ec-zipInput select:focus, .ec-telInput select:focus {
  border-color: #66afe9;
  outline: 0;
}
.ec-input select::-moz-placeholder, .ec-birth select::-moz-placeholder, .ec-select select::-moz-placeholder, .ec-halfInput select::-moz-placeholder, .ec-numberInput select::-moz-placeholder, .ec-zipInput select::-moz-placeholder, .ec-telInput select::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.ec-input select:-ms-input-placeholder, .ec-birth select:-ms-input-placeholder, .ec-select select:-ms-input-placeholder, .ec-halfInput select:-ms-input-placeholder, .ec-numberInput select:-ms-input-placeholder, .ec-zipInput select:-ms-input-placeholder, .ec-telInput select:-ms-input-placeholder {
  color: #999;
}
.ec-input select::-webkit-input-placeholder, .ec-birth select::-webkit-input-placeholder, .ec-select select::-webkit-input-placeholder, .ec-halfInput select::-webkit-input-placeholder, .ec-numberInput select::-webkit-input-placeholder, .ec-zipInput select::-webkit-input-placeholder, .ec-telInput select::-webkit-input-placeholder {
  color: #999;
}
.ec-input select::-ms-expand, .ec-birth select::-ms-expand, .ec-select select::-ms-expand, .ec-halfInput select::-ms-expand, .ec-numberInput select::-ms-expand, .ec-zipInput select::-ms-expand, .ec-telInput select::-ms-expand {
  border: 0;
  background-color: transparent;
}
.ec-input select[disabled], .ec-birth select[disabled], .ec-select select[disabled], .ec-halfInput select[disabled], .ec-numberInput select[disabled], .ec-zipInput select[disabled], .ec-telInput select[disabled], .ec-input select[readonly], .ec-birth select[readonly], .ec-select select[readonly], .ec-halfInput select[readonly], .ec-numberInput select[readonly], .ec-zipInput select[readonly], .ec-telInput select[readonly], fieldset[disabled] .ec-input select, fieldset[disabled] .ec-birth select, fieldset[disabled] .ec-select select, fieldset[disabled] .ec-halfInput select, fieldset[disabled] .ec-numberInput select, fieldset[disabled] .ec-zipInput select, fieldset[disabled] .ec-telInput select {
  opacity: 1;
}
.ec-input select[disabled], .ec-birth select[disabled], .ec-select select[disabled], .ec-halfInput select[disabled], .ec-numberInput select[disabled], .ec-zipInput select[disabled], .ec-telInput select[disabled], fieldset[disabled] .ec-input select, fieldset[disabled] .ec-birth select, fieldset[disabled] .ec-select select, fieldset[disabled] .ec-halfInput select, fieldset[disabled] .ec-numberInput select, fieldset[disabled] .ec-zipInput select, fieldset[disabled] .ec-telInput select {
  cursor: not-allowed;
}
@media only screen and (min-width: 768px) {
  .ec-input select, .ec-birth select, .ec-select select, .ec-halfInput select, .ec-numberInput select, .ec-zipInput select, .ec-telInput select {
    font-size: 16px;
  }
}
.ec-input textarea, .ec-birth textarea, .ec-select textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea {
  display: block;
  width: 100%;
  height: 1rem px12px2 px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857142;
  color: #212529;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ff9cbd;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 3px;
}
@media (prefers-reduced-motion: reduce) {
  .ec-input textarea, .ec-birth textarea, .ec-select textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea {
    transition: none;
  }
}
.ec-input textarea:focus, .ec-birth textarea:focus, .ec-select textarea:focus, .ec-halfInput textarea:focus, .ec-numberInput textarea:focus, .ec-zipInput textarea:focus, .ec-telInput textarea:focus {
  border-color: #66afe9;
  outline: 0;
}
.ec-input textarea::-moz-placeholder, .ec-birth textarea::-moz-placeholder, .ec-select textarea::-moz-placeholder, .ec-halfInput textarea::-moz-placeholder, .ec-numberInput textarea::-moz-placeholder, .ec-zipInput textarea::-moz-placeholder, .ec-telInput textarea::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.ec-input textarea:-ms-input-placeholder, .ec-birth textarea:-ms-input-placeholder, .ec-select textarea:-ms-input-placeholder, .ec-halfInput textarea:-ms-input-placeholder, .ec-numberInput textarea:-ms-input-placeholder, .ec-zipInput textarea:-ms-input-placeholder, .ec-telInput textarea:-ms-input-placeholder {
  color: #999;
}
.ec-input textarea::-webkit-input-placeholder, .ec-birth textarea::-webkit-input-placeholder, .ec-select textarea::-webkit-input-placeholder, .ec-halfInput textarea::-webkit-input-placeholder, .ec-numberInput textarea::-webkit-input-placeholder, .ec-zipInput textarea::-webkit-input-placeholder, .ec-telInput textarea::-webkit-input-placeholder {
  color: #999;
}
.ec-input textarea::-ms-expand, .ec-birth textarea::-ms-expand, .ec-select textarea::-ms-expand, .ec-halfInput textarea::-ms-expand, .ec-numberInput textarea::-ms-expand, .ec-zipInput textarea::-ms-expand, .ec-telInput textarea::-ms-expand {
  border: 0;
  background-color: transparent;
}
.ec-input textarea[disabled], .ec-birth textarea[disabled], .ec-select textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled], .ec-input textarea[readonly], .ec-birth textarea[readonly], .ec-select textarea[readonly], .ec-halfInput textarea[readonly], .ec-numberInput textarea[readonly], .ec-zipInput textarea[readonly], .ec-telInput textarea[readonly], fieldset[disabled] .ec-input textarea, fieldset[disabled] .ec-birth textarea, fieldset[disabled] .ec-select textarea, fieldset[disabled] .ec-halfInput textarea, fieldset[disabled] .ec-numberInput textarea, fieldset[disabled] .ec-zipInput textarea, fieldset[disabled] .ec-telInput textarea {
  opacity: 1;
}
.ec-input textarea[disabled], .ec-birth textarea[disabled], .ec-select textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled], fieldset[disabled] .ec-input textarea, fieldset[disabled] .ec-birth textarea, fieldset[disabled] .ec-select textarea, fieldset[disabled] .ec-halfInput textarea, fieldset[disabled] .ec-numberInput textarea, fieldset[disabled] .ec-zipInput textarea, fieldset[disabled] .ec-telInput textarea {
  cursor: not-allowed;
}
@media only screen and (min-width: 768px) {
  .ec-input textarea, .ec-birth textarea, .ec-select textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea {
    font-size: 16px;
  }
}
.ec-input input:focus, .ec-birth input:focus, .ec-select input:focus, .ec-halfInput input:focus, .ec-numberInput input:focus, .ec-zipInput input:focus, .ec-telInput input:focus, .ec-input textarea:focus, .ec-birth textarea:focus, .ec-select textarea:focus, .ec-halfInput textarea:focus, .ec-numberInput textarea:focus, .ec-zipInput textarea:focus, .ec-telInput textarea:focus {
  box-shadow: none;
  border-color: #3c8dbc;
}
.ec-input input, .ec-birth input, .ec-select input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input {
  height: 40px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-input input, .ec-birth input, .ec-select input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input {
    margin-bottom: 16px;
  }
}
.ec-input textarea, .ec-birth textarea, .ec-select textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea {
  height: auto;
  min-height: 100px;
}
.ec-input p, .ec-birth p, .ec-select p, .ec-halfInput p, .ec-numberInput p, .ec-zipInput p, .ec-telInput p {
  line-height: 1.4;
}
.ec-input .ec-errorMessage, .ec-birth .ec-errorMessage, .ec-select .ec-errorMessage, .ec-halfInput .ec-errorMessage, .ec-numberInput .ec-errorMessage, .ec-zipInput .ec-errorMessage, .ec-telInput .ec-errorMessage {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50;
}

.error.ec-input input, .error.ec-birth input, .error.ec-select input, .error.ec-halfInput input, .error.ec-numberInput input, .error.ec-zipInput input, .error.ec-telInput input, .error.ec-input select, .error.ec-birth select, .error.ec-select select, .error.ec-halfInput select, .error.ec-numberInput select, .error.ec-zipInput select, .error.ec-telInput select, .error.ec-input textarea, .error.ec-birth textarea, .error.ec-select textarea, .error.ec-halfInput textarea, .error.ec-numberInput textarea, .error.ec-zipInput textarea, .error.ec-telInput textarea {
  margin-bottom: 5px;
  border-color: #CF3F34;
  background: #FDF1F0;
}

.form-error-box {
  margin: 0 auto 1em;
  padding: 0.5em;
  text-align: center;
  border: 2px solid #CF3F34;
  background: #FDF1F0;
  color: #CF3F34;
}

.ec-checkbox .ec-errorMessage, .ec-radio .ec-errorMessage {
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: bold;
  color: #DE5D50;
}

.error.ec-checkbox input, .error.ec-checkbox label, .error.ec-checkbox div, .error.ec-radio input, .error.ec-radio label, .error.ec-radio div {
  border-color: #CF3F34;
  background: #FDF1F0;
}

/*
フォーム（text２つ）

姓名など2つ入力させたい入力項目で使用します。

入力フォームを半分で用意したいときにも利用可能です。

ex [会員情報編集画面　フォーム部分](http://demo3.ec-cube.net/mypage/change)

Markup:
p.ec-halfInput
  input(type="text")
  input(type="text")
p.ec-halfInput
  input(type="text")

Styleguide 3.1.2
*/
.ec-halfInput input[type=text] {
  display: inline-block;
  width: 47%;
  margin-left: 2%;
}
@media only screen and (min-width: 768px) {
  .ec-halfInput input[type=text] {
    margin-left: 15px;
    width: 45%;
  }
}
.ec-halfInput .ec-errorMessage {
  display: inline-block;
  width: 47%;
}
@media only screen and (min-width: 768px) {
  .ec-halfInput .ec-errorMessage {
    margin-left: 15px;
    width: 45%;
  }
}
.ec-halfInput input[type=text]:first-of-type,
.ec-halfInput .ec-errorMessage:first-of-type {
  margin-left: 0;
}

/*
数量ボタン

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [商品詳細画面　数量ボタン部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-numberInput
  span 数量
  input(type="number",value="0")

Styleguide 3.1.3
*/
.ec-numberInput input[type=number] {
  display: inline-block;
  width: auto;
  max-width: 100px;
  text-align: right;
}

/*
郵便番号フォーム

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　郵便番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-zipInput
  span 〒
  input(type="text")
.ec-zipInputHelp
  a(href="http://www.post.japanpost.jp/zipcode/" target="_blank")
    .ec-zipInputHelp__icon
      .ec-icon
        img(src='/moc/icon/question-white.svg', alt='')
    span 郵便番号検索
.ec-zipAuto
  a.ec-inlineBtn 郵便番号から自動入力

Styleguide 3.1.4
*/
.ec-zipInput {
  display: inline-block;
}
.ec-zipInput input {
  display: inline-block;
  text-align: left;
  width: auto;
  max-width: 5em;
  font-size: 16px;
}
.ec-zipInput span {
  display: inline-block;
  padding: 0 5px 0 3px;
  margin-left: 5px;
}

.ec-zipInputHelp {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 16px;
  vertical-align: baseline;
  line-height: 0;
}
.ec-zipInputHelp .ec-zipInputHelp__icon {
  display: inline-block;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  background: #525263;
  border-radius: 50%;
  font-size: 13px;
  position: relative;
  top: -6px;
}
.ec-zipInputHelp .ec-zipInputHelp__icon .ec-icon {
  display: inline-block;
}
.ec-zipInputHelp .ec-zipInputHelp__icon .ec-icon img {
  width: 1em;
  height: 1em;
  position: relative;
  left: 3px;
  top: 3px;
}
.ec-zipInputHelp span {
  margin-left: 8px;
  display: inline-block;
  color: #fa8fb3;
  vertical-align: 3px;
}

.zip-search a {
  background: #000;
  color: #fff;
  text-align: center;
  border-radius: 10px;
}

.ec-zipAuto {
  margin-bottom: 16px;
}
.ec-zipAuto .ec-inlineBtn {
  font-weight: normal;
}

/*
電話番号ボタン

数量を表示するための小さなコンポーネントです。

内部に input 要素を配置してコーディングします。

ex [会員情報編集画面　電話番号部分](http://demo3.ec-cube.net/mypage/change)

Markup:
.ec-telInput
  input(type="text")

Styleguide 3.1.5
*/
.ec-telInput input {
  max-width: 5em;
  text-align: left;
}

/*
フォーム部品(その他)

フォーム部品でテキストの入力以外の動作要素を定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 3.2
*/
/*
ラジオ（水平）

水平に並ぶラジオボタンフィールドです。

各要素をlabelでくくって、コーディングします。

ex [新規会員登録画面　性別選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-radio
  label
    input(type="radio")
    span 男性
  label
    input(type="radio")
    span 女性

Styleguide 3.2.2
*/
.ec-radio div, .ec-blockRadio div {
  display: inline-block;
}
.ec-radio label, .ec-blockRadio label {
  width: calc(100% - 25px);
  display: inline-block !important;
  margin-left: 4px;
}
.ec-radio input, .ec-blockRadio input {
  width: 14px;
  margin: 0;
}
.ec-radio span, .ec-blockRadio span {
  font-weight: normal;
  padding-left: 0 !important;
}

/*
ラジオ(垂直)

垂直に並ぶラジオボタンフィールドです。

各要素をlabelでくくって、コーディングします。

ex [購入画面 お支払方法](http://demo3.ec-cube.net/shopping)

Markup:
.ec-blockRadio
  label
    input(type="radio")
    span 郵便振替
  label
    input(type="radio")
    span 現金書留
  label
    input(type="radio")
    span 銀行振込
  label
    input(type="radio")
    span 代金引換

Styleguide 3.2.3
*/
.ec-blockRadio label {
  display: block;
}
.ec-blockRadio span {
  padding-left: 10px;
  font-weight: normal;
}

/*
セレクトボックス

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [新規会員登録画面　都道府県選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-select
  select
    option 都道府県を選択
    option 北海道
    option 青森県
    option 岩手県
    option ...
.ec-select
  select
    option 選択して下さい
    option 公務員
    option コンサルタント
    option コンピュータ関連技術職
    option コンピュータ関連以外の技術職
    option ...

Styleguide 3.2.4
*/
.ec-selects {
  margin-bottom: 20px;
  border-bottom: 1px dotted #ccc;
}

.ec-select {
  margin-bottom: 16px;
}
.ec-select select {
  display: inline-block;
  width: auto;
  max-width: 100%;
  background-color: #f8f8f8;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
}
.ec-select select:focus {
  box-shadow: none;
}
.ec-select label {
  margin-right: 10px;
  font-weight: bold;
}
.ec-select label:nth-child(3) {
  margin-left: 10px;
  font-weight: bold;
}

.ec-select__delivery {
  display: block;
  margin-right: 16px;
}
@media only screen and (min-width: 768px) {
  .ec-select__delivery {
    display: inline-block;
  }
}

.ec-select__time {
  display: block;
}
@media only screen and (min-width: 768px) {
  .ec-select__time {
    display: inline-block;
  }
}

/*
生年月日選択

数量を表示するための小さなコンポーネントです。

数値表示に最適化するため、数字は右端揃えで表示されます。

ex [新規会員登録画面　生年月日選択部分](http://demo3.ec-cube.net/entry)

Markup:
.ec-birth
  select
    option ----
    option 1960
    option 1961
    option 1962
    option ...
  span /
  select
    option --
    option 01
    option 02
    option 03
    option ...
  span /
  select
    option --
    option 01
    option 02
    option 03
    option ...

Styleguide 3.2.5
*/
.ec-birth select {
  display: inline-block;
  width: auto;
  margin: 0 0 10px;
  background-color: #f8f8f8;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
}
.ec-birth select:focus {
  box-shadow: none;
}
@media only screen and (min-width: 768px) {
  .ec-birth select {
    margin: 0 8px 10px;
  }
}
.ec-birth span {
  margin-left: 5px;
}

/*
チェックボックス （水平）

水平に並ぶチェックボックス フィールドです。

各要素をlabelでくくって、コーディングします。

ex [新規会員登録画面　利用規約](http://demo3.ec-cube.net/entry)

Markup:
.ec-checkbox
  label
    input(type="checkbox")
    span 利用規約に同意する

Styleguide 3.2.6
*/
.ec-checkbox label, .ec-blockCheckbox label {
  width: calc(100% - 25px);
  display: inline-block !important;
  margin-left: 4px !important;
  margin-right: 0 !important;
  margin-top: 5px;
  font-weight: 700;
}
.ec-checkbox input, .ec-blockCheckbox input {
  width: 14px;
  margin: 0;
}
.ec-checkbox span, .ec-blockCheckbox span {
  font-weight: normal;
}

/*
チェックボックス (垂直)

垂直に並ぶチェックボックス フィールドです。

各要素をlabelでくくって、コーディングします。

Markup:
.ec-blockCheckbox
  label
    input(type="checkbox")
    span 利用規約に同意する

Styleguide 3.2.7
*/
.ec-blockCheckbox span {
  font-weight: normal;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
フォームラベル

フォームのラベルに関する要素を定義します。

sg-wrapper:
<div class="ec-registerRole">
  <div class="ec-off1Grid">
    <div class="ec-off1Grid__cell">
      <div class="ec-borderedDefs">
        <sg-wrapper-content/>
      </div>
    </div>
  </div>
</div>

Styleguide 3.3
*/
/*
ラベル

フォーム要素で利用するラベル要素です。

ex [お問い合わせページ　ラベル部分](http://demo3.ec-cube.net/contact)

Markup:
.ec-borderedDefs
  dl
    dt
      label.ec-label お名前
    dd
      .ec-input
        input(type="text")

Styleguide 3.3.1
*/
.ec-label {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 5px;
}

/*
必須ラベル

必須文字を表示するラベル要素です。

ex [お問い合わせページ　必須ラベル部分](http://demo3.ec-cube.net/contact)


Markup:
.ec-borderedDefs
  dl
    dt
      label.ec-label お名前
        span.ec-required 必須
    dd
      .ec-input
        input(type="text")

Styleguide 3.3.2
*/
.ec-required {
  padding: 2px;
  color: #fff;
  background: #f5568b;
  font-weight: normal !important;
  margin-left: 1em;
  font-size: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-required {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

/*
アイコン

デフォルトテンプレートのアイコンは`.ec-icon`>`img`タグで使用することができます

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Markup:
include /assets/tmpl/elements/4.1.icon.pug
div(style="background-color: rgba(130,130,130,.15); padding: 20px;")
  +icon-all

Styleguide 4.1
*/
.ec-icon img {
  max-width: 80px;
  max-height: 80px;
  color: #f5568b;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
グリッド

画面を12分割し、グリッドレイアウトに対応するためのスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 5.1
*/
/*
2分割グリッド

画面 ２分割の　グリッドです。
Bootstrap の col-sm-6 相当のグリッドを提供します。

Markup:
.ec-grid2
  .ec-grid2__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid2__cell
  .ec-grid2__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid2__cell

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 5.1.1
*/
.ec-grid2 {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-grid2 {
    display: flex;
  }
}
.ec-grid2 .ec-grid2__cell {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid2 .ec-grid2__cell {
    width: 50%;
  }
}
.ec-grid2 .ec-grid2__cell2 {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid2 .ec-grid2__cell2 {
    width: 100%;
  }
}
/*
3分割グリッド

画面　３分割の　グリッドです。


Markup:
.ec-grid3
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell
  .ec-grid3__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid3__cell

Styleguide 5.1.2
*/
.ec-grid3 {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-grid3 {
    display: flex;
  }
}
.ec-grid3 .ec-grid3__cell {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid3 .ec-grid3__cell {
    width: 33.3333333333%;
  }
}
.ec-grid3 .ec-grid3__cell2 {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid3 .ec-grid3__cell2 {
    width: 66.6666666667%;
  }
}
.ec-grid3 .ec-grid3__cell3 {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid3 .ec-grid3__cell3 {
    width: 100%;
  }
}
/*
4分割グリッド

画面　４分割の　グリッドです。


Markup:
.ec-grid4
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid4__cell

Styleguide 5.1.3
*/
.ec-grid4 {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-grid4 {
    display: flex;
  }
}
.ec-grid4 .ec-grid4__cell {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid4 .ec-grid4__cell {
    width: 25%;
  }
}
/*
6分割グリッド

2つにまとめた cell2 や 3つをまとめた cell3 タグも使用可能です。


Markup:
.ec-grid6
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
  .ec-grid6__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell
.ec-grid6
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
  .ec-grid6__cell2(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell2
.ec-grid6
  .ec-grid6__cell3(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell3
  .ec-grid6__cell3(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") .ec-grid6__cell3

Styleguide 5.1.4
*/
.ec-grid6 {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-grid6 {
    display: flex;
  }
}
.ec-grid6 .ec-grid6__cell {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid6 .ec-grid6__cell {
    width: 16.6666666667%;
  }
}
.ec-grid6 .ec-grid6__cell2 {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid6 .ec-grid6__cell2 {
    width: 33.3333333333%;
  }
}
.ec-grid6 .ec-grid6__cell3 {
  position: relative;
  min-height: 1px;
}
@media (min-width: 768px) {
  .ec-grid6 .ec-grid6__cell3 {
    width: 50%;
  }
}
/*
中央寄せグリッド 10/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の１０グリッドです

ex [ご利用規約ページ　本文](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-off1Grid
  .ec-off1Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.5
*/
.ec-off1Grid {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off1Grid {
    display: block;
    margin: 0;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .ec-off1Grid {
    display: flex;
  }
}
.ec-off1Grid .ec-off1Grid__cell {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off1Grid .ec-off1Grid__cell {
    position: relative;
    min-height: 1px;
    margin-left: 8.3333333333%;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .ec-off1Grid .ec-off1Grid__cell {
    width: 83.3333333333%;
  }
}
/*
中央寄せグリッド 8/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の８グリッドです


Markup:
.ec-off2Grid
  .ec-off2Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.6
*/
.ec-off2Grid {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off2Grid {
    display: flex;
  }
}
.ec-off2Grid .ec-off2Grid__cell {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off2Grid .ec-off2Grid__cell {
    position: relative;
    min-height: 1px;
    margin-left: 16.6666666667%;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .ec-off2Grid .ec-off2Grid__cell {
    width: 66.6666666667%;
  }
}
/*
中央寄せグリッド 6/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の６グリッドです


Markup:
.ec-off3Grid
  .ec-off3Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod

Styleguide 5.1.7
*/
.ec-off3Grid {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off3Grid {
    display: flex;
  }
}
.ec-off3Grid .ec-off3Grid__cell {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off3Grid .ec-off3Grid__cell {
    position: relative;
    min-height: 1px;
    margin-left: 25%;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .ec-off3Grid .ec-off3Grid__cell {
    width: 50%;
  }
}
/*
中央寄せグリッド 4/12

左右にマージンを持つ、中央寄せグリッドを提供します。１２分の４グリッドです


Markup:
.ec-off4Grid
  .ec-off4Grid__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod


Styleguide 5.1.8
*/
.ec-off4Grid {
  display: block;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off4Grid {
    display: flex;
  }
}
.ec-off4Grid .ec-off4Grid__cell {
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .ec-off4Grid .ec-off4Grid__cell {
    position: relative;
    min-height: 1px;
    margin-left: 33.3333333333%;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .ec-off4Grid .ec-off4Grid__cell {
    width: 33.3333333333%;
  }
}
/*
グリッドオプション

グリッドのセルに対して「左寄せ」「中央寄せ」「右寄せ」のオプションを付与することができます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Styleguide 5.1.9
*/
/*
グリッドセルの左寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--left
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.10
*/
.ec-grid--left {
  justify-content: flex-start;
}

/*
グリッドセルの右寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--right
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.11
*/
.ec-grid--right {
  justify-content: flex-end;
}

/*
グリッドセルの中央寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

Markup:
.ec-grid4.ec-grid--center
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell
  .ec-grid4__cell(style="background-color: rgba(86,61,124,.15);border: 1px solid rgba(86,61,124,.2);height:50px;") ec-grid4__cell

Styleguide 5.1.12
*/
.ec-grid--center {
  justify-content: center;
}

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
レイアウト

様々なレイアウトを変更する為のスタイル群です。

Styleguide 5.2
*/
/*
画像レイアウト

画像とテキストを水平に並べるレイアウトです。

画像は20%で表示されます。

ex [注文履歴 ログイン後→注文履歴ボタンを押下](http://demo3.ec-cube.net/mypage)

Markup:
.ec-imageGrid
  .ec-imageGrid__img: img(src="http://demo3.ec-cube.net/upload/save_image/0701113537_559351f959620.jpeg")
  .ec-imageGrid__content
    p.ec-font-bold ホーローマグ
    p ¥ 1,728 x 1

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 5.2.1
*/
.ec-imageGrid {
  display: table;
  border-top: 1px dotted #ccc;
  width: 100%;
}
.ec-imageGrid .ec-imageGrid__img {
  display: table-cell;
  padding: 10px;
  width: 100px;
}
@media only screen and (min-width: 768px) {
  .ec-imageGrid .ec-imageGrid__img {
    padding: 10px;
    width: 130px;
  }
}
.ec-imageGrid .ec-imageGrid__img img {
  width: 100%;
}
.ec-imageGrid .ec-imageGrid__content {
  vertical-align: middle;
  display: table-cell;
}
.ec-imageGrid .ec-imageGrid__content span {
  margin-left: 10px;
}
.ec-imageGrid .ec-imageGrid__content p {
  margin-bottom: 0;
}

.inner {
  position: relative;
  margin: 0 auto;
  width: 98% !important;
}
@media only screen and (min-width: 768px) {
  .inner {
    max-width: 1060px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
ログイン

主にログインフォームのスタイルを表示します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 6.1
*/
/*
ログインフォーム

ログインフォームを表示します。

ex [ログイン画面](http://demo3.ec-cube.net/mypage/login)

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-login


Styleguide 6.1.1
*/
.ec-login {
  margin: 0 0 20px;
  padding: 30px 13% 20px;
  height: auto;
  background: #ffe8e8;
  box-sizing: border-box;
  border-radius: 20px;
}
@media only screen and (min-width: 768px) {
  .ec-login {
    margin: 0 16px;
    padding: 30px 13% 60px;
  }
}
.ec-login .ec-login__icon {
  text-align: center;
}
.ec-login .ec-icon {
  margin-bottom: 10px;
}
.ec-login .ec-icon img {
  width: 90px;
  height: 90px;
  display: inline-block;
}
.ec-login .ec-login__input {
  margin-bottom: 40px;
}
.ec-login .ec-login__input .ec-checkbox span {
  margin-left: 5px;
  font-weight: normal;
}
.ec-login .ec-login__actions {
  color: #fff;
}
.ec-login .ec-login__actions a {
  color: inherit;
  text-decoration: none;
}
.ec-login .ec-login__actions a:hover {
  text-decoration: none;
}
.ec-login .ec-login__link {
  margin-top: 5px;
  margin-left: 0;
}
@media only screen and (min-width: 768px) {
  .ec-login .ec-login__link {
    margin-left: 20px;
  }
}
.ec-login .ec-errorMessage {
  color: #DE5D50;
  margin-bottom: 20px;
}

/*
ゲスト購入

ゲスト購入ボタンとそのフォームを表示します。

ex [ゲスト購入画面](http://demo3.ec-cube.net/shopping/login)

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-guest
hoge

Styleguide 6.1.2
*/
.ec-guest {
  display: table;
  margin-bottom: 1.8rem !important;
  padding: 13%;
  height: auto;
  box-sizing: border-box;
  background: #ffe8e8;
  box-sizing: border-box;
  border-radius: 20px;
}
@media only screen and (min-width: 768px) {
  .ec-guest {
    padding: 2% 5% 5%;
    margin: 0 16px;
  }
}
.ec-guest .ec-guest__inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.ec-guest .ec-guest__inner p {
  margin-bottom: 16px;
  text-align: left;
}
.ec-guest .ec-guest__actions {
  display: block;
  vertical-align: middle;
  text-align: center;
  color: #fff;
}
.ec-guest .ec-guest__actions a {
  color: inherit;
  text-decoration: none;
}
.ec-guest .ec-guest__actions a:hover {
  text-decoration: none;
}
.ec-guest .ec-guest__icon {
  font-size: 70px;
  text-align: center;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
商品掲載

トップページに商品掲載するスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 7.1
*/
/*
商品アイテム（商品紹介B）

３項目横並びの商品アイテムを表示します。
必要に応じて商品詳細や、キャッチコピーなどを添えることが出来ます。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayB

Styleguide 7.1.1
*/
.ec-displayB {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .ec-displayB {
    flex-direction: row;
  }
}
.ec-displayB .ec-displayB__cell {
  width: 100%;
  margin-bottom: 16px;
}
.ec-displayB .ec-displayB__cell a {
  color: inherit;
  text-decoration: none;
}
.ec-displayB .ec-displayB__cell a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-displayB .ec-displayB__cell {
    width: 31.4466%;
    margin-bottom: 0;
  }
}
.ec-displayB .ec-displayB__cell:hover {
  text-decoration: none;
}
.ec-displayB .ec-displayB__cell:hover img {
  opacity: 0.8;
}
.ec-displayB .ec-displayB__cell:hover a {
  text-decoration: none;
}
.ec-displayB .ec-displayB__img {
  margin-bottom: 15px;
}
.ec-displayB .ec-displayB__catch {
  margin-bottom: 15px;
  text-decoration: none;
  font-weight: bold;
  color: #9a947e;
}
.ec-displayB .ec-displayB__comment {
  margin-bottom: 14px;
  text-decoration: none;
  color: #525263;
  font-size: 14px;
}
.ec-displayB .ec-displayB__link {
  text-decoration: none;
  font-weight: bold;
  color: #9a947e;
}

/*
商品アイテム（商品紹介C）

４項目横並びの商品アイテムを表示します。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayC
p hoge

Styleguide 7.1.2
*/
.ec-displayC {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px;
}
.ec-displayC .ec-displayC__cell {
  width: 47%;
}
.ec-displayC .ec-displayC__cell a {
  color: inherit;
  text-decoration: none;
}
.ec-displayC .ec-displayC__cell a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-displayC .ec-displayC__cell {
    width: 22.8775%;
  }
}
.ec-displayC .ec-displayC__cell:hover a {
  text-decoration: none;
}
.ec-displayC .ec-displayC__cell:hover img {
  opacity: 0.8;
}
.ec-displayC .ec-displayC__img {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
.ec-displayC .ec-displayC__catch {
  display: block;
  width: 100%;
  font-weight: bold;
  color: #9a947e;
}
.ec-displayC .ec-displayC__title {
  display: block;
  width: 100%;
  color: #525263;
}
.ec-displayC .ec-displayC__price {
  display: block;
  width: 100%;
  font-weight: bold;
  color: #525263;
}
.ec-displayC .ec-displayC__price--sp {
  display: block;
  width: 100%;
  font-weight: bold;
  color: #DE5D50;
}

/*
商品アイテム（商品紹介D）

６項目横並びの商品アイテムを表示します。

ex [トップページ　商品紹介部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/7.1.itembanner.pug
+ec-displayD

Styleguide 7.1.3
*/
.ec-displayD {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}
@media only screen and (min-width: 768px) {
  .ec-displayD {
    box-sizing: border-box;
    flex-wrap: nowrap;
  }
}
.ec-displayD .ec-displayD__cell {
  width: 30%;
  margin-bottom: 8px;
}
.ec-displayD .ec-displayD__cell a {
  color: inherit;
  text-decoration: none;
}
.ec-displayD .ec-displayD__cell a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-displayD .ec-displayD__cell {
    width: 14.3083%;
    margin-bottom: 16px;
  }
}
.ec-displayD .ec-displayD__cell:hover {
  text-decoration: none;
}
.ec-displayD .ec-displayD__cell:hover img {
  opacity: 0.8;
}
.ec-displayD .ec-displayD__img {
  display: block;
  width: 100%;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
検索・一覧表示

検索欄や、一覧表示に使用するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.2
*/
/*
トピックパス

検索結果で表示されるトピックパスのスタイルです。

ex [商品一覧ページ　横並びリスト部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-topicpath

Styleguide 7.2.1
*/
.ec-searchnavRole__topicpath {
  position: relative;
  margin-top: 24px;
  padding: 28px 5px 50px;
  background-image: linear-gradient(transparent 8%, rgba(245, 86, 139, 0.1) 8%, rgba(245, 86, 139, 0.1) 14%, transparent 14%, transparent 86%, rgba(245, 86, 139, 0.1) 86%, rgba(245, 86, 139, 0.1) 92%, transparent 92%), linear-gradient(45deg, transparent 40%, rgba(245, 86, 0, 0.1) 40%, rgba(245, 86, 0, 0.1) 60%, transparent 60%), linear-gradient(white, white);
  background-size: cover, 4px 4px, cover;
}
@media only screen and (min-width: 768px) {
  .ec-searchnavRole__topicpath {
    border: 1px solid green;
    border: 0;
  }
}
.ec-searchnavRole__topicpath .ec-topicpath {
  position: relative;
  margin: 0 auto;
  width: 100%;
  text-transform: uppercase;
  color: #f5568b;
}
@media only screen and (min-width: 768px) {
  .ec-searchnavRole__topicpath .ec-topicpath {
    padding: 0;
    border: 0;
    font-size: 16px;
  }
}
.ec-searchnavRole__topicpath .ec-topicpath li {
  float: left;
  color: #333;
  font-size: 16px;
  font-size: 1.6rem;
}
.ec-searchnavRole__topicpath .ec-topicpath li a {
  color: #f5568b !important;
}
.ec-searchnavRole__topicpath .ec-topicpath li:first-child {
  font-weight: bold;
}
.ec-searchnavRole__topicpath .ec-topicpath li a {
  float: left;
  display: inline-block;
}
.ec-searchnavRole__topicpath .ec-topicpath .ec-searchnavRole__topicpath .ec-topicpath__item a {
  color: inherit;
  text-decoration: none;
}
.ec-searchnavRole__topicpath .ec-topicpath .ec-searchnavRole__topicpath .ec-topicpath__item a:hover {
  text-decoration: none;
}
.ec-searchnavRole__topicpath .ec-topicpath .ec-searchnavRole__topicpath .ec-topicpath__divider {
  color: #000;
}
.ec-searchnavRole__topicpath .ec-topicpath .ec-searchnavRole__topicpath .ec-topicpath__item, .ec-searchnavRole__topicpath .ec-topicpath .ec-searchnavRole__topicpath .ec-topicpath__divider, .ec-searchnavRole__topicpath .ec-topicpath .ec-searchnavRole__topicpath .ec-topicpath__item--active {
  display: inline-block;
  min-width: 16px;
  text-align: center;
  position: relative;
  letter-spacing: normal;
}
.ec-searchnavRole__topicpath .ec-topicpath .ec-searchnavRole__topicpath .ec-topicpath__item--active {
  font-weight: bold;
}
.ec-searchnavRole__topicpath .ec-topicpath .ec-searchnavRole__topicpath .ec-topicpath__item--active a {
  color: inherit;
  text-decoration: none;
}
.ec-searchnavRole__topicpath .ec-topicpath .ec-searchnavRole__topicpath .ec-topicpath__item--active a:hover {
  text-decoration: none;
}
.ec-searchnavRole__topicpath p.topic-num {
  position: absolute;
  padding: 6px;
  border-radius: 5px;
  border: 1px solid #f2f2f2;
  font-size: 12px;
  top: 25px;
  right: 10px;
  background: #fff;
  color: #333;
}

/*
ページャ

検索結果で表示される商品一覧のスタイルです。

ex [商品一覧ページ　ページャ部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-pager

Styleguide 7.2.2
*/
.ec-pager {
  list-style: none;
  list-style-type: none;
  margin: 0 auto;
  padding: 1em 0;
  text-align: center;
}
.ec-pager .ec-pager__item, .ec-pager .ec-pager__item--active {
  display: inline-block;
  min-width: 29px;
  padding: 0 3px 0 2px;
  text-align: center;
  position: relative;
}
.ec-pager .ec-pager__item a, .ec-pager .ec-pager__item--active a {
  color: inherit;
  text-decoration: none;
}
.ec-pager .ec-pager__item a:hover, .ec-pager .ec-pager__item--active a:hover {
  text-decoration: none;
}
.ec-pager .ec-pager__item a, .ec-pager .ec-pager__item--active a {
  color: inherit;
  display: block;
  line-height: 1.8;
  padding: 5px 1em;
  text-decoration: none;
}
.ec-pager .ec-pager__item a:hover, .ec-pager .ec-pager__item--active a:hover {
  color: inherit;
}
.ec-pager .ec-pager__item--active {
  background: #F3F3F3;
}
.ec-pager .ec-pager__item:hover {
  background: #F3F3F3;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1;
}

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
カート

ショッピングカートに関するスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 7.3
*/
/*
カートヘッダ

購入完了までの手順や、現在の状態を表示します。

ul 要素を用いたリスト要素としてマークアップします。

ex [カートページ　ヘッダ部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-progress

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.3.1
*/
.ec-progress {
  margin: 0 auto;
  padding: 8px 0 16px;
  display: table;
  table-layout: fixed;
  width: 100%;
  max-width: 600px;
  list-style: none;
}
@media only screen and (min-width: 768px) {
  .ec-progress {
    margin-bottom: 30px;
    padding: 0;
  }
}
.ec-progress .ec-progress__item {
  display: table-cell;
  position: relative;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  z-index: 10;
}
.ec-progress .ec-progress__item:after {
  content: "";
  position: absolute;
  display: block;
  background: #525263;
  width: 100%;
  height: 0.25em;
  top: 1.25em;
  left: 50%;
  margin-left: 1.5em\9 ;
  z-index: -1;
}
.ec-progress .ec-progress__item:last-child:after {
  display: none;
}
.ec-progress .ec-progress__number {
  line-height: 30px;
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  font-size: 12px;
  background: #525263;
  color: #fff;
  top: 0;
  left: 18px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
}
@media only screen and (min-width: 768px) {
  .ec-progress .ec-progress__number {
    line-height: 42px;
    width: 42px;
    height: 42px;
    font-size: 20px;
  }
}
.ec-progress .ec-progress__label {
  font-size: 12px;
  color: #f5568b;
}
.ec-progress .is-complete .ec-progress__number {
  background: #f5568b;
}
.ec-progress .is-complete .ec-progress__label {
  color: #f5568b;
}

/*
カートナビゲーション

カートナビゲーションを表示します。　カートに追加された商品の個数も表示します。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerCart

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.5
*/
.ec-cartNaviWrap {
  border: none;
}
@media only screen and (min-width: 1025px) {
  .ec-cartNaviWrap {
    position: relative;
    border: 4px solid #fff7f7;
  }
}

.ec-cartNavi {
  display: inline-block;
  padding: 10px 0 0 20px;
  width: auto;
  color: black;
  background: transparent;
  border: none;
}
@media only screen and (min-width: 768px) {
  .ec-cartNavi {
    display: flex;
    justify-content: space-between;
    border-radius: 0;
    box-sizing: border-box;
    padding: 12px 17px 10px;
    width: auto;
    min-width: 140px;
    height: 44px;
    white-space: nowrap;
    cursor: pointer;
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
  }
}
.ec-cartNavi .ec-cartNavi__icon {
  display: inline-block;
  font-size: 20px;
  display: inline-block;
  opacity: 1;
  visibility: visible;
  animation: fadeIn 200ms linear 0s;
  position: relative;
}
.ec-cartNavi .ec-cartNavi__badge {
  display: inline-block;
  border-radius: 99999px;
  box-sizing: border-box;
  padding: 5px;
  height: 17px;
  font-size: 10px;
  line-height: 0.7;
  vertical-align: top;
  color: #fff;
  text-align: left;
  white-space: nowrap;
  background-color: #DE5D50;
  position: absolute;
  right: -9px;
  top: -12px;
}
@media only screen and (min-width: 1024px) {
  .ec-cartNavi .ec-cartNavi__badge {
    display: inline-block;
    min-width: 17px;
    position: relative;
    left: 0;
    top: 0;
  }
}
.ec-cartNavi .ec-cartNavi__price {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-cartNavi .ec-cartNavi__price {
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
  }
}

.ec-cartNavi.is-active .ec-cartNavi__icon:before {
  content: "\f00d";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.ec-cartNavi.is-active .ec-cartNavi__badge {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-cartNavi.is-active .ec-cartNavi__badge {
    display: none;
  }
}

/*
カートナビゲーションのポップアップ(商品詳細)

カートナビゲーションのポップアップを表示します。カートに追加された商品の詳細が表示されます。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:350px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        img(src='/moc/icon/cart-dark.svg', alt='close')
      .ec-cartNavi__iconClose
        img(src='/moc/icon/cross-dark.svg', alt='close')
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    +b.ec-cartNaviIsset
      +e.cart
        +e.cartImage
          img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
        +e.cartContent
          +e.cartContentTitle ミニテーブル
          +e.cartContentPrice ¥ 12,960
            +e.cartContentTax 税込
          +e.cartContentNumber 数量：1
      +e.action
        a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
        a.ec-blockBtn.ec-cartNavi--cancel キャンセル

Styleguide 7.3.6
*/
.ec-cartNaviIsset {
  display: none;
  width: 100%;
  text-align: center;
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 14px;
  z-index: 20;
  position: absolute;
  right: 0;
}
.ec-cartNaviIsset .sample-area {
  background: url("../img/common/th_bg_yellow.gif") repeat scroll 0 0;
}
.ec-cartNaviIsset .sample-area .ec-cartNaviIsset__cartContent span.ic {
  display: inline-block;
  background: #de5d50;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  padding: 3px;
}
.ec-cartNaviIsset .sample-area .ec-cartNaviIsset__cartContent span {
  margin-bottom: 8px;
  font-size: 14px;
  color: #f5568b;
}
@media only screen and (min-width: 768px) {
  .ec-cartNaviIsset {
    margin-top: 10px;
    min-width: 256px;
    max-width: 256px;
  }
  .ec-cartNaviIsset::before {
    display: inline-block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8.5px 10px 8.5px;
    border-color: transparent transparent #f8f8f8 transparent;
    position: absolute;
    top: -9px;
  }
}
.ec-cartNaviIsset .ec-cartNaviIsset__cart {
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 16px;
  padding: 5px 5px 32px;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cart:after {
  content: " ";
  display: table;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cart:after {
  clear: both;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartImage {
  float: left;
  width: 15%;
}
@media only screen and (min-width: 1024px) {
  .ec-cartNaviIsset .ec-cartNaviIsset__cartImage {
    max-width: 80px;
    width: 23%;
  }
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartImage img {
  width: 100%;
  border: 1px solid #CCC;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContent {
  float: right;
  width: 83%;
  padding-left: 16px;
  text-align: left;
  box-sizing: border-box;
}
@media only screen and (min-width: 1024px) {
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContent {
    padding-left: 7px;
    width: 75%;
  }
}
.ec-cartNaviIsset .ec-cartNaviIsset__action .ec-blockBtn--action {
  color: #fff;
  margin-bottom: 8px;
}
.ec-cartNaviIsset .ec-cartNaviIsset__action .ec-cartNavi--cancel {
  color: #fff;
  background: #424d6a !important;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentTitle {
  margin-bottom: 8px;
  font-size: 14px;
  color: #f5568b;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentPrice {
  font-weight: bold;
  font-size: 14px;
  color: #f5568b;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentTax {
  display: inline-block;
  font-size: 10px;
  font-weight: normal;
  margin-left: 2px;
}
.ec-cartNaviIsset .ec-cartNaviIsset__cartContentNumber {
  font-size: 12px;
}

.ec-cartNaviIsset.is-active {
  display: block;
}

/*
カートナビゲーションのポップアップ(商品なし)

カートナビゲーションのポップアップを表示します。商品が登録されていない場合の表示です。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:170px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        img(src='/moc/icon/cart-dark.svg', alt='cart')
      .ec-cartNavi__iconClose
        img(src='/moc/icon/cross-dark.svg', alt='close')
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    .ec-cartNaviNull
      .ec-cartNaviNull__message
        p 現在カート内に
          br
          | 商品がございません。
    //+b.ec-cartNaviIsset
    //  +e.cart
    //    +e.cartImage
    //      img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
    //    +e.cartContent
    //      +e.cartContentTitle ミニテーブル
    //      +e.cartContentPrice ¥ 12,960
    //        +e.cartContentTax 税込
    //      +e.cartContentNumber 数量：1
    //  +e.action
    //    a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
    //    a.ec-blockBtn キャンセル

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.7
*/
.ec-cartNaviNull {
  display: none;
  width: 100%;
  text-align: center;
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 16px;
  z-index: 3;
  position: absolute;
  right: 0;
}
@media only screen and (min-width: 768px) {
  .ec-cartNaviNull {
    margin-top: 10px;
    min-width: 300px;
    max-width: 300px;
  }
  .ec-cartNaviNull::before {
    display: inline-block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8.5px 10px 8.5px;
    border-color: transparent transparent #f8f8f8 transparent;
    position: absolute;
    top: -9px;
  }
}
.ec-cartNaviNull .ec-cartNaviNull__message {
  border: 1px solid #D9D9D9;
  padding: 16px 0;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #F99;
}
.ec-cartNaviNull .ec-cartNaviNull__message p {
  margin: 0;
}

.ec-cartNaviNull.is-active {
  display: block;
}

/*
総計

会計時の合計金額、総計を表示します。

ex [カートページ　統計部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-totalBox

Styleguide 7.3.8
*/
.ec-totalBox {
  background: #FBFDE5;
  padding: 16px;
  margin-bottom: 16px;
}
.ec-totalBox h2, .ec-totalBox .h2 {
  margin: 0 auto 0.5rem;
  padding: 5px 0;
  text-align: center;
  font-size: 18px;
  font-size: 1.8rem;
  background: #ff6c00;
  color: #fff;
}
.ec-totalBox .ec-totalBox__spec {
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 8px;
}
.ec-totalBox .ec-totalBox__spec dt {
  font-weight: normal;
  text-align: left;
}
.ec-totalBox .ec-totalBox__spec dd {
  text-align: right;
}
.ec-totalBox .ec-totalBox__spec .ec-totalBox .ec-totalBox__spec__specTotal {
  color: #DE5D50;
}
.ec-totalBox .ec-totalBox__total {
  border-top: 1px dotted #ccc;
  padding: 8px 0;
  text-align: right;
  font-size: 14px;
  font-weight: bold;
}
.ec-totalBox .ec-totalBox__paymentTotal {
  border-top: 1px dotted #ccc;
  padding: 8px 0;
  text-align: right;
  font-size: 14px;
  font-weight: bold;
}
.ec-totalBox .ec-totalBox__paymentTotal .ec-totalBox__price,
.ec-totalBox .ec-totalBox__paymentTotal .ec-totalBox__taxLabel {
  color: #DE5D50;
}
.ec-totalBox .ec-totalBox__price {
  margin-left: 16px;
  font-size: 16px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-totalBox .ec-totalBox__price {
    font-size: 24px;
  }
}
.ec-totalBox .ec-totalBox__taxLabel {
  margin-left: 8px;
  font-size: 12px;
}
@media only screen and (min-width: 768px) {
  .ec-totalBox .ec-totalBox__taxLabel {
    font-size: 14px;
  }
}
.ec-totalBox .ec-totalBox__taxRate {
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 8px;
  font-size: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-totalBox .ec-totalBox__taxRate {
    font-size: 12px;
  }
}
.ec-totalBox .ec-totalBox__taxRate dt {
  font-weight: normal;
  text-align: left;
  margin-right: 8px;
}
.ec-totalBox .ec-totalBox__taxRate dt::before {
  content: "[ ";
}
.ec-totalBox .ec-totalBox__taxRate dd {
  text-align: right;
}
.ec-totalBox .ec-totalBox__taxRate dd::after {
  content: " ]";
}
.ec-totalBox .ec-totalBox__pointBlock {
  padding: 18px 20px 10px;
  margin-bottom: 10px;
  background: #fff;
}
.ec-totalBox .ec-totalBox__btn {
  color: #fff;
}
.ec-totalBox .ec-totalBox__btn a {
  color: inherit;
  text-decoration: none;
}
.ec-totalBox .ec-totalBox__btn a:hover {
  text-decoration: none;
}
.ec-totalBox .ec-totalBox__btn .ec-blockBtn--action {
  font-size: 16px;
  font-weight: bold;
}
.ec-totalBox .ec-totalBox__btn .ec-blockBtn--cancel {
  margin-top: 8px;
}
.ec-totalBox .total-bg {
  background: #ffdec5;
  color: #de5d50;
}

#history-bg {
  padding: 5px;
  background: url("../img/common/th_bg.gif") repeat scroll 0 0;
}
@media only screen and (min-width: 768px) {
  #history-bg {
    font-size: 1.8rem;
  }
}
#history-bg::before {
  content: "\f10c";
  color: #fc6a72;
  font-family: "FontAwesome";
  padding: 0 7px 0 0;
  font-size: 2rem;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お知らせ

新着情報やバナーなどの掲載項目を紹介していきます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 8.1
*/
/*
新着情報

新着情報の掲載をします。

ex [トップページ　新着情報部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+ec-news

Styleguide 8.1.1
*/
.ec-news {
  margin-bottom: 16px;
  background: #F8F8F8;
}
@media only screen and (min-width: 768px) {
  .ec-news {
    margin-right: 3%;
  }
}
@media only screen and (min-width: 768px) {
  .ec-news {
    margin-bottom: 32px;
  }
}
.ec-news .ec-news__title {
  font-weight: bold;
  padding: 8px;
  font-size: 16px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .ec-news .ec-news__title {
    padding: 16px;
    text-align: left;
    font-size: 24px;
  }
}
.ec-news .ec-news__items {
  padding: 0;
  list-style: none;
  border-top: 1px dotted #ccc;
}

/*
折りたたみ項目

折りたたみ項目を掲載します。

ex [トップページ　折りたたみ項目部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+b.ec-news
        +e.title 新着情報
        +e.UL.items
            +e.LI.item
                +b.ec-newsline.is_active
                    +e.info
                        +e.date 2016/09/29
                        +e.comment サイトオープンしました
                        +e.close
                            a.ec-closeBtn--circle
                                span.ec-closeBtn--circle__icon
                                    .ec-icon
                                        img(src='/moc/icon/angle-down-white.svg', alt='')
                    +e.description 一人暮らしからオフィスなどさまざまなシーンで あなたの生活をサポートするグッズをご家庭へお届けします！

Styleguide 8.1.2
*/
.ec-newsline {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0 16px;
}
.ec-newsline .ec-newsline__info {
  width: 100%;
  padding: 16px 0;
}
.ec-newsline .ec-newsline__info:after {
  content: " ";
  display: table;
}
.ec-newsline .ec-newsline__info:after {
  clear: both;
}
.ec-newsline .ec-newsline__date {
  display: inline-block;
  margin-right: 10px;
  float: left;
}
.ec-newsline .ec-newsline__comment {
  display: inline-block;
  float: left;
}
.ec-newsline .ec-newsline__close {
  float: right;
  display: inline-block;
  text-align: right;
}
.ec-newsline .ec-newsline__close .ec-closeBtn--circle {
  display: inline-block;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
}
.ec-newsline .ec-newsline__description {
  width: 100%;
  height: 0;
  transition: all 0.2s ease-out;
}
.ec-newsline.is_active .ec-newsline__description {
  height: auto;
  transition: all 0.2s ease-out;
  padding-bottom: 16px;
}
.ec-newsline.is_active .ec-icon img {
  transform: rotateX(180deg);
}

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
マイページ

マイページで利用するためのスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 9.1
*/
/*
マイページ

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist

Styleguide 9.1.1
*/
.ec-navlistRole .ec-navlistRole__navlist {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
  padding: 0;
  list-style: none;
  font-size: 14px;
  font-size: 1.4rem;
}
.ec-navlistRole .ec-navlistRole__navlist a {
  color: inherit;
  text-decoration: none;
}
.ec-navlistRole .ec-navlistRole__navlist a:hover {
  text-decoration: none;
}
.ec-navlistRole .ec-navlistRole__navlist::before {
  content: " ";
  display: table;
}
@media only screen and (min-width: 768px) {
  .ec-navlistRole .ec-navlistRole__navlist {
    flex-wrap: nowrap;
  }
}
.ec-navlistRole .ec-navlistRole__item {
  display: flex;
  width: 48%;
  margin: 1%;
  text-align: center;
  font-weight: bold;
  background: #FFF0F0;
}
@media only screen and (min-width: 768px) {
  .ec-navlistRole .ec-navlistRole__item {
    border-right: 1px solid #ffa3a3;
    margin: 0 auto;
  }
  .ec-navlistRole .ec-navlistRole__item:first-child {
    border-radius: 10px 0 0 10px;
  }
  .ec-navlistRole .ec-navlistRole__item:last-child {
    border-radius: 0 10px 10px 0;
    border-right: none;
  }
}
.ec-navlistRole .ec-navlistRole__item:hover {
  background: #ffcfcf;
}
.ec-navlistRole .ec-navlistRole__item a {
  padding: 1.2rem 0.5rem !important;
  font-weight: bold;
  color: #727272;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 auto;
  transition: all 0.3s ease;
}
.ec-navlistRole .ec-navlistRole__item a.my-rireki:before {
  content: "\f07a";
  font-family: "FontAwesome";
  padding: 0 7px 0 0;
  font-size: 1.5rem;
}
.ec-navlistRole .ec-navlistRole__item a.my-favorite:before {
  content: "\f004";
  font-family: "FontAwesome";
  padding: 0 7px 0 0;
  font-size: 1.5rem;
}
.ec-navlistRole .ec-navlistRole__item a.my-member:before {
  content: "\f007";
  font-family: "FontAwesome";
  padding: 0 7px 0 0;
  font-size: 1.5rem;
}
.ec-navlistRole .ec-navlistRole__item a.my-deliver:before {
  content: "\f0d1";
  font-family: "FontAwesome";
  padding: 0 7px 0 0;
  font-size: 1.5rem;
}
.ec-navlistRole .ec-navlistRole__item a.my-withdrawal:before {
  content: "\f08b";
  font-family: "FontAwesome";
  padding: 0 7px 0 0;
  font-size: 1.5rem;
}
.ec-navlistRole .active a {
  color: #DE5D50;
}

/*
マイページ（お気に入り機能無効）

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist_noFavorite

Styleguide 9.1.2
*/
/*
Welcome メッセージ

マイページで表示するログイン名の表示コンポーネントです。

ex [マイページ　メニューリスト下部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-welcomeMsg

Styleguide 9.1.3
*/
.ec-welcomeMsg {
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  font-size: 1.7rem;
  font-size: 17px;
  font-weight: bold;
  margin: 1em 0;
  padding-bottom: 32px;
  text-align: center;
}
.ec-welcomeMsg:after {
  content: " ";
  display: table;
}
.ec-welcomeMsg:after {
  clear: both;
}
.ec-welcomeMsg textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-welcomeMsg img {
  max-width: 100%;
}
.ec-welcomeMsg html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-welcomeMsg *,
.ec-welcomeMsg *::before,
.ec-welcomeMsg *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-welcomeMsg img {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-welcomeMsg {
    padding-left: 26px;
    padding-right: 26px;
  }
}
@media only screen and (min-width: 768px) {
  .ec-welcomeMsg {
    font-size: 20px;
    font-size: 2rem;
  }
}

/*
お気に入り一覧

お気に入り一覧で表示するアイテムの表示コンポーネントです。

ex [マイページ　お気に入り一覧](http://demo3.ec-cube.net/mypage/favorite)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-favorite

Styleguide 9.1.4
*/
.ec-favoriteRole .ec-favoriteRole__header {
  margin-bottom: 16px;
}
.ec-favoriteRole .ec-favoriteRole__header p span {
  color: #f5568b;
  font-weight: bold;
}
.ec-favoriteRole .ec-favoriteRole__itemList {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}
.ec-favoriteRole .ec-favoriteRole__itemList a {
  color: inherit;
  text-decoration: none;
}
.ec-favoriteRole .ec-favoriteRole__itemList a:hover {
  text-decoration: none;
}
.ec-favoriteRole .ec-favoriteRole__item {
  margin-bottom: 8px;
  width: 47.5%;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
}
.ec-favoriteRole .ec-favoriteRole__item-image {
  margin-bottom: 10px;
  text-align: center;
}
.ec-favoriteRole .ec-favoriteRole__item img {
  width: auto;
  max-height: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-favoriteRole .ec-favoriteRole__item {
    width: 25%;
  }
}
.ec-favoriteRole .ec-favoriteRole__item .ec-closeBtn--circle {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
}
.ec-favoriteRole .ec-favoriteRole__item .ec-closeBtn--circle .ec-icon img {
  width: 1em;
  height: 1em;
}
.ec-favoriteRole .ec-favoriteRole__itemThumb {
  display: block;
  height: auto;
  margin-bottom: 8px;
}
.ec-favoriteRole .ec-favoriteRole__itemThumb img {
  border-radius: 10px;
  border: 5px solid #ffe2e2;
}
.ec-favoriteRole .ec-favoriteRole__itemTitle {
  margin: 0 auto 2px;
  text-align: center;
  color: #f5568b;
}
.ec-favoriteRole .ec-favoriteRole__itemPrice {
  font-weight: bold;
  margin: 0 auto;
  text-align: center;
  color: #f5568b;
  font-size: 13px;
  font-size: 1.3rem;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
標準セクション

通常のコンテナブロックです。

ex [商品詳細ページ　コンテナ](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-roleRole

Styleguide 11.1
*/
.ec-role {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-role:after {
  content: " ";
  display: table;
}
.ec-role:after {
  clear: both;
}
.ec-role textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-role img {
  max-width: 100%;
}
.ec-role html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-role *,
.ec-role *::before,
.ec-role *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-role img {
  width: 100%;
}

/*
マイページセクション

マイページ専用のコンテナブロックです。

ex [マイページ　コンテナ](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-mypageRole

Styleguide 11.1.2
*/
.ec-mypageRole {
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
}
.ec-mypageRole:after {
  content: " ";
  display: table;
}
.ec-mypageRole:after {
  clear: both;
}
.ec-mypageRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-mypageRole img {
  max-width: 100%;
}
.ec-mypageRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-mypageRole *,
.ec-mypageRole *::before,
.ec-mypageRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-mypageRole img {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-mypageRole {
    padding-left: 26px;
    padding-right: 26px;
  }
}
@media only screen and (min-width: 768px) {
  .ec-mypageRole .ec-pageHeader h1, .ec-mypageRole .ec-pageHeader .h1 {
    margin: 10px 0 48px;
    padding: 8px 0 18px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1;
}

/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [トップページ　ヘッダー](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.2.header.pug
include /assets/tmpl/elements/11.3.headerNavi.pug
include /assets/tmpl/elements/11.4.categoryNavi.pug
+b.ec-layoutRole
  +e.header
    +ec-headerRole
    +ec-headerNaviRole
    +ec-categoryNaviRole

Styleguide 11.2
*/
.ec-layoutRole {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;
  transition: transform 0.3s;
  background: #fff;
}
.ec-layoutRole .ec-layoutRole__contentTop {
  padding: 0;
}
.ec-layoutRole .ec-layoutRole__contents {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
}
.ec-layoutRole .ec-layoutRole__main {
  width: 100%;
}
.ec-layoutRole .ec-layoutRole__mainWithColumn {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-layoutRole .ec-layoutRole__mainWithColumn {
    width: 75%;
  }
}
.ec-layoutRole .ec-layoutRole__mainBetweenColumn {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-layoutRole .ec-layoutRole__mainBetweenColumn {
    width: 50%;
  }
}
.ec-layoutRole .ec-layoutRole__left, .ec-layoutRole .ec-layoutRole__right {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-layoutRole .ec-layoutRole__left, .ec-layoutRole .ec-layoutRole__right {
    display: block;
    width: 25%;
  }
}

.ec-headerNaviRole {
  width: 100%;
  background: #fff;
  padding: 7px 0;
  border-top: 5px solid #f5568b;
}
.ec-headerNaviRole .ec-headerNaviRole__left {
  width: 68%;
}
@media only screen and (min-width: 1024px) {
  .ec-headerNaviRole .ec-headerNaviRole__left {
    width: 30%;
  }
  .ec-headerNaviRole .ec-headerNaviRole__left a {
    color: inherit;
    text-decoration: none;
  }
  .ec-headerNaviRole .ec-headerNaviRole__left a:hover {
    text-decoration: none;
  }
}
.ec-headerNaviRole .ec-headerNaviRole__middle {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .ec-headerNaviRole .ec-headerNaviRole__middle {
    display: block;
    width: 45%;
  }
  .ec-headerNaviRole .ec-headerNaviRole__middle a {
    color: inherit;
    text-decoration: none;
  }
  .ec-headerNaviRole .ec-headerNaviRole__middle a:hover {
    text-decoration: none;
  }
}
.ec-headerNaviRole .ec-headerNaviRole__right {
  width: 28%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (min-width: 1024px) {
  .ec-headerNaviRole .ec-headerNaviRole__right {
    width: 20%;
    display: block;
  }
  .ec-headerNaviRole .ec-headerNaviRole__right .ec-headerRole__contact {
    display: none;
  }
  .ec-headerNaviRole .ec-headerNaviRole__right .ec-headerRole__cart {
    width: auto;
    height: auto;
    background: none;
    color: #000;
  }
  .ec-headerNaviRole .ec-headerNaviRole__right .ec-headerRole__cart .ec-cartNavi {
    padding: 14px 0 0 10px;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .ec-headerNaviRole .ec-headerNaviRole__right .ec-headerRole__cart .ec-cartNavi {
    padding: 10px 14px 0 10px;
  }
}
@media only screen and (min-width: 1024px) {
  .ec-headerNaviRole .ec-headerNaviRole__right a {
    color: inherit;
    text-decoration: none;
  }
}
@media only screen and (min-width: 1024px) {
  .ec-headerNaviRole .ec-headerNaviRole__right a:hover {
    text-decoration: none;
  }
}
.ec-headerNaviRole .ec-headerNaviRole__right .ec-headerRole__contact {
  width: 45px;
  height: 45px;
  background: #FEEEF3;
  text-align: center;
}
.ec-headerNaviRole .ec-headerNaviRole__right .ec-headerRole__contact a {
  display: block;
  padding-top: 6px;
  font-size: 21px;
  line-height: 0.2;
  color: #000;
}
.ec-headerNaviRole .ec-headerNaviRole__right .ec-headerRole__contact a svg {
  width: 100%;
}
.ec-headerNaviRole .ec-headerNaviRole__right .ec-headerRole__contact a span {
  font-size: 0.35em;
  line-height: 1;
  display: inline-block;
}
.ec-headerNaviRole .ec-headerNaviRole__right .ec-headerRole__cart {
  width: 45px;
  height: 45px;
  background: #efefef;
}
@media only screen and (min-width: 1024px) {
  .ec-headerNaviRole .ec-headerNaviRole__right .ec-headerRole__cart {
    display: block;
  }
}
.ec-headerNaviRole .ec-headerNaviRole__right .ec-headerRole__cart .ec-cartNavi {
  padding: 14px 0 0 10px;
}
.ec-headerNaviRole .inblock {
  margin: 0 auto;
  max-width: 1024px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (min-width: 768px) {
  .ec-headerNaviRole .inblock {
    padding: 0 7px;
  }
}
.ec-headerNaviRole .fa-bars {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 768px) {
  .ec-headerNaviRole {
    background: #fff url(../img/common/head_bg.png) 0 0 no-repeat;
  }
  .ec-headerNaviRole .ec-headerNaviRole__middle {
    display: block;
  }
  .ec-headerNaviRole .ec-headerNaviRole__middle ul {
    list-style-type: none;
  }
  .ec-headerNaviRole .ec-headerNaviRole__middle #member {
    margin: 5px auto 1em;
    background: #feeef3;
    border-radius: 15px;
    padding: 0 4px;
    height: 30px;
    line-height: 30px;
  }
  .ec-headerNaviRole .ec-headerNaviRole__middle #member ul.member_link {
    margin: 0;
    padding-left: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ec-headerNaviRole .ec-headerNaviRole__middle #member ul.member_link li {
    padding: 0 !important;
    margin: 0 5px !important;
  }
  .ec-headerNaviRole .ec-headerNaviRole__middle #member ul.member_link li a {
    display: block;
    padding: 0 5px;
    border: 0 none;
    font-size: 13px;
    font-size: 1.3rem;
    height: 30px;
    line-height: 30px;
    white-space: nowrap;
    color: #333;
  }
  .ec-headerNaviRole .ec-headerNaviRole__middle #member ul.member_link li a:hover {
    opacity: 0.6;
  }
  .ec-headerNaviRole .ec-headerNaviRole__middle #member ul.member_link li a.h-mypage::before {
    content: "\f004";
    color: #f5568b;
    font-family: "FontAwesome";
    padding: 0 7px 0 0;
    font-size: 1.4rem;
    font-size: 14px;
  }
  .ec-headerNaviRole .ec-headerNaviRole__middle #member ul.member_link li a.h-login::before {
    content: "\f023";
    color: #34babe;
    font-family: "FontAwesome";
    padding: 0 7px 0 0;
    font-size: 1.4rem;
    font-size: 14px;
  }
  .ec-headerNaviRole .ec-headerNaviRole__middle #member ul.member_link li a.h-member::before {
    content: "\f007";
    color: #f26363;
    font-family: "FontAwesome";
    padding: 0 7px 0 0;
    font-size: 1.4rem;
    font-size: 14px;
  }
}
@media only screen and (min-width: 768px) and (min-width: 768px) {
  .ec-headerNaviRole .ec-headerNaviRole__middle #member ul.member_link li {
    color: #525263;
    display: block;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    padding: 0 10px;
    text-overflow: ellipsis;
    transform: translateZ(0px);
    transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
    white-space: nowrap;
  }
  .ec-headerNaviRole .ec-headerNaviRole__middle #member ul.member_link li:hover {
    color: #9797a1;
  }
}
.ec-headerNaviRole .ec-headerNaviRole__search {
  width: 100%;
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-headerNaviRole .ec-headerNaviRole__search {
    display: inline-block;
    margin-top: 10px;
  }
  .ec-headerNaviRole .ec-headerNaviRole__search a {
    color: inherit;
    text-decoration: none;
  }
  .ec-headerNaviRole .ec-headerNaviRole__search a:hover {
    text-decoration: none;
  }
}
.ec-headerNaviRole .ec-headerNaviRole__nav {
  display: inline-block;
}
.ec-headerNaviRole .ec-headerNaviRole__nav a {
  color: inherit;
  text-decoration: none;
}
.ec-headerNaviRole .ec-headerNaviRole__nav a:hover {
  text-decoration: none;
}
.ec-headerNaviRole .ec-headerNaviRole__cart {
  display: inline-block;
}
.ec-headerNaviRole .ec-headerNaviRole__cart a {
  color: inherit;
  text-decoration: none;
}
.ec-headerNaviRole .ec-headerNaviRole__cart a:hover {
  text-decoration: none;
}

.ec-headerRole {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto;
}
.ec-headerRole:after {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-headerRole:after {
    content: " ";
    display: table;
  }
  .ec-headerRole:after {
    clear: both;
  }
}
.ec-headerRole::before {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-headerRole {
    width: 100%;
  }
  .ec-headerRole:after {
    content: " ";
    display: table;
  }
  .ec-headerRole:after {
    clear: both;
  }
}
.ec-headerRole .ec-headerRole__title {
  width: 100%;
}
.ec-headerRole .ec-headerRole__navSP {
  display: block;
  position: absolute;
  top: 15px;
  width: 27%;
  right: 0;
  text-align: right;
  background: transparent;
}

#category ul {
  display: flex;
  justify-content: center;
}
#category ul li {
  position: relative;
}
#category ul li ul.menu {
  position: absolute;
  z-index: 9999;
}

ul.menu {
  position: relative;
}

.menu > li:hover {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.menu__second-level {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.menu__second-level li {
  font-size: 14px;
  font-weight: bold;
  width: 33.3%;
}

/* floatクリア */
li.menu__mega ul.menu__second-level {
  position: absolute;
  margin: auto;
  left: -240px;
  width: 640px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  background: #fbfaf2;
  box-sizing: border-box;
  padding: 0 2% 20px;
  color: #072A24;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: start !important;
  z-index: 999;
}
li.menu__mega ul.menu__second-level li a {
  display: block !important;
  background: #FEEEF3;
  color: #515151;
  margin: 5% 1%;
  padding: 0 10px 20px;
  width: 98%;
  height: 90%;
  height: 40px;
  line-height: 40px;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}
li.menu__mega ul.menu__second-level li a:hover {
  background: #f6f5ee;
}

ul.menu__second-level {
  visibility: hidden;
  opacity: 0;
  z-index: 1;
}

li.menu__mega:hover ul.menu__second-level {
  top: 50px;
  visibility: visible;
  opacity: 1;
}

li.menu__mega ul.menu__second-level > li {
  width: 32%;
  border: none;
}

li.menu__mega ul.menu__second-level > li a {
  display: block;
}

li.menu__mega ul.menu__second-level > li:nth-child(3n+2) {
  margin: 0 1%;
}

/*	Category navigation
--------------------------- */
/* category-nav */
.ec-headerNavSP {
  display: block;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: white;
  background: transparent;
  position: fixed;
  top: 15px;
  left: 34px;
  z-index: 1000;
}
@media only screen and (min-width: 768px) {
  .ec-headerNavSP {
    top: 17px;
  }
}
.ec-headerNavSP .fas {
  display: inline-block;
  background: pink;
  vertical-align: top;
  border: 10px solid pink;
}
@media only screen and (min-width: 1024px) {
  .ec-headerNavSP {
    display: none;
  }
}

.ec-headerNavSP.is-active {
  display: none;
}

/*
ヘッダー：タイトル

ヘッダー内で使用されるタイトルコンポーネントです。

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerTitle

Styleguide 11.2.1
*/
.ec-headerTitle {
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
}
.ec-headerTitle textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-headerTitle img {
  max-width: 100%;
}
.ec-headerTitle html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-headerTitle *,
.ec-headerTitle *::before,
.ec-headerTitle *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-headerTitle img {
  width: 100%;
}
.ec-headerTitle .ec-headerTitle__title {
  margin-left: 50px;
}
@media only screen and (min-width: 1024px) {
  .ec-headerTitle .ec-headerTitle__title {
    margin-left: 0;
  }
}
.ec-headerTitle .ec-headerTitle__title p.copy {
  position: relative;
  z-index: 1000;
  margin-bottom: 0;
  line-height: 1.2;
  font-size: 0.6em;
}
.ec-headerTitle .ec-headerTitle__title h1, .ec-headerTitle .ec-headerTitle__title .h1 {
  position: relative;
  z-index: 1000;
  margin-top: -3px;
  margin-bottom: 0;
}
.ec-headerTitle .ec-headerTitle__title h1 a, .ec-headerTitle .ec-headerTitle__title .h1 a {
  max-width: 290px;
  width: 100%;
}
.ec-headerTitle .ec-headerTitle__title h1 svg, .ec-headerTitle .ec-headerTitle__title .h1 svg {
  width: 100%;
}
.ec-headerTitle .ec-headerTitle__title a {
  display: inline-block;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-headerTitle .ec-headerTitle__title a {
    font-size: 40px;
  }
}
.ec-headerTitle .ec-headerTitle__title a:hover {
  opacity: 0.8;
}
.ec-headerTitle .ec-headerTitle__subtitle {
  font-size: 10px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .ec-headerTitle .ec-headerTitle__subtitle {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.ec-headerTitle .ec-headerTitle__subtitle a {
  display: inline-block;
  color: #0092C4;
  text-decoration: none;
  cursor: pointer;
}

/*
ヘッダー：ユーザナビゲーション

ヘッダー内でユーザに関与するナビゲーションコンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__nav`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerNav

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__nav">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.3
*/
#middle {
  margin: 0 auto;
  width: 100%;
}

.ec-headerNav {
  width: 100%;
}
.ec-headerNav .ec-headerNav__item {
  margin-left: 0;
  display: inline-block;
  font-size: 28px;
}
.ec-headerNav .ec-headerNav__itemIcon {
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 18px;
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-headerNav .ec-headerNav__itemIcon {
    margin-right: 0;
    font-size: 20px;
  }
}
.ec-headerNav .ec-headerNav__itemLink {
  display: none;
  margin-right: 5px;
  font-size: 14px;
  vertical-align: middle;
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-headerNav .ec-headerNav__itemLink {
    display: inline-block;
  }
}

/*
ヘッダー：検索ボックス

ヘッダー内で使用される商品検索コンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__search`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerSearch

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__search">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.4
*/
.ec-headerSearch {
  width: 100%;
  display: block;
}
.ec-headerSearch:after {
  content: " ";
  display: table;
}
.ec-headerSearch:after {
  clear: both;
}
.ec-headerSearch .ec-headerSearch__category {
  display: none;
}
.ec-headerSearch .ec-headerSearch__category .ec-select {
  overflow: hidden;
  width: 100%;
  margin: 0;
  text-align: center;
}
.ec-headerSearch .ec-headerSearch__category .ec-select select {
  width: 100%;
  cursor: pointer;
  padding: 8px 24px 8px 8px;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  background: transparent;
  background-image: none;
  box-shadow: none;
  appearance: none;
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .ec-headerSearch .ec-headerSearch__category .ec-select select {
    height: 36px;
  }
}
.ec-headerSearch .ec-headerSearch__category .ec-select select option {
  color: #000;
}
.ec-headerSearch .ec-headerSearch__category .ec-select select::-ms-expand {
  display: none;
}
.ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search {
  position: relative;
  border: 0;
  background: #000;
  color: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search {
    border-top-right-radius: inherit;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
}
.ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search::before {
  position: absolute;
  top: 0.8em;
  right: 0.4em;
  width: 0;
  height: 0;
  padding: 0;
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff;
  pointer-events: none;
}
.ec-headerSearch .ec-headerSearch__keyword {
  position: relative;
  color: #525263;
  border: 1px solid #ccc;
  background-color: #f6f6f6;
  border-radius: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-headerSearch .ec-headerSearch__keyword {
    float: right;
    width: 100%;
    border-bottom-left-radius: inherit;
    border-radius: 50px;
  }
}
.ec-headerSearch .ec-headerSearch__keyword input[type=search] {
  width: 100%;
  height: 34px;
  font-size: 16px;
  border: 0 none;
  padding: 0.5em 50px 0.5em 1em;
  box-shadow: none;
  background: none;
  box-sizing: border-box;
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) {
  .ec-headerSearch .ec-headerSearch__keyword input[type=search] {
    font-size: 12px;
  }
}
.ec-headerSearch .ec-headerSearch__keyword .ec-icon {
  width: 22px;
  height: 22px;
  color: #f5568b;
}
.ec-headerSearch .ec-headerSearch__keyword .ec-icon img {
  fill: #f5568b;
  color: #f5568b;
}
.ec-headerSearch .ec-headerSearch__keywordBtn {
  border: 0;
  background: none;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-55%);
  display: block;
  white-space: nowrap;
  z-index: 1;
}

/*
ヘッダー：カテゴリナビ

ヘッダー内で使用されている商品のカテゴリ一覧として使用します。
`li`の中に`ul > li`要素を入れることで、階層を深くする事ができます。

Markup:
include /assets/tmpl/elements/11.4.categoryNavi.pug
+ec-itemNav

sg-wrapper:
<div class="ec-categoryNaviRole" style="padding-bottom:150px;">
  <sg-wrapper-content/>
</div>

Styleguide 11.2.5
*/
.ec-categoryNaviRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  display: none;
}
.ec-categoryNaviRole:after {
  content: " ";
  display: table;
}
.ec-categoryNaviRole:after {
  clear: both;
}
.ec-categoryNaviRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-categoryNaviRole img {
  max-width: 100%;
}
.ec-categoryNaviRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-categoryNaviRole *,
.ec-categoryNaviRole *::before,
.ec-categoryNaviRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-categoryNaviRole img {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-categoryNaviRole {
    display: block;
    width: 100%;
  }
  .ec-categoryNaviRole a {
    color: inherit;
    text-decoration: none;
  }
  .ec-categoryNaviRole a:hover {
    text-decoration: none;
  }
}

.ec-itemNav {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.ec-itemNav__nav {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: auto;
  height: auto;
  list-style-type: none;
  text-align: center;
  vertical-align: bottom;
}
@media only screen and (min-width: 768px) {
  .ec-itemNav__nav {
    display: inline-block;
  }
}

.ec-itemNav__nav li {
  float: none;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li {
    float: left;
  }
}

.ec-itemNav__nav li a {
  display: block;
  border-bottom: 1px solid #E8E8E8;
  margin: 0;
  padding: 10px 16px;
  height: auto;
  color: #2e3233;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-decoration: none;
  text-align: left;
  background: #ffe8e8 !important;
  border-bottom: 1px solid #E8E8E8;
}
@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li a {
    text-align: center;
    border-bottom: none;
  }
}

.ec-itemNav__nav li ul {
  display: none;
  z-index: 0;
  margin: 0;
  padding: 0;
  min-width: 200px;
  list-style: none;
  position: static;
  top: 100%;
  left: 0;
}
@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li ul {
    display: block;
    z-index: 100;
    position: absolute;
  }
}

.ec-itemNav__nav li ul li {
  overflow: hidden;
  width: 100%;
  height: auto;
  transition: 0.3s;
}
@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li ul li {
    overflow: hidden;
    height: 0;
  }
}

.ec-itemNav__nav li ul li a {
  border-bottom: 1px solid #E8E8E8;
  padding: 16px 22px 16px 16px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: left;
  background: black;
}

.ec-itemNav__nav > li:hover > a {
  background: #fafafa;
}

.ec-itemNav__nav > li:hover li:hover > a {
  background: #333;
}

@media only screen and (min-width: 768px) {
  .ec-itemNav__nav > li:hover > ul > li {
    overflow: visible;
    height: auto;
  }
}

.ec-itemNav__nav li ul li ul {
  top: 0;
  left: 100%;
  width: auto;
}

@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li ul li ul:before {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    color: white;
    position: absolute;
    top: 19px;
    right: auto;
    left: -20px;
  }
}

@media only screen and (min-width: 768px) {
  .ec-itemNav__nav li ul li:hover > ul > li {
    overflow: visible;
    height: auto;
    width: auto;
  }
}

.ec-itemNav__nav li ul li ul li a {
  background: #7D7D7D;
}

.ec-itemNav__nav li:hover ul li ul li a:hover {
  background: #333;
}

/*
ヘッダー：SPヘッダー

SP時のみ出現するヘッダーに関係するコンポーネントです。<br>
ex [トップページ](http://demo3.ec-cube.net/)画面サイズが768px以下に該当。<br>
<br>
`.ec-drawerRole`：SPのドロワー内の要素をwrapするコンポーネントです。<br>
`.ec-headerSearch`、`.ec-headerNav`、`.ec-itemNav`は`.ec-drawerRole`の子要素にある場合、ドロワーに適したスタイルに変化します。<br><br>
`.ec-overlayRole`：SPのドロワー出現時にz-indexがドロワー以下の要素に半透明の黒背景をかぶせるコンポーネントです。<br>

Styleguide 11.2.6
*/
.ec-drawerRole {
  overflow-y: scroll;
  background: #efefef;
  width: 260px;
  height: 100vh;
  transform: translateX(-300px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: z-index 0ms 1ms;
}
@media only screen and (min-width: 1024px) {
  .ec-drawerRole {
    display: none;
  }
}
.ec-drawerRole .ec-headerSearchArea {
  padding: 20px 10px;
  width: 100%;
  background: #F8F8F8;
}
.ec-drawerRole .ec-headerSearch {
  padding: 16px 8px;
  background: #EBEBEB;
  color: #636378;
}
.ec-drawerRole .ec-headerSearch select {
  width: 100% !important;
}
.ec-drawerRole .ec-headerCategoryArea .ec-headerCategoryArea__heading {
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  font-size: 16px;
  font-weight: bold;
  color: black;
  background: #F8F8F8;
}
.ec-drawerRole .ec-headerCategoryArea .ec-headerCategoryArea__heading p {
  padding: 12px 15px;
  margin: 0;
}
.ec-drawerRole .ec-headerCategoryArea .ec-headerCategoryArea__heading a {
  padding: 1em 10px;
  display: block;
  color: #000;
  background: #F8F8F8;
}
.ec-drawerRole .ec-headerCategoryArea .ec-headerCategoryArea__heading a:hover {
  background: #fff9f2;
  color: #686868;
  display: block;
}
.ec-drawerRole .ec-headerCategoryArea .sp-bottom {
  margin-bottom: 50px;
}
.ec-drawerRole .ec-headerCategoryArea p {
  margin-top: 0;
  margin-bottom: 0;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li a {
  border-bottom: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: black;
  font-weight: normal;
  background: #f8f8f8;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li ul li a {
  border-bottom: 1px solid #ccc;
  padding-left: 20px;
  font-weight: normal;
  background: white;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav > li:hover > a {
  background: #f8f8f8;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav > li:hover li:hover > a {
  background: white;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li ul li ul li a {
  padding-left: 40px;
  color: black;
  background: white;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li:hover ul li ul li a:hover {
  background: white;
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li ul li ul li ul li a {
  padding-left: 60px;
  font-weight: normal;
}
.ec-drawerRole .ec-headerLinkArea {
  background: #F6F6F6;
}
.ec-drawerRole .ec-headerLinkArea .ec-headerLink__list {
  border-top: 1px solid #ccc;
}
.ec-drawerRole .ec-headerLinkArea .ec-headerLink__item {
  display: block;
  border-bottom: 1px solid #ccc;
  padding: 12px 15px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
}
.ec-drawerRole .ec-headerLinkArea .ec-headerLink__icon {
  display: inline-block;
  width: 28px;
  font-size: 17px;
}

.ec-drawerRoleClose {
  display: none;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: white;
  position: fixed;
  top: 10px;
  left: 270px;
  z-index: 1000;
}
.ec-drawerRoleClose .fas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 768px) {
  .ec-drawerRoleClose {
    display: none;
  }
}

.ec-drawerRole.is_active {
  display: block;
  transform: translateX(0);
  transition: all 0.3s;
  z-index: 100000;
}
@media only screen and (min-width: 1024px) {
  .ec-drawerRole.is_active {
    display: none;
  }
}

.ec-drawerRoleClose.is_active {
  display: inline-block;
  transition: all 0.3s;
}
@media only screen and (min-width: 1024px) {
  .ec-drawerRoleClose.is_active {
    display: none;
  }
}

.ec-overlayRole {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  background: transparent;
  transform: translateX(0);
  transition: all 0.3s;
  visibility: hidden;
}
@media only screen and (min-width: 768px) {
  .ec-overlayRole {
    display: none;
  }
}

.have_curtain .ec-overlayRole {
  display: block;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  visibility: visible;
}
@media only screen and (min-width: 768px) {
  .have_curtain .ec-overlayRole {
    display: none;
  }
}

/*
ヘッダー：test

test

Markup:
span.ec-itemAccordionParent test1
ul.ec-itemNavAccordion
  li.ec-itemNavAccordion__item
    a(href='') test2
    ul.ec-itemNavAccordion
      li.ec-itemNavAccordion__item
        a(href='') test3
        ul.ec-itemNavAccordion
          li.ec-itemNavAccordion__item
            a(href='') test4

Styleguide 11.2.7
*/
.ec-itemNavAccordion {
  display: none;
}

.ec-maintenanceAlert {
  background: steelblue;
  height: 50px;
  position: fixed;
  top: 0;
  width: 100%;
  color: white;
  z-index: 9999;
  display: flex;
  font-weight: bold;
}
.ec-maintenanceAlert > * {
  margin: auto;
}
.ec-maintenanceAlert .ec-maintenanceAlert__icon {
  display: inline-block;
  margin-right: 1rem;
  width: 20px;
  height: 20px;
  color: #fff;
  fill: #fff;
  vertical-align: top;
}
.ec-maintenanceAlert + * {
  margin-top: 50px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole {
  border-top: 1px solid #7d7d7d;
  margin-top: 30px;
  background: black;
}
@media only screen and (min-width: 768px) {
  .ec-footerRole {
    padding-top: 40px;
    margin-top: 100px;
  }
}
@media only screen and (min-width: 768px) {
  .ec-footerRole .ec-footerRole__inner {
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.4;
    color: #525263;
    -webkit-text-size-adjust: 100%;
    width: 100%;
    max-width: 1130px;
  }
  .ec-footerRole .ec-footerRole__inner:after {
    content: " ";
    display: table;
  }
  .ec-footerRole .ec-footerRole__inner:after {
    clear: both;
  }
  .ec-footerRole .ec-footerRole__inner textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif;
  }
  .ec-footerRole .ec-footerRole__inner img {
    max-width: 100%;
  }
  .ec-footerRole .ec-footerRole__inner html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .ec-footerRole .ec-footerRole__inner *,
.ec-footerRole .ec-footerRole__inner *::before,
.ec-footerRole .ec-footerRole__inner *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }
  .ec-footerRole .ec-footerRole__inner img {
    width: 100%;
  }
}

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerNavi {
  padding: 0;
  color: white;
  list-style: none;
  text-align: center;
}
.ec-footerNavi .ec-footerNavi__link {
  display: block;
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi .ec-footerNavi__link {
    display: inline-block;
  }
}
.ec-footerNavi .ec-footerNavi__link a {
  display: block;
  border-bottom: 1px solid #7d7d7d;
  padding: 15px 0;
  font-size: 14px;
  color: inherit;
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-footerNavi .ec-footerNavi__link a {
    display: inline-block;
    border-bottom: none;
    margin: 0 10px;
    padding: 0;
    text-decoration: underline;
  }
}
.ec-footerNavi .ec-footerNavi__link:hover a {
  opacity: 0.8;
  text-decoration: none;
}

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerTitle {
  padding: 40px 0 60px;
  text-align: center;
  color: white;
}
@media only screen and (min-width: 768px) {
  .ec-footerTitle {
    padding: 50px 0 80px;
  }
}
.ec-footerTitle .ec-footerTitle__logo {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
.ec-footerTitle .ec-footerTitle__logo a {
  color: inherit;
  text-decoration: none;
}
.ec-footerTitle .ec-footerTitle__logo a:hover {
  text-decoration: none;
}
.ec-footerTitle .ec-footerTitle__logo a {
  font-size: 22px;
  color: inherit;
}
@media only screen and (min-width: 768px) {
  .ec-footerTitle .ec-footerTitle__logo a {
    font-size: 24px;
  }
}
.ec-footerTitle .ec-footerTitle__logo:hover a {
  opacity: 0.8;
  text-decoration: none;
}
.ec-footerTitle .ec-footerTitle__copyright {
  font-size: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-footerTitle .ec-footerTitle__copyright {
    font-size: 12px;
  }
}

/****** shopping-guide ********/
#bottom-shopguide {
  width: 100%;
  margin: 5rem auto 0;
  padding: 20px 0 60px;
  font-size: 13px;
  font-size: 1.3rem;
  /*	position:relative;*/
  background: #ffe1d6 url(../img/common/f_ribbon_bg.jpg) 0 bottom repeat-x;
}
#bottom-shopguide .sub-h2 {
  margin: 0 auto;
  padding: 0;
  color: #f5568b;
}
#bottom-shopguide .sub-h2 h2, #bottom-shopguide .sub-h2 .h2 {
  padding: 0;
  margin: 0 auto;
  position: relative;
  text-align: center;
  border-bottom: none !important;
  border-top: none !important;
  font-size: 35px;
  font-size: 3.5rem;
  font-weight: bold;
}
#bottom-shopguide .sub-h2 h2::after, #bottom-shopguide .sub-h2 .h2::after {
  background: #f5568b;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 49%;
  width: 100%;
  z-index: 0;
}
#bottom-shopguide .sub-h2 h2 span, #bottom-shopguide .sub-h2 .h2 span {
  font-size: 14px;
  font-size: 1.4rem;
  display: block;
}
#bottom-shopguide .sub-h2 h2 div, #bottom-shopguide .sub-h2 .h2 div {
  position: relative;
  padding: 0 10px;
  display: inline-block;
  background: #ffe1d6;
  z-index: 10;
}
#bottom-shopguide .bottom-shopguide-in {
  margin: 0 auto;
  max-width: 1200px;
  width: 96%;
}
#bottom-shopguide .guide-box {
  padding-bottom: 50px;
}
#bottom-shopguide .guide-box h3, #bottom-shopguide .guide-box .h3 {
  color: #45281d;
  border-bottom: 1px dotted #45281d;
  font-weight: bold;
}
#bottom-shopguide .guide-box dl {
  margin-bottom: 0.7em;
  background-color: rgba(255, 255, 255, 0.6);
}
#bottom-shopguide .guide-box dl dt {
  padding: 10px 0 0 10px;
}
#bottom-shopguide .guide-box dl dd ul {
  padding: 3px 0 10px 10px;
  display: flex;
  flex-wrap: wrap;
}
#bottom-shopguide .guide-box dl dd ul li {
  margin: 0;
  width: 44px;
  padding-right: 3px !important;
  letter-spacing: normal;
}
#bottom-shopguide .guide-box dl dd ul li:last-child {
  width: 77px;
}
#bottom-shopguide .guide-box dl dd ul li img {
  width: 100%;
}
#bottom-shopguide .guide-box table {
  margin: 0 auto 0.7em;
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-size: 1.2rem;
}
#bottom-shopguide .guide-box table th {
  padding: 10px;
  background: #ffede6;
  color: #45281d !important;
  border-collapse: collapse;
  border: 1px solid #ffe1d6;
  font-weight: normal;
}
#bottom-shopguide .guide-box table td {
  padding: 10px;
  background: #ffede6;
  color: #45281d !important;
  font-weight: normal;
  background: #fff;
  border-collapse: collapse;
  border: 1px solid #ffe1d6;
}
#bottom-shopguide .guide-box p.btn {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
#bottom-shopguide .guide-box p.btn a {
  border: 1px solid #f55b8e;
  border-radius: 0;
}

/****** footer ********/
#footer {
  position: relative;
  background: #faf8f3;
  padding-top: 20px;
  padding-bottom: 24px;
}
#footer p.f-ribbon {
  position: absolute;
  top: -60px;
  left: 0;
}
#footer ul {
  text-align: center;
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#footer ul.f-nav {
  margin: 65px auto 0;
  padding-bottom: 30px;
  border-bottom: 1px solid #fff;
}
#footer ul.f-nav:first-child {
  content: "|";
  color: #f26363;
  padding: 0;
  font-size: 1.5rem;
}
#footer ul.f-nav li {
  font-size: 14px;
}
#footer ul.f-nav li::after {
  content: "|";
  color: #f26363;
  padding: 0 20px;
  font-size: 1.5rem;
}
#footer ul.f-nav li a {
  color: #f5568b;
}
#footer #f-area {
  border-top: 1px solid #ede6d3;
  padding-top: 1em;
}
#footer #f-area .footer_logo_area {
  margin: 0 auto;
  text-align: center;
  max-width: 298px;
  font-size: 16px;
}
#footer #f-area .footer_logo_area a {
  width: 100%;
  color: #000;
}
#footer #f-area .footer_logo_area address {
  margin-bottom: 20px;
  font-size: 12px;
}
#footer #f-area .footer_logo_area address em {
  font-weight: bold;
}
#footer #f-area .logo {
  margin-bottom: 20px;
}
#footer #f-area .f-tel {
  margin: 0 auto;
  padding: 15px;
  width: 100%;
  background: url(../img/common/bg_basic.jpg) 0 0 repeat;
  border-radius: 15px;
}
#footer #f-area .f-tel ul {
  display: flex;
  align-items: center;
}
#footer #f-area .f-tel ul li {
  margin: 0 0.5em;
}
#footer #f-area .f-tel ul li.t-area {
  font-size: 25px;
  font-size: 2.5rem;
  font-weight: bold;
}
#footer #f-area .f-tel ul li.t-area span {
  display: block;
  font-size: 12px;
  font-size: 1.2rem;
}
#footer #f-area .f-tel ul li.f-area {
  font-size: 23px;
  font-size: 2rem;
  font-weight: bold;
}
#footer #f-area .f-tel ul li.f-area a {
  margin-left: 10px;
  text-decoration: underline;
  color: #000;
}
#footer #f-area .f-tel ul li.f-area a:hover {
  text-decoration: none;
}
#footer #f-area .f-tel ul li.f-area a::after {
  content: "\f1c1";
  color: #f26363;
  font-family: "FontAwesome";
  padding: 0 0 0 7px;
  font-size: 1.6rem;
}
#footer #f-area .f-tel ul li.f-area span {
  display: block;
  font-size: 14px;
  font-size: 1.4rem;
}

.btn {
  padding: 0;
  text-align: center;
  width: 100%;
}
.btn a {
  border-radius: 10px;
  border: 3px solid #f5568b;
  background: #fff;
  color: #f5568b !important;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  padding: 7px 20px;
  text-decoration: none;
}
.btn a::before {
  content: "\f0da";
  font-family: "FontAwesome";
  padding: 0 8px;
  color: #f5568b;
}

.copyright {
  text-align: center;
  font-size: 12px;
  font-size: 1.2rem;
  margin-top: 5px;
}

.pagetop {
  display: none;
  text-align: right;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 2;
}
.pagetop a {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  background-color: #B8BEC4;
  border-radius: 50%;
}
.pagetop a:hover {
  opacity: 0.8;
}
.pagetop a .cb-angle-down {
  fill: #fff;
  font-size: 25px;
  font-size: 2.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.5em;
  margin-top: -0.5em;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

/*=============================================================================

    Media Query:PC

=============================================================================*/
@media only screen and (min-width: 1024px) {
  /****** shopping-guide ********/
  #bottom-shopguide {
    width: 100%;
    padding: 20px 0 60px;
    font-size: 13px;
    font-size: 1.3rem;
    /*	position:relative;*/
    background: #ffe1d6 url(../img/common/f_ribbon_bg.jpg) 0 bottom repeat-x;
  }
  #bottom-shopguide .bottom-shopguide-in {
    margin: 0 auto;
    max-width: 1200px;
    width: 96%;
    display: flex;
    justify-content: space-between;
  }
  #bottom-shopguide .bottom-shopguide-in .guide-column {
    width: 48%;
  }

  /****** Footer Setting ********/
  #footer #f-area {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    align-items: center;
  }
  #footer #f-area .f-tel {
    width: calc(100% - 320px);
  }

  /*ribbonイラスト*/
  #footer p.f-ribbon {
    position: absolute;
    top: -60px;
    left: 0;
  }

  #footer ul.f-nav {
    margin: 0 auto;
    padding: 60px 0 30px;
    font-size: 14px;
    font-size: 1.4rem;
    border-bottom: 1px solid #fff;
  }

  #footer ul.f-nav li:after {
    content: "|";
    color: #f26363;
    padding: 0 20px;
    font-size: 1.5rem;
  }

  #footer ul.f-nav:first-child {
    content: "|";
    color: #f26363;
    padding: 0;
    font-size: 1.5rem;
  }

  #footer .f-tel {
    padding: 30px 20px !important;
    display: flex;
    justify-content: space-between;
    max-width: 760px !important;
  }
  #footer .f-tel ul {
    width: calc(100% - 260px);
    text-align: left !important;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  #footer .f-tel ul li span {
    display: inline !important;
  }
  #footer .f-tel p.btn {
    width: 250px;
  }

  #footer #f-area .logo {
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: bold;
  }

  #footer .btn {
    padding: 0;
    text-align: center;
  }

  #footer .copyright {
    text-align: right;
    font-size: 14px;
    font-size: 1.4rem;
  }

  #footer .footer_logo_area address em {
    display: inline-block;
    margin-left: 0;
  }
}
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
トップページ

トップページ スライド部に関する Project コンポーネントを定義します。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.1.slider.pug
+ec-sliderRole

Styleguide 12.1
*/
.ec-sliderRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  margin-bottom: 24px;
}
.ec-sliderRole:after {
  content: " ";
  display: table;
}
.ec-sliderRole:after {
  clear: both;
}
.ec-sliderRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-sliderRole img {
  max-width: 100%;
}
.ec-sliderRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-sliderRole *,
.ec-sliderRole *::before,
.ec-sliderRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-sliderRole img {
  width: 100%;
}
.ec-sliderRole ul {
  padding: 0;
  list-style: none;
}

.ec-sliderItemRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 24px;
}
.ec-sliderItemRole:after {
  content: " ";
  display: table;
}
.ec-sliderItemRole:after {
  clear: both;
}
.ec-sliderItemRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-sliderItemRole img {
  max-width: 100%;
}
.ec-sliderItemRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-sliderItemRole *,
.ec-sliderItemRole *::before,
.ec-sliderItemRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-sliderItemRole img {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-sliderItemRole {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.ec-sliderItemRole .item_visual {
  position: relative;
}
.ec-sliderItemRole .item_visual img {
  height: 100%;
  border-right: 2px solid #ccc;
  border-left: 1px solid #ccc;
  display: block;
}
.ec-sliderItemRole .item_visual .border-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 1%;
  left: 0;
  pointer-events: none;
  /* ボーダー上のクリックやホバーを無視する */
  border: 1px solid #ccc;
  z-index: 999;
}
.ec-sliderItemRole ul {
  padding: 0;
  list-style: none;
}
.ec-sliderItemRole .item_nav {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ec-sliderItemRole .item_nav {
    display: flex;
    justify-content: flex-start;
  }
  .ec-sliderItemRole .item_nav .slideThumb {
    margin: 0 !important;
    width: 25% !important;
    margin-right: 1% !important;
  }
  .ec-sliderItemRole .item_nav .slideThumb img {
    width: 100%;
  }
  .ec-sliderItemRole .item_nav .slideThumb:last-child {
    margin-right: 0 !important;
  }
}
.ec-sliderItemRole .slideThumb {
  margin: 0 auto 25px;
  text-align: center;
  width: 25%;
  opacity: 0.8;
  cursor: pointer;
  border: 1px solid #ccc;
}
.ec-sliderItemRole .slideThumb:focus {
  outline: none;
}
.ec-sliderItemRole .slideThumb:hover {
  opacity: 1;
}
.ec-sliderItemRole .slideThumb img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.slick-dots li button::before {
  color: pink !important;
  background-color: pink !important;
  border-radius: 50%;
}

.slick-dots li.slick-active button::before {
  color: #f5568b !important;
  background-color: #f5568b !important;
  border-radius: 50%;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
アイキャッチ

トップページ アイキャッチ部に関する Project コンポーネントを定義します。

ex [トップページスライダー直下 アイキャッチ部](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.2.eyecatch.pug
+ec-eyecatchRole

Styleguide 12.2
*/
.ec-eyecatchRole {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  /* Deprecated https://github.com/EC-CUBE/ec-cube/pull/4220 */
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole {
    flex-wrap: nowrap;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__image {
  display: block;
  margin-bottom: 40px;
  width: 100%;
  height: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__image {
    order: 2;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__intro {
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__intro {
    padding-right: 5%;
    order: 1;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__introEnTitle {
  margin-bottom: 0.8em;
  font-size: 16px;
  font-weight: normal;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__introEnTitle {
    margin-top: 45px;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__introTitle {
  margin-bottom: 0.8em;
  font-size: 24px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__introTitle {
    margin-bottom: 1em;
    font-size: 26px;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__introDescriptiron {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 2;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__introDescriptiron {
    margin-bottom: 30px;
  }
}
.ec-eyecatchRole .ec-eyecatchRole__introDescription {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 2;
}
@media only screen and (min-width: 768px) {
  .ec-eyecatchRole .ec-eyecatchRole__introDescription {
    margin-bottom: 30px;
  }
}

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
ボタン

トップページで使用されているボタンのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.3
*/
/*
通常ボタン

インラインの要素としてボタンを定義出来ます。

Markup:
.ec-inlineBtn--top more

Styleguide 12.3.1
*/
.ec-inlineBtn--top {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 1.42857142;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: white;
  background-color: black;
  border-color: black;
}
.ec-inlineBtn--top:focus, .ec-inlineBtn--top.focus, .ec-inlineBtn--top:active:focus, .ec-inlineBtn--top:active.focus, .ec-inlineBtn--top.active:focus, .ec-inlineBtn--top.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-inlineBtn--top:hover, .ec-inlineBtn--top:focus, .ec-inlineBtn--top.focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.8;
}
.ec-inlineBtn--top:active, .ec-inlineBtn--top.active {
  outline: 0;
  background-image: none;
}
.ec-inlineBtn--top.disabled, .ec-inlineBtn--top[disabled], fieldset[disabled] .ec-inlineBtn--top {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
}
.ec-inlineBtn--top:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-check:focus + .ec-inlineBtn--top, .ec-inlineBtn--top:focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-check:checked + .ec-inlineBtn--top, .btn-check:active + .ec-inlineBtn--top, .ec-inlineBtn--top:active, .ec-inlineBtn--top.active, .show > .ec-inlineBtn--top.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-check:checked + .ec-inlineBtn--top:focus, .btn-check:active + .ec-inlineBtn--top:focus, .ec-inlineBtn--top:active:focus, .ec-inlineBtn--top.active:focus, .show > .ec-inlineBtn--top.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.ec-inlineBtn--top:disabled, .ec-inlineBtn--top.disabled {
  color: white;
  background-color: black;
  border-color: black;
}
.ec-inlineBtn--top .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}

/*
ロングボタン（全幅）

ロングタイプのボタンです。

Markup:
.ec-blockBtn--top 商品一覧へ

Styleguide 2.1.2
*/
.ec-blockBtn--top {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 1.42857142;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 16px;
  text-decoration: none;
  color: white;
  background-color: black;
  border-color: black;
  display: block;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
}
.ec-blockBtn--top:focus, .ec-blockBtn--top.focus, .ec-blockBtn--top:active:focus, .ec-blockBtn--top:active.focus, .ec-blockBtn--top.active:focus, .ec-blockBtn--top.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ec-blockBtn--top:hover, .ec-blockBtn--top:focus, .ec-blockBtn--top.focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.8;
}
.ec-blockBtn--top:active, .ec-blockBtn--top.active {
  outline: 0;
  background-image: none;
}
.ec-blockBtn--top.disabled, .ec-blockBtn--top[disabled], fieldset[disabled] .ec-blockBtn--top {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
}
.ec-blockBtn--top:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-check:focus + .ec-blockBtn--top, .ec-blockBtn--top:focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-check:checked + .ec-blockBtn--top, .btn-check:active + .ec-blockBtn--top, .ec-blockBtn--top:active, .ec-blockBtn--top.active, .show > .ec-blockBtn--top.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-check:checked + .ec-blockBtn--top:focus, .btn-check:active + .ec-blockBtn--top:focus, .ec-blockBtn--top:active:focus, .ec-blockBtn--top.active:focus, .show > .ec-blockBtn--top.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.ec-blockBtn--top:disabled, .ec-blockBtn--top.disabled {
  color: white;
  background-color: black;
  border-color: black;
}
.ec-blockBtn--top .ec-icon img {
  width: 1em;
  vertical-align: text-bottom;
}
@media only screen and (min-width: 768px) {
  .ec-blockBtn--top {
    max-width: 260px;
  }
}

.btnarea {
  margin: 0 auto 1rem;
  text-align: center;
}
.btnarea a {
  display: inline;
  font-size: 10px;
  padding: 10px 16px;
  font-weight: normal;
}

/*
見出し

トップページで使用されている見出しのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.4
*/
/*
横並び見出し

横並びの見出しです。

Markup:
.ec-secHeading
  span.ec-secHeading__en TOPIC
  span.ec-secHeading__line |
  span.ec-secHeading__ja 特集

Styleguide 12.4.1
*/
.ec-secHeading {
  margin-bottom: 15px;
  color: black;
}
.ec-secHeading .ec-secHeading__en {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.2em;
}
.ec-secHeading .ec-secHeading__line {
  display: inline-block;
  margin: 0 20px;
  width: 1px;
  height: 14px;
  background: black;
}
.ec-secHeading .ec-secHeading__ja {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.15em;
  vertical-align: 2px;
}

/*
縦並び見出し

縦並びの見出しです。

Markup:
.ec-secHeading--tandem
  span.ec-secHeading__en TOPIC
  span.ec-secHeading__line |
  span.ec-secHeading__ja 特集

Styleguide 12.4.2
*/
.ec-secHeading--tandem {
  margin-bottom: 15px;
  color: black;
  text-align: center;
}
.ec-secHeading--tandem .ec-secHeading__en {
  display: block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.2em;
}
.ec-secHeading--tandem .ec-secHeading__line {
  display: block;
  margin: 13px auto;
  width: 20px;
  height: 1px;
  background: black;
}
.ec-secHeading--tandem .ec-secHeading__ja {
  display: block;
  margin-bottom: 30px;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.15em;
  vertical-align: 2px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
トピック（アイテム2列）

トップページで使用されているトピックのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.5.1
*/
.ec-topicRole {
  padding: 40px 0;
  background: #F8F8F8;
}
@media only screen and (min-width: 768px) {
  .ec-topicRole {
    padding: 60px 0;
  }
}
.ec-topicRole .ec-topicRole__list {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  .ec-topicRole .ec-topicRole__list {
    flex-wrap: nowrap;
  }
}
.ec-topicRole .ec-topicRole__listItem {
  margin-bottom: 20px;
  width: 100%;
  height: auto;
}
@media only screen and (min-width: 768px) {
  .ec-topicRole .ec-topicRole__listItem {
    width: 50%;
  }
  .ec-topicRole .ec-topicRole__listItem:not(:last-of-type) {
    margin-right: 30px;
  }
}
.ec-topicRole .ec-topicRole__listItemTitle {
  margin-top: 0.5em;
  font-size: 14px;
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-topicRole .ec-topicRole__listItemTitle {
    margin-top: 1em;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
カテゴリ（アイテム4列 スマホの時は2列）

トップページで使用されているアイテムリストのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.6.1
*/
.ec-newItemRole {
  padding: 40px 0;
}
@media only screen and (min-width: 768px) {
  .ec-newItemRole {
    padding: 60px 0;
  }
}
.ec-newItemRole .ec-newItemRole__list {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  .ec-newItemRole .ec-newItemRole__list {
    flex-wrap: nowrap;
  }
}
.ec-newItemRole .ec-newItemRole__listItem {
  margin-bottom: 4%;
  width: 48%;
  height: auto;
}
.ec-newItemRole .ec-newItemRole__listItem:not(:first-child) a {
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-newItemRole .ec-newItemRole__listItem {
    margin-bottom: 15px;
    width: 25%;
  }
  .ec-newItemRole .ec-newItemRole__listItem:not(:last-of-type) {
    margin-right: 30px;
  }
}
.ec-newItemRole .ec-newItemRole__listItem:nth-child(odd) {
  margin-right: 4%;
}
@media only screen and (min-width: 768px) {
  .ec-newItemRole .ec-newItemRole__listItem:nth-child(odd) {
    margin-right: 30px;
  }
}
.ec-newItemRole .ec-newItemRole__listItemHeading {
  margin-top: calc(45% - 20px);
}
.ec-newItemRole .ec-newItemRole__listItemTitle {
  margin: 8px 0;
  font-size: 14px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .ec-newItemRole .ec-newItemRole__listItemTitle {
    margin: 20px 0 10px;
  }
}
.ec-newItemRole .ec-newItemRole__listItemPrice {
  font-size: 12px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
カテゴリ（アイテム3列）

トップページで使用されているカテゴリのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.7.1
*/
.ec-categoryRole {
  padding: 40px 0;
  color: black;
  background: #F8F8F8;
}
@media only screen and (min-width: 768px) {
  .ec-categoryRole {
    padding: 60px 0;
  }
}
.ec-categoryRole .ec-categoryRole__list {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 768px) {
  .ec-categoryRole .ec-categoryRole__list {
    flex-wrap: nowrap;
  }
}
.ec-categoryRole .ec-categoryRole__listItem {
  margin-bottom: 20px;
  width: 100%;
  height: auto;
}
@media only screen and (min-width: 768px) {
  .ec-categoryRole .ec-categoryRole__listItem {
    width: 33.3333333333%;
  }
  .ec-categoryRole .ec-categoryRole__listItem:not(:last-of-type) {
    margin-right: 30px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
見出し

トップページで使用されている新着情報のスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.8.1
*/
.ec-newsRole {
  margin: 2rem auto;
  padding: 0;
  border: 1px solid #e3e3e3;
  background: #fff;
}
.ec-newsRole #news-in {
  margin: 3px;
  overflow: hidden;
  background: #f4efe9;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole #news-in {
    display: flex;
    justify-content: space-between;
  }
  .ec-newsRole #news-in .ec-secHeading {
    width: 230px;
  }
}
.ec-newsRole #news-in .ec-secHeading {
  padding: 20px 0;
  background: #f4efe9;
  margin: 0 auto 4px;
  width: 100%;
  height: 100%;
  color: #f678a0;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0;
  font-size: 23px;
  font-size: 2.3rem;
  font-weight: bold;
}
.ec-newsRole #news-in .ec-secHeading span {
  display: block;
  font-size: 12px;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
}
.ec-newsRole .ec-newsRole__news {
  border-left: 4px solid #fff;
  margin: 0 auto;
  width: 100%;
  background: #fdf4ed;
  height: 100%;
  box-sizing: border-box;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole .ec-newsRole__news {
    width: calc(100% - 250px);
  }
}
.ec-newsRole .ec-newsRole__newsItem {
  position: relative;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole .ec-newsRole__newsItem {
    padding: 7px 10px;
  }
}
.ec-newsRole .ec-newsRole__newsHeading {
  position: relative;
  cursor: pointer;
}
.ec-newsRole .ec-newsRole__newsDate {
  display: block;
  margin: 15px 0 5px;
  font-size: 12px;
  color: black;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole .ec-newsRole__newsDate {
    display: inline-block;
    margin: 0;
    min-width: 120px;
    font-size: 14px;
  }
}
.ec-newsRole .ec-newsRole__newsColumn {
  display: flex;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole .ec-newsRole__newsColumn {
    display: inline-flex;
    min-width: calc(100% - 120px);
  }
}
.ec-newsRole .ec-newsRole__newsTitle {
  display: inline-block;
  margin-bottom: 10px;
  width: 90%;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  line-height: 1.6;
}
.ec-newsRole .ec-newsRole__newsTitle:hover {
  text-decoration: underline;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole .ec-newsRole__newsTitle {
    margin-bottom: 0;
    line-height: 1.8;
  }
}
.ec-newsRole .ec-newsRole__newsClose {
  display: inline-block;
  width: 10%;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole .ec-newsRole__newsClose {
    position: absolute;
    right: 0;
  }
}
.ec-newsRole .ec-newsRole__newsCloseBtn {
  display: inline-block;
  margin-left: auto;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: white;
  text-align: center;
  background: #f5568b;
  cursor: pointer;
  position: absolute;
  right: 5px;
}
.ec-newsRole .ec-newsRole__newsDescription {
  display: none;
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 1.4;
  overflow: hidden;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole .ec-newsRole__newsDescription {
    margin: 20px 0 0;
    line-height: 1.8;
  }
}
.ec-newsRole .ec-newsRole__newsDescription a {
  color: #0092C4;
}
.ec-newsRole__newsItem.is_active .ec-newsRole__newsDescription {
  margin: 0 0 10px;
}
@media only screen and (min-width: 768px) {
  .ec-newsRole__newsItem.is_active .ec-newsRole__newsDescription {
    margin: 20px 0 0;
  }
}
.ec-newsRole__newsItem.is_active .ec-newsRole__newsCloseBtn i {
  display: inline-block;
  transform: rotateX(180deg) translateY(2px);
}

#top-sns {
  margin: 0 auto 3rem;
  text-align: center;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
#top-sns .insta {
  margin-right: 10px;
  width: 50%;
  max-width: 160px;
}
@media only screen and (min-width: 768px) {
  #top-sns .insta {
    max-width: 370px;
  }
}
#top-sns .message {
  font-size: 12px;
  width: 48%;
  max-width: 170px;
}
@media only screen and (min-width: 768px) {
  #top-sns .message {
    font-size: 16px;
    max-width: 220px;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
見出し

トップページで使用されているカレンダーのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.9.1
*/
/* 背景や文字の色調整 */
.ec-calendar {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .ec-calendar {
    flex-direction: row;
    margin-left: -30px;
  }
}
.ec-calendar__month {
  border-collapse: collapse;
  margin-top: 30px;
}
@media screen and (min-width: 768px) {
  .ec-calendar__month {
    margin-top: 0;
    margin-left: 30px;
  }
}
.ec-calendar__month th, .ec-calendar__month td {
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}
.ec-calendar .ec-calendar__title {
  border: 0;
}
.ec-calendar .ec-calendar__sun {
  background: #F8F8F8;
  color: #DE5D50;
}
.ec-calendar .ec-calendar__mon, .ec-calendar .ec-calendar__tue, .ec-calendar .ec-calendar__wed, .ec-calendar .ec-calendar__thu, .ec-calendar .ec-calendar__fri {
  background: #F8F8F8;
  color: #333;
}
.ec-calendar .ec-calendar__sat {
  background: #F8F8F8;
  color: #5CB1B1;
}
.ec-calendar .ec-calendar__day {
  background: #FFF;
  color: #333;
}
.ec-calendar .ec-calendar__holiday {
  background: #FFF;
  color: #DE5D50 !important;
}
.ec-calendar .ec-calendar__today {
  color: #333;
  position: relative;
  z-index: 1;
}
.ec-calendar .ec-calendar__today::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #FFFDE7;
  transform: translate(-50%, -50%);
  z-index: -1;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
検索ラベル

商品一覧 ヘッダー部 に関する Project コンポーネントを定義します。

ex [商品一覧 ヘッダー部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.1.searchnav.pug
+ec-searchnavRole__topicpath
+ec-searchnavRole__info

Styleguide 13.1

*/
.ec-searchnavRole {
  margin-bottom: 0;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .ec-searchnavRole {
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.4;
    color: #525263;
    -webkit-text-size-adjust: 100%;
    width: 100%;
    max-width: 1130px;
  }
  .ec-searchnavRole:after {
    content: " ";
    display: table;
  }
  .ec-searchnavRole:after {
    clear: both;
  }
  .ec-searchnavRole textarea {
    /* for chrome fontsize bug */
    font-family: sans-serif;
  }
  .ec-searchnavRole img {
    max-width: 100%;
  }
  .ec-searchnavRole html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .ec-searchnavRole *,
.ec-searchnavRole *::before,
.ec-searchnavRole *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }
  .ec-searchnavRole img {
    width: 100%;
  }
}
.ec-searchnavRole .ec-searchnavRole__infos {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  display: flex;
  border-top: 0;
  margin-bottom: 16px;
  padding-top: 5px;
  flex-direction: column;
}
.ec-searchnavRole .ec-searchnavRole__infos:after {
  content: " ";
  display: table;
}
.ec-searchnavRole .ec-searchnavRole__infos:after {
  clear: both;
}
.ec-searchnavRole .ec-searchnavRole__infos textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-searchnavRole .ec-searchnavRole__infos img {
  max-width: 100%;
}
.ec-searchnavRole .ec-searchnavRole__infos html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-searchnavRole .ec-searchnavRole__infos *,
.ec-searchnavRole .ec-searchnavRole__infos *::before,
.ec-searchnavRole .ec-searchnavRole__infos *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-searchnavRole .ec-searchnavRole__infos img {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-searchnavRole .ec-searchnavRole__infos {
    padding-left: 0;
    padding-right: 0;
    padding-top: 16px;
    flex-direction: row;
  }
}
.ec-searchnavRole .ec-searchnavRole__counter {
  margin-bottom: 16px;
  width: 100%;
}
.ec-searchnavRole .ec-searchnavRole__counter span.ec-font-bold {
  color: #f5568b;
}
@media only screen and (min-width: 768px) {
  .ec-searchnavRole .ec-searchnavRole__counter {
    margin-bottom: 0;
    width: 50%;
  }
}
.ec-searchnavRole .ec-searchnavRole__actions {
  text-align: right;
  width: 100%;
}
.ec-searchnavRole .ec-searchnavRole__actions select {
  border: 1px solid #f5568b;
}
@media only screen and (min-width: 768px) {
  .ec-searchnavRole .ec-searchnavRole__actions {
    width: 50%;
  }
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
商品一覧

商品一覧 に関する Project コンポーネントを定義します。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2

*/
.ec-shelfRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-shelfRole:after {
  content: " ";
  display: table;
}
.ec-shelfRole:after {
  clear: both;
}
.ec-shelfRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-shelfRole img {
  max-width: 100%;
}
.ec-shelfRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-shelfRole *,
.ec-shelfRole *::before,
.ec-shelfRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-shelfRole img {
  width: 100%;
}

/*
商品一覧グリッド

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2.1

*/
.ec-shelfGrid {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}
.ec-shelfGrid a {
  color: inherit;
  text-decoration: none;
}
.ec-shelfGrid a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGrid {
    margin-left: -16px;
    margin-right: -16px;
  }
}
.ec-shelfGrid .ec-shelfGrid__item {
  margin-bottom: 36px;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.ec-shelfGrid .ec-shelfGrid__item-image {
  margin-bottom: 10px;
  text-align: center;
}
.ec-shelfGrid .ec-shelfGrid__item img {
  border-radius: 10px;
  border: 5px solid #ffe2e2;
  width: auto;
  max-height: 100%;
}
.ec-shelfGrid .ec-shelfGrid__item p.product-name {
  color: #f5568b;
  margin: 0 auto 8px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGrid .ec-shelfGrid__item {
    padding: 0 16px;
    width: 25%;
  }
}
.ec-shelfGrid .ec-shelfGrid__item .ec-productRole__btn {
  margin-top: auto;
  margin-bottom: 15px;
}
.ec-shelfGrid .ec-shelfGrid__item:nth-child(odd) {
  padding-right: 8px;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGrid .ec-shelfGrid__item:nth-child(odd) {
    padding: 0 16px;
  }
}
.ec-shelfGrid .ec-shelfGrid__item:nth-child(even) {
  padding-left: 8px;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGrid .ec-shelfGrid__item:nth-child(even) {
    padding: 0 16px;
  }
}
.ec-shelfGrid .ec-shelfGrid__title {
  margin-bottom: 7px;
}
.ec-shelfGrid .ec-shelfGrid__plice {
  font-weight: bold;
}

/*
13.2.2 商品一覧グリッド（中央寄せ）

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。
商品のあまりはセンタリングされ、中央に表示されます。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGridCenter

Styleguide 13.2.2

*/
.ec-shelfGridCenter {
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  justify-content: center;
}
.ec-shelfGridCenter a {
  color: inherit;
  text-decoration: none;
}
.ec-shelfGridCenter a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGridCenter {
    margin-left: -16px;
    margin-right: -16px;
  }
}
.ec-shelfGridCenter .ec-shelfGridCenter__item {
  margin-bottom: 36px;
  width: 50%;
}
.ec-shelfGridCenter .ec-shelfGridCenter__item-image {
  margin-bottom: 10px;
  text-align: center;
}
.ec-shelfGridCenter .ec-shelfGridCenter__item img {
  width: auto;
  max-height: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGridCenter .ec-shelfGridCenter__item {
    padding: 0 16px;
    width: 25%;
  }
}
.ec-shelfGridCenter .ec-shelfGridCenter__item .ec-productRole__btn {
  margin-top: auto;
  padding-top: 1em;
}
.ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(odd) {
  padding-right: 8px;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(odd) {
    padding: 0 16px;
  }
}
.ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(even) {
  padding-left: 8px;
}
@media only screen and (min-width: 768px) {
  .ec-shelfGridCenter .ec-shelfGridCenter__item:nth-child(even) {
    padding: 0 16px;
  }
}
.ec-shelfGridCenter .ec-shelfGridCenter__title {
  margin-bottom: 7px;
}
.ec-shelfGridCenter .ec-shelfGridCenter__plice {
  font-weight: bold;
}

/*
商品一覧フッター

商品一覧 フッター に関する Project コンポーネントを定義します。

ex [商品一覧 ページャ部](http://demo3.ec-cube.net/products/list)

Markup:
include /assets/tmpl/elements/13.3.pager.pug
+ec-pagerRole

Styleguide 13.3

*/
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
カート追加モーダル

カート追加モーダルに関する Project コンポーネントを定義します。

ex [商品一覧、商品詳細](http://demo3.ec-cube.net/products/list)

+ec-modal

Styleguide 13.4

*/
.ec-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
}
.ec-modal.small {
  width: 30%;
}
.ec-modal.full {
  width: 100%;
  height: 100%;
}
.ec-modal .ec-modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}
.ec-modal .ec-modal-wrap {
  position: relative;
  border-radius: 2px;
  border: 1px solid #333;
  background-color: #fff;
  width: 90%;
  margin: 20px;
  padding: 40px 5px;
}
@media only screen and (min-width: 768px) {
  .ec-modal .ec-modal-wrap {
    padding: 40px 10px;
    width: 50%;
    margin: 20px auto;
  }
}
.ec-modal .ec-modal-close {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 20px;
  height: 30px;
  width: 20px;
}
.ec-modal .ec-modal-close:hover {
  color: #4b5361;
}
.ec-modal .ec-modal-box {
  text-align: center;
}
.ec-modal .ec-role {
  margin-top: 20px;
}
.ec-modal .ec-role span.ec-inlineBtn--cancel {
  font-size: 14px;
  margin-bottom: 5px;
}
.ec-modal .ec-role a.ec-inlineBtn--action {
  font-size: 14px;
  margin-bottom: 5px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  margin-top: 24px;
}
.ec-productRole:after {
  content: " ";
  display: table;
}
.ec-productRole:after {
  clear: both;
}
.ec-productRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-productRole img {
  max-width: 100%;
}
.ec-productRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-productRole *,
.ec-productRole *::before,
.ec-productRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-productRole img {
  width: 100%;
}
.ec-productRole .ec-productRole__btn {
  width: 100%;
  margin: 0 auto 10px;
  text-align: center;
}
.ec-productRole .ec-productRole__btn button.add-cart::before {
  content: "\f07a";
  color: #fff;
  font-family: "FontAwesome";
  padding: 0 7px 0 0;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole__btn {
    width: 60%;
    margin-bottom: 16px;
    min-width: 285px;
  }
}
@media only screen and (min-width: 1024px) {
  .ec-productRole .ec-productRole__btn {
    width: 96%;
    min-width: auto;
  }
}
.ec-productRole .ec-productRole__description {
  margin-bottom: 16px !important;
}
.ec-productRole .ec-productRole_in {
  /*カート上テキスト*/
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole_in {
    margin: 24px auto;
    display: flex;
    justify-content: space-between;
  }
}
.ec-productRole .ec-productRole_in #item_photo_area {
  margin: 0 auto 4em;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole_in #item_photo_area {
    width: 58%;
  }
}
.ec-productRole .ec-productRole_in #item_photo_area #icon {
  margin: 0 auto 0.5rem;
  font-size: 11px;
  font-size: 1.1rem;
  letter-spacing: -0.4em;
}
.ec-productRole .ec-productRole_in #item_photo_area #icon li {
  position: relative;
  margin: 0 5px 5px;
  padding-right: 15px;
  display: inline-block;
  padding: 0 1em;
  height: 26px;
  line-height: 26px;
  color: #fff;
  letter-spacing: normal;
  font-weight: bold;
}
.ec-productRole .ec-productRole_in #item_photo_area #icon li.ic-print {
  background: #f5568b;
}
.ec-productRole .ec-productRole_in #item_photo_area #icon li.ic-cut {
  background: #00a5e3;
}
.ec-productRole .ec-productRole_in #item_photo_area #icon li.ic-marumaki {
  background: #34b9be;
}
.ec-productRole .ec-productRole_in #item_photo_area #icon li.ic-zaiko {
  background: #ff6c00;
}
.ec-productRole .ec-productRole_in #item_photo_area #icon li.ic-nouki {
  background: #38bc80;
}
.ec-productRole .ec-productRole_in #item_photo_area #icon li.ic-wire {
  background: #ff91b6;
}
.ec-productRole .ec-productRole_in #item_photo_area #icon li.ic-ori {
  background: #fcc800;
}
.ec-productRole .ec-productRole_in #item_photo_area #icon li.ic-tape {
  background: #9070af;
}
.ec-productRole .ec-productRole_in #item_photo_area #icon li.ic-himo {
  background: #f26363;
}
.ec-productRole .ec-productRole_in #item_photo_area #icon li.ic-toriyose {
  background: #ff9c00;
}
.ec-productRole .ec-productRole_in #item_photo_area #icon li.ic-special {
  background: #fb1313;
}
.ec-productRole .ec-productRole_in #item_photo_area #icon li.ic-other {
  background: #00e0e3;
}
.ec-productRole .ec-productRole_in #item_photo_area #icon li.ic-basic {
  background: #fa8484;
}
.ec-productRole .ec-productRole_in #item_photo_area #icon li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  border-width: 14px 5px;
  border-style: solid;
  border-color: transparent;
  border-right-color: #fff;
}
.ec-productRole .ec-productRole_in #item_detail_area {
  margin: 0 auto;
  width: 100%;
  /*商品名*/
  /*btnarea
  ---------------------------*/
  /*cartボタン*/
  /*disabled*/
  /*在庫について問い合わせる*/
  /*サンプル帳について*/
  /*サンプル帳を購入*/
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole_in #item_detail_area {
    width: 40%;
  }
}
.ec-productRole .ec-productRole_in #item_detail_area .ic_r_toriyose {
  margin: 0 auto;
  padding: 5px;
  text-align: center;
  font-size: 12px;
  font-size: 1.2rem;
  color: #fff;
  background: #fb1313;
  font-weight: bold;
}
.ec-productRole .ec-productRole_in #item_detail_area #detailarea {
  margin-bottom: 0;
  padding: 10px 0;
  background: #626271;
}
.ec-productRole .ec-productRole_in #item_detail_area #detailarea #relative_category_box {
  margin: 0 auto 0.5rem;
  text-align: center;
  overflow: hidden;
  zoom: 1;
  color: #fff;
}
.ec-productRole .ec-productRole_in #item_detail_area #detailarea #relative_category_box li {
  display: inline-block;
  font-size: 11px;
  font-size: 1rem;
}
.ec-productRole .ec-productRole_in #item_detail_area #detailarea #relative_category_box li a {
  padding: 2px 3px;
  color: #fff;
  background: #f5568b;
  border-radius: 5px;
}
.ec-productRole .ec-productRole_in #item_detail_area #detailarea #relative_category_box li a:hover {
  padding: 2px 3px;
  color: #fff;
  color: #f698b8;
  border-radius: 5px;
}
.ec-productRole .ec-productRole_in #item_detail_area #detailarea .ec-productRole__title {
  margin: 0 auto;
  text-align: center;
  color: #fff !important;
}
.ec-productRole .ec-productRole_in #item_detail_area #detailarea .ec-productRole__title .ec-headingTitle {
  font-size: 20px;
  color: #fff !important;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole_in #item_detail_area #detailarea .ec-productRole__title .ec-headingTitle {
    font-size: 1.8rem;
    font-size: 18px;
  }
}
.ec-productRole .ec-productRole_in #item_detail_area .ec-productRole__profile {
  margin: 0 auto;
  padding: 2%;
  background: #fbfde5;
}
.ec-productRole .ec-productRole_in #item_detail_area .ec-productRole__profile .cart_area {
  margin: 0 auto;
}
.ec-productRole .ec-productRole_in #item_detail_area .ec-productRole__profile .cart_area ul li dl {
  display: flex;
  align-items: center;
}
.ec-productRole .ec-productRole_in #item_detail_area .ec-productRole__profile .cart_area ul li dl dt {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  width: 50px;
}
.ec-productRole .ec-productRole_in #item_detail_area .ec-productRole__profile .cart_area ul li dl dd {
  width: 80%;
}
.ec-productRole .ec-productRole_in #item_detail_area .ec-productRole__profile .cart_area ul li dl dd select {
  width: 100%;
}
.ec-productRole .ec-productRole_in #item_detail_area .ec-productRole__profile .cart_area .quantity {
  margin: 8px 0;
}
.ec-productRole .ec-productRole_in #item_detail_area .ec-productRole__profile .cart_area .quantity dt {
  font-size: 1.2rem;
  font-weight: bold;
}
.ec-productRole .ec-productRole_in #item_detail_area .ec-productRole__profile .cart_area .quantity dd input {
  max-width: 80px;
}
.ec-productRole .ec-productRole_in #item_detail_area .display-area {
  margin: 15px auto;
  padding: 2%;
  width: 100%;
  background: #fff;
}
.ec-productRole .ec-productRole_in #item_detail_area .display-area dl {
  overflow: hidden;
  zoom: 1;
  margin-bottom: 0.5rem;
}
.ec-productRole .ec-productRole_in #item_detail_area .display-area dt {
  float: left;
  padding-top: 5px;
  width: 50px;
  font-size: 11px;
  font-size: 1.1rem;
}
.ec-productRole .ec-productRole_in #item_detail_area .display-area dd {
  font-weight: bold;
}
.ec-productRole .ec-productRole_in #item_detail_area .display-area dl.display-price {
  padding: 5px;
  background: #de5d50;
  color: #fff;
  font-weight: bold;
}
.ec-productRole .ec-productRole_in #item_detail_area .display-area dl.display-price dt {
  padding-top: 2px;
  font-size: 15px;
  font-size: 1.5rem;
}
.ec-productRole .ec-productRole_in #item_detail_area .display-area dl.display-price dd {
  float: right;
  padding: 0;
  font-size: 18px;
  font-size: 1.8rem;
  text-align: right;
}
.ec-productRole .ec-productRole_in #item_detail_area .btn_area ul {
  margin-bottom: 0;
  padding: 0;
}
.ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__button_area #cart {
  margin-bottom: 5px;
  border-radius: 10px;
  background: #f5568b;
  border: none;
}
.ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__button_area #cart:disabled {
  background: #8b8b8b;
}
.ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__button_area #cart:hover {
  border-radius: 15px;
  background: #f488ac;
  border: none;
}
.ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__button_area #cart:before {
  content: "\f07a";
  color: #fff;
  font-family: "FontAwesome";
  padding: 0 7px 0 0;
}
.ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__favorite_button {
  display: block;
  width: 100%;
  margin: 0 auto 1em;
  /*favorit*/
  /*見積り依頼*/
}
.ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__favorite_button li {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__favorite_button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__favorite_button li {
    line-height: 50px;
    width: 50%;
  }
}
.ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__favorite_button button:active {
  border: none !important;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__favorite_button ul li {
    margin: 0 10px;
  }
}
.ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__favorite_button #favorite {
  height: 50px;
  padding: 0;
  background: none;
  border: none;
  /*color:#de5d50;*/
  color: #333;
  font-size: 12px;
  font-size: 1.2rem;
  /*favorit:disabled*/
}
.ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__favorite_button #favorite:hover {
  color: #de5d50;
}
.ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__favorite_button #favorite::before {
  content: "\f004";
  color: #fff;
  font-family: "FontAwesome";
  padding: 5px;
  border-radius: 50%;
  background: #de5d50;
  font-size: 1.2rem;
  margin: 0 3px 0 0;
}
.ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__favorite_button #favorite:disabled {
  color: #333;
}
.ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__favorite_button #favorite:disabled::before {
  content: "\f004";
  color: #fff;
  font-family: "FontAwesome";
  padding: 6px;
  border-radius: 50%;
  /*background: #de5d50;*/
  background: #333;
  font-size: 1.3rem;
  margin: 0 3px 0 0;
}
.ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__favorite_button .btn {
  border: 3px solid green;
}
.ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__favorite_button .btn:active {
  border: none !important;
}
.ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__favorite_button #estimate-request {
  display: block;
  font-size: 12px;
  font-size: 1.2rem;
  height: 50px;
  font-weight: bold;
  color: #333;
  text-align: center;
}
.ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__favorite_button #estimate-request:hover {
  color: #ff6c00;
}
.ec-productRole .ec-productRole_in #item_detail_area #detail_cart_box__favorite_button #estimate-request::before {
  content: "\f0f6";
  color: #fff;
  font-family: "FontAwesome";
  padding: 5px 6px;
  border-radius: 50%;
  background: #ff6c00;
  font-size: 1.2rem;
  margin: 0 3px 0 0;
}
.ec-productRole .ec-productRole_in #item_detail_area #zaiko {
  margin-bottom: 5px;
  padding: 10px 16px;
  border-radius: 10px;
  background: #ffb400;
  border: none;
  font-size: 16px;
  font-size: 1.6rem;
}
.ec-productRole .ec-productRole_in #item_detail_area #zaiko:hover {
  background: #ffc333;
}
.ec-productRole .ec-productRole_in #item_detail_area #samplebook {
  overflow: hidden;
  zoom: 1;
  margin: 0 auto;
  padding: 15px 6px;
  width: 100%;
  background: #f2f2f2;
}
.ec-productRole .ec-productRole_in #item_detail_area #samplebook .sample-txt {
  font-size: 12px;
  font-size: 1.2rem;
}
.ec-productRole .ec-productRole_in #item_detail_area #samplebook .sample-txt p.link {
  margin: 5px 0;
  text-align: right;
  font-weight: bold;
  font-size: 1.1rem;
}
.ec-productRole .ec-productRole_in #item_detail_area #samplebook .sample-txt p.link a {
  text-decoration: underline !important;
  color: #f5568b;
}
.ec-productRole .ec-productRole_in #item_detail_area #samplebook .sample-txt p.link a:hover {
  text-decoration: none;
}
.ec-productRole .ec-productRole_in #item_detail_area #samplebook .sample-txt p.link a::after {
  content: "\f0a9";
  color: #333;
  font-family: "FontAwesome";
  margin: 0 0 0 3px;
}
.ec-productRole .ec-productRole_in #item_detail_area #sample {
  width: 100%;
  background: #ff6c00;
  border-radius: 10px;
  color: #fff;
  height: 45px;
  line-height: 45px;
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: bold;
  border: none;
}
.ec-productRole .ec-productRole_in #item_detail_area #sample:hover {
  background: #ff8c37;
}
.ec-productRole .ec-productRole_in #item_detail_area #sample::before {
  content: "\f07a";
  color: #fff;
  font-family: "FontAwesome";
  padding: 0 7px 0 0;
}
.ec-productRole .ec-productRole_in #item_detail_area #catalog {
  border-top: 1px dotted #333;
  overflow: hidden;
  zoom: 1;
  margin: 0 auto 2em;
  padding: 15px 6px;
  width: 100%;
  background: #f2f2f2;
}
.ec-productRole .ec-productRole_in #item_detail_area #catalog .sample-txt {
  font-size: 12px;
  font-size: 1.2rem;
}
.ec-productRole .ec-productRole_in #item_detail_area #catalog button {
  background: #fcbe00;
}
.ec-productRole .ec-productRole_in #item_detail_area #catalog button:hover {
  background: #ffda4a;
}
.ec-productRole .ec-productRole_in #item_detail_area p.comment {
  margin: 0 auto;
  background: #F2F2F2;
  padding: 15px 10px 10px;
  border-top: 1px dashed #222;
}
.ec-productRole .ec-productRole_in .cart-comment {
  margin: 1rem auto;
  font-size: 0.9em;
  width: 96%;
}
.ec-productRole .ec-productRole_in .cart-comment ul li {
  margin-left: 15px;
  list-style-type: disc;
}
.ec-productRole .ec-productRole_in .cart-comment ol li {
  margin-left: 15px;
  list-style-type: decimal;
}
.ec-productRole .ec-productRole_in .ec-productRole .ec-productRole_in__img {
  margin-right: 0;
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole_in .ec-productRole .ec-productRole_in__img {
    margin-right: 16px;
    margin-bottom: 0;
  }
}
.ec-productRole .ec-productRole_in .ec-productRole .ec-productRole_in__profile {
  margin: 0 1.5em;
  border: 1px solid red;
}
.ec-productRole .ec-productRole_in .ec-productRole .ec-productRole_in__tags {
  margin-top: 16px;
  padding: 0;
  padding-bottom: 16px;
  border-bottom: 1px dotted #ccc;
}
.ec-productRole .ec-productRole_in .ec-productRole .ec-productRole_in__tag {
  display: inline-block;
  padding: 2px 5px;
  list-style: none;
  font-size: 80%;
  color: #525263;
  border: solid 1px #D7DADD;
  border-radius: 3px;
  background-color: #F5F7F8;
}
.ec-productRole .ec-productRole_in .ec-productRole .ec-productRole_in__priceRegular {
  padding-top: 14px;
}
.ec-productRole .ec-productRole_in .ec-productRole .ec-productRole_in__priceRegularTax {
  margin-left: 5px;
  font-size: 10px;
}
.ec-productRole .ec-productRole_in .ec-productRole .ec-productRole_in__price {
  color: #DE5D50;
  font-size: 28px;
  padding: 0;
  border-bottom: 0;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole_in .ec-productRole .ec-productRole_in__price {
    padding: 14px 0;
    border-bottom: 1px dotted #ccc;
  }
}
.ec-productRole .ec-productRole_in .ec-productRole .ec-productRole_in__code {
  padding: 14px 0;
  border-bottom: 1px dotted #ccc;
}
.ec-productRole .ec-productRole_in .ec-productRole .ec-productRole_in__category {
  padding: 14px 0;
  border-bottom: 1px dotted #ccc;
}
.ec-productRole .ec-productRole_in .ec-productRole .ec-productRole_in__category a {
  color: #33A8D0;
}
.ec-productRole .ec-productRole_in .ec-productRole .ec-productRole_in__category ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.ec-productRole .ec-productRole_in .ec-productRole .ec-productRole_in__actions {
  padding: 14px 0;
}
.ec-productRole .ec-productRole_in .ec-productRole .ec-productRole_in__actions .ec-select select {
  height: 40px;
  max-width: 100%;
  min-width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-productRole .ec-productRole_in .ec-productRole .ec-productRole_in__actions .ec-select select {
    min-width: 350px;
    max-width: 350px;
  }
}

/* 数値の入力欄にスピナーを常時表示する */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

.ec-productRole__description {
  margin-bottom: 16px !important;
}
.ec-productRole__description .wysiwyg {
  margin: 0 auto 3em;
}
.ec-productRole__description .t-area p.flag-sample {
  position: absolute;
  right: 0;
  top: 6px;
  margin-right: 5px;
  padding: 4px;
  border-radius: 5px;
  font-size: 13px;
  font-size: 1.3rem;
  background: #ff6c00;
  color: #fff;
}
.ec-productRole__description .star img {
  width: auto !important;
  max-width: 77px;
}
.ec-productRole__description .color-fig img {
  width: auto !important;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [カートページ](http://demo3.ec-cube.net/shopping)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartRole

Styleguide 15.1

*/
.ec-cartRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.ec-cartRole:after {
  content: " ";
  display: table;
}
.ec-cartRole:after {
  clear: both;
}
.ec-cartRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-cartRole img {
  max-width: 100%;
}
.ec-cartRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-cartRole *,
.ec-cartRole *::before,
.ec-cartRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-cartRole img {
  width: 100%;
}
.ec-cartRole::before {
  display: none;
}
.ec-cartRole .ec-cartRole__progress {
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
}
.ec-cartRole .ec-cartRole__error {
  width: 100%;
  text-align: center;
}
.ec-cartRole .ec-cartRole__error .ec-alert-warning {
  max-width: 80%;
  display: inline-block;
}
.ec-cartRole .ec-cartRole__totalText {
  margin-bottom: 0;
  padding: 16px 0 6px;
  width: 100%;
  text-align: center;
  font-weight: normal;
}
@media only screen and (min-width: 768px) {
  .ec-cartRole .ec-cartRole__totalText {
    margin-bottom: 5px;
    padding: 0;
  }
}
.ec-cartRole .ec-cartRole__totalText strong {
  color: #f5568b;
}
.ec-cartRole .ec-cartRole__cart {
  margin: 0;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-cartRole .ec-cartRole__cart {
    margin: 0 10%;
  }
}
.ec-cartRole .ec-cartRole__actions {
  padding: 1em;
  text-align: right;
  width: 100%;
  background: #fbfde5;
}
@media only screen and (min-width: 768px) {
  .ec-cartRole .ec-cartRole__actions {
    margin-top: 3em;
  }
}
.ec-cartRole .ec-cartRole__actions p {
  margin: 0 auto;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  background: #ff6c00;
  color: #fff;
}
@media only screen and (min-width: 768px) {
  .ec-cartRole .ec-cartRole__actions {
    width: 30%;
    margin-right: 10%;
  }
}
.ec-cartRole .ec-cartRole__total {
  padding: 0 0 20px;
  font-weight: bold;
  font-size: 16px;
}
.ec-cartRole .ec-cartRole__sample, .ec-cartRole .ec-cartRole__goods {
  padding: 0;
  font-size: 14px;
}
.ec-cartRole .ec-cartRole__totalAmount {
  margin-left: 30px;
  color: #de5d50;
  font-size: 16px;
}
@media only screen and (min-width: 768px) {
  .ec-cartRole .ec-cartRole__totalAmount {
    font-size: 24px;
  }
}
.ec-cartRole .ec-blockBtn--action {
  margin-bottom: 10px;
}

/*
カート商品表示枠（テーブルヘッダ）

カート内の商品をを表示するテーブル枠です。

ex [カートページ　テーブル部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartTable

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.2
*/
.ec-cartTable {
  display: table;
  border-top: 1px solid #ccc;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-cartTable {
    border-top: none;
  }
}

/*
カート商品表示枠（テーブルヘッダ）

カート内の商品を表示するテーブルのヘッダです。
スマホでは非表示となります。

ex [カートページ　カートテーブルヘッダ部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)


Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartHeader

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>



Styleguide 15.1.3
*/
.ec-cartHeader {
  display: none;
  border: 1px solid #ccc;
  width: 100%;
  background: #F4F3F0;
}
@media only screen and (min-width: 768px) {
  .ec-cartHeader {
    display: table-row;
    border: 1px solid #ccc;
  }
}
.ec-cartHeader .ec-cartHeader__label {
  display: table-cell;
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 16px;
  text-align: center;
  background: url(../img/common/th_bg.gif) 0 0 repeat;
  overflow-x: hidden;
  font-weight: bold;
}
.ec-cartHeader .ec-cartHeader__label:first-child {
  border-left: 1px solid #ccc;
}

#sample-bg {
  padding: 5px;
  background: url("../img/common/th_bg_yellow.gif") repeat scroll 0 0;
}

#sample li {
  background: url("../img/common/th_bg_yellow.gif") repeat scroll 0 0 !important;
}

#history-bg {
  padding: 5px;
  background: url("../img/common/th_bg.gif") repeat scroll 0 0;
}

.ec-cartCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  /*JNB*/
}
.ec-cartCompleteRole:after {
  content: " ";
  display: table;
}
.ec-cartCompleteRole:after {
  clear: both;
}
.ec-cartCompleteRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-cartCompleteRole img {
  max-width: 100%;
}
.ec-cartCompleteRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-cartCompleteRole *,
.ec-cartCompleteRole *::before,
.ec-cartCompleteRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-cartCompleteRole img {
  width: 100%;
}
.ec-cartCompleteRole .thanks-jnb, .ec-cartCompleteRole .thanks-credit {
  margin: 0 auto 2rem;
  text-align: center;
}
.ec-cartCompleteRole .thanks-jnb h3, .ec-cartCompleteRole .thanks-jnb .h3, .ec-cartCompleteRole .thanks-credit h3, .ec-cartCompleteRole .thanks-credit .h3 {
  font-size: 24px;
  font-size: 2.4rem;
}
.ec-cartCompleteRole .thanks-jnb h3 strong, .ec-cartCompleteRole .thanks-jnb .h3 strong, .ec-cartCompleteRole .thanks-credit h3 strong, .ec-cartCompleteRole .thanks-credit .h3 strong {
  color: #003894;
}
.ec-cartCompleteRole .thanks-jnb h3 a, .ec-cartCompleteRole .thanks-jnb .h3 a, .ec-cartCompleteRole .thanks-credit h3 a, .ec-cartCompleteRole .thanks-credit .h3 a {
  text-decoration: underline;
}
.ec-cartCompleteRole .thanks-jnb h3 a:hover, .ec-cartCompleteRole .thanks-jnb .h3 a:hover, .ec-cartCompleteRole .thanks-credit h3 a:hover, .ec-cartCompleteRole .thanks-credit .h3 a:hover {
  text-decoration: none;
}
.ec-cartCompleteRole .thanks-jnb p.jnb-furikomi, .ec-cartCompleteRole .thanks-credit p.jnb-furikomi {
  max-width: 700px;
  margin: 8px auto 2rem;
  padding: 5px;
  background: #003894;
  color: #fff;
  font-weight: bold;
}
.ec-cartCompleteRole .thanks-jnb p.btn-url a, .ec-cartCompleteRole .thanks-credit p.btn-url a {
  display: inline-block;
  color: #fff;
  background: #f5568b;
  font-size: 1.3em;
  font-weight: bold;
  border-radius: 20px;
  padding: 10px 15px;
  text-decoration: none;
}
.ec-cartCompleteRole dl.pay-txt {
  max-width: 700px;
  margin: 1.5rem auto 2rem;
  padding: 10px;
  border: 1px solid #de5d50;
}
.ec-cartCompleteRole dl.pay-txt dt {
  padding-bottom: 0.5rem;
  border-bottom: 1px dashed #ccc;
  font-size: 24px;
  font-size: 2.4rem;
  text-align: center;
  font-weight: bold;
  color: #de5d50;
}
.ec-cartCompleteRole dl.pay-txt dd {
  padding: 15px;
  font-size: 14px;
  font-size: 1.4rem;
  text-align: left;
}
.ec-cartCompleteRole dl.pay-txt dd span {
  color: #de5d50;
}

/*
カート内商品

カート内のアイテムを表示するテーブル行です。
スマホでは非表示となります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRow

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.4
*/
.ec-cartTable {
  margin-bottom: 2em;
  font-size: 14px;
}

.ec-cartRow {
  border: 1px solid #ccc;
  display: table-row;
}
.ec-cartRow li {
  border-right: 1px solid #ccc;
}
.ec-cartRow .ec-cartRow__delColumn {
  border-bottom: 1px solid #ccc;
  text-align: center;
  display: table-cell;
  width: 14%;
  vertical-align: middle;
}
.ec-cartRow .ec-cartRow__delColumn a {
  width: 14px;
  display: inline-block;
}
.ec-cartRow .ec-cartRow__delColumn a img {
  width: 16px;
  max-width: 16px;
  display: inline-block;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__delColumn {
    width: 8.3333333%;
  }
}
.ec-cartRow .ec-cartRow__delColumn .ec-icon img {
  width: 1.5em;
  height: 1.5em;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__delColumn .ec-icon img {
    width: 1em;
    height: 1em;
  }
}
.ec-cartRow .ec-cartRow__contentColumn {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  padding: 10px;
  display: table;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__contentColumn {
    display: table-cell;
  }
}
.ec-cartRow .ec-cartRow__img {
  display: table-cell;
  width: 40%;
  vertical-align: middle;
  padding-right: 10px;
}
.ec-cartRow .ec-cartRow__img img {
  border: 1px solid #ccc;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__img {
    display: inline-block;
    min-width: 80px;
    max-width: 100px;
    padding-right: 0;
  }
}
.ec-cartRow .ec-cartRow__summary {
  display: table-cell;
  margin-left: 5px;
  font-weight: bold;
  vertical-align: middle;
  width: 46%;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__summary {
    display: inline-block;
    width: calc(100% - 130px);
    margin-left: 20px;
    vertical-align: middle;
  }
}
.ec-cartRow .ec-cartRow__summary .ec-cartRow__name {
  margin-bottom: 5px;
}
.ec-cartRow .ec-cartRow__summary .ec-cartRow__name a {
  text-decoration: underline;
}
.ec-cartRow .ec-cartRow__summary .ec-cartRow__name a:hover {
  text-decoration: none;
}
.ec-cartRow .ec-cartRow__summary .ec-cartRow__unitPriceSP {
  display: block;
  font-weight: normal;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__summary .ec-cartRow__unitPriceSP {
    display: none;
  }
}
.ec-cartRow .ec-cartRow__summary .ec-cartRow__sutbtotalSP {
  display: block;
  font-weight: normal;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__summary .ec-cartRow__sutbtotalSP {
    display: none;
  }
}
.ec-cartRow .ec-cartRow__amountColumn {
  display: table-cell;
  border-bottom: 1px solid #ccc;
  vertical-align: middle;
  text-align: center;
  width: 20%;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__amountColumn {
    width: 16.66666667%;
  }
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount {
  display: none;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount {
    display: block;
  }
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountSP {
  display: block;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountSP {
    display: none;
  }
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpDown {
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpDown {
    display: block;
  }
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton {
  margin: 0 2px;
  display: inline-block;
  border: 2px solid #c9c9c9;
  border-radius: 50%;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 40px;
  vertical-align: middle;
  position: relative;
  text-align: center;
  background: #fff;
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton .ec-cartRow__amountUpButton__icon img {
  display: block;
  margin-left: -0.4em;
  width: 0.8em;
  height: 0.8em;
  position: absolute;
  top: 28%;
  left: 50%;
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton, .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
  margin: 0 2px;
  display: inline-block;
  border: 2px solid #c9c9c9;
  border-radius: 50%;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 40px;
  vertical-align: middle;
  position: relative;
  text-align: center;
  background: #fff;
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton .ec-cartRow__amountDownButton__icon img, .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled .ec-cartRow__amountDownButton__icon img {
  display: block;
  margin-left: -0.4em;
  width: 0.8em;
  height: 0.8em;
  position: absolute;
  top: 28%;
  left: 50%;
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
  cursor: default;
}
.ec-cartRow .ec-cartRow__subtotalColumn {
  display: none;
  border-bottom: 1px solid #ccc;
  text-align: right;
  padding: 0 10px;
  width: 16.66666667%;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__subtotalColumn {
    display: table-cell;
  }
}

/*
カート内商品(商品が１の場合)

商品が１の場合はカート商品を減らす「-」ボタンの無効化状態になります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRowOnly

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.5
*/
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
  cursor: default;
}

/*
アラート

カート内の商品に問題があることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartRole
  .ec-cartRole__cart
    +ec-alert-warning

Styleguide 15.1.6
*/
.ec-alert-warning {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #F99;
  margin-bottom: 20px;
}
.ec-alert-warning .ec-alert-warning__icon {
  display: inline-block;
  margin-right: 1rem;
  width: 20px;
  height: 20px;
  color: #fff;
  fill: #fff;
  vertical-align: top;
}
.ec-alert-warning .ec-alert-warning__text {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  position: relative;
}

/*
アラート(空)

カートが空であることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-off3Grid
        .ec-off3Grid__cell
            +ec-alert-warningEnpty

Styleguide 15.1.7
*/
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
注文内容確認

カート内 注文内容確認に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/shopping)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderRole




Styleguide 15.2
*/
.ec-orderRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  display: flex;
  flex-direction: column;
  margin-top: 0;
}
.ec-orderRole:after {
  content: " ";
  display: table;
}
.ec-orderRole:after {
  clear: both;
}
.ec-orderRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-orderRole img {
  max-width: 100%;
}
.ec-orderRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-orderRole *,
.ec-orderRole *::before,
.ec-orderRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-orderRole img {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-orderRole {
    margin-top: 20px;
    flex-direction: row;
  }
}
.ec-orderRole .ec-inlineBtn {
  font-weight: normal;
}
.mypage .ec-orderRole .ec-inlineBtn {
  font-weight: bold;
}
.ec-orderRole .ec-orderRole__detail {
  padding: 0;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-orderRole .ec-orderRole__detail {
    padding: 0 16px;
    width: 66.66666%;
  }
}
.ec-orderRole .ec-orderRole__summary {
  width: 100%;
}
.ec-orderRole .ec-orderRole__summary .ec-inlineBtn {
  display: inline-block;
}
@media only screen and (min-width: 768px) {
  .ec-orderRole .ec-orderRole__summary {
    width: 33.33333%;
    padding: 0 16px;
  }
  .ec-orderRole .ec-orderRole__summary .ec-inlineBtn {
    display: none;
  }
}
.ec-orderRole .ec-borderedList {
  margin-bottom: 20px;
  border-top: 1px dotted #ccc;
}
@media only screen and (min-width: 768px) {
  .ec-orderRole .ec-borderedList {
    border-top: none;
  }
}

.ec-orderOrder, .ec-orderConfirm {
  margin-bottom: 30px;
}

.link-question a:hover {
  opacity: 0.8;
}
.link-question a::before {
  content: "\f059";
  padding: 0 0 0 5px;
  font-family: "Font Awesome 5 Free", "Font Awesome 5 Brands";
  font-weight: 900;
  color: #525263;
}

/*
注文履歴詳細 オーダ情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderInfo

Styleguide 15.2.1
*/
.ec-orderOrder {
  margin-bottom: 30px;
}
.ec-orderOrder .ec-orderOrder__items {
  border-bottom: 1px dotted #ccc;
  border-top: 1px dotted #ccc;
}

/*
注文履歴詳細 お客様情報

マイページ 注文詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAccount

Styleguide 15.2.2
*/
.ec-orderAccount {
  margin-bottom: 30px;
}
.ec-orderAccount p {
  margin-bottom: 0;
}
.ec-orderAccount:after {
  content: " ";
  display: table;
}
.ec-orderAccount:after {
  clear: both;
}
.ec-orderAccount .ec-orderAccount__change {
  display: inline-block;
  margin-left: 10px;
  float: right;
}
.ec-orderAccount .ec-orderAccount__account {
  margin-bottom: 16px;
}

/*
注文詳細 配送情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　配送情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderDelivery

Styleguide 15.2.3
*/
.ec-orderDelivery {
  margin-bottom: 30px;
}
.ec-orderDelivery .ec-orderDelivery__title {
  padding: 16px 0 17px;
  font-weight: bold;
  font-size: 18px;
  position: relative;
}
.ec-orderDelivery .ec-orderDelivery__change {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
}
.ec-orderDelivery .ec-orderDelivery__items {
  border-bottom: 1px dotted #ccc;
  border-top: 1px dotted #ccc;
}
.ec-orderDelivery .ec-orderDelivery__address {
  margin: 10px 0 18px;
}
.ec-orderDelivery .ec-orderDelivery__address p {
  margin: 0;
}
.ec-orderDelivery .ec-orderDelivery__edit {
  margin-bottom: 2rem;
}

h2.shopping-list, .shopping-list.h2 {
  padding: 5px;
  font-size: 1.6rem;
  background: url("../img/common/th_bg.gif") repeat scroll 0 0;
}
@media only screen and (min-width: 768px) {
  h2.shopping-list, .shopping-list.h2 {
    font-size: 1.8rem;
  }
}
h2.shopping-list:before, .shopping-list.h2:before {
  content: "\f10c";
  color: #fc6a72;
  font-family: "FontAwesome";
  padding: 0 7px 0 0;
  font-size: 2rem;
}

.delivery-table th, .delivery-table td {
  padding: 10px 5px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  font-size: 14px;
}
.delivery-table th {
  background: url(../img/common/th_bg.gif) 0 0 repeat;
  text-align: center;
  font-weight: bold;
}
.delivery-table td a {
  color: #f5568b;
}
.delivery-table td.goods-img {
  width: 15%;
}
.delivery-table td.goods-num {
  width: 10%;
}
.delivery-table td.goods-name {
  width: 45%;
}
.delivery-table td.goods-name a {
  text-decoration: underline;
  font-weight: bold;
}
.delivery-table td.goods-name a:hover {
  text-decoration: none;
}
.delivery-table td.goods-price {
  width: 15%;
}
.delivery-table td.goods-subtotal {
  width: 15%;
}

#sample-bg {
  padding: 5px;
  background: url("../img/common/th_bg_yellow.gif") repeat scroll 0 0;
}

#sample table.delivery-table th {
  background: url("../img/common/th_bg_yellow.gif") repeat scroll 0 0 !important;
}

.payment-box {
  margin: 15px 0 15px 15px !important;
  font-size: 14px;
  font-size: 1.4rem;
  width: 100%;
}
.payment-box ul {
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
}
.payment-box ul.credit li {
  width: 40px;
  margin-right: 5px;
}
.payment-box ul.credit li img {
  width: 100%;
}
.payment-box .payment-in {
  width: 100%;
}
.payment-box .payment-in p.fig {
  max-width: 400px !important;
}
.payment-box .payment-in p.link-question {
  text-align: right;
  font-size: 15px;
  font-size: 1.5rem;
}

/*
注文履歴詳細 支払情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　支払情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderPayment
    .ec-rectHeading
      h2 お支払方法
    p 支払方法： 郵便振替

Styleguide 15.2.4
*/
.ec-orderPayment {
  margin-bottom: 30px;
}
.ec-orderPayment .t-area h2, .ec-orderPayment .t-area .h2 {
  background: none !important;
  padding: 0 !important;
  padding-left: 50px !important;
  margin-top: 0px !important;
  margin-bottom: 0 !important;
}
.ec-orderPayment label span {
  font-weight: bold;
}

/*
注文履歴詳細 お問い合わせ

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　お問い合わせ(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderConfirm
    .ec-rectHeading
      h2 お問い合わせ
    p 記載なし

Styleguide 15.2.5
*/
.ec-orderConfirm {
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) {
  .ec-orderConfirm {
    margin-bottom: 30px;
  }
}
.ec-orderConfirm .ec-input textarea, .ec-orderConfirm .ec-halfInput textarea, .ec-orderConfirm .ec-numberInput textarea, .ec-orderConfirm .ec-zipInput textarea, .ec-orderConfirm .ec-telInput textarea, .ec-orderConfirm .ec-select textarea, .ec-orderConfirm .ec-birth textarea {
  height: 96px;
}

/*
お届け先の複数指定

お届け先の複数指定に関するコンポーネントを定義します。

ex [マイページ　お届け先の複数指定](http://demo3.ec-cube.net/shopping/shipping_multiple)
(商品購入画面 → 「お届け先を追加する」を押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAddAddress

Styleguide 15.2.6
*/
.ec-AddAddress {
  padding: 0 10px;
}
@media only screen and (min-width: 768px) {
  .ec-AddAddress {
    margin: 0 10%;
  }
}
.ec-AddAddress .ec-AddAddress__info {
  margin-bottom: 32px;
  text-align: center;
  font-size: 16px;
}
.ec-AddAddress .ec-AddAddress__add {
  border-top: 1px solid #f4f4f4;
  padding-top: 20px;
  margin-bottom: 20px;
}
.ec-AddAddress .ec-AddAddress__item {
  display: table;
  padding: 16px;
  background: #f4f4f4;
  margin-bottom: 16px;
}
.ec-AddAddress .ec-AddAddress__itemThumb {
  display: table-cell;
  min-width: 160px;
  width: 20%;
}
.ec-AddAddress .ec-AddAddress__itemThumb img {
  width: 100%;
}
.ec-AddAddress .ec-AddAddress__itemtContent {
  display: table-cell;
  vertical-align: middle;
  padding-left: 16px;
  font-size: 16px;
}
.ec-AddAddress .ec-AddAddress__itemtContent a {
  color: #f5568b;
  text-decoration: underline;
}
.ec-AddAddress .ec-AddAddress__itemtContent a:hover {
  text-align: none;
}
.ec-AddAddress .ec-AddAddress__itemtTitle {
  font-weight: bold;
  margin-bottom: 10px;
}
.ec-AddAddress .ec-AddAddress__itemtSize {
  margin-bottom: 10px;
}
.ec-AddAddress .ec-AddAddress__select {
  margin-bottom: 5px;
}
.ec-AddAddress .ec-AddAddress__selectAddress {
  display: block;
}
@media only screen and (min-width: 768px) {
  .ec-AddAddress .ec-AddAddress__selectAddress {
    display: inline-block;
  }
}
.ec-AddAddress .ec-AddAddress__selectAddress label {
  font-size: 16px;
  font-weight: normal;
}
.ec-AddAddress .ec-AddAddress__selectAddress select {
  min-width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-AddAddress .ec-AddAddress__selectAddress select {
    min-width: 350px;
  }
}
.ec-AddAddress .ec-AddAddress__selectNumber {
  display: block;
}
@media only screen and (min-width: 768px) {
  .ec-AddAddress .ec-AddAddress__selectNumber {
    display: inline-block;
    margin-left: 30px;
  }
}
.ec-AddAddress .ec-AddAddress__selectNumber label {
  font-size: 16px;
  font-weight: normal;
}
.ec-AddAddress .ec-AddAddress__selectNumber input {
  display: inline-block;
  margin-left: 10px;
  width: 80px;
}
@media only screen and (min-width: 768px) {
  .ec-AddAddress .ec-AddAddress__actions {
    width: 33.3333%;
    min-width: 250px;
    margin: 0 auto;
  }
}
.ec-AddAddress .ec-AddAddress__actions .ec-blockBtn--action {
  margin-bottom: 8px;
}
.ec-AddAddress .ec-AddAddress__new {
  margin-bottom: 20px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
注文履歴一覧

マイページ 注文履歴部に関する Project コンポーネントを定義します。

ex [マイページ　注文履歴一覧](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyRole

Styleguide 16.1
*/
.ec-historyRole .ec-historyRole__contents {
  padding-top: 1em;
  padding-bottom: 16px;
  border-top: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  color: #525263;
}
@media only screen and (min-width: 768px) {
  .ec-historyRole .ec-historyRole__contents {
    flex-direction: row;
  }
}
.ec-historyRole .ec-historyRole__header {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-historyRole .ec-historyRole__header {
    width: 33.3333%;
  }
}
.ec-historyRole .ec-historyRole__detail {
  border-top: 1px dotted #ccc;
  width: 100%;
}
.ec-historyRole .ec-historyRole__detail .ec-imageGrid {
  margin-top: 15px;
}
@media only screen and (min-width: 768px) {
  .ec-historyRole .ec-historyRole__detail .ec-imageGrid {
    margin-top: 0;
  }
}
.ec-historyRole .ec-historyRole__detail .ec-imageGrid__img img {
  border: 1px solid #ccc;
}
.ec-historyRole .ec-historyRole__detail .ec-imageGrid:nth-of-type(1) {
  border-top: none;
}
.ec-historyRole .ec-historyRole__detail .ec-imageGrid__content p {
  font-size: 14px;
  font-size: 1.4rem;
}
.ec-historyRole .ec-historyRole__detail .ec-imageGrid__content p a {
  color: #f5568b;
  text-decoration: underline;
}
.ec-historyRole .ec-historyRole__detail .ec-imageGrid__content p a:hover {
  text-decoration: none;
}
.ec-historyRole .ec-historyRole__detail .ec-historyRole__detailTitle {
  margin-bottom: 4px;
  font-size: 1rem;
  font-weight: bold;
}
.ec-historyRole .ec-historyRole__detail .ec-historyRole__detailPrice {
  margin-bottom: 2px;
  font-size: 1rem;
}
.ec-historyRole .ec-historyRole__detail .ec-historyRole__detailPrice a {
  color: #f5568b;
}
@media only screen and (min-width: 768px) {
  .ec-historyRole .ec-historyRole__detail {
    width: 66.6666%;
    border-top: none;
  }
}

/*
注文履歴一覧 規格

マイページ 注文履歴内アイテムの規格を定義します。

ex [マイページ　注文履歴一覧](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyRole-option

Styleguide 16.1.1
*/
.ec-historyRole .ec-historyRole__detail .ec-historyRole__detailOption {
  display: inline-block;
  margin-bottom: 8px;
  margin-right: 0.5rem;
  font-size: 1.6rem;
}
.ec-historyRole .ec-historyRole__detail .ec-historyRole__detailOption::after {
  display: inline-block;
  padding-left: 0.5rem;
  content: "/";
  font-weight: bold;
}

/*
注文履歴一覧ヘッダ

注文履歴一覧で使用するヘッダのコンポーネントを定義します。

ex [マイページ　注文履歴一覧ヘッダ](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyHeader
p hofe

Styleguide 16.1.2
*/
.ec-historyListHeader .ec-historyListHeader__date {
  font-weight: bold;
  font-size: 16px;
}
@media only screen and (min-width: 768px) {
  .ec-historyListHeader .ec-historyListHeader__date {
    font-weight: bold;
  }
}
.ec-historyListHeader .ec-historyListHeader__action {
  margin: 16px 0;
}
.ec-historyListHeader .ec-historyListHeader__action a {
  font-size: 12px;
  font-weight: normal;
}

/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
注文履歴詳細

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailRole

Styleguide 16.2
*/
/*
注文履歴詳細 メール履歴

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　メール履歴](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailMail

Styleguide 16.2.5
*/
.ec-orderMails .ec-orderMails__item {
  padding-bottom: 10px;
  border-bottom: 1px dotted #ccc;
}
.ec-orderMails .ec-orderMails__time {
  margin: 0;
}
.ec-orderMails .ec-orderMails__body {
  display: none;
}

/*
注文履歴詳細 メール履歴個別

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　メール履歴個別](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailMailHistory

Styleguide 16.2.6
*/
.ec-orderMail {
  padding-bottom: 10px;
  border-bottom: 1px dotted #ccc;
  margin-bottom: 16px;
}
.ec-orderMail .ec-orderMail__time {
  margin: 0;
}
.ec-orderMail .ec-orderMail__body {
  display: none;
}
.ec-orderMail .ec-orderMail__time {
  margin-bottom: 4px;
}
.ec-orderMail .ec-orderMail__link {
  margin-bottom: 4px;
}
.ec-orderMail .ec-orderMail__link a {
  color: #0092C4;
  text-decoration: none;
  cursor: pointer;
}
.ec-orderMail .ec-orderMail__link a:hover {
  color: #33A8D0;
}
.ec-orderMail .ec-orderMail__close a {
  color: #0092C4;
  text-decoration: none;
  cursor: pointer;
}
.ec-orderMail .ec-orderMail__close a:hover {
  color: #33A8D0;
}

/*
住所一覧

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [マイページ内 お届け先編集](http://demo3.ec-cube.net/mypage/delivery)

Markup:
include /assets/tmpl/elements/17.1.address.pug
+ec-addressList
+ec-addressRole

sg-wrapper:
<div class="ec-addressRole">
  <sg-wrapper-content/>
</div>

Styleguide 17.1

*/
.ec-addressRole .ec-addressRole__item {
  border-top: 1px dotted #ccc;
}
.ec-addressRole .ec-addressRole__actions {
  margin-top: 32px;
  padding-bottom: 20px;
  border-bottom: 1px dotted #ccc;
}

.ec-addressList .ec-addressList__item {
  display: table;
  width: 100%;
  position: relative;
  border-bottom: 1px dotted #ccc;
}
.ec-addressList .ec-addressList__remove {
  vertical-align: middle;
  padding: 16px;
  text-align: center;
}
.ec-addressList .ec-addressList__remove .ec-icon img {
  width: 1em;
  height: 1em;
}
.ec-addressList .ec-addressList__address {
  display: table-cell;
  vertical-align: middle;
  padding: 16px;
  margin-right: 4em;
  width: 80%;
}
.ec-addressList .ec-addressList__action {
  position: relative;
  vertical-align: middle;
  text-align: right;
  top: 27px;
  padding-right: 10px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
パスワードリセット

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [パスワードリセット画面](http://demo3.ec-cube.net/forgot)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/18.1.password.pug
+ec-passwordRole

Styleguide 18.1

*/
.ec-forgotRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-forgotRole:after {
  content: " ";
  display: table;
}
.ec-forgotRole:after {
  clear: both;
}
.ec-forgotRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-forgotRole img {
  max-width: 100%;
}
.ec-forgotRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-forgotRole *,
.ec-forgotRole *::before,
.ec-forgotRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-forgotRole img {
  width: 100%;
}
.ec-forgotRole .ec-forgotRole__intro {
  font-size: 16px;
}
.ec-forgotRole .ec-forgotRole__form {
  margin-bottom: 16px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
会員登録

新規会員登録 に関する Project コンポーネントを定義します。

ex [新規会員登録画面　会員登録](http://demo3.ec-cube.net/entry)

Markup:
include /assets/tmpl/elements/19.1.register.pug
+ec-registerRole

Styleguide 19.1

*/
.ec-registerRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-registerRole:after {
  content: " ";
  display: table;
}
.ec-registerRole:after {
  clear: both;
}
.ec-registerRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-registerRole img {
  max-width: 100%;
}
.ec-registerRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-registerRole *,
.ec-registerRole *::before,
.ec-registerRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-registerRole img {
  width: 100%;
}
.ec-registerRole .ec-registerRole__actions {
  padding-top: 20px;
  text-align: center;
}
.ec-registerRole .ec-registerRole__actions p {
  margin-bottom: 16px;
}
.ec-registerRole .ec-blockBtn--action {
  margin-bottom: 16px;
}

.ec-registerCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-registerCompleteRole:after {
  content: " ";
  display: table;
}
.ec-registerCompleteRole:after {
  clear: both;
}
.ec-registerCompleteRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-registerCompleteRole img {
  max-width: 100%;
}
.ec-registerCompleteRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-registerCompleteRole *,
.ec-registerCompleteRole *::before,
.ec-registerCompleteRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-registerCompleteRole img {
  width: 100%;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お問い合わせ

お問い合わせ に関する Project コンポーネントを定義します。

ex [お問い合わせ](http://demo3.ec-cube.net/contact)

Markup:
include /assets/tmpl/elements/19.2.contact.pug
+ec-contactRole

Styleguide 19.2

*/
.ec-contactRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-contactRole:after {
  content: " ";
  display: table;
}
.ec-contactRole:after {
  clear: both;
}
.ec-contactRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-contactRole img {
  max-width: 100%;
}
.ec-contactRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-contactRole *,
.ec-contactRole *::before,
.ec-contactRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-contactRole img {
  width: 100%;
}
.ec-contactRole .ec-contactRole__actions {
  padding-top: 20px;
}
.ec-contactRole p {
  margin: 16px 0;
}
.ec-contactRole .ec-errorMessage {
  margin: 0 0 5px !important;
}

.ec-contactConfirmRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-contactConfirmRole:after {
  content: " ";
  display: table;
}
.ec-contactConfirmRole:after {
  clear: both;
}
.ec-contactConfirmRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-contactConfirmRole img {
  max-width: 100%;
}
.ec-contactConfirmRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-contactConfirmRole *,
.ec-contactConfirmRole *::before,
.ec-contactConfirmRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-contactConfirmRole img {
  width: 100%;
}
.ec-contactConfirmRole .ec-contactConfirmRole__actions {
  padding-top: 20px;
}
.ec-contactConfirmRole .ec-blockBtn--action {
  margin-bottom: 16px;
}

.ec-contactCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-contactCompleteRole:after {
  content: " ";
  display: table;
}
.ec-contactCompleteRole:after {
  clear: both;
}
.ec-contactCompleteRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-contactCompleteRole img {
  max-width: 100%;
}
.ec-contactCompleteRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-contactCompleteRole *,
.ec-contactCompleteRole *::before,
.ec-contactCompleteRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-contactCompleteRole img {
  width: 100%;
}

.filebox {
  margin-bottom: 10px;
}
.filebox label {
  display: inline;
  padding: 8px 5px;
  position: relative;
  z-index: 1;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #F7F7F7;
  text-decoration: none;
  cursor: pointer;
}
.filebox label:hover {
  opacity: 0.8;
}
.filebox label input {
  display: none !important;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
お客様情報の入力

ログインせずゲストとして商品を購入する際の、お客様情報の入力 に関する Project コンポーネントを定義します。

ex [カートSTEP2 お客様情報の入力(ゲスト購入)](http://demo3.ec-cube.net/shopping/nonmember)

Markup:
include /assets/tmpl/elements/19.3.customer.pug
+ec-customerRole
hoge

Styleguide 19.3

*/
.ec-customerRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-customerRole:after {
  content: " ";
  display: table;
}
.ec-customerRole:after {
  clear: both;
}
.ec-customerRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-customerRole img {
  max-width: 100%;
}
.ec-customerRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-customerRole *,
.ec-customerRole *::before,
.ec-customerRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-customerRole img {
  width: 100%;
}
.ec-customerRole .ec-customerRole__actions {
  padding-top: 20px;
}
.ec-customerRole .ec-blockBtn--action {
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-customerRole .ec-blockBtn--action {
    margin-bottom: 16px;
  }
}

.ec-RegisterRole__actions p#top_box__agreement {
  text-align: center;
  margin: 0 auto 1em;
}

.ec-contactConfirmRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-contactConfirmRole:after {
  content: " ";
  display: table;
}
.ec-contactConfirmRole:after {
  clear: both;
}
.ec-contactConfirmRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-contactConfirmRole img {
  max-width: 100%;
}
.ec-contactConfirmRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-contactConfirmRole *,
.ec-contactConfirmRole *::before,
.ec-contactConfirmRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-contactConfirmRole img {
  width: 100%;
}
.ec-contactConfirmRole .ec-contactConfirmRole__actions {
  padding-top: 20px;
}
.ec-contactConfirmRole .ec-blockBtn--action {
  margin-bottom: 16px;
}

.ec-contactCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-contactCompleteRole:after {
  content: " ";
  display: table;
}
.ec-contactCompleteRole:after {
  clear: both;
}
.ec-contactCompleteRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-contactCompleteRole img {
  max-width: 100%;
}
.ec-contactCompleteRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-contactCompleteRole *,
.ec-contactCompleteRole *::before,
.ec-contactCompleteRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-contactCompleteRole img {
  width: 100%;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1;
}

/*
404ページ

404 エラー画面で使用するページコンポーネントです。

ex [404エラー画面](http://demo3.ec-cube.net/404)

Markup:
include /assets/tmpl/elements/20.1.404.pug
+ec-404Role

Styleguide 20.1

*/
.ec-404Role {
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  height: 100vh;
  background-color: #f2f2f2;
  text-align: center;
  box-sizing: border-box;
}
.ec-404Role textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-404Role img {
  max-width: 100%;
}
.ec-404Role html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-404Role *,
.ec-404Role *::before,
.ec-404Role *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-404Role img {
  width: 100%;
}
.ec-404Role .ec-404Role__icon img {
  width: 1em;
  height: 1em;
}
.ec-404Role .ec-404Role__title {
  font-weight: bold;
  font-size: 25px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
退会手続き

退会手続きで使用するページコンポーネントです。

ex [退会手続き](http://demo3.ec-cube.net/mypage/withdraw)

Markup:
include /assets/tmpl/elements/21.1.withdraw.pug
+ec-withdrawRole

Styleguide 21.1

*/
.ec-withdrawRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  text-align: center;
  padding: 0 16px;
}
.ec-withdrawRole:after {
  content: " ";
  display: table;
}
.ec-withdrawRole:after {
  clear: both;
}
.ec-withdrawRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-withdrawRole img {
  max-width: 100%;
}
.ec-withdrawRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-withdrawRole *,
.ec-withdrawRole *::before,
.ec-withdrawRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-withdrawRole img {
  width: 100%;
}
.ec-withdrawRole .ec-withdrawRole__title {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 24px;
}
.ec-withdrawRole .ec-withdrawRole__description {
  margin-bottom: 32px;
  font-size: 16px;
}
.ec-withdrawRole .ec-icon img {
  width: 100px;
  height: 100px;
}

/*
退会手続き実行確認

退会手続き実行確認で使用するページコンポーネントです。

ex [退会手続き　退会手続きへボタン→押下](http://demo3.ec-cube.net/mypage/withdraw)

Markup:
include /assets/tmpl/elements/21.1.withdraw.pug
+ec-withdrawConfirm

Styleguide 21.1.2

*/
.ec-withdrawConfirmRole .ec-withdrawConfirmRole__cancel {
  margin-bottom: 20px;
}
.ec-withdrawConfirmRole .ec-withdrawConfirmRole__title {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 24px;
}
.ec-withdrawConfirmRole .ec-withdrawConfirmRole__description {
  margin-bottom: 32px;
  font-size: 16px;
}
.ec-withdrawConfirmRole .ec-icon img {
  width: 100px;
  height: 100px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/*
会員情報編集完了

会員情報編集完了で使用するページコンポーネントです。

ex [会員情報編集完了](http://demo3.ec-cube.net/mypage/change_complete)

Markup:
include /assets/tmpl/elements/22.1.editComplete.pug
+ec-userEditCompleteRole

Styleguide 22.1

*/
.ec-userEditCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  text-align: center;
  padding: 0 16px;
}
.ec-userEditCompleteRole:after {
  content: " ";
  display: table;
}
.ec-userEditCompleteRole:after {
  clear: both;
}
.ec-userEditCompleteRole textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-userEditCompleteRole img {
  max-width: 100%;
}
.ec-userEditCompleteRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-userEditCompleteRole *,
.ec-userEditCompleteRole *::before,
.ec-userEditCompleteRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-userEditCompleteRole img {
  width: 100%;
}
.ec-userEditCompleteRole .ec-userEditCompleteRole__title {
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 24px;
}
@media only screen and (min-width: 768px) {
  .ec-userEditCompleteRole .ec-userEditCompleteRole__title {
    font-size: 32px;
  }
}
.ec-userEditCompleteRole .ec-userEditCompleteRole__description {
  margin-bottom: 32px;
  font-size: 16px;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
@media only screen and (min-width: 768px) {
  #page_homepage {
    overflow-x: hidden !important;
  }
}

#top-sample .btn a {
  border-radius: 10px;
  border: 3px solid #f5568b;
  background: #f5568b;
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  padding: 7px 20px;
  text-decoration: none;
}
@media only screen and (min-width: 768px) {
  #top-sample .btn {
    text-align: right;
  }
}

/*

トップページ

*/
#mainbox {
  position: relative;
}

.main_visual img {
  width: 100% !important;
}

.top-read {
  margin: 0 auto 2rem !important;
  width: 98%;
}
@media only screen and (min-width: 768px) {
  .top-read {
    position: relative;
    margin: 1rem auto 2rem !important;
    max-width: 1060px !important;
  }
}
.top-read p.top-text {
  width: 100%;
  margin: 0 auto;
  font-size: 13px;
  font-size: 1.3rem;
}
@media only screen and (min-width: 1024px) {
  .top-read p.top-text {
    padding-right: 21% !important;
  }
}
.top-read p.top-ic {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .top-read p.top-ic {
    position: absolute;
    right: 5px;
    bottom: 0;
    display: block;
    width: 21%;
    max-width: 213px;
  }
}

.sendfree {
  margin: 0 auto 0.5em !important;
  text-align: center;
  width: 96%;
}
.sendfree span {
  display: block;
  padding: 0.5em 0 !important;
  text-align: center;
  font-size: 0.8em;
  color: #cc0000;
  font-weight: bold;
}

/* topcategory */
#top_category {
  margin: 0 auto 3rem;
  width: 96%;
}
#top_category ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#top_category ul li {
  margin: 0 0 3px;
  padding: 0;
  width: 50%;
}
#top_category ul li a dl {
  padding: 2px;
  overflow: hidden;
  zoom: 1;
  border: 1px solid #dbdbdb;
  color: #333 !important;
  font-weight: bold;
  transition: all 0.4s ease;
  display: flex;
}
#top_category ul li a dl:hover {
  color: #f5568b !important;
  background: #fff4f8;
}
#top_category ul li a dl dt {
  margin-right: 3px;
  width: 40px;
}
#top_category ul li a dl dd {
  padding-top: 6px;
  font-size: 12px;
  font-size: 1.2rem;
}

#top_category h2, #top_category .h2, #checkeditem h2, #checkeditem .h2 {
  text-align: center;
  font-size: 3.8rem;
  line-height: 3rem;
  color: #34babe;
}

#top_category span, #checkeditem span {
  display: block;
  font-size: 1.3em;
  font-size: 1.3rem;
  color: #333;
}

/* top_sample */
#top-sample {
  margin: 6rem auto 3rem;
}

.top-sample,
.top-original {
  position: relative;
  margin: 0 1% 3rem;
  width: 98%;
  padding: 40px 5px 100px 5px !important;
  border: 1px solid #e3e3e3;
  border-radius: 15px;
  font-size: 14px;
  font-size: 1.4rem;
  color: #000;
}
.top-sample p.txt,
.top-original p.txt {
  margin-top: 2em;
}

.top-sample h2, .top-sample .h2,
.top-original h2,
.top-original .h2 {
  position: absolute;
  margin: auto;
  padding-top: 8px;
  top: -20px;
  left: 0;
  right: 0;
  text-align: center;
  width: 85%;
  height: 85%;
  color: #fff;
  font-size: 19px;
  font-size: 1.9rem;
  background: url(../img/top/fig_ribbon.png) center 0 no-repeat;
  background-size: 100%;
  max-width: 393px;
  max-height: 85px;
}

.top-sample {
  background: url(../img/top/top_bg_sample.png) 0 bottom no-repeat, url(../img/common/sample_bg.gif) 0 0 repeat;
  background-size: 100%, auto;
}

.top-sample p.txt, .top-original p.txt {
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, 1px 1px 0 #fff, -1px 1px 0 #fff;
  color: #000;
}

.top-original {
  background: url(../img/top/top_bg_original.png) 0 bottom no-repeat, url(../img/common/original_bg.gif) 0 0 repeat;
  background-size: 100%, auto;
}

.top-sample .btn,
.top-original .btn {
  opacity: 0.9;
}

.top-sample .btn a,
.top-original .btn a {
  background: #f5568b;
  color: #fff !important;
}

.top-sample .btn a::before,
.top-original .btn a::before {
  color: #fff;
}

.top-sample .btnarea li p,
.top-original .btnarea li p {
  padding: 0;
  margin: 0;
}

.top-sample .btnarea,
.top-original .btnarea {
  padding: 0;
  margin: 0;
  position: absolute;
  right: 5px;
  bottom: 5px;
}

#top-blog {
  margin: 0 auto;
  padding: 4rem 0 1rem;
  background-size: 100%;
}
#top-blog .blog-box * {
  margin: 0;
  padding: 0;
}
#top-blog .blog-box {
  margin: 0 auto 2em;
  width: 96%;
  position: relative;
  background: #fbf4e2 url(../img/top/blog_bg.jpg) right bottom no-repeat;
  border: 1px solid #d9c784;
  border-radius: 5px;
  box-shadow: 0 0 3px 3px #fbf6e8;
  padding: 50px 20px 45px !important;
  height: 300px;
}
#top-blog .blog-box p.top-ribbon {
  position: absolute;
  top: -30px;
  left: -4px;
}
#top-blog .blog-box .t-blog {
  margin: 0 auto 1rem;
  padding: 0 0 10px;
  border-bottom: 1px solid #d9c784;
}
@media only screen and (min-width: 768px) {
  #top-blog .blog-box .t-blog {
    display: flex;
    justify-content: space-between;
  }
}
#top-blog .blog-box .t-blog h2, #top-blog .blog-box .t-blog .h2 {
  margin: 0;
  padding: 0 0 0 10px;
  font-size: 22px;
  font-size: 2.2rem;
  color: #f5568b;
}
#top-blog .blog-box .t-blog .btn {
  margin: 0;
  width: 200px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
#top-blog .blog-box .t-blog .btn a {
  padding: 5px 10px !important;
  background: #f5568b;
  color: #fff !important;
  font-size: 14px !important;
  font-size: 1.4rem !important;
  font-weight: normal;
}
#top-blog .blog-box .t-blog .btn a::before {
  content: "\f0da";
  font-family: "FontAwesome";
  padding: 0 8px;
  color: #fff;
}
#top-blog .blog-box .t-blog .btn .blog-con {
  margin: 0;
  padding-bottom: 1.5rem;
  width: 70%;
}
#top-blog .blog-box .t-blog .btn .blog-con p {
  margin-bottom: 1rem;
  font-size: 14px;
  font-size: 1.4rem;
}
#top-blog .blog-box .t-blog .btn .blog-con h3, #top-blog .blog-box .t-blog .btn .blog-con .h3 {
  margin-bottom: 1rem;
}
#top-blog .blog-box .t-blog .btn .blog-con h3 a, #top-blog .blog-box .t-blog .btn .blog-con .h3 a {
  text-decoration: underline;
}
#top-blog .blog-box .t-blog .btn .blog-con h3 a:hover, #top-blog .blog-box .t-blog .btn .blog-con .h3 a:hover {
  text-decoration: none;
}
#top-blog .facebook {
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
}
#top-blog .facebook .fb_iframe_widget,
#top-blog .facebook .fb_iframe_widget span,
#top-blog .facebook .fb_iframe_widget iframe[style] {
  width: 100% !important;
}

#newscalendar-box {
  margin: 2em auto 3em;
  width: 96%;
}
@media only screen and (min-width: 768px) {
  #newscalendar-box {
    display: flex;
    justify-content: space-between;
  }
  #newscalendar-box #news-box {
    margin: 0;
    width: 70% !important;
  }
  #newscalendar-box #news-box #news-in {
    width: 100%;
  }
  #newscalendar-box #calendar {
    margin: 0;
    width: 28% !important;
  }
}

#top-sns p img {
  border: 1;
  display: none;
}

/* pickup item:checkedite; */
#pickup_item,
#checkeditem {
  margin: 0 auto 3em;
  padding: 2px 0 1em;
  background: url(../img/common/stripe_bg.gif) 0 0 repeat;
}

#pickup_item a, #checkeditem a {
  color: #f5568b !important;
  font-weight: normal !important;
}

#pickup_item a dl, #checkeditem a dl {
  margin: 0 auto 5px;
  text-align: center;
}

#pickup_item .item_photo, #checkeditem .item_photo {
  margin: 0 auto 5px;
}

#pickup_item .item_photo img,
#checkeditem .item_photo img {
  border-radius: 15px;
  border: 5px solid #ffe2e2;
  margin-bottom: 5px;
}

#pickup_item p.item_name,
#checkeditem p.item_name {
  text-align: center;
  margin: 0 auto;
}

#checkeditem p.heading02 {
  z-index: 5;
  position: relative;
}

/*=============================================================================

    Media Query:tablet

=============================================================================*/
@media screen and (min-width: 768px) {
  #top-sample {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* top_sample */
  .top-sample,
.top-original {
    width: 48%;
    padding: 80px 40px 150px 40px !important;
    min-height: 380px;
  }
  .top-sample p.txt,
.top-original p.txt {
    margin-top: 1em;
  }

  #top-sns p img {
    display: block;
  }
}
/*=============================================================================

    Media Query:PC

=============================================================================*/
@media screen and (min-width: 1024px) {
  /* top_category */
  #top_category ul li {
    width: 25%;
  }
  #top_category ul li a dl dt {
    margin-right: 3px;
    width: 69px;
  }
  #top_category ul li a dl dd {
    padding-top: 23px;
    font-size: 14px;
    font-size: 1.4rem;
  }

  #top-blog {
    display: flex;
    justify-content: space-between;
  }
  #top-blog .blog-box {
    width: 54%;
    padding: 50px 20px 45px !important;
    height: 300px;
  }
  #top-blog .blog-box p.top-ribbon {
    position: absolute;
    top: -30px;
    left: -4px;
  }
  #top-blog .blog-box .t-blog {
    margin: 0 auto 1rem;
    padding: 0 0 10px;
  }
  #top-blog .blog-box .t-blog h2, #top-blog .blog-box .t-blog .h2 {
    margin: 0;
    padding: 0 0 0 10px;
    font-size: 19px;
    font-size: 1.9rem;
  }
  #top-blog .facebook {
    width: 45%;
    max-width: 500px;
  }
  #top-blog .facebook .fb_iframe_widget,
#top-blog .facebook .fb_iframe_widget span,
#top-blog .facebook .fb_iframe_widget iframe[style] {
    width: 100% !important;
  }

  .top-original .btnarea {
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }

  .top-original .btnarea li {
    display: inline-block;
  }
}
/******************************
	Media Query
*******************************/
@media print, screen and (max-width: 1024px) {
  /* --------- common ---------- */
  .drawer-open #contents, .drawer-open #header, .drawer-open #footer, .drawer-open .pagetop {
    -webkit-transform: translateX(-260px);
    -moz-transform: translateX(-260px);
    -ms-transform: translateX(-260px);
    -o-transform: translateX(-260px);
    transform: translateX(-260px);
  }

  .sp {
    display: block !important;
  }

  .pc {
    display: none !important;
  }

  span.sp {
    display: inline !important;
  }

  /****** Header Setting ********/
  /****** Side Setting ********/
  .drawer {
    height: 0;
    visibility: hidden;
    -webkit-transition: visibility 0s 0.3s;
    -moz-transition: visibility 0s 0.3s;
    transition: visibility 0s 0.3s;
  }

  .drawer-open .drawer {
    height: 100%;
    visibility: visible;
    -webkit-transition: visibility 0s 0s;
    -moz-transition: visibility 0s 0s;
    transition: visibility 0s 0s;
  }

  .category-nav a {
    will-change: transform, opacity;
  }

  /****** Effect Setting ********/
  /* Overlay */
  .overlay.is-visible {
    -webkit-transform: translateX(-260px);
    -moz-transform: translateX(-260px);
    -ms-transform: translateX(-260px);
    -o-transform: translateX(-260px);
    transform: translateX(-260px);
  }

  .overlay.is-visible.cart-is-visible {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
/******************************
	Media Query (tablet)
*******************************/
@media only screen and (min-width: 767px) {
  /*送料変更のお知らせ*/
  .postage {
    margin: 3em auto;
    max-width: 1060px;
  }
  .postage dl dt {
    position: relative;
    padding: 0.5em 0.5em 0.5em 120px;
    font-size: 1.6em;
    font-weight: bold;
  }
  .postage dl dt p.fig {
    position: absolute;
    left: 10px;
    bottom: -10px !important;
    top: auto;
    width: 120px;
  }

  /*flow-box*/
  .flow-box dl {
    padding: 25px;
  }
  .flow-box dl dt {
    padding-right: 20px;
    border-bottom: none;
  }
  .flow-box dl dt span {
    display: block;
    font-size: 50px;
    font-size: 5rem;
    height: 70px;
  }
  .flow-box dl dd {
    border-left: 5px solid #40bdc1;
    padding-left: 20px;
  }
  .flow-box dl dd p {
    margin-bottom: 2rem;
  }
  .flow-box dl dd .print-data h3, .flow-box dl dd .print-data .h3 {
    font-size: 17px;
    font-size: 1.7rem;
  }
}
/*=============================================================================

    Media Query:PC

=============================================================================*/
@media only screen and (min-width: 1024px) {
  /* --------- common ---------- */
  .sp {
    display: none !important;
  }

  .pc {
    display: block !important;
  }

  span.sp {
    display: inline !important;
  }

  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    margin: 0 auto;
    width: 100%;
    padding: 0 !important;
  }

  .row {
    margin-left: -16px;
    margin-right: -16px;
    padding-bottom: 16px;
  }

  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 16px;
    padding-right: 16px;
  }

  .sp {
    display: none !important;
  }

  .pc {
    display: block !important;
  }

  span.pc {
    display: inline !important;
  }

  /* --------------------------------
      Parts
  -------------------------------- */
  /* btn */
  .btn-lg, .btn-group-lg > .btn {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
  }

  .btn-sm, .btn-group-sm > .btn {
    padding: 8px 10px;
    font-size: 14px;
    line-height: 1.5;
  }

  .btn-xs {
    padding: 5px 8px;
    font-size: 12px;
    line-height: 1.5;
  }

  /* badge */
  .badge {
    font-size: 12px;
    font-size: 1.2rem;
  }

  /* tableStyle */
  div.table .thead {
    display: table-header-group;
  }

  div.table ol {
    display: table-row;
    margin: 0;
  }

  div.table ol li {
    display: table-cell;
    padding: 16px;
    text-align: center;
    background: #F4F3F0;
    overflow-x: hidden;
    font-weight: bold;
  }

  div.table .tbody .tr > .td {
    padding: 16px;
  }

  /* tableish */
  .tableish {
    display: table;
    width: 100%;
  }

  .tableish .thead {
    display: table-header-group;
  }

  .tableish .tbody {
    display: table-row-group;
  }

  .tableish .tr {
    display: table-row;
  }

  .tableish .th,
.tableish .td {
    display: table-cell;
    vertical-align: top;
  }

  /* dl-table */
  .dl_table {
    border-top: none;
    font-size: 14px;
    font-size: 1.4rem;
    width: 100%;
  }

  .dl_table h2, .dl_table .h2 {
    padding: 8px;
    margin: 3rem auto 1rem;
  }

  .dl_table dl {
    margin: 0;
    padding: 0;
    display: table;
    width: 100%;
    border: 0 none;
  }

  .dl_table dt,
.dl_table dd {
    display: table-cell;
    border-bottom: 1px dotted #ff9cbd;
    padding: 16px;
  }

  .dl_table dt {
    width: 30%;
  }

  .dl_table dd {
    padding: 16px;
  }

  .dl_table dd .form-group {
    padding-top: 16px;
  }

  .dl_table .zip-search {
    margin-top: 16px;
  }

  table#shopping-history,
table#shopping-sample {
    margin: 0 auto 2rem;
    width: 100%;
    border: 1px solid #ccc;
    border-collapse: collapse;
    font-size: 14px;
    font-size: 1.4rem;
  }

  table#shopping-history th, table#shopping-history td,
table#shopping-sample th, table#shopping-sample td {
    margin: 0 auto;
    padding: 5px;
    width: 100%;
    border: 1px solid #ccc;
    border-collapse: collapse;
  }

  table#shopping-history th {
    background: url(../img/common/th_bg.gif) 0 0 repeat;
  }

  table#shopping-sample th {
    background: url(../img/common/th_bg_yellow.gif) 0 0 repeat;
  }

  table#shopping-history td a,
table#shopping-sample td a {
    font-weight: bold;
    text-decoration: underline;
  }

  table#shopping-history td a:hover,
table#shopping-sample td a:hover {
    text-decoration: none;
  }

  table#shopping-history .item_photo,
table#shopping-sample .item_photo {
    margin: 0 !important;
    padding: 0;
  }

  table#shopping-history td img,
table#shopping-sample td img {
    width: 100%;
  }

  table#shopping-history .goods-img,
table#shopping-sample .goods-img {
    width: 15%;
  }

  table#shopping-history .goods-name,
table#shopping-sample .goods-name {
    width: 45%;
  }

  table#shopping-history .goods-price,
table#shopping-sample .goods-price {
    width: 15%;
  }

  table#shopping-history .goods-num,
table#shopping-sample .goods-num {
    width: 10%;
  }

  table#shopping-history .goods-subtotal,
table#shopping-sample .goods-subtotal {
    width: 15%;
  }

  .goods-img img {
    border: 1px solid #ccc;
  }

  /* pagination */
  .pagination {
    width: 100%;
  }

  /* topicpath */
  #topicpath {
    padding: 24px 0 5px;
  }

  #topicpath ol {
    padding: 20px 30px 45px;
  }

  #topicpath li {
    font-size: 2rem;
  }

  #topicpath p.topic-num {
    top: 24px !important;
    font-size: 1.6rem;
  }

  /*ページ内リンク*/
  .sublink {
    margin: 3rem auto !important;
    text-align: center;
  }

  .sublink li {
    display: inline-block;
  }

  .sublink li a {
    border-radius: 8px;
    border: none;
  }

  /* intro */
  .intro {
    margin: 0;
    padding-top: 0.5em;
    padding-bottom: 28px;
  }

  .intro strong {
    color: #f5568b;
  }

  /* message */
  .heading04 + .message {
    padding: 0;
  }

  .message .errormsg {
    padding: 16px;
  }

  /* flowline */
  .flowline {
    margin: 0;
    border-bottom: 0 none;
  }

  .flowline ul::before {
    top: 20px;
  }

  .flowline.step3 ul {
    max-width: 450px;
  }

  .flowline.step4 ul {
    max-width: 550px;
  }

  .flowline ul li {
    font-size: 14px;
    font-size: 1.4rem;
  }

  .flowline li .flow_number {
    line-height: 42px;
    width: 42px;
    height: 42px;
    font-size: 20px;
    font-size: 2rem;
  }

  /* .cart .item_box */
  #cart .inner {
    padding: 0 16px;
  }

  .cart .item_box > div,
.cart .item_box > dl {
    padding: 16px 0;
  }

  .cart .item_box .item_photo {
    padding: 16px 8px 16px 0;
  }

  .cart .item_box .icon_edit {
    right: -7px;
    top: 10px;
  }

  .cart .btn_area {
    padding: 16px 0 8px;
  }

  /* error_box */
  .error_box {
    margin-bottom: 48px;
  }

  /****** Layout ********/
  /* header */
  .header_logo_area {
    padding: 0;
    position: relative;
  }

  .header_logo_area .header_logo img {
    height: 36px;
  }

  #contents {
    padding-top: 0 !important;
    min-height: 100%;
    z-index: 2;
  }

  #side_left {
    float: left;
    width: 25%;
    padding-right: 16px;
  }

  #side_right {
    float: right;
    width: 25%;
    padding-left: 16px;
  }

  /* -- main_left (side_right only) -- */
  /* main */
  .theme_side_right #main {
    width: 75%;
    float: left;
  }

  /* -- main_right (side_left only) -- */
  /* main */
  .theme_side_left #main {
    width: 75%;
    float: right;
  }

  /* -- main_only -- */
  /* main */
  .theme_main_only #main {
    width: 100%;
  }

  /* -- main_left_right (side_left & side_right) -- */
  /* main */
  .theme_side_both #main {
    width: 50%;
    float: left;
  }

  /****** Header Setting ********/
  #header {
    height: auto;
    padding: 10px 0;
  }

  #header .inner {
    width: 1060px !important;
  }

  #header #cart .inner {
    /*width:258px !important;*/
    width: 287px !important;
  }

  #header:after {
    content: "";
    display: table;
    clear: both;
  }

  #header .copy {
    font-size: 11px !important;
    font-size: 1.1rem !important;
    letter-spacing: -1px !important;
  }

  .header_logo_area .header_logo {
    font-size: 40px;
    font-size: 4rem;
    margin: 0;
  }

  #header .header_bottom_area {
    clear: both;
    /*padding-top: 16px;*/
    padding: 0;
    height: 0;
  }

  /* header-buttons */
  .header-buttons {
    top: 18px;
    right: 4em;
  }

  /* cart_area */
  #header #cart_area {
    border: 4px solid #fff7f7;
    width: auto;
    right: 0;
  }

  #header #cart_area p.cart-trigger a {
    position: relative;
    float: left;
  }

  #header #cart_area p.cart-trigger .cb-shopping-cart {
    position: relative;
    left: 9px;
  }

  #header #cart_area p.cart-trigger .badge {
    position: relative;
    left: 15px;
    top: 0;
  }

  #header #cart_area .cart_price {
    float: right;
    height: 44px;
    line-height: 44px;
    vertical-align: middle;
    margin-right: 16px;
  }

  .search select option {
    max-width: 246px;
  }

  .search select {
    display: none !important;
  }

  /****** Side Setting ********/
  #btn_menu {
    display: none;
  }

  .drawer {
    position: static;
    height: auto;
    width: auto;
    margin: 0 auto;
    overflow: visible;
    background: transparent;
    text-align: center;
  }

  .drawer:after {
    content: "";
    display: table;
    clear: both;
  }

  /*	Category navigation
  --------------------------- */
  /* category_nav */
  #category {
    margin: 5px auto 0;
    text-align: center;
  }

  #category li {
    text-align: center !important;
    height: 45px;
  }

  #category li a span {
    display: inline;
  }

  .category-nav > li {
    text-align: left;
    margin: 0;
    padding: 0 13px;
    position: relative;
    background: url(../img/common/ic_circle.png) no-repeat right center;
  }

  .category-nav > li:last-child {
    background: none;
  }

  .category-nav ul {
    position: static;
    height: auto;
    width: auto;
    overflow: visible;
    z-index: 3;
    background: #fff;
  }

  .category-nav > li > a {
    position: relative;
    color: #2e3233;
    overflow: visible;
    -webkit-transition: color 0.3s, box-shadow 0.3s;
    -moz-transition: color 0.3s, box-shadow 0.3s;
    transition: color 0.3s, box-shadow 0.3s;
  }

  /* ----- category_nav in header ---- */
  /* category-nav :上書き*/
  header .category-nav a {
    border: none;
  }

  header .category-nav > li {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }

  header .category-nav li span {
    font-size: 11px;
    color: #f5568b;
  }

  /*width: 0;
  height: 0;*/
  /*
  content: "<";
  top: 13px;
  left: 200%;
  margin-left: -15px;
  */
  /* ----- category_nav in side ---- */
  /* category-nav */
  .side .category-nav > li {
    display: block;
    margin: 0;
  }

  .side .category-nav li > a {
    width: 100%;
  }

  .side .category-nav > ul ul {
    width: 100%;
  }

  .side .category-nav > ul {
    width: 100%;
  }

  .side .category-nav > ul > li {
    width: 100%;
  }

  .side .category-nav > ul > li a {
    padding-left: 10px;
  }

  /* newslist */
  .newslist .heading01 {
    padding: 16px;
    text-align: left;
  }

  .newslist dt {
    padding: 16px;
  }

  .newslist dt .angle-circle {
    top: 14px;
    width: 25px;
    height: 25px;
  }

  .newslist dt .cb {
    font-size: 20px;
    top: 0;
    left: 3px;
  }

  .newslist dt .date {
    float: left;
    width: 6em;
  }

  .newslist dt .news_title {
    padding-left: 7em;
    padding-right: 30px;
  }

  .newslist dd {
    margin: 0 16px 16px;
  }

  /* Item List Page
  ------------------------------------------- */
  .product_page .product_item {
    padding-bottom: 24px;
  }

  .item_photo {
    margin: 0 0 15px;
  }

  .item_comment {
    margin: 0 0 5rem;
    font-size: 14px;
    font-size: 1.4rem;
  }

  /* Item Detail Page
  ------------------------------------------- */
  #item_photo_area {
    padding-top: 0;
    padding-bottom: 0;
  }

  #item_detail_area .small {
    font-size: 12px;
    font-size: 1.2rem;
  }

  #item_detail_area .item_name {
    padding: 15px;
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 10px;
    font-weight: bold;
  }

  #item_detail_area .item_detail .sale_price {
    font-size: 28px;
    font-size: 2.8rem;
  }

  #item_detail_area .item_detail .sale_price .small {
    font-size: 16px;
    font-size: 1.6rem;
  }

  #item_detail_area .item_detail .announce {
    padding: 14px 0;
  }

  #item_detail_area .item_detail .point {
    padding: 14px 0;
    border-top: 1px dotted #ccc;
  }

  #item_detail_area .item_detail .item_code {
    padding: 14px 0;
    border-top: 1px dotted #ccc;
  }

  #item_detail_area .item_detail .relative_cat {
    padding: 14px 0;
    border-top: 1px dotted #ccc;
    border-bottom: 1px dotted #ccc;
  }

  #item_detail_area .item_detail .item_comment {
    margin-top: 14px;
    margin-bottom: 14px;
  }

  .cart_area {
    padding-top: 14px;
  }

  .cart_area .classcategory_list li {
    margin-top: 16px;
  }

  .cart_area .classcategory_list li:first-child {
    margin-top: 8px;
  }

  .cart_area .classcategory_list li select {
    width: auto;
    width: 80%;
  }

  .cart_area .quantity {
    margin: 14px 0;
  }

  #item_detail .snsbtn_area {
    padding: 14px 0;
  }

  /* freearea */
  #item_detail .freearea p {
    margin-bottom: 40px;
  }

  #item_detail .freearea .heading01 {
    margin-bottom: 16px;
  }

  /* review_area */
  #review_area {
    border-top: none;
    padding-bottom: 16px;
  }

  #review_area dl {
    padding-bottom: 32px;
  }

  #review_area dl dt {
    padding: 16px;
  }

  #review_area dl dt .cb {
    position: absolute;
    right: 32px;
    top: 24px;
    font-size: 16px;
    font-size: 1.6rem;
  }

  #review_area dl dd {
    display: block;
    padding: 16px 0 0;
  }

  #review_area dl dd .review_btn {
    padding: 16px 0 0;
    text-align: center;
  }

  #review_area dl dd .review_btn a {
    width: auto;
    min-width: 350px;
  }

  #review_area .review_list {
    padding: 16px 0 0;
  }

  #review_area .review_list li {
    padding: 16px 0;
  }

  #review_area .review_list p {
    margin: 6px 0;
  }

  /* keyword_area */
  #keyword_area {
    border: 0 none;
  }

  #keyword_area .heading03 {
    border-top: 1px solid #E8E8E8;
    margin-top: 0;
    padding-top: 18px;
  }

  #keyword_area ul li a {
    font-size: 14px;
    font-size: 1.4rem;
  }

  #keyword_area .keyword_list {
    padding-bottom: 16px;
  }

  /* recommend_area */
  #recommend_area {
    border: 0 none;
  }

  #recommend_area .heading03 {
    border-top: 1px solid #E8E8E8;
    margin-top: 0;
    padding-top: 18px;
  }

  #recommend_area .carousel .product_item .btn_circle {
    right: 24px;
  }

  #recommend_area .carousel {
    margin-left: -16px;
    margin-right: -16px;
  }

  #recommend_area .carousel .product_item {
    padding: 0 16px;
  }

  #recommend_area .slick-next,
#recommend_area .slick-prev {
    font-size: 37px;
    font-size: 3.7rem;
    top: -1.4em;
  }

  #recommend_area .slick-next {
    right: 14px;
  }

  #recommend_area .slick-prev {
    right: 74px;
  }

  #recommend_area .angle-circle {
    width: 35px;
    height: 35px;
    margin-top: 0;
  }

  #recommend_area .slick-prev .cb,
#recommend_area .slick-next .cb {
    fill: #fff;
    position: relative;
    top: -4px;
    right: -2px;
    font-size: 25px;
    font-size: 2.5rem;
  }

  /* Cart Page
  ------------------------------------------- */
  .total_box {
    min-width: 256px;
    float: right;
    padding: 0;
  }

  .total_box dl {
    margin-left: 0;
  }

  .total_box .btn_group {
    padding: 16px 0;
  }

  .total_box .btn_group p {
    margin: 16px 0;
  }

  .total_box dl.total_price dt {
    padding-top: 0.3em;
  }

  .total_box dl.total_price dd {
    font-size: 24px;
    font-size: 2.4rem;
  }

  .cart_item {
    margin: 24px 0 16px;
    border-top: 0 none;
  }

  .cart_item .item_box .table {
    width: 58.33333333%;
  }

  .cart_item .item_quantity::before {
    content: "";
  }

  .cart_item.table .tbody .tr > .item_quantity {
    text-align: center;
  }

  .cart_item .item_detail .item_subtotal {
    display: none;
  }

  .cart_item.table .tbody .tr div.item_subtotal {
    display: table-cell;
    text-align: right;
  }

  .cart_item .item_box .table .item_photo {
    min-width: 160px;
    padding-right: 16px;
  }

  .column p {
    margin: 16px 0;
  }

  .column .form-inline input,
.column .form-inline select {
    margin-bottom: 16px;
  }

  div.table .tbody .addr_box .column {
    text-align: left;
    position: relative;
  }

  .addr_box .column.is-edit .btn_edit {
    top: 16px;
  }

  .multiple_column .item_box > div,
.multiple_column .item_box > dl {
    padding: 16px 0;
    vertical-align: middle;
  }

  .multiple_column .item_box .item_photo {
    padding: 16px;
    max-width: 198px;
  }

  .multiple_column .item_box .item_detail .item_price {
    margin: 8px 0 16px;
  }

  .multiple_column .form-group {
    margin-top: 16px;
  }

  .complete_message {
    text-align: center;
  }

  .complete_message .heading01 {
    font-size: 32px;
    font-size: 3.2rem;
  }

  #confirm_side {
    top: 0;
  }

  /* payment_list */
  .payment_list .radio {
    display: inline;
    margin-right: 16px;
    vertical-align: middle;
  }

  .payment_list .radio input[type=radio] {
    margin-right: 10px;
  }

  .payment_list li img {
    margin-top: 0;
    vertical-align: middle;
  }

  /* MyPage
  ------------------------------------------- */
  /*マイページナビゲーション*/
  .local_nav ul {
    background: #FFF0F0;
    border-radius: 10px !important;
    font-size: 14px;
    font-size: 1.4rem;
    /*    border-style:solid solid none solid;
      border-width:1px;
      border-color:#ffa3a3;*/
  }

  .local_nav ul:before, .local_nav ul:after {
    content: " ";
    display: table;
  }

  .local_nav ul:after {
    clear: both;
  }

  .local_nav li {
    float: left;
    width: 20%;
    margin: 0;
    background: none;
    /* 項目が1行に3件の場合
    width: 33.33333333%;*/
    /*  border-color: #D0D0D0;
      border-style: solid;
      border-width: 0 1px 1px 0;*/
    text-align: center;
  }

  .local_nav li:last-child {
    border-right: none;
  }

  .local_nav li {
    width: 25%;
  }

  .local_nav.favorite li {
    width: 20%;
  }

  .local_nav li {
    border-right: 1px solid #ffa3a3;
  }

  .local_nav li:last-child {
    border-right: none;
  }

  .local_nav li a.my-rireki:hover {
    border-radius: 10px 0 0 10px;
  }

  .local_nav li a.my-withdrawal:hover {
    border-radius: 0 10px 10px 0;
  }

  /*履歴一覧*/
  .historylist_column {
    border-top: 1px solid #ccc;
    margin: 0;
  }

  .historylist_column p a.btn {
    width: 80%;
  }

  .historylist_column .order_date {
    font-size: 14px;
    font-size: 1.4rem;
    margin-top: 16px;
  }

  .historylist_column .table.item_box:first-child {
    border-top: 0 none;
  }

  .historylist_column .item_box .item_photo {
    min-width: 130px;
  }

  .historylist_column + .pagination {
    padding-top: 16px;
  }

  .unsubscribe_box h3, .unsubscribe_box .h3 {
    font-size: 24px;
    font-size: 2.4rem;
  }

  .message_box h1, .message_box .h1 {
    font-size: 24px;
    font-size: 2.4rem;
  }

  /****** Effect Setting ********/
  /* Overlay */
  .overlay {
    display: none;
  }

  /****** Each BlockParts Setting ********/
  /* Cart */
  .cart {
    height: 120px;
    top: 44px;
  }

  /* Search */
  /* ----- category_nav in header  ---- */
  #header #searchform select,
#header #searchform input {
    float: left;
  }

  /* ----- category_nav in side ---- */
  .side #searchform select,
.side #searchform input {
    float: none;
  }

  /****** Each Page Setting ********/
  /* Item List Page
  ------------------------------------------- */
  /* Item Detail Page
  ------------------------------------------- */
  /* Cart Page
  ------------------------------------------- */
  .cart_page .dl_table {
    margin: 24px 0 32px;
  }

  .cart_page .dl_table dt {
    margin-bottom: 0;
  }

  #shopping_confirm .cart_item.table {
    border-top: 1px dotted #ccc;
  }

  #shopping_confirm .column .cart_item.table {
    margin-top: 0;
  }

  #shopping_confirm .total_box {
    margin-top: 24px;
    padding: 16px;
    min-width: 0;
    width: 100%;
    float: none;
  }

  #shopping_confirm .total_box dl {
    padding-top: 8px;
  }

  #shopping_confirm .total_box dl:first-child {
    padding-top: 0;
  }

  #shopping_confirm .total_box dl dt {
    font-size: 14px;
    font-size: 1.4rem;
  }

  #shopping_confirm .total_box .total_amount {
    margin-top: 16px;
  }

  #shopping_confirm .total_box .total_amount p.total_price {
    font-size: 14px;
    font-size: 1.4rem;
  }

  #shopping_confirm .total_box .total_amount p.total_price strong {
    font-size: 24px;
    font-size: 2.4rem;
  }

  #shopping_confirm .total_box .total_amount p.total_price strong .small {
    font-size: 14px;
    font-size: 1.4rem;
  }

  #shopping_confirm .heading02 {
    font-size: 20px;
    font-size: 2rem;
    margin-top: 32px;
  }

  #shopping_confirm .cart_item .item_box .table .item_photo {
    min-width: 80px;
    max-width: 96px;
  }

  #deliveradd_input .dl_table {
    border-top: 1px dotted #ccc;
  }

  /* MyPage
  ------------------------------------------- */
  .mypage .intro {
    padding-bottom: 16px;
  }

  .mypage #shopping_confirm .cart_item,
.mypage #shopping_confirm .total_box {
    margin-top: 0;
  }

  .mypage #item_list .product_item {
    padding-bottom: 24px;
  }

  .mypage .title_hint {
    margin: 24px 0 0;
  }

  .mypage .title_hint + .form-group {
    padding-top: 8px;
  }

  /* Registration Page
  ------------------------------------------- */
  .registration_page .dl_table {
    margin: 24px 0 32px;
  }

  /* Other Page
  ------------------------------------------- */
  /* Login Page */
  #login_box .column {
    /*padding: 16px 16px 40px;*/
    background: #ffe8e8;
    border-radius: 20px;
  }

  #login_box .column p {
    margin-bottom: 8px;
  }

  #login_box .column .form-group {
    padding: 8px 0 8px 13%;
  }

  #login_box .column .btn_area p {
    /*float: left;
    width: 37%;
    min-width: 195px;
    margin-right: 3%;*/
  }

  #login_box .column .btn_area ul {
    margin-top: 0;
  }

  #login_box .column .btn_area button {
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  #login_box .column .btn_area ul {
    float: left;
  }

  #login_box .column .btn_area ul li {
    margin: 4px 0;
  }

  #login_box .column input[type=text],
#login_box .column input[type=password] {
    width: 85%;
  }

  #login_box .column .btn_area,
#login_box .column .message {
    padding: 8px 13%;
    margin: 0;
  }

  .login_cart .column .column_inner {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .login-retention {
    padding: 8px 0 8px 13%;
  }

  /* Forgot Page */
  #forgot_page .column {
    padding: 32px;
    margin-bottom: 16px;
  }

  #forgot_page .column .form-group {
    padding: 8px 0 8px 11%;
    width: 89%;
  }

  #forgot_page .column .btn_area {
    padding: 20px 0 0 21%;
    width: 79%;
  }

  #forgot_page .column .message {
    margin-bottom: 16px;
  }

  /* Error Page */
  .error_page .footer_logo_area {
    margin-top: 0;
  }

  /* common_add */
  .common-add .common-top {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0;
    line-height: 1;
  }

  .common-add .common-bottom {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 0;
    padding: 0;
    margin: 0;
  }

  .common-add h2, .common-add .h2 {
    margin: 0 auto 1.5rem;
    color: #f5568b;
    text-align: center;
    font-size: 18px;
    font-size: 1.8rem;
  }

  .common-add ul {
    margin: 0 auto 1rem;
    width: 70%;
  }

  .common-add li {
    font-size: 2.5rem !important;
    font-weight: bold;
    vertical-align: middle;
  }

  .common-add li em {
    margin-right: 5px;
    padding: 3px;
    background: #f5568b;
    color: #fff;
    font-size: 1.7rem;
  }

  .common-add li span {
    padding: 4px 0 0 45px;
    display: block;
    font-size: 11px;
    font-size: 1.1rem;
  }
}