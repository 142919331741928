@import "../mixins/media";
/*
お問い合わせ

お問い合わせ に関する Project コンポーネントを定義します。

ex [お問い合わせ](http://demo3.ec-cube.net/contact)

Markup:
include /assets/tmpl/elements/19.2.contact.pug
+ec-contactRole

Styleguide 19.2

*/
.ec-contactRole{
  @include container;
  & &__actions {
    padding-top:20px;
  }
  p{
    margin:16px 0;
  }
  .ec-errorMessage{
    margin:0 0 5px !important;
  }

}
.ec-contactConfirmRole {
  @include container;
  & &__actions {
    padding-top:20px;
  }
  .ec-blockBtn--action {
    margin-bottom: 16px;
  }
}
.ec-contactCompleteRole {
  @include container;
}


.filebox{
  margin-bottom:10px;
  label{
  display: inline;
  padding:8px 5px;
  position: relative;
  z-index: 1;
  border:1px solid #ccc;
  border-radius:3px;
  background: #F7F7F7;
  text-decoration:none;
  cursor: pointer;
  &:hover{
    opacity: 0.8
  }
  input{
    display:none !important;
  }

}
}
