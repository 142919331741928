@import "../mixins/media";
/*
トップページ

トップページ スライド部に関する Project コンポーネントを定義します。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.1.slider.pug
+ec-sliderRole

Styleguide 12.1
*/
.ec-sliderRole{
  @include container;
  margin-bottom: 24px;

  ul{
    padding: 0;
    list-style: none;
  }
}
.ec-sliderItemRole{
  @include container;
  padding-left:0;
  padding-right:0;
  margin-bottom: 24px;
  @include media_desktop {
    padding-left:20px;
    padding-right:20px;
  }

  // .slick-item{
  //   position:relative;
  //   img{
  //     border:1px solid green;
  //     position:absolute;
  //     display: block;

  //   }
  // }
  .item_visual{
    position:relative;
    img{
      height:100%;
      border-right:2px solid #ccc;
      border-left:1px solid #ccc;
      display: block; 
    }
    .border-overlay{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 1%;
      left: 0;
      pointer-events: none; /* ボーダー上のクリックやホバーを無視する */
      border:1px solid #ccc;
      z-index:999;
    }

   } 


  ul{
    padding: 0;
    list-style: none;
  }
  .item_nav {
    display: none;
    @include media_desktop {
      display: flex;
      justify-content: flex-start;
      // justify-content: flex-start;
      // justify-content: start;
      // flex-wrap: wrap;
      // margin-bottom: 0;
      .slideThumb{
        margin:0 !important;
        width:25% !important;
        margin-right:1% !important;
        img{
          width:100%;
        }
        &:last-child{
          margin-right:0 !important;
        }
      }
    }

  }
  .slideThumb{
    margin:0 auto 25px;
    text-align :center;
    width: 25%;
    opacity: .8;
    cursor: pointer;
    border:1px solid #ccc;

    &:focus {
      outline: none;
    }
    &:hover {
      opacity: 1;
    }
    img {
      width:100%;
      height: auto;
      object-fit: cover;
    }
  }
}

.slick-dots li button::before{
  color:pink !important;
  background-color: pink !important;
  border-radius: 50%;
}
.slick-dots li.slick-active button::before{
  color:#f5568b !important;
  background-color: #f5568b !important;
  border-radius: 50%;
}
