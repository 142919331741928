@import "../mixins/media";

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  @include container;
  margin-top: 24px;
  & &__btn {
    width: 100%;
    margin: 0 auto 10px;
    text-align: center;
    button{
      &.add-cart{
        &::before{
          content: "\f07a";
          color: #fff;
          font-family: "FontAwesome";
          padding: 0 7px 0 0;
        }
      }
      
    }
    @include media_desktop {
      width: 60%;
      margin-bottom: 16px;
      min-width: 285px;
    }
    @include media_pc {
      width: 96%;
      min-width: auto;
    } 
  }
  & &__description {
    margin-bottom: 16px !important;
  } 
  .ec-productRole_in{
    @include media_desktop {
      margin:24px auto;
      display:flex;
      justify-content: space-between;
    }
    #item_photo_area{
      margin:0 auto 4em;
      width:100%;
      @include media_desktop {
        width:58%;
      }
      #icon{
        margin:0 auto 0.5rem;
        font-size:11px;
        font-size:1.1rem;
        letter-spacing:-0.4em;
        li{
          position: relative;
          margin:0 5px 5px;
          padding-right:15px;
          display:inline-block;
          padding: 0 1em;
          height: 26px;
          line-height: 26px;
          color:#fff;
          letter-spacing:normal;
          font-weight:bold; 
          &.ic-print{background: #f5568b;}
          &.ic-cut{background: #00a5e3;}
          &.ic-marumaki{background: #34b9be;}
          &.ic-zaiko{background: #ff6c00;}
          &.ic-nouki{background: #38bc80;}
          &.ic-wire{background: #ff91b6;}
          &.ic-ori{background: #fcc800;}
          &.ic-tape{background: #9070af;}
          &.ic-himo{background: #f26363;}
          &.ic-toriyose{background: #ff9c00;}
          &.ic-special{background: #fb1313;}
          &.ic-other{background: #00e0e3;}
          &.ic-basic{background: #fa8484;}    
          &::after{
            content: '';
            position: absolute;  
            top: 0;
            right: 0;
            z-index: 2;
            border-width: 14px 5px;
            border-style: solid;
            border-color: transparent;
            border-right-color: #fff;                     
          }              
        }
      }

    }
    #item_detail_area{
      margin:0 auto;
      width:100%;
      // background: #fbfde5;
      @include media_desktop {
        width:40%;
      }
      .ic_r_toriyose{
        margin:0 auto;
        padding:5px;
        text-align:center;
        font-size:12px;
        font-size:1.2rem;
        color:#fff;
        background: #fb1313;
        font-weight:bold;
      }
      /*商品名*/
      #detailarea{
        margin-bottom:0;
        padding:10px 0;
        background: #626271;
        #relative_category_box{
          margin:0 auto 0.5rem;
          text-align:center;
          overflow: hidden;
          zoom: 1;
          color:#fff;
          li{
            display: inline-block;
            font-size:11px;
            font-size:1rem;  
            // &::after {
            //   content:" > ";
            //   color:#fff;   
            // }
            a{
              padding:2px 3px;
              color:#fff;
              background: #f5568b;
              border-radius:5px;   
              &:hover{
                padding:2px 3px;
                color:#fff;
                color:#f698b8;
                border-radius:5px;                
              }          
            }      
          }

        }
 

        .ec-productRole__title {
          margin:0 auto;
          text-align:center;
          color:#fff !important;
          .ec-headingTitle {
            font-size: 20px;
            color:#fff !important;
            @include media_desktop {
              font-size: 1.8rem;
              font-size:18px;
            }
          }
        }

     
      }
      .ec-productRole__profile{
        margin:0 auto;
        padding:2%;
        background: #fbfde5;
        // width:94%;
        .cart_area {
          margin:0 auto;
          ul{
            li{
              dl{
                display:flex;
                align-items:center;
                dt{
                  font-size:12px;
                  font-size:1.2rem;
                  font-weight:bold;
                  width:50px;
                }
                dd{
                  width:80%;
                  select{
                    width:100%;
                  }

                }

              }
            }
          }
        }
      .cart_area .quantity {
          margin: 8px 0;
      }
      .cart_area .quantity dt{
          font-size: 1.2rem;
          font-weight: bold;
      }
      .cart_area .quantity dd input {
          max-width: 80px;
      }

      }
      .display-area{
        margin:15px auto;
        padding:2%;
        width:100%;
        background: #fff;
    }
    .display-area dl{
        overflow: hidden;
        zoom: 1;
        margin-bottom:0.5rem;
    }
    .display-area dt{
        float:left;
        padding-top:5px;
        width:50px;
        font-size:11px;
        font-size:1.1rem;
    }
    .display-area dd{
        font-weight:bold;
    }
    .display-area dl.display-price{
        padding:5px;
        background: #de5d50;
        color:#fff;
        font-weight:bold;
    }
    .display-area dl.display-price dt{
        padding-top:2px;
       font-size:15px;
       font-size:1.5rem;
    }
    .display-area dl.display-price dd{
        float:right;
        padding:0;
       font-size:18px;
       font-size:1.8rem;
       text-align:right;
    }
    /*btnarea
    ---------------------------*/
    .btn_area ul{
        margin-bottom:0;
        padding:0;
    }
    
    /*cartボタン*/
    #detail_cart_box__button_area #cart{
        margin-bottom:5px;
        border-radius:10px;
        background: #f5568b;
        border:none;
    }
    /*disabled*/
    #detail_cart_box__button_area #cart:disabled{
        background: #8b8b8b;
    }
    
    #detail_cart_box__button_area #cart:hover{
        border-radius:15px;
        background: #f488ac;
        border:none;
    }
    #detail_cart_box__button_area #cart:before{
      content: "\f07a";
      color: #fff;
      font-family: "FontAwesome";
      padding: 0 7px 0 0;
    }
    #detail_cart_box__favorite_button{
      display:block;
      width:100%;
      margin:0 auto 1em;
      li{
        width:100%;
      }
      @include media_desktop {
        display:flex;
        justify-content: center;
        align-items: center;
        li{
          line-height:50px;
          width:50%;
        }
        
      }
      button{
        &:active{
          border:none !important;
        }
      }
      ul{
        li{}
        @include media_desktop {
          li{
            margin:0 10px;
          }
        }
      }

      /*favorit*/
      #favorite{
        height: 50px;
        padding:0;
        background: none;
        border:none;
        /*color:#de5d50;*/
        color:#333;
        font-size:12px;
        font-size:1.2rem;
        &:hover{
          color:#de5d50;
        }
        &::before{
          content: "\f004";
          color: #fff;
          font-family: "FontAwesome";
          padding: 5px;
          border-radius: 50%;
          background:#de5d50;
          font-size:1.2rem;
          margin:0 3px 0 0;
        }
        /*favorit:disabled*/
        &:disabled{
          color:#333;
          &::before{
            content: "\f004";
            color: #fff;
            font-family: "FontAwesome";
            padding: 6px;
            border-radius: 50%;
            /*background: #de5d50;*/
            background:#333;
            font-size:1.3rem;
            margin:0 3px 0 0;
          }
        }
      }
      .btn{
        border:3px solid green;
        &:active{
          border:none !important;
        }
      }
      /*見積り依頼*/
      #estimate-request{
        display:block;
        font-size:12px;
        font-size:1.2rem;
        height: 50px;
        font-weight:bold;
        color:#333;
        text-align:center;
        &:hover{
          color:#ff6c00;
        }
        &::before{
          content: "\f0f6";
          color: #fff;
          font-family: "FontAwesome";
          padding: 5px 6px;
          border-radius: 50%;
          background:#ff6c00;
          font-size:1.2rem;
          margin:0 3px 0 0;
        }
      }
    }


    /*在庫について問い合わせる*/
    #zaiko{
      margin-bottom:5px;
      padding:10px 16px;
      border-radius:10px;
      background: #ffb400;
      border:none;
      font-size:16px;
      font-size:1.6rem;
      &:hover{
        background: #ffc333;
      }
    }

    /*サンプル帳について*/
    #samplebook{
      overflow: hidden;
      zoom: 1;
      margin:0 auto;
      padding:15px 6px;
      width:100%;
      background: #f2f2f2;
      .sample-txt{
        font-size:12px;
        font-size:1.2rem;
        p.link{
          margin:5px 0 ;
          text-align:right;
          font-weight:bold;
          font-size:1.1rem;
          a{
            text-decoration:underline !important;
            color:#f5568b;
            &:hover{
              text-decoration:none;
            }
            &::after{
              content: "\f0a9";
              color: #333;
              font-family: "FontAwesome";
              margin:0 0 0 3px;
            }
          }
        }
      }
    }

    /*サンプル帳を購入*/
    #sample{
      width:100%;
      background: #ff6c00;
      border-radius:10px;
      color:#fff;
      height: 45px;
      line-height: 45px;
      font-size:13px;
      font-size:1.3rem;
      font-weight:bold;
      border:none;
      &:hover{
        background: #ff8c37;
      }
      &::before{
        content: "\f07a";
        color: #fff;
        font-family: "FontAwesome";
        padding: 0 7px 0 0;
      }
    }
    #catalog{
      border-top:1px dotted #333;
      overflow: hidden;
      zoom: 1;
      margin:0 auto 2em;
      padding:15px 6px;
      width:100%;
      background: #f2f2f2;
      .sample-txt{
        font-size:12px;
        font-size:1.2rem;
      }
      button{
        background: #fcbe00;
        &:hover{
          background: #ffda4a;
          
        }
      }
    }

    p.comment{
      margin:0 auto;
      background: #F2F2F2;
      padding:15px 10px 10px;
      border-top:1px dashed #222;
    }
   }
    /*カート上テキスト*/
    .cart-comment{
      margin:1rem auto;
      font-size:0.9em;
      width:96%;
    }
    .cart-comment ul li{
    margin-left:15px;
    list-style-type:disc;
    }
    .cart-comment ol li{
    margin-left:15px;
    list-style-type:decimal;
    }
    & &__img {
      margin-right: 0;
      margin-bottom: 20px;
      @include media_desktop {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }
    & &__profile {
      margin:0 1.5em;
      border:1px solid red;
      @include media_desktop {
        // margin-left: 16px;
      }
    }

    & &__tags {
      margin-top: 16px;
      padding: 0;
      padding-bottom: 16px;
      border-bottom: 1px dotted #ccc;
    }
    & &__tag {
      display: inline-block;
      padding: 2px 5px;
      list-style: none;
      font-size: 80%;
      color: #525263;
      border: solid 1px #D7DADD;
      border-radius: 3px;
      background-color: #F5F7F8;
    }
    & &__priceRegular {
      padding-top: 14px
    }
    & &__priceRegularTax {
      margin-left: 5px;
      font-size: 10px;
    }
    & &__price {
      color: #DE5D50;
      font-size: 28px;
      padding: 0;
      border-bottom: 0;
      @include media_desktop {
        padding: 14px 0;
        border-bottom: 1px dotted #ccc;
      }
    }
  & &__code {
    padding: 14px 0;
    border-bottom: 1px dotted #ccc;
  }
  & &__category {
    padding: 14px 0;
    border-bottom: 1px dotted #ccc;
    a {
      color: #33A8D0;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
  & &__actions {
    padding: 14px 0;
    .ec-select {
      select {
        height: 40px;
        max-width: 100%;
        min-width: 100%;
        @include media_desktop {
          min-width: 350px;
          max-width: 350px;
        }
      }
    }
  }





  }

}
/* 数値の入力欄にスピナーを常時表示する */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

// freearea
.ec-productRole__description {
  margin-bottom: 16px !important;
  .wysiwyg{
    margin:0 auto 3em;
  }
  .t-area{
    p.flag-sample{
      position:absolute;
      right:0;
      top:6px;
      margin-right:5px;
      padding:4px;
      border-radius: 5px;
      font-size:13px;
      font-size:1.3rem;
      background: #ff6c00;
      color:#fff;
    }
  }
  .star{
    img{
      width:auto !important;
      max-width:77px;
    }
  }
  .color-fig{
    img{
      width:auto !important;     
    }
  }

}
