@import "../mixins/media";


#page_homepage{
  @include media_desktop {
    overflow-x: hidden !important;
  }

}

#top-sample{
  .btn a{
    border-radius: 10px;
    border: 3px solid #f5568b;
    background: #f5568b;
    color: #fff !important;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    padding: 7px 20px;
    text-decoration: none;
  }
  @include media_desktop {
    .btn{
      text-align:right;
    }
  }
}
/*

トップページ

*/
#mainbox{
	position: relative;
}
.main_visual img{width:100% !important;}

.top-read{
  margin:0 auto 2rem !important;
  width:98%;
  @include media_desktop() {
    position: relative;
    margin:1rem auto 2rem !important;
    max-width:1060px !important;
  }
  p.top-text{
    width:100%;
    margin:0 auto;
    font-size:13px;
    font-size:1.3rem;
    // @include media_desktop() {
    //   width:100% !important;
    // }
    @include media_pc() {
      padding-right:21% !important;
    }
  }
  p.top-ic{
    display:none; 
    @include media_pc() {
      position: absolute;
      right: 5px;
      bottom: 0;
      display: block;
      width: 21%;
      max-width: 213px;
    }
    
  }
 }

 .sendfree{
	margin:0 auto 0.5em !important;
	text-align:center;
	width:96%;
  span{
    display:block;
    padding:0.5em 0 !important;
    text-align:center;
    font-size:0.8em;
    color:#cc0000;
    font-weight:bold;	   
  }
}



/* topcategory */
#top_category{
	margin:0 auto 3rem;
	width:96%;
  ul{
    display:flex;
    flex-wrap:wrap;
    align-items: center;
    li{
      margin:0 0 3px;
      padding:0;
      width:50%;
      a{
        dl{
          padding:2px;
          overflow:hidden;
          zoom: 1;
          border:1px solid #dbdbdb;
          color:#333 !important;
          font-weight:bold;
          transition: all .4s ease;
          display:flex;
          &:hover{
            color:#f5568b !important;
            background: #fff4f8;
          }
          dt{
            margin-right:3px;
            width:40px;         
          }  
          dd{
            padding-top:6px;
            font-size:12px;
            font-size:1.2rem;          
          }  
        }
      }
    }
  }

}
#top_category h2,#checkeditem h2{
  text-align:center;
  font-size:3.8rem;
  line-height: 3.0rem;
  color:#34babe;
}
#top_category span,#checkeditem span {
  display:block;
  font-size:1.3em;
  font-size:1.3rem;
  color:#333;
}

/* top_sample */
#top-sample{
	margin:6rem auto 3rem;
}
.top-sample,
.top-original{
	position:relative;
	margin:0 1% 3rem;
	width:98%;
	padding:40px 5px 100px 5px !important;
	border:1px solid #e3e3e3;
	border-radius: 15px;
	font-size:14px;
	font-size:1.4rem;
	color:#000;
  p.txt{
   margin-top: 2em; 
  }
}
.top-sample h2,
.top-original h2{
	position:absolute;
	margin:auto;
	padding-top:8px;
	top:-20px;
	left:0;
	right:0;
	text-align:center;
	width:85%;
	height:85%;
	color:#fff;
	font-size:19px;
	font-size:1.9rem;
	background: url(../img/top/fig_ribbon.png) center 0 no-repeat;
	background-size:100% ;
	max-width:393px;
	max-height:85px;
}
.top-sample{
	background: url(../img/top/top_bg_sample.png) 0 bottom no-repeat,url(../img/common/sample_bg.gif) 0 0 repeat;
	background-size:100%,auto ;
}
.top-sample p.txt,.top-original p.txt{
		text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, 1px 1px 0 #fff, -1px 1px 0 #fff;
		color:#000;

}
.top-original{
	background: url(../img/top/top_bg_original.png) 0 bottom no-repeat,url(../img/common/original_bg.gif) 0 0 repeat;
	background-size:100%,auto ;

}
.top-sample .btn,
.top-original .btn{
    opacity: 0.9;
}
.top-sample .btn a,
.top-original .btn a{
    background: #f5568b;
    color: #fff !important;
}
.top-sample .btn a::before,
.top-original .btn a::before{
    color:#fff;
}
.top-sample .btnarea li p,
.top-original .btnarea li p{
	padding:0;
	margin:0;
}
.top-sample .btnarea,
.top-original .btnarea{
	padding:0;
	margin:0;
	position:absolute;
	right:5px;
	bottom:5px;
}



#top-blog{
  margin:0 auto;
  padding:4rem 0 1rem;
  background-size: 100%;
  .blog-box *{
		margin:0;
		padding:0;
	}
  .blog-box{
    margin:0 auto 2em;
    width:96%;
    position:relative;
		background: #fbf4e2 url(../img/top/blog_bg.jpg) right bottom no-repeat;
		border: 1px solid #d9c784;
		border-radius: 5px;
		box-shadow: 0 0 3px 3px #fbf6e8;
		padding: 50px 20px 45px !important;
		height:300px; 
    p.top-ribbon{
      position:absolute;
      top:-30px;
      left:-4px;
    }
    .t-blog{
      margin:0 auto 1rem;
      padding:0 0 10px;
      border-bottom:1px solid #d9c784;
      @include media_desktop() {
        display:flex;
        justify-content:space-between;
      }
      h2{
        margin:0;
        padding:0 0 0 10px;
        font-size:22px;
        font-size:2.2rem;
        color:#f5568b;       
      } 
      .btn{
        margin:0;
        width:200px;
        text-align:right;
        display:flex;
        justify-content: flex-end;
        a{
          padding:5px 10px !important;
          background: #f5568b;
          color:#fff !important;
          font-size:14px !important;
          font-size:1.4rem !important;
          font-weight:normal;
          &::before{
            content: '\f0da';
            font-family: "FontAwesome";
            padding: 0 8px;
            color:#fff;            
          }        
        }
        .blog-con{
          margin:0;
          padding-bottom:1.5rem;
          width:70%;
          p{
            margin-bottom:1rem;
            font-size:14px;
            font-size:1.4rem;
          }
          h3{
            margin-bottom:1rem;
            a{
              text-decoration:underline;
              &:hover{
                text-decoration:none;
              }
            }
          }
        }    
      }  
    }
  }
  .facebook{
    margin:0 auto;
    width:100%;
    max-width:500px;
    .fb_iframe_widget,
    .fb_iframe_widget span,
    .fb_iframe_widget iframe[style]{
        width: 100% !important;
    }
  }
}


#newscalendar-box{
  margin:2em auto 3em;
  width:96%;
  
  @include  media_desktop{
    display:flex;
    justify-content: space-between;
    
    #news-box{
      margin:0;

      width:70% !important;
      #news-in{
        width:100%;
      }
    }
    #calendar{
      margin:0;
      width:28% !important;
    }

   
  }
}
#top-sns p img {
  border: 1;
  display: none;
}


/* pickup item:checkedite; */
#pickup_item,
#checkeditem{
	margin:0 auto 3em;
	padding:2px 0 1em;
	background: url(../img/common/stripe_bg.gif) 0 0 repeat;
}
#pickup_item a,#checkeditem a{
	color:#f5568b !important;
  font-weight:normal !important;
}
#pickup_item a dl,#checkeditem a dl{
	margin:0 auto 5px;
  text-align:center;
}
#pickup_item .item_photo,#checkeditem .item_photo{
	margin:0 auto 5px;
}
#pickup_item .item_photo img,
#checkeditem .item_photo img{
	border-radius: 15px;
	border:5px solid #ffe2e2;
	margin-bottom:5px;
}
#pickup_item p.item_name,
#checkeditem p.item_name{
	text-align:center;
	margin:0 auto;
}

#checkeditem p.heading02{
	z-index: 5;
	position: relative;
}





/*=============================================================================

    Media Query:tablet

=============================================================================*/
@media screen and (min-width: 768px) {

  #top-sample{
    display:flex;
    justify-content: space-between;
    align-items: center;
  }
	/* top_sample */
	.top-sample,
	.top-original{
		width:48%;
		padding:80px 40px 150px 40px !important;
		min-height:380px;
    p.txt{
      margin-top: 1em; 
     }
	}

  #top-sns p img {
    display: block;
  }
}

/*=============================================================================

    Media Query:PC

=============================================================================*/

@media screen and (min-width: 1024px) {
	/* top_category */
  #top_category{
    ul{
      li{
        width:25%;
        a{
          dl{
            dt{
              margin-right:3px;
              width:69px;
            }
            dd{
              padding-top:23px;
              font-size:14px;
              font-size:1.4rem;           
            }
          }
        }
      }
    }

  }

  #top-blog{
    display:flex;
    justify-content: space-between;
    .blog-box{
      width:54%;
      padding: 50px 20px 45px !important;
      height:300px; 
      p.top-ribbon{
        position:absolute;
        top:-30px;
        left:-4px;
      }
      .t-blog{
        margin:0 auto 1rem;
        padding:0 0 10px;
        h2{
          margin:0;
          padding:0 0 0 10px;
          font-size:19px;
          font-size:1.9rem;
        } 
      }
    }
    .facebook{
      width:45%;
      max-width:500px;
      .fb_iframe_widget,
      .fb_iframe_widget span,
      .fb_iframe_widget iframe[style]{
          width: 100% !important;
      }
    }
  }

	.top-original .btnarea{
		margin:0 auto;
		width:100%;
		text-align:center;
	}	
	.top-original .btnarea li{
		display:inline-block;
	}

}