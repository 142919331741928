@import "../mixins/media";
@import "../mixins/projects";

@mixin pager(){
}
/*
検索・一覧表示

検索欄や、一覧表示に使用するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.2
*/

/*
トピックパス

検索結果で表示されるトピックパスのスタイルです。

ex [商品一覧ページ　横並びリスト部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-topicpath

Styleguide 7.2.1
*/
.ec-searchnavRole__topicpath{
  position: relative;
  margin-top:24px;
  padding:28px 5px 50px;
  background-image: linear-gradient( transparent 8%, rgba(245, 86, 139, 0.1) 8%, rgba(245, 86, 139, 0.1) 14%, transparent 14%, transparent 86%, rgba(245, 86, 139, 0.1) 86%, rgba(245, 86, 139, 0.1) 92%, transparent 92% ), linear-gradient( 45deg, transparent 40%, rgba(245, 86, 0, 0.1) 40%, rgba(245, 86, 0, 0.1) 60%, transparent 60% ), linear-gradient(white, white);
  background-size: cover, 4px 4px, cover;
  @include media_desktop {
    border:1px solid green;
    // padding: 15px 5px;
    border: 0;
    // font-size: 16px;
  }
  .ec-topicpath{
    position: relative;
    margin:0 auto;
    width:100%;
    // line-height: 28px;
    text-transform: uppercase;
  
    color:#f5568b;
  //   &::before{
  //     background-image: 
  //     linear-gradient(
  //         transparent 8%, 
  //         rgba(245, 86, 139, 0.1) 8%, 
  //         rgba(245, 86, 139, 0.1) 14%, 
  //         transparent 14%, 
  //         transparent 86%, 
  //         rgba(245, 86, 139, 0.1) 86%, 
  //         rgba(245, 86, 139, 0.1) 92%, 
  //         transparent 92%
  //     ), 
  //     /*thin stripes*/
  //     linear-gradient(
  //         45deg, 
  //         transparent 40%, 
  //         rgba(245, 86, 0, 0.1) 40%, 
  //         rgba(245, 86, 0, 0.1) 60%, 
  //         transparent 60%
  //     ), 
  //     /*white base*/
  //     linear-gradient(white, white); 
  // background-size:
  //     cover, /*borders*/
  //     4px 4px, /*thin stripes*/
  //     cover; /*base white*/
  //   }
    // letter-spacing: -.4em;
    // -webkit-margin-before: 0;
    // -webkit-margin-after: 0;
    // -webkit-margin-start: 0;
    // -webkit-margin-end: 0;
    // -webkit-padding-start: 0;
    // border-top: 1px solid #ccc;
    // border-bottom: 1px dotted #ccc;
    // padding: 10px;
    // list-style: none;
    // overflow: hidden;
    // font-size: 12px;
    // color: #0092C4;
    @include media_desktop {
      padding: 0;
      border: 0;
      font-size: 16px;
    }
    li{
      float: left;
      color: #333;
      font-size: 16px;
      font-size: 1.6rem;
      a{
        color:#f5568b !important;
      }
      // &::after{
      //   content:"｜";
      // }
      &:first-child{
        font-weight: bold;
      }
      &:last-child{
        
      }
      a{
        float: left;
        display: inline-block;        
      }
    }
  
  
  
  
    & &__item {
      @include reset_link();
    }
    & &__divider{
      color: #000;
    }
    & &__item,
    & &__divider,
    & &__item--active{
      display: inline-block;
      min-width: 16px;
      text-align: center;
      position: relative;
      letter-spacing: normal;
    }
    & &__item--active{
      font-weight: bold;
      @include reset_link();
    }
  } 
  p.topic-num{
    position:absolute;
    padding:6px;
    border-radius:5px;
    border:1px solid #f2f2f2;
    font-size:12px;
    top:25px;
    right:10px;
    background: #fff;
    color:#333;   
  }
}


/*
ページャ

検索結果で表示される商品一覧のスタイルです。

ex [商品一覧ページ　ページャ部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-pager

Styleguide 7.2.2
*/
.ec-pager{
  list-style: none;
  list-style-type: none;
  margin: 0 auto;
  padding: 1em 0;
  text-align: center;
  & &__item,
  & &__item--active{
    display: inline-block;
    min-width: 29px;
    padding: 0 3px 0 2px;
    text-align: center;
    position: relative;
    @include reset_link();
    a{
      color: inherit;
      display: block;
      line-height: 1.8;
      padding: 5px 1em;
      text-decoration: none;
    }
    a:hover{
      color: inherit;
    }
  }
  & &__item--active {
    background: $clrGray;
  }
  & &__item:hover{
    background: $clrGray;
  }

}
