@import "../mixins/media";
@import "../mixins/projects";
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole{
  border-top: 1px solid #7d7d7d;
  margin-top: 30px;
  background: black;

  @include media_desktop(){
    padding-top: 40px;
    margin-top: 100px;
  }
  & &__inner{
    @include media_desktop {
      @include container;
    }
  }
}

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerNavi{
  padding: 0;
  color: white;
  list-style: none;
  text-align: center;

  & &__link{
    display: block;

    @include media_desktop {
      display: inline-block;
    }

    a{
      display: block;
      border-bottom: 1px solid #7d7d7d;
      padding: 15px 0;
      font-size: 14px;
      color: inherit;
      text-decoration: none;

      @include media_desktop {
        display: inline-block;
        border-bottom: none;
        margin: 0 10px;
        padding: 0;
        text-decoration: underline;
      }
    }
    &:hover {
      a {
        opacity: .8;
        text-decoration: none;
      }

    }

  }
}

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerTitle{
  padding: 40px 0 60px;
  text-align: center;
  color: white;

  @include media_desktop {
    padding: 50px 0 80px;
  }

  & &__logo{
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    @include reset_link();

    a{
      font-size: 22px;
      color: inherit;
      @include media_desktop {
        font-size: 24px;
      }

    }

    &:hover {
      a {
        opacity: .8;
        text-decoration: none;
      }
    }
  }
  & &__copyright{
    font-size: 10px;

    @include media_desktop {
      font-size: 12px;
    }
  }
}


/****** shopping-guide ********/
#bottom-shopguide{
	width:100%;
	margin: 5rem auto 0;
	padding:20px 0 60px;
	font-size:13px;
	font-size:1.3rem;
/*	position:relative;*/
	background: #ffe1d6 url(../img/common/f_ribbon_bg.jpg) 0 bottom repeat-x;
  .sub-h2{
    margin: 0 auto;
    padding: 0;
    color:#f5568b;
    h2{
      padding:0;
      margin:0 auto;
      position: relative;
      text-align: center;
      border-bottom: none !important;
      border-top: none !important;
      font-size: 35px;
      font-size:3.5rem;
      font-weight: bold;
      &::after{
        background: #f5568b;
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        top: 49%;
        width: 100%;
        z-index: 0;        
      }
      span{
        font-size:14px;
        font-size:1.4rem;
        display:block;        
      }
      div{
        position:relative;
        padding:0 10px;
        display:inline-block;
        background: #ffe1d6;
        z-index:10;        
      }
    }
  }
  .bottom-shopguide-in{
    margin:0 auto;
    max-width: 1200px;
    width:96%;
  }
  .guide-box{
    padding-bottom:50px;
    h3{
      color:#45281d;
      border-bottom:1px dotted #45281d;  
      font-weight:bold;    
    }
    dl{
      margin-bottom:0.7em;
      background-color: rgba(255,255,255,0.6);
      dt{
        padding:10px 0 0 10px;
      }
      dd{
        ul{
          padding:3px 0 10px 10px;
          display:flex;
          flex-wrap: wrap;
          li{
            margin:0;
            width:44px;
            padding-right:3px !important;
            // display:inline-block;
            letter-spacing:normal; 
            &:last-child{
              width:77px;
            } 
            img{
              width:100%;
            }         
          }
        }
      }
    }
    table{
      margin:0 auto 0.7em;
      width:100%;
      text-align:center;
      font-size:12px;
      font-size:1.2rem;  
      th{
        padding:10px;
        background: #ffede6;
        color:#45281d !important;
        border-collapse: collapse;
        border:1px solid #ffe1d6;
        font-weight:normal;    
      }
      td{
      	padding:10px;
        background: #ffede6;
        color:#45281d !important;
        font-weight:normal;
        background: #fff;
        border-collapse: collapse;
        border:1px solid #ffe1d6;	  
      }  
    }
    p.btn{
      text-align:right;
      display:flex;
      justify-content: flex-end;
      a{
        border:1px solid #f55b8e;
        border-radius: 0;
      }
    }
  }
}



/****** footer ********/
#footer {
  position:relative;
  background: #faf8f3;
  padding-top: 20px;
  padding-bottom: 24px;
  p.f-ribbon{
    position: absolute;
    top:-60px;
    left:0;
  }
  ul{
    text-align:center;
    list-style-type:none;
    display:flex;
    justify-content: center;
    flex-wrap:wrap;
    &.f-nav{
      margin:65px auto 0;
      padding-bottom:30px;
      border-bottom:1px solid #fff;
      &:first-child{
        content: "|";
        color: #f26363;
        padding:0;
        font-size:1.5rem;
      }
      li{
        font-size:14px;
        &::after{
          content: "|";
          color: #f26363;
          padding:0 20px;
          font-size:1.5rem;
        }
        a{
          color:#f5568b;
        }
      }
   }

  }
  #f-area{
    border-top:1px solid #ede6d3;
    padding-top:1em;
   .footer_logo_area{
      margin:0 auto;
      text-align:center;
      max-width:298px;
      font-size:16px;
      a{
        width:100%;
        color:#000;
      }
      address{
      margin-bottom:20px;
      font-size:12px;
      // font-size:0.8rem;
      em{
        font-weight:bold;
        // display:block;
      }
    }
    }
    .logo{
      margin-bottom:20px;
      // font-size:14px;
      // font-size:1.4rem;
    }


    .f-tel{
      margin:0 auto;
      padding:15px;
      width:100%;
      background: url(../img/common/bg_basic.jpg) 0 0 repeat;
      border-radius:15px;   
 
      ul{
        display:flex;
        align-items:center;
        li{
          margin:0 0.5em;
          &.t-area{
            font-size:25px;
            font-size:2.5rem;
            font-weight:bold;
            span{
              display:block;
              font-size:12px;
              font-size:1.2rem;         
            }
          }
          &.f-area{
            font-size:23px;
            font-size:2.0rem;
            font-weight:bold;
            a{
              margin-left:10px;
              text-decoration: underline;
              color:#000;
              &:hover{
                text-decoration: none;
              }
              &::after{
                content: "\f1c1";
                color: #f26363;
                font-family: "FontAwesome";
                padding: 0 0 0 7px;
                font-size:1.6rem;              
              }
            }
            span{
              display:block;
              font-size:14px;
              font-size:1.4rem;           
            }          
          }
        }
      }

    }
  }

}

.btn{
  padding:0;
  text-align:center;
  width:100%;
  a{
    border-radius: 10px;
    border: 3px solid #f5568b;
    background: #fff;
    color: #f5568b !important;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    padding: 7px 20px;
    text-decoration: none;
    &::before{
      content: '\f0da';
      font-family: "FontAwesome";
      padding: 0 8px;
      color: #f5568b;
    }
  }
}

.copyright {
  text-align:center;
  font-size: 12px;
  font-size: 1.2rem;
  margin-top:5px;
}

.pagetop {
  display: none;
  text-align: right;
  position: fixed;
  bottom: 10px; right: 10px;
  z-index: 2;
  a{
    display: inline-block;
    position: relative;
    width: 30px; height: 30px;
    background-color: #B8BEC4;
    border-radius: 50%;
    &:hover{
      opacity: 0.8;
    }
    .cb-angle-down{
      fill: #fff;
      font-size: 25px;
      font-size: 2.5rem;
      position: absolute;
      top: 50%; left: 50%;
      margin-left: -0.5em;
      margin-top: -0.5em;
      -webkit-transform:rotate(-180deg);
      -ms-transform:rotate(-180deg);
      -o-transform:rotate(-180deg);
      transform:rotate(-180deg);      
    }
  }
}


// .pagetop a .cb-angle-down {
//   fill: #fff;
//   font-size: 25px;
//   font-size: 2.5rem;
//   position: absolute;
//   top: 50%; left: 50%;
//   margin-left: -0.5em;
//   margin-top: -0.5em;
//   -webkit-transform:rotate(-180deg);
//   -ms-transform:rotate(-180deg);
//   -o-transform:rotate(-180deg);
//   transform:rotate(-180deg);
// }




/*=============================================================================

    Media Query:PC

=============================================================================*/
  
@media only screen and (min-width: 1024px) {

/****** shopping-guide ********/
#bottom-shopguide{
	width:100%;
	padding:20px 0 60px;
	font-size:13px;
	font-size:1.3rem;
/*	position:relative;*/
	background: #ffe1d6 url(../img/common/f_ribbon_bg.jpg) 0 bottom repeat-x;

  .bottom-shopguide-in{
    margin:0 auto;
    max-width: 1200px;
    width:96%;
    display:flex;
    justify-content: space-between;
    .guide-column{
      width:48%;
    }
  }

}


/****** Footer Setting ********/

    #footer #f-area{
      display:flex;
      justify-content: center;
      padding-top:30px;
      align-items:center;
      .f-tel{
        width:calc(100% - 320px);
      }
    }


  /*ribbonイラスト*/
  #footer p.f-ribbon{
      position: absolute;
      top:-60px;
      left:0;
  }
  #footer ul.f-nav {
      margin:0 auto;
      padding:60px 0 30px;
      font-size:14px;
      font-size:1.4rem;
      border-bottom:1px solid #fff;
  }

  #footer ul.f-nav li:after{
    content: "|";
    color: #f26363;
    padding:0 20px;
    font-size:1.5rem;
      }

  #footer ul.f-nav:first-child{
    content: "|";
    color: #f26363;
    padding:0;
    font-size:1.5rem;
      }


  #footer .f-tel{
        padding:30px 20px !important;
        display:flex;
        justify-content: space-between;
        max-width:760px !important;
        ul{
          width:calc(100% - 260px);
          text-align:left !important;
          display:flex;
          align-items:center;
          justify-content: start;
          li{
            span{
              display:inline !important;
            }
          }
        }
        p.btn{
          width:250px;
        }
    }



  #footer #f-area .logo{
      margin-bottom:30px;
      font-size:12px;
      font-weight:bold;
  }
  #footer .btn{
      padding:0;
      text-align:center;
  }
  #footer .copyright{
      text-align:right;
      font-size:14px;
      font-size:1.4rem;
  }
  #footer .footer_logo_area address em{
    display:inline-block;
    margin-left:0;
  }
}