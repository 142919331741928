@import "../mixins/media";
/*
見出し

ページ内で見出しとして機能する要素のスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.1
*/

/*
見出し

商品紹介等で利用される、一般的な見出しのスタイルです。

ex [商品詳細ページ　商品見出し部分](http://demo3.ec-cube.net/products/detail/27)

Markup:
.ec-headingTitle マトリョーシカ

Styleguide 1.1.1
*/
.ec-headingTitle{
  margin: 0 0 8px;
  font-size: 32px;
  font-weight: normal;
  color: #525263;
}

/*
ページヘッダ

各種ページで用いられるページヘッダのデザインです。

ex [利用規約ページ　ページヘッダ部](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-pageHeader
  h1 利用規約

Styleguide 1.1.2
*/
.ec-pageHeader h1{
  margin: 0 0 3rem;
  // border-bottom: 1px dotted #ccc;
  // border-top: 1px solid #ccc;
  padding: 8px 0 12px;
  font-size: 16px;
  font-weight: bold;
  border-top: none;
  background: url(../img/common/t_line.gif) 0 bottom repeat-x;
  span{
    display: block;
    text-align: center;
    width: 90px;
    vertical-align: middle;
    margin: 0;
    font-size: 14px;
    font-size: 1.4rem;
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: normal;
    background: #f5568b;
    color: #fff;
    padding: 8px;
    padding: 2px 5px;
    border-radius: 10px;
  }
  @include media_desktop {
    // border-top: none;
    // border-bottom: 1px solid #ccc;
    // margin: 10px 16px 48px;
    // padding: 8px;
    // font-size: 32px;
    // font-weight: bold;
    margin: 20px auto 3rem;
    padding: 16px 10px 12px;
    text-align: center;
    font-size: 30px;
    font-size: 3rem;
    span{
      display: inline-block;
      text-align: center;
      width: auto;
      vertical-align: middle;
      margin: 0 15px 0 0;
      font-size: 14px;
      font-size: 1.4rem;
      padding: 8px;}
  }
}


.page-heading{
  margin: 0 0 3rem;
  padding: 8px 0 12px;
  font-size: 16px;
  font-weight: bold;
  border-top: none;
  background: url(../img/common/t_line.gif) 0 bottom repeat-x;
  span{
    display: block;
    text-align: center;
    width: 90px;
    vertical-align: middle;
    margin: 0;
    font-size: 14px;
    font-size: 1.4rem;
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: normal;
    background: #f5568b;
    color: #fff;
    padding: 8px;
    padding: 2px 5px;
    border-radius: 10px;
  }
  @include media_desktop() {
    margin: 20px auto 24px;
    padding: 16px 10px 12px;
    text-align:center;
    font-size: 30px;
    font-size: 3rem;
    span{
      display:inline-block;
      text-align:center;
      width:auto;
      vertical-align: middle;
      margin: 0 15px 0 0;
      font-size: 14px;
      font-size: 1.4rem;
      padding: 8px;
    }
  }
  




}

 /* heading */
h2 {
    font-size: 24px;
    font-size: 2.4rem;
}

.heading01 {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}
.heading02,
.heading03 {
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
}
.heading04 {
  clear: both;
  margin-bottom: 5px;
}
.heading05 {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}

@include media_desktop() {
  .heading01,
  .heading03 {
      font-size: 24px;
      font-size: 2.4rem;
      font-weight: bold;
  }
  .heading02 {
      font-size: 24px;
      font-size: 2.4rem;
      font-weight: bold;
  }
  .heading05 {
      font-size: 16px;
      font-size: 1.6rem;
  }
}


#main {
    margin:0 auto 3rem;
}



/*
サブ見出し

利用規約など、文字主体のページで用いられるサブ見出しです。

ex [利用規約ページ サブ見出し部分](http://demo3.ec-cube.net/help/agreement)

Markup:
.ec-heading 第1条 (会員)

Styleguide 1.1.3
*/

.ec-heading{
  margin: 24px 0;
}



/*
サブ見出し(太字)

文字主体のページで用いられるサブ見出しの太字のスタイルです。

ex [プライバシーポリシー サブ見出し部分](http://demo3.ec-cube.net/help/privacy)

Markup:
.ec-heading-bold 個人情報の定義

Styleguide 1.1.4
*/

.ec-heading-bold {
  margin: 16px 0;
  font-size: 16px;
  font-weight: bold;
  @include media_desktop {
    font-size: 18px;
  }
}

/*
背景付き見出し

マイページ注文履歴等で用いられる背景付きの見出しです。

ex [ご注文履歴詳細　背景付き見出し部分](http://demo3.ec-cube.net/mypage/history/1063)

Markup:
.ec-rectHeading
  h2 配送情報
.ec-rectHeading
  h2 お支払について

Styleguide 1.1.5
*/
.ec-rectHeading{
  h1, h2, h3,
  h4, h5, h6{
    background: $clrGray;
    padding: 8px 12px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
  }

}
h2 {
  font-size: 16px;
  font-size: 1.6rem;
}


/*
メッセージ見出し

ユーザが行った操作に対する、完了報告やエラー表示のページで使用される見出しのスタイルです。

ex [注文完了 ログイン後、カートに商品を入れ注文完了まで行う](http://demo3.ec-cube.net/shopping/)

Markup:
.ec-reportHeading
  h2 ご注文ありがとうございました

Styleguide 1.1.6
*/
.ec-reportHeading{
  width: 100%;
  border-top: 1px dotted #ccc;
  margin: 20px 0 30px;
  padding: 0;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  @include media_desktop {
    border-top: 0;
    font-size: 32px;
  }
  h1, h2, h3,
  h4, h5, h6,p {
    font-weight: bold;
    font-size: 24px;
    @include media_desktop {
      font-size: 32px;
    }
  }
}



/* ピンクballoon */
div.balloon{
	position: relative;
	margin:0 auto 3rem;
	background: #f5568b;
	font-weight:bold;
	color:#fff;
	width:260px;
	height: 60px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	border-radius:10px;
}
div.balloon:after {
	content: "";
	position: absolute;
	top: 100%;
	left: 120px;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-top: 10px solid #f5568b;
}

div.balloon h2 {
	padding-top:3px;
	text-align:center;
	font-size:3.5rem;
	line-height: 3.0rem;
}
div.balloon span {
	display:block;
	font-size:1.3em;
	font-size:1.3rem;
}

/*共通タイトル*/
.t-area *{
  margin:0;
  padding:0;
}
.t-area:before,.t-area:after {content:" ";display:table}
.t-area:after {clear:both}
.t-area{
  margin:0 auto 2rem;
  padding:7px 0;
  position: relative;
  background: #ffe8e8;
  p.icon{
    position:absolute;
    top:-5px;
    left:10px;
    width:30px;   
  }
  h2{
    float:left;
    padding-left:50px;
    font-size:1.8px;
    font-size:1.8rem;    
    font-weight:bold;
  }
}



/*背景がリボンの見出し*/
h1.ribbon-heading{
  margin:1.5rem auto;
  text-align:center;
  background: url(../img/sample/fig_ribbon.png) 50% 0 no-repeat;
  background-size: 100%;
  color:#fff;
  font-size:16px;
  font-size:1.6rem;
  padding:10px 0 0;
  height:70px;
  max-width:324px;
  @include media_desktop() {
    margin:1.5rem auto 2rem !important;
    text-align:center;
    background: url(../img/sample/fig_ribbon.png) 50% 0 no-repeat;
    background-size: 100%;
    color:#fff;
    font-size:24px !important;
    font-size:2.4rem !important;
    padding:15px 0 0 !important;
    width:518px;
    height:112px !important;
    max-width:518px !important;
  }
   
}


/*共通タイトル●*/
.sub-area *{
  margin:0;
  padding:0;
}
.sub-area{
  margin:0 auto 2rem;
  padding:7px 0;
  border-bottom:3px solid #f5568b;
  h3{
    font-size:1.9px;
    font-size:1.9rem;  
    font-weight:bold;  
    &::before{
      color: #f5568b;
      content: "";
      font-family: "FontAwesome";
      font-size: 2rem;
      padding: 0 7px 0 0;    
    }
  }
}


/* topicpath */
#common-topicpath *{
	margin:0;
	padding:0;
}
#common-topicpath{
	padding:10px;
	background: url(../img/common/path_bg.gif) 0 0 repeat;
	font-size:11px;
	font-size:1.1rem;
  .path-in{
    margin:0 auto;
    overflow:hidden;
    zoom: 1;
    @include media_desktop() {
      width:1060px;
    }
    
    p.back-list{
      a{
        position: relative;
        float:left;
        display:inline-block;
        margin-right:10px;
        margin-left: 20px;
        padding: 0 10px 0 5px;
        background: #f5568b;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        font-size: 11px;
        color: #fff;
        line-height: 16px;  
        &::before{
          content: "";
          position: absolute;
          top: 0;
          left: -8px;
          border-right: 8px solid #f5568b;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          transform-origin: right top;
          transform: scaleX(.7);          
        }
        &:hover{
          color:#ffb6b6;
        }     
      }
    }
    .topicpath{
      a{
        color:#4e290e;
        &:hover{
          color:#f5568b;
        }
        span.home{
          &::before{
            content: "\f015";
            color: #4e290e;
            font-family: "FontAwesome";
            padding: 0 7px 0 0;
            font-size:1.2rem;            
          }
        }
      }
    }
  }
}



/*ページ内リンク*/
.sublink *{
  margin:0;
  padding:0;
}
.sublink{
  margin:0 auto 3rem;
  @include media_desktop(){
    display:flex;
    justify-content: center;
  }
  li{
    letter-spacing:normal;
    display: block;
    text-align:center;
    width:100%;
    @include media_desktop(){
      width:auto;
      display: inline-block;
      margin:0 2px;
    }
    a{
      margin:0 auto;
      display:block;
      background: #FF6C00;
      color:#fff;
      padding:10px;
      border-radius: none;
      border-bottom:2px solid #fff;
      width:100%;
      @include media_desktop(){
        border-radius: 8px;
        width:auto;
        display:inline-block;
      }
      &::before{
        content: "\f063";
        color: #fff;
        font-family: "FontAwesome";
        padding: 0 7px 0 0;
        font-size:1.5rem;         
      }  
      &:hover{
        background: #ffb57e;
      }    
    }    
  }
  
}



