@import url('../../../../../node_modules/normalize.css/normalize.css');
@import "mixins/variables";
@import "../../../../../node_modules/bootstrap/scss/bootstrap";
@import "//maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css";

@import url('https://fonts.googleapis.com/css2?family=Cabin+Sketch:wght@400;700&family=Changa+One:ital@0;1&family=Fredericka+the+Great&display=swap');

html {
  font-size: 62.5%; /* 62.5%を指定すると「1.0 rem = 10px」 */
  overflow-x:hidden;
}


body {
	font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
	line-height: 1.4;
	color: #333;
	-webkit-text-size-adjust: 100%;
    margin: 0;
    font-size: 16px;
    font-size: 1.6rem;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: bold !important;
}

a {
  text-decoration: none;
}
a {/* color: #ff6c00;*/
  color:#f5568b;
  text-decoration: none;
  cursor: pointer;
}
a,
a img {
transition: all .4s ease;
}
textarea { /* for chrome fontsize bug */
  font-family: sans-serif;
  font-size:16px;
}

a:hover img,
.btn a:hover {
  opacity: 0.7;
}

a:hover,
a:focus,
a:active { /*color: #ff6c00;*/color:#f5568b;text-decoration: none; outline: none;}


ul, ol {
  list-style: none;
  margin: 0; padding: 0;
}

pre {
  background-color: transparent;
  border: none;
  padding: 16px 0;
}
dl, dt, dd, li{
  margin: 0; padding: 0;
}
img {
  max-width: 100%;
}
address,em{font-style:normal;}

/*基本table*/
table{
    margin:0 auto 3rem;
    width:100%;
    border-collapse:collapse;
    border:1px solid #e5dacd;
    font-size:14px;
    font-size:1.4rem;
}
th,td{
    padding:10px 5px;
    border-collapse:collapse;
    border:1px solid #e5dacd;
}
th{background: #ececec;}
th.head-base{
    background: url(../img/common/th_bg.gif) 0 0 repeat;
    width:20%;
}
th.head2{
    background: url(../img/common/th_bg_yellow.gif) 0 0 repeat;
}




.right{text-align:right;}
.center{text-align:center;}

.read{
    margin:0 auto 1rem;
}
.comment{
    margin:0 auto 0.5rem;
    font-size:12px;
    font-size:1.2rem;
}
.comment a{
    color:#333;
    text-decoration: underline;
}
.caution{
    color:#de5d50;
}
/* svg icon */
#svgicon {
    display: block;
    width: 0; height: 0; overflow: hidden;
}
.cb {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
}
.cb-images {
    width: 1.125em;
}
.icon-bg {
    fill: #d4d4d5;
}
.icon-with-bg {
    fill: #fff;
}
.link-question{
    margin:0 auto 1.5rem;
    text-align:right;
}
.link-question a{
    font-weight:bold;
}
/*?アイコン*/
.question-circle {
    font-size: 11px;
    display: inline-block;
    width: 15px; height: 15px;
    line-height: 15px;
    text-align: center;
    vertical-align: middle;
    background: #525263;
    border-radius: 50%;
    fill: #fff;
}
.question-circle .cb-question {
    fill: #fff;
    color:#fff !important;
}


p {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}


.cute{
  font-family: 'Cabin Sketch', cursive;
  font-weight: 700;
  span{
    font-family: Roboto, "游ゴシック", YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, "メイリオ", Meiryo, sans-serif;
  }
}



.fig{
	margin:0 auto 1.5rem;
	text-align:center;
}
.fig img{
	width:100%;
	max-width:500px;
}


/* dl_table */
.dl_table {
    margin:0 auto 3rem;
    border-top: 1px dotted #ff6c00;
    font-size:14px;
    font-size:1.4rem;
    h2{
        padding:5px;
        width:100% !important;
        font-size:18px;
        font-size:1.8rem;
        border-radius:5px;
        background: #FFF0F0;  
        &:before{
            content: "\f10c";
            color: #f5568b;
            font-family: "FontAwesome";
            padding: 0 7px 0 0;
            font-size:2rem;          
        }      
    }
    dl{
        padding: 8px 0;
        dt{
            background: #FBFDE5;
            font-weight:bold;
            padding:1rem 0.5rem;
            margin-bottom:1rem;
            vertical-align: middle;
            dd{
                padding-left:0.5rem;
                .form-group{
                    padding-top: 8px;
                    &:first-child{
                        padding-top: 0;
                    }
                    
                }
            }         
        }
    }
    .zip-search {
        margin-top: 8px;
    } 
}


/*flow-box*/

.flow-box *{
	margin:0;padding:0;
}
.flow-box{
	margin:0 auto;
	width:98%;
	background: #efefef;
    dl{
        margin:0 auto !important;
        /*padding:25px;*/
        padding:15px;
        dt{
            margin-bottom:15px;
            padding-bottom:10px;
            font-weight:bold;
            color:#40bdc1;
            font-size:18px;
            font-size:1.8rem;
            border-bottom:3px solid #40bdc1;
            span{
                display:block;
                font-size:45px;
                font-size:4.5rem;
                height:50px;
            }
        }
        dd{
            p{
                margin-bottom:1rem;
                &.fig{
                    text-align:center;
                    img{
                        border:1px solid #ccc; 
                    }
                }
            }
            em{
                margin:1rem 0;
                padding:0.5rem 1rem;
                display:block;
                background:#de5d50;
                color:#fff;
                font-weight:bold;
                border-radius:5px;
            }
            .print-data{
                margin:0 auto 2rem;
                border-radius: 15px;
                border:3px solid #40bdc1;
                background: #fff;
                h3{
                    margin-bottom:15px;
                    padding:5px 0;
                    border-radius:10px 10px 0 0;
                    text-align:center;
                    background: #40bdc1;
                    color:#fff;
                    font-size:14px;
                    font-size:1.4rem;
                }
                .data-in{
                    padding:0 10px 10px;
                    font-size:13px;
                    font-size:1.3rem;
                    img{
                        border:none; 
                    }
                }
            }
            .flow-detail{
                h3{
                    margin-bottom:0.5rem;
                    color:#ff6c00;
                    font-size:15px;
                    font-size:1.5rem;
                }
                p{
                    margin-left:15px;
                    font-size:16px;
                    font-size:1.6rem;
                }
            }

        }
    }
}

.flow-box dl:nth-child(1){
	background: #fbfbf6;
}
.flow-box dl:nth-child(2n){
	background: #fbfbf6;
}
.flow-box dl:nth-child(2n+1){
	background: #fff5f9;
}


/* btn */
.btn {
    margin:0 auto;
    padding:0;
    text-align:center;
    padding:10px 16px;
    font-weight: bold;
    overflow: hidden;
    zoom: 1;
    a{
        border-radius: 10px;
        color: #f5568b !important;
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        padding: 7px 20px;
        text-decoration: none;
        &::before{
            content: '\f0da';
            font-family: "FontAwesome";
            padding: 0 8px;
            color:#f5568b;          
        }
        &:hover{
            background: #fff;
        }
        &:active{
            background: none;
            border:none;
            box-shadow: none;
        }
    }
}
/* btn+green */
.btn-green{
    margin:0 auto;
    width:100%;
    text-align:center;
    font-size: 15px;
    font-size: 1.5rem;
    font-weight: bold;   
    a{
        border-radius: 10px;
        color: #fff !important;
        cursor: pointer;
        display: inline-block;
        font-size: 15px;
        padding:20px 35px !important;
        text-decoration: none;
        background: #34babe;  
        &::before{
            content: '\f0da';
            font-family: "FontAwesome";
            padding: 0 8px;
            color:#fff;            
        } 
        &:hover{
            background: #66cdd0;
        }     
    }
}

/* btn:fax; */
.btn-fax {
    margin:0 auto;
    width:100%;
    text-align:center;
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    a{
        border-radius: 10px;
        background: #f5568b;
        color: #fff !important;
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        padding:20px 35px !important;
        text-decoration: none;
        &::before{
            content: '\f1c1';
            font-family: "FontAwesome";
            padding: 0 8px;
            color:#fff;
        }
        &:hover{
            background: #f490b2;
        }
    }
}


/*送料変更のお知らせ*/
.postage{
	margin:2em auto 3em;
	width: 98%;
	position:relative;
    dl{
        border:8px solid #f5568b;
        border-radius:10px;
        background: #FFF;
        dt{
            position:relative;
            padding:0.5em 0.5em 0.5em 80px;
            background:#f5568b;
            color:#fff;
            font-size:1.2em;
            font-weight:bold;
            text-align:left;
            p.fig{
                position:absolute;
                left:5px;
                top:-10px;
                width: 80px;
                img{
                    width:100%;
                }
            }
        }
        dd{
            padding:1em;
        }
    }
}



/* common_add */
.common-add{
    position: relative;
    margin:0 auto 2rem;
    padding:15px !important;
    background: #fff0f0;
    border-radius: 15px;
    .common-top,.common-bottom{
        display: none;
    }
    h2{
        margin:0 auto 1rem;
        color:#f5568b;
        text-align:center;
        font-size:14px;
        font-size:1.4rem;       
    }
    ul{
        margin:0 auto;
        width:100%;     
        li{
            margin-bottom:10px;
            font-size:1.4rem;
            font-weight:bold;
            vertical-align: top;
            em{
                margin:0 3px !important;
                padding:3px !important;
                background: #f5568b !important;
                color:#fff;
                font-size:1rem;
                border-radius: 0;
                color: #fff;
                display: inline !important;
                font-weight: bold;               
            } 
            span{
                display:block;
                font-size:10px;
                font-size:1rem;
                text-align:left;               
            }  
            a{
                color:#333;
                text-decoration: underline;
                &:hover{
                    text-decoration:none;
                }
            } 
        }  
    }
    .row,.col-md-6{
        padding:0 !important;
    }
}


/*notfound*/
#nofound{
    background: url(../img/common/stripe_bg.gif) 0 0 repeat;
    #main{
        margin:5em auto;
        max-width:640px;
        text-align:center;
        .notfound-box{
            p.txt{
                margin:0 auto 2em;
                width:90%;
                text-align:left;
            }
            p.btn{
                max-width:300px;
            }
        }
    }
}
#nofound #contents{
    background: none;
}
#inner{
    margin:10rem auto 2rem;
}
#inner p{
    margin:0 auto 1.5rem;
    text-align:center;
}

.notfound-box h2{color:#F5568B;}

/****** Effect Setting ********/

/* Overlay */
.overlay {
    /* shadow layer visible when navigation is active */
    position: fixed;
    z-index: 2;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    background-color: rgba(0,0,0, 0.5);
    visibility: hidden;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s, -webkit-transform 0.3s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s, -moz-transform 0.3s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.3s 0s;
}
.overlay.is-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0s, -webkit-transform 0.3s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0s, -moz-transform 0.3s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0s, transform 0.3s 0s;
}

/* modal */

.modal-header .close {
    font-size: 22px;
    font-size: 2.2rem;
}
.modal-content {
    background-color: #F3F3F3;
}
.modal-body .form-control {
    width: 100%;
}
.modal-body .quantity {
    width: 50%;
    float: right;
    text-align: right;
    padding: 16px 0;
    font-size: 16px;
    font-size: 1.6rem;
}
.modal-body .quantity dd input {
    text-align: right;
}
.modal-body .classcategory_list li {
    margin-top: 16px;
}
.modal-body .classcategory_list li:first-child {
    margin-top: 0;
}
.modal-body .item_price {
    margin: 0;
    clear: both;
    text-align: right;
    font-size: 16px;
    font-size: 1.6rem;
}
.modal-body .item_price .small {
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: normal;
    margin-left: 5px;
}
.modal-footer {
    padding: 16px 0;
    margin: 0 16px;
}
.modal-footer .btn_area {
    padding-bottom: 0;
}
.modal-footer .btn_area li:last-child {
    padding-left: 0;
}
.modal-footer .btn_area button {
    font-size: 16px;
    font-size: 1.6rem;
    height: 56px;
    line-height: 56px;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
}
.modal-footer .btn_area .btn_icon .cb {
    display: inline-block;
    font-size: 30px;
    font-size: 3.0rem;
    height: 54px;
    line-height: 54px;
    vertical-align: middle;
    position: relative;
    top: -2px;
}



/*animatioln*/
/*bounce*/
@keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0,-4px,0);
    transform: translate3d(0,-4px,0);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

/**
 * $keyframes \ gelatine 
 **/
 @keyframes gelatine {
  from, to {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  25% {
    -webkit-transform: scale(0.9, 1.1);
    transform: scale(0.9, 1.1);
  }

  50% {
    -webkit-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }

  75% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }

  from, to {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  25% {
    -webkit-transform: scale(0.9, 1.1);
    transform: scale(0.9, 1.1);
  }

  50% {
    -webkit-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }

  75% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }
}


.pickup_item:hover,#checkeditem:hover{
    animation: gelatine 1s;
/*    animation: bounce 1s;*/
    animation-iteration-count: 1;/*アニメーションの繰り返し回数*/
    -webkit-animation: bounce 1s; /*Safari & Chrome */
    -webkit-animation-iteration-count: 1;/*Safari & Chrome */
}

.modal-body{
    margin-top:15px;
}

#item_photo_area .wysiwyg {
       margin:0 auto 3rem;
       font-size:0.9em;
}
#item_photo_area .wysiwyg ol,#item_photo_area .wysiwyg ul{
}
#item_photo_area .wysiwyg ol li{
       margin-left:15px;
       list-style-type:decimal; 
}

#item_photo_area .wysiwyg ul li{
    margin-left:15px;
     list-style-type:disc; 
}
#item_photo_area .wysiwyg em{
     font-style: oblique;
}



/* pagetop
------------------------------------------------*/
@keyframes vertical {
  0% { transform:translateY(-10px); }
100% { transform:translateY(  0px); }
}

.pagetop{
  animation: vertical 2s ease-in-out infinite alternate;
  -webkit-animation:vertical 2s ease-in-out infinite alternate;
}



@import "component/1.1.heading";
@import "component/1.2.typo";
@import "component/1.3.list";
@import "component/2.1.buttonsize";
@import "component/2.2.closebutton.scss";
@import "component/2.3.otherbutton";
@import "component/3.1.inputText";
@import "component/3.2.inputMisc";
@import "component/3.3.form";
@import "component/4.1.icon";
@import "component/5.1.grid";
@import "component/5.2.layout";
@import "component/6.1.login";
@import "component/7.1.itembanner";
@import "component/7.2.search";
@import "component/7.3.cart";
@import "component/8.1.info";
@import "component/8.2.banner";
@import "component/9.1.mypage";
@import "project/11.1.role";
@import "project/11.2.header";
@import "project/11.3.footer";
@import "project/12.1.slider";
@import "project/12.2.eyecatch";
@import "project/12.3.button";
@import "project/12.4.heading";
@import "project/12.5.topics";
@import "project/12.6.newItem";
@import "project/12.7.category";
@import "project/12.8.news";
@import "project/12.9.calendar";
@import "project/13.1.searchnav";
@import "project/13.2.shelf";
@import "project/13.3.pager";
@import "project/13.4.cartModal";
@import "project/14.1.product";
@import "project/15.1.cart";
@import "project/15.2.order";
@import "project/16.1.history";
@import "project/16.2.historyDetail";
@import "project/17.1.address";
@import "project/18.1.password";
@import "project/19.1.register";
@import "project/19.2.contact";
@import "project/19.3.customer";
@import "project/20.1.404";
@import "project/21.1.withdraw";
@import "project/22.1.editComplete";
@import "project/23.1.top";


/******************************
	Media Query
*******************************/

@media print, screen and (max-width: 1024px) {

  /* --------- common ---------- */

  .drawer-open #contents, .drawer-open #header, .drawer-open #footer, .drawer-open .pagetop {
      -webkit-transform: translateX(-260px);
      -moz-transform: translateX(-260px);
      -ms-transform: translateX(-260px);
      -o-transform: translateX(-260px);
      transform: translateX(-260px);
  }

  .sp{display: block !important;}
  .pc{display: none !important;}
  span.sp{display: inline !important;}

  /****** Header Setting ********/


  /****** Side Setting ********/

  .drawer {
      height: 0;
      visibility: hidden;
      -webkit-transition: visibility 0s 0.3s;
      -moz-transition: visibility 0s 0.3s;
      transition: visibility 0s 0.3s;
  }
  .drawer-open .drawer {
      height: 100%;
      visibility: visible;
      -webkit-transition: visibility 0s 0s;
      -moz-transition: visibility 0s 0s;
      transition: visibility 0s 0s;
  }
  .category-nav a {
      will-change: transform, opacity;
  }


  /****** Effect Setting ********/

  /* Overlay */
  .overlay.is-visible {
      -webkit-transform: translateX(-260px);
      -moz-transform: translateX(-260px);
      -ms-transform: translateX(-260px);
      -o-transform: translateX(-260px);
      transform: translateX(-260px);
  }
  .overlay.is-visible.cart-is-visible {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
  }
  

}

/******************************
	Media Query (tablet)
*******************************/

@media only screen and (min-width: 767px) {


/*送料変更のお知らせ*/
.postage{
    margin:3em auto;
    max-width:1060px;
    dl{
        dt{
            position:relative;
            padding:0.5em 0.5em 0.5em 120px;
            font-size:1.6em;
            font-weight:bold;
            p.fig{
                position:absolute;
                left:10px;
                bottom: -10px !important;
                top:auto;
                width: 120px;

            }
        }

    }
}





	/*flow-box*/
	.flow-box dl{
		padding:25px;
        dt{
          padding-right:20px;
          border-bottom:none;
          span{
            display:block;
            font-size:50px;
            font-size:5rem;
            height:70px;
          }
        }
        dd{
            border-left:5px solid #40bdc1;
            padding-left:20px;
            p{
                margin-bottom:2rem;
            }
            .print-data{
                h3{
                    font-size:17px;
                    font-size:1.7rem;
                }
            }
        }
	}

}



/*=============================================================================

    Media Query:PC

=============================================================================*/

@media only screen and (min-width: 1024px) {

  /* --------- common ---------- */

  .sp{display: none !important;}
  .pc{display: block !important;}
  span.sp{display: inline !important;}

  .container-fluid {
      margin:0 auto;
      width:100%;
      padding:0 !important;
  }
  .row {
      margin-left: -16px;
      margin-right: -16px;
      padding-bottom: 16px;
  }
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
      padding-left: 16px;
      padding-right: 16px;
  }

  .sp{display: none !important;}
  .pc{display: block !important;}
  span.pc{display: inline !important;}


  /* --------------------------------
      Parts
  -------------------------------- */



  /* btn */

  .btn-lg{padding:10px 16px;font-size:18px;line-height:1.3333333}
  .btn-sm{padding:8px 10px;font-size:14px;line-height:1.5}
  .btn-xs{padding:5px 8px;font-size:12px;line-height:1.5}

  /* badge */

  .badge {
      font-size:12px;
      font-size:1.2rem;
  }

  /* tableStyle */

  div.table .thead {
      display: table-header-group;
  }
  div.table ol {
      display: table-row;
      margin: 0;
  }
  div.table ol li {
      display: table-cell;
      padding: 16px;
      text-align: center;
      background: #F4F3F0;
      overflow-x:hidden;
      font-weight: bold;
  }
  div.table .tbody .tr > .td {
      padding: 16px;
  }

  /* tableish */

  .tableish {
      display: table;
      width: 100%;
  }
  .tableish .thead {
      display: table-header-group;
  }
  .tableish .tbody {
      display: table-row-group;
  }
  .tableish .tr {
      display: table-row;
  }
  .tableish .th,
  .tableish .td {
      display: table-cell;
      vertical-align: top;
  }

  /* dl-table */

  .dl_table {
      border-top:none;
      font-size:14px;
      font-size:1.4rem;
      width: 100%;
  }
  .dl_table h2{
      padding:8px;
      margin:3rem auto 1rem;
  }
  .dl_table dl{
      margin:0;
      padding:0;
      display: table;
     width:100%;
       border: 0 none;
  }

  .dl_table dt,
  .dl_table dd {
      display: table-cell;
      border-bottom: 1px dotted #ff9cbd;
      padding: 16px;
  }
  .dl_table dt {
      width: 30%;
  }

  .dl_table dd {
      padding: 16px;
  }
  .dl_table dd .form-group {
      padding-top: 16px;
  }
  .dl_table .zip-search {
      margin-top: 16px;
  }

table#shopping-history,
 table#shopping-sample{
    margin:0 auto 2rem;
    width:100%;
    border:1px solid #ccc;
    border-collapse:collapse;
    font-size:14px;
    font-size:1.4rem;
}
table#shopping-history th,table#shopping-history td,
table#shopping-sample th,table#shopping-sample td{
    margin:0 auto;
    padding:5px;
    width:100%;
    border:1px solid #ccc;
    border-collapse:collapse;
}

table#shopping-history th{
  background: url(../img/common/th_bg.gif) 0 0 repeat;
}
table#shopping-sample th{
  background: url(../img/common/th_bg_yellow.gif) 0 0 repeat;
}
table#shopping-history td a,
table#shopping-sample td a{
 font-weight:bold;
 text-decoration: underline;
}
table#shopping-history td a:hover,
table#shopping-sample td a:hover{
 text-decoration:none;
}

table#shopping-history .item_photo,
table#shopping-sample .item_photo{
margin:0 !important;padding:0;
}
table#shopping-history td img,
table#shopping-sample td img{
width:100%;
}
table#shopping-history .goods-img,
table#shopping-sample .goods-img{width:15%;}
table#shopping-history .goods-name,
table#shopping-sample .goods-name{width:45%;}
table#shopping-history .goods-price,
table#shopping-sample .goods-price{width:15%;}
table#shopping-history .goods-num,
table#shopping-sample .goods-num{width:10%;}
table#shopping-history .goods-subtotal,
table#shopping-sample .goods-subtotal{width:15%;}
.goods-img img{border:1px solid #ccc;}


  

  /* pagination */

  .pagination {
      width: 100%;
  }


  /* topicpath */
  #topicpath {
      padding: 24px 0 5px;
  }
  #topicpath ol {
      padding: 20px 30px 45px;
  }

  #topicpath li {
      font-size:2rem;
  }
  #topicpath p.topic-num {
      top:24px !important;
      font-size:1.6rem;
  }

/*ページ内リンク*/
.sublink{
  margin:3rem auto !important;
  text-align:center;
}

.sublink li{
  display: inline-block;
}
.sublink li a{
  border-radius: 8px;
  border:none;
}



  /* intro */
  .intro {
      margin: 0;
      padding-top: 0.5em;
      padding-bottom: 28px;
  }
  .intro strong{
      color:#f5568b;
  }
  /* message */
  .heading04 + .message {
      padding: 0;
  }
  .message .errormsg {
      padding: 16px;
  }


  /* flowline */

  .flowline {
      margin: 0;
      border-bottom: 0 none;
  }
  .flowline ul::before {
      top: 20px;
  }
  .flowline.step3 ul {
      max-width: 450px;
  }
  .flowline.step4 ul {
      max-width: 550px;
  }
  .flowline ul li {
      font-size: 14px;
      font-size: 1.4rem;
  }
  .flowline li .flow_number {
      line-height: 42px;
      width: 42px; height: 42px;
      font-size: 20px;
      font-size: 2rem;
  }

  /* .cart .item_box */

  #cart .inner {
      padding: 0 16px;
  }
  .cart .item_box > div,
  .cart .item_box > dl {
      padding: 16px 0;
  }
  .cart .item_box .item_photo {
      padding: 16px 8px 16px 0;
  }
  .cart .item_box .icon_edit {
      right: -7px; top: 10px;
  }

  .cart .btn_area {
      padding: 16px 0 8px;
  }

  /* error_box */
  .error_box {
      margin-bottom: 48px;
  }


  /****** Layout ********/


  /* header */
  .header_logo_area {
      padding:0;
      position: relative;
  }
  .header_logo_area .header_logo img{
  height:36px;
  }

  #contents {
      padding-top:0 !important;
      min-height: 100%;
      z-index: 2;
  }


  #side_left {
      float: left;
      width: 25%;
      padding-right: 16px;
  }
  #side_right {
      float: right;
      width: 25%;
      padding-left: 16px;
  }

  /* -- main_left (side_right only) -- */

  /* main */
  .theme_side_right #main {
      width: 75%;
      float: left;
  }

  /* -- main_right (side_left only) -- */

  /* main */
  .theme_side_left #main {
      width: 75%;
      float: right;
  }

  /* -- main_only -- */

  /* main */
  .theme_main_only #main {
      width: 100%;
  }


  /* -- main_left_right (side_left & side_right) -- */

  /* main */
  .theme_side_both #main {
      width: 50%;
      float: left;
  }


  /****** Header Setting ********/

  #header {
      height: auto;
      padding:10px 0;
  }
  #header .inner{
      width:1060px !important;
  }
  #header #cart .inner{
      /*width:258px !important;*/
      width:287px !important;
  }
  #header:after {
      content: "";
      display: table;
      clear: both;
  }
  #header .copy {
      font-size: 11px !important;
      font-size: 1.1rem !important;
      letter-spacing:-1px !important;
  }
  .header_logo_area .header_logo {
      font-size: 40px;
      font-size: 4rem;
      margin: 0;
  }

  #header .header_bottom_area {
      clear: both;
      /*padding-top: 16px;*/
      padding:0;
      height:0;
  }

  /* header-buttons */

  .header-buttons {
      top: 18px;
      right: 4em;
  }

  /* cart_area */

  #header #cart_area {
      border:4px solid #fff7f7;
      width: auto;
      right:0;
  }
      #header #cart_area p{
  }
  #header #cart_area p.cart-trigger a {
      position: relative;
      float: left;
  }
  #header #cart_area p.cart-trigger .cb-shopping-cart {
      position: relative;
      left: 9px;
  }
  #header #cart_area p.cart-trigger .badge {
      position: relative;
      left: 15px; top: 0;
  }
  #header #cart_area .cart_price {
      float: right;
      height: 44px;
      line-height: 44px;
      vertical-align: middle;
      margin-right: 16px;
  }


  .search select option {
      max-width: 246px;
  }
  .search select {
  display:none !important;
  }





  /****** Side Setting ********/

  #btn_menu {
      display: none;
  }
  .drawer {
      position: static;
      height: auto;
      width: auto;
      margin: 0 auto;
      overflow: visible;
      background: transparent;
      text-align: center;
  }
  .drawer:after {
      content: "";
      display: table;
      clear: both;
  }


  /*	Category navigation
  --------------------------- */

  /* category_nav */
  #category {
    //   position:absolute;
    //   top:70px;
    //   left:340px;
      margin:5px auto 0;
      text-align:center;
  }
  #category li{
    text-align:center !important;
    height:45px;
}
  #category li a span {
      display:inline;
  }

  .category-nav > li {
      text-align: left;
      margin: 0;
      padding:0 13px;
      position: relative;
       background: url(../img/common/ic_circle.png) no-repeat right center;
  }
  .category-nav > li:last-child{
      background:none;
  }
  .category-nav ul {
      position: static;
      height: auto;
      width: auto;
      overflow: visible;
      z-index: 3;
      background: #fff;
  }
  .category-nav > li > a {
      position: relative;
      color: #2e3233;
      overflow: visible;
      -webkit-transition: color 0.3s, box-shadow 0.3s;
      -moz-transition: color 0.3s, box-shadow 0.3s;
      transition: color 0.3s, box-shadow 0.3s;
  }
  .category-nav > li > a:hover {
}

  /* ----- category_nav in header ---- */

//   #header #category {


//   }

  /* category-nav :上書き*/


  header .category-nav a {
      border:none;
  }

  header .category-nav > li {
    font-size:14px;
    font-weight:bold;
    margin: 0;
  }


//   #header .category-nav li {
//       position: relative;
//       display: inline-block;
//       text-align:center;
//   }
header .category-nav li span{
    font-size:11px;
    color:#f5568b;
}
//   header .category-nav > li > a {
//       padding: 0 1em;
//     //   height:20px;
//     //   line-height:20px;
//       border-bottom: none;

//   }
//   #header .category-nav li a {
//       display: block;
//   }

//   #header .category-nav li ul {
//       padding-bottom:30px;
//       display: block;
//       position: absolute;
//       z-index: 100;
//       top: 100%;
//       left: -280px;
//       width: 640px;
//       box-shadow: 1px 1px 2px rgba(0,0,0,0.1);
//       background: #fbfaf2;
//   }
//    #header .category-nav .row{padding:0;margin:0;}
//   #header .category-nav li ul:hover:after{
//   content: ' ';
//   width:0;
//   height: 0;
//   position:absolute;
//   border:10px solid transparent;
//   border-bottom-color:#fbfaf2;
//   bottom:100%;
//   left:50%;
// }

//   #header .category-nav li ul li {
//       float: left;
//       width:33.3%;
//       overflow: hidden;
//       height: 0;
//       font-size:12px;
//       font-size:1.2rem;
//       transition: .2s;
//   }
//   #header .category-nav li ul li a {
//       display:block;
//       margin:5% 2%;
//       width: 96%;
//       height:90%;
//       height:40px;
//       line-height:40px;
//       text-align: left;
//       background: #FEEEF3;
//   }
//   #header .category-nav > li:hover > a {}
//   #header .category-nav > li:hover li:hover > a {  background: rgba(204, 204, 204, 0.1)}
//   #header .category-nav > li:hover > ul > li {
//       overflow: visible;
//       height: 50px;
//   }
//   #header .category-nav li ul li ul {
//       top: 0;
//       left: 100%;
//   }
//   #header .category-nav li:last-child ul li ul {
//       left: 100%;
//       width: 100%;
//   }
//   #header .category-nav li ul li ul:before {
//       position: absolute;
//       content: ">";
//       top: 13px;
//       left: -15px;
//       /*width: 0;
//       height: 0;*/
//   }
//   #header .category-nav li:last-child ul li ul:before {
//       position: absolute;
//       content: ">";
//       top: 13px;
//       left: -15px;
      /*width: 0;
      height: 0;*/
      /*
      content: "<";
      top: 13px;
      left: 200%;
      margin-left: -15px;
      */
//   }
//   #header .category-nav li ul li:hover > ul > li {
//       overflow: visible;
//       height: 50px;
//   }
//   #header .category-nav li ul li ul li a {}
//   #header .category-nav li:hover ul li ul li a:hover {  background: rgba(204, 204, 204, 0.1)}

//   #header .category-nav ul > li > a,
//   #header .category-nav ul > li > ul > li > a,
//   #header .category-nav ul > li > ul > li > ul > li a {
//       padding-left: 10px;
//   }



  /* ----- category_nav in side ---- */

  /* category-nav */

  .side .category-nav > li {
      display: block;
      margin: 0;
  }
  .side .category-nav li > a {
      width: 100%;
  }

  .side .category-nav > ul ul {
      width: 100%;
  }
  .side .category-nav > ul {
      width: 100%;
  }
  .side .category-nav > ul > li {
      width: 100%;
  }
  .side .category-nav > ul > li a {
      padding-left: 10px;
  }


  /* newslist */

  .newslist .heading01 {
      padding: 16px;
      text-align: left;
  }
  .newslist dt {
      padding: 16px;
  }
  .newslist dt .angle-circle {
      top: 14px;
      width: 25px; height: 25px;
  }
  .newslist dt .cb {
      font-size: 20px;
      top: 0; left: 3px;
  }
  .newslist dt .date {
      float: left;
      width: 6em;
  }
  .newslist dt .news_title {
      padding-left: 7em;
      padding-right: 30px;
  }
  .newslist dd {
      margin: 0 16px 16px;
  }

  /* Item List Page
  ------------------------------------------- */

  .product_page .product_item {
      padding-bottom: 24px;
  }
  .item_photo {
      margin: 0 0 15px;
  }
  .item_comment {
      margin: 0 0 5rem;
      font-size:14px;
      font-size:1.4rem;
  }


  /* Item Detail Page
  ------------------------------------------- */

  #item_photo_area {
      padding-top: 0;
      padding-bottom: 0;
  }
  #item_detail_area .small {
      font-size: 12px;
      font-size: 1.2rem;
  }
  #item_detail_area .item_name {
      padding:15px;
      font-size: 20px;
      font-size: 2.0rem;
      margin-bottom: 10px;
      font-weight:bold;
  }
  #item_detail_area .item_detail .sale_price {
      font-size: 28px;
      font-size: 2.8rem;
  }
  #item_detail_area .item_detail .sale_price .small {
      font-size: 16px;
      font-size: 1.6rem;
  }
  #item_detail_area .item_detail .announce {
      padding: 14px 0;
  }
  #item_detail_area .item_detail .point {
      padding: 14px 0;
      border-top: 1px dotted #ccc;
  }
  #item_detail_area .item_detail .item_code {
      padding: 14px 0;
      border-top: 1px dotted #ccc;
  }
  #item_detail_area .item_detail .relative_cat {
      padding: 14px 0;
      border-top: 1px dotted #ccc;
      border-bottom: 1px dotted #ccc;
  }
  #item_detail_area .item_detail .item_comment {
      margin-top: 14px;
      margin-bottom: 14px;
  }

  .cart_area {
      padding-top: 14px;
  }
  .cart_area .classcategory_list li {
      margin-top: 16px;
  }
  .cart_area .classcategory_list li:first-child {
      margin-top: 8px;
  }
  .cart_area .classcategory_list li select {
      width: auto;
      width: 80%;
  }
  .cart_area .quantity {
      margin: 14px 0;
  }
  #item_detail .snsbtn_area {
      padding: 14px 0;
  }

  /* freearea */

  #item_detail .freearea p {
      margin-bottom: 40px;
  }
  #item_detail .freearea .heading01 {
      margin-bottom: 16px;
  }

  /* review_area */

  #review_area {
      border-top: none;
      padding-bottom: 16px;
  }
  #review_area dl {
      padding-bottom: 32px;
  }
  #review_area dl dt {
      padding: 16px;
  }
  #review_area dl dt .cb {
      position: absolute;
      right: 32px;
      top: 24px;
      font-size: 16px;
      font-size: 1.6rem;
  }
  #review_area dl dd {
      display: block;
      padding: 16px 0 0;
  }
  #review_area dl dd .review_btn {
      padding: 16px 0 0;
      text-align: center;
  }
  #review_area dl dd .review_btn a {
      width: auto;
      min-width: 350px;
  }
  #review_area .review_list {
      padding: 16px 0 0;
  }
  #review_area .review_list li {
      padding: 16px 0;
  }
  #review_area .review_list p {
      margin: 6px 0;
  }

  /* keyword_area */

  #keyword_area {
      border: 0 none;
  }
  #keyword_area .heading03 {
      border-top: 1px solid #E8E8E8;
      margin-top: 0;
      padding-top: 18px;
  }
  #keyword_area ul li a {
      font-size: 14px;
      font-size: 1.4rem;
  }
  #keyword_area .keyword_list {
      padding-bottom: 16px;
  }

  /* recommend_area */

  #recommend_area {
      border: 0 none;
  }
  #recommend_area .heading03 {
      border-top: 1px solid #E8E8E8;
      margin-top: 0;
      padding-top: 18px;
  }
  #recommend_area .carousel .product_item .btn_circle {
      right: 24px;
  }
  #recommend_area .carousel {
      margin-left: -16px;
      margin-right: -16px;
  }
  #recommend_area .carousel .product_item {
      padding: 0 16px;
  }
  #recommend_area .slick-next,
  #recommend_area .slick-prev {
      font-size: 37px;
      font-size: 3.7rem;
      top: -1.4em;
  }

  #recommend_area .slick-next {
      right: 14px;
  }
  #recommend_area .slick-prev {
      right: 74px;
  }

//   #pickup_item .slick-next{
//     right: 5px;
//   }
//   #pickup_item .slick-prev{
//     left:5px;
//   }

  #recommend_area .angle-circle {
      width: 35px; height: 35px;
      margin-top: 0;
  }
  #recommend_area .slick-prev .cb,
  #recommend_area .slick-next .cb {
      fill: #fff;
      position: relative;
      top: -4px; right: -2px;
      font-size: 25px;
      font-size: 2.5rem;
  }




  /* Cart Page
  ------------------------------------------- */

  .total_box {
      min-width: 256px;
      float: right;
      padding: 0;
  }
  .total_box dl {
      margin-left: 0;
  }
  .total_box .btn_group {
      padding: 16px 0;
  }
  .total_box .btn_group p {
      margin: 16px 0;
  }
  .total_box dl.total_price dt {
      padding-top: 0.3em;
  }
  .total_box dl.total_price dd {
      font-size: 24px;
      font-size: 2.4rem;
  }
  .cart_item {
      margin: 24px 0 16px;
      border-top: 0 none;
  }
  .cart_item .item_box .table {
      width:58.33333333%;
  }
  .cart_item .item_quantity::before {
      content: "";
  }
  .cart_item.table .tbody .tr > .item_quantity {
      text-align: center;
  }
  .cart_item .item_detail .item_subtotal {
      display: none;
  }
  .cart_item.table .tbody .tr div.item_subtotal {
      display: table-cell;
      text-align: right;
  }
  .cart_item .item_box .table .item_photo {
      min-width: 160px;
      padding-right: 16px;
  }
  .column p {
      margin: 16px 0;
  }
  .column .form-inline input,
  .column .form-inline select {
      margin-bottom: 16px;
  }

  div.table .tbody .addr_box .column {
      text-align: left;
      position: relative;
  }
  .addr_box .column.is-edit .btn_edit {
      top: 16px;
  }

  .multiple_column .item_box > div,
  .multiple_column .item_box > dl {
      padding: 16px 0;
      vertical-align: middle;
  }
  .multiple_column .item_box .item_photo {
      padding: 16px;
      max-width: 198px;
  }
  .multiple_column .item_box .item_detail .item_price {
      margin: 8px 0 16px;
  }
  .multiple_column .form-group {
      margin-top: 16px;
  }
  .complete_message {
      text-align: center;
  }
  .complete_message .heading01 {
      font-size: 32px;
      font-size: 3.2rem;
  }

  #confirm_side {
      top: 0;
  }

/* payment_list */

.payment_list .radio {
  display: inline;
  margin-right: 16px;
  vertical-align:middle;
}
.payment_list .radio input[type="radio"] {
  margin-right: 10px;
}
.payment_list li img {
  margin-top: 0;
  vertical-align:middle;
}

  /* MyPage
  ------------------------------------------- */
/*マイページナビゲーション*/
.local_nav ul {
  background: #FFF0F0;
  border-radius:10px !important;
  font-size:14px;
  font-size:1.4rem;
/*    border-style:solid solid none solid;
  border-width:1px;
  border-color:#ffa3a3;*/
}

.local_nav ul:before,.local_nav ul:after {content:" ";display:table}
.local_nav ul:after {clear:both}

.local_nav li {
  float: left;
  width: 20%;
  margin:0;
  background: none;
  /* 項目が1行に3件の場合
  width: 33.33333333%;*/
/*  border-color: #D0D0D0;
  border-style: solid;
  border-width: 0 1px 1px 0;*/
  text-align: center;
}
.local_nav li:last-child{
  border-right:none;
}
  .local_nav li {
      width: 25%;
  }
 .local_nav.favorite li {
      width: 20%;
  }
  .local_nav li{
      border-right:1px solid #ffa3a3;
  }
  .local_nav li:last-child{
      border-right:none;
  }
  .local_nav li a.my-rireki:hover {
      border-radius:10px 0 0 10px;
  }
  .local_nav li a.my-withdrawal:hover {
      border-radius:0 10px 10px 0;
  }


/*履歴一覧*/

  .historylist_column {
      border-top: 1px solid #ccc;
      margin: 0;
  }

  .historylist_column p a.btn{
      width:80%;
  }

  .historylist_column .order_date {
      font-size: 14px;
      font-size: 1.4rem;
      margin-top: 16px;
  }
  .historylist_column .table.item_box:first-child {
      border-top: 0 none;
  }
  .historylist_column .item_box .item_photo {
      min-width: 130px;
  }
  .historylist_column + .pagination {
      padding-top: 16px;
  }
  .unsubscribe_box h3 {
      font-size: 24px;
      font-size: 2.4rem;
  }
.message_box h1 {
  font-size: 24px;
  font-size: 2.4rem;
}


  /****** Effect Setting ********/

  /* Overlay */
  .overlay {
      display: none;
  }

  /****** Each BlockParts Setting ********/

  /* Cart */
  .cart {
      height: 120px;
      top: 44px;
  }

  /* Search */

  /* ----- category_nav in header  ---- */

  #header #searchform select,
  #header #searchform input {
      float: left;
  }

  /* ----- category_nav in side ---- */

  .side #searchform select,
  .side #searchform input {
      float: none;
  }


  /****** Each Page Setting ********/

  /* Item List Page
  ------------------------------------------- */

  /* Item Detail Page
  ------------------------------------------- */

  /* Cart Page
  ------------------------------------------- */

  .cart_page .dl_table {
      margin: 24px 0 32px;
  }
  .cart_page .dl_table dt {
      margin-bottom: 0;
  }
  #shopping_confirm .cart_item.table {
      border-top: 1px dotted #ccc;
  }
  #shopping_confirm .column .cart_item.table {
      margin-top: 0;
  }
  #shopping_confirm .total_box {
      margin-top: 24px;
      padding: 16px;
      min-width: 0;
      width: 100%;
      float: none;
  }
  #shopping_confirm .total_box dl {
  padding-top: 8px;
}
  #shopping_confirm .total_box dl:first-child {
  padding-top: 0;
}
  #shopping_confirm .total_box dl dt {
      font-size: 14px;
      font-size: 1.4rem;
  }
  #shopping_confirm .total_box .total_amount {
      margin-top: 16px;
  }
  #shopping_confirm .total_box .total_amount p.total_price {
      font-size: 14px;
      font-size: 1.4rem;
  }
  #shopping_confirm .total_box .total_amount p.total_price strong {
      font-size: 24px;
      font-size: 2.4rem;
  }
  #shopping_confirm .total_box .total_amount p.total_price strong .small {
      font-size: 14px;
      font-size: 1.4rem;
  }
  #shopping_confirm .heading02 {
      font-size: 20px;
      font-size: 2rem;
      margin-top: 32px;
  }
  #shopping_confirm .cart_item .item_box .table .item_photo {
      min-width: 80px;
      max-width: 96px;
  }
  #deliveradd_input .dl_table {
      border-top: 1px dotted #ccc;
  }

  /* MyPage
  ------------------------------------------- */
  .mypage .intro {
      padding-bottom: 16px;
  }
  .mypage #shopping_confirm .cart_item,
  .mypage #shopping_confirm .total_box {
      margin-top: 0;
  }
  .mypage #item_list .product_item {
      padding-bottom: 24px;
  }
  .mypage .title_hint {
      margin: 24px 0 0;
  }
  .mypage .title_hint + .form-group {
      padding-top: 8px;
  }


  /* Registration Page
  ------------------------------------------- */

  .registration_page .dl_table {
      margin: 24px 0 32px;
  }


  /* Other Page
  ------------------------------------------- */


  /* Login Page */
  #login_box{}
  #login_box .column {
      /*padding: 16px 16px 40px;*/
      background: #ffe8e8;
      border-radius:20px;
  }
  #login_box .column p {
      margin-bottom: 8px;
  }
  #login_box .column .form-group {
      padding: 8px 0 8px 13%;
  }
  #login_box .column .btn_area p {
      /*float: left;
      width: 37%;
      min-width: 195px;
      margin-right: 3%;*/
  }
  #login_box .column .btn_area ul {
  margin-top: 0;
}
  #login_box .column .btn_area button {
      width: 100%;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
  }

  #login_box .column .btn_area ul {
      float: left;
  }
  #login_box .column .btn_area ul li {
      margin: 4px 0;
  }

  #login_box .column input[type="text"],
  #login_box .column input[type="password"]{
      width: 85%;
  }
  #login_box .column .btn_area,
  #login_box .column .message {
      padding: 8px 13%;
      margin: 0;
  }

  .login_cart .column .column_inner {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  }
    .login-retention{
    padding: 8px 0 8px 13%;
    }


  /* Forgot Page */

  #forgot_page .column {
      padding: 32px;
      margin-bottom: 16px;
  }
  #forgot_page .column .form-group {
      padding: 8px 0 8px 11%;
      width: 89%;
  }
  #forgot_page .column .btn_area {
      padding: 20px 0 0 21%;
      width: 79%;
  }
  #forgot_page .column .message {
      margin-bottom: 16px;
  }

    /* Error Page */

    .error_page .footer_logo_area {
    margin-top: 0;

    }

    /* common_add */
    .common-add .common-top{
        display: block;
        position: absolute;
        top:0;
        left:0;
        font-size:0;
        line-height:1;
    }
    .common-add .common-bottom{
        display: block;
        position: absolute;
        bottom:0;
        right:0;
        font-size:0;
        padding:0;
        margin:0;
    }
    .common-add h2{
        margin:0 auto 1.5rem;
        color:#f5568b;
        text-align:center;
        font-size:18px;
        font-size:1.8rem;
    }
    .common-add ul{
        margin:0 auto 1rem;
        width:70%;
    }
    .common-add li{
        font-size:2.5rem !important;
        font-weight:bold;
        vertical-align: middle;
    }
    .common-add li em{
        margin-right:5px;
        padding:3px;
        background: #f5568b;
        color:#fff;
        font-size:1.7rem;
    }
    .common-add li span{
        padding:4px 0 0 45px;
        display:block;
        font-size:11px;
        font-size:1.1rem;
    }


}

