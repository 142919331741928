@import "../mixins/media";
@import "../mixins/clearfix";

/*
見出し

トップページで使用されている新着情報のスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.8.1
*/

.ec-newsRole {
  margin: 2rem auto;
  padding: 0;
  border: 1px solid #e3e3e3;
  background: #fff;
  #news-in{
    margin: 3px;
    overflow: hidden;
    background: #f4efe9;
    @include media_desktop {
      display: flex;
      justify-content: space-between;
      .ec-secHeading{
        width:230px;
      }

    }
    .ec-secHeading{
      padding: 20px 0;
      background:#f4efe9;
      margin: 0 auto 4px;
      width: 100%;
      height: 100%;
      color: #f678a0;
      text-align: center;
      white-space: nowrap;
      letter-spacing: 0;
      font-size: 23px;
      font-size: 2.3rem;
      font-weight:bold;
      span{
        display: block;
        font-size: 12px;
        font-size: 1.2rem;
        color: #333;
        text-align: center;
      }
    }
  }
  & &__news {
    border-left:4px solid #fff;
    margin: 0 auto;
    width: 100%;
    background: #fdf4ed;
    height: 100%;
    box-sizing: border-box;
    @include media_desktop {
      // border: 16px solid #F8F8F8;
      // padding: 20px 30px;
      width:calc(100% - 250px);
    }
  }
  & &__newsItem {
    position: relative;
    border-top:2px solid #fff;
    border-bottom:2px solid #fff;
    margin:0 auto;
    padding:0 20px;
    width: 100%;
    @include media_desktop {
      padding:7px 10px;
    }
    // &:not(:last-of-type){
    //   border-bottom: 1px solid #ccc;
    // }

    // &:last-of-type {
    //   margin-bottom: 20px;

    //   @include media_desktop {
    //     margin-bottom: 0;
    //   }
    // }


  }
  & &__newsHeading {
    position:relative;
    cursor: pointer;

  }
  & &__newsDate {
    display: block;
    margin:  15px 0 5px;
    font-size: 12px;
    color: black;

    @include media_desktop {
      display: inline-block;
      margin: 0;
      min-width: 120px;
      font-size: 14px;
    }

  }
  & &__newsColumn {
    display: flex;

    @include media_desktop {
      display: inline-flex;
      min-width: calc(100% - 120px);
    }
  }

  & &__newsTitle {
    display: inline-block;
    margin-bottom: 10px;
    width: 90%;
    font-size: 14px;
    font-weight: bold;
    color: #000;
    line-height: 1.6;
    &:hover{
      text-decoration: underline;
    }

    @include media_desktop {
      margin-bottom: 0;
      line-height: 1.8;
    }

  }
  & &__newsClose {
    display: inline-block;
    width: 10%;
    position: relative;
    @include media_desktop {
      position:absolute;
      right:0;
      // bottom:auto;
    }

  }
  & &__newsCloseBtn {
    display: inline-block;
    margin-left: auto;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: white;
    text-align: center;
    background: #f5568b;
    cursor: pointer;
    position: absolute;
    right: 5px;
  }
  & &__newsDescription {
    display: none;
    margin: 0 0 10px;
    font-size: 14px;
    line-height: 1.4;
    overflow: hidden;

    @include media_desktop {
      margin: 20px 0 0;

      line-height: 1.8;
    }

    a {
      color: #0092C4;
    }
  }
  &__newsItem.is_active &__newsDescription{
    margin: 0 0 10px;

    @include media_desktop {
      margin: 20px 0 0;
    }
  }
  &__newsItem.is_active &__newsCloseBtn i {
    display: inline-block;
    transform: rotateX(180deg) translateY(2px);

  }

}



#top-sns{
  margin:0 auto 3rem;
  text-align: center;
  flex-wrap: wrap;
  display:flex;
  justify-content: center;
  align-items: center;
  .insta{
    margin-right:10px;
    width:50%;
    max-width: 160px;
    @include media_desktop {
      max-width: 370px;
    }
  }
  .message{
    font-size:12px;
    width:48%;
    max-width:170px;
    @include media_desktop {
      font-size:16px;
      max-width:220px;
    }
  }

}
