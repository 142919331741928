@import "../mixins/projects";
@import "../mixins/media";
/*
マイページ

マイページで利用するためのスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 9.1
*/

/*
マイページ

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist

Styleguide 9.1.1
*/
.ec-navlistRole{
  & &__navlist {
    @include reset_link;
    display: flex;
    flex-wrap: wrap;
    // border-color: #D0D0D0;
    // border-style: solid;
    // border-width: 1px 0 0 1px;
    margin-bottom: 32px;
    padding: 0;
    list-style: none;
    font-size: 14px;
    font-size: 1.4rem;
    &::before{
      content: " ";
      display: table;
    }
    @include media_desktop {
      flex-wrap: nowrap;
    }
  }

  & &__item{
    display: flex;
    width: 48%;
    margin:1%;
    text-align: center;
    font-weight: bold;
    background: #FFF0F0;
    @include media_desktop {
      border-right: 1px solid #ffa3a3;
      margin:0 auto;
      &:first-child{
        border-radius:10px 0 0 10px;
      }
      &:last-child{
        border-radius:0 10px 10px 0;
        border-right: none;
      }
    }
    &:hover{
      background: #ffcfcf;
    }
    a {
      padding:1.2rem 0.5rem !important;
      font-weight: bold;
      color: #727272;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin:0 auto;
      transition: all 0.3s ease;
      &.my-rireki:before{
        content: "\f07a";
        font-family: "FontAwesome";
        padding: 0 7px 0 0;
        font-size:1.5rem;
      }
      &.my-favorite:before{
        content: "\f004";
        font-family: "FontAwesome";
        padding: 0 7px 0 0;
        font-size:1.5rem;
      }
      &.my-member:before{
        content: "\f007";
        font-family: "FontAwesome";
        padding: 0 7px 0 0;
        font-size:1.5rem;
      }
      &.my-deliver:before{
        content: "\f0d1";
        font-family: "FontAwesome";
        padding: 0 7px 0 0;
        font-size:1.5rem;
      }
      &.my-withdrawal:before{
        content: "\f08b";
        font-family: "FontAwesome";
        padding: 0 7px 0 0;
        font-size:1.5rem;
      }

    }
  }
  .active {
    a {
      color: #DE5D50;
    }
  }
}

/*
マイページ（お気に入り機能無効）

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist_noFavorite

Styleguide 9.1.2
*/

/*
Welcome メッセージ

マイページで表示するログイン名の表示コンポーネントです。

ex [マイページ　メニューリスト下部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-welcomeMsg

Styleguide 9.1.3
*/
.ec-welcomeMsg{
  @include mypageContainer;
  font-size: 1.7rem;
  font-size:17px;
  font-weight: bold;
  margin: 1em 0;
  padding-bottom: 32px;
  text-align: center;
  // @include borderBottom;
  @include media_desktop {
    font-size: 20px;
    font-size: 2.0rem;
  }

}

/*
お気に入り一覧

お気に入り一覧で表示するアイテムの表示コンポーネントです。

ex [マイページ　お気に入り一覧](http://demo3.ec-cube.net/mypage/favorite)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-favorite

Styleguide 9.1.4
*/
.ec-favoriteRole{
  & &__header {
    margin-bottom: 16px;
    p{
      span{
        color:#f5568b;
        font-weight:bold;
      }
    }
  }
  & &__detail {
  }
  & &__itemList {
    @include reset_link;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
  }
  & &__item{
    margin-bottom: 8px;
    width: 47.5%;
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    &-image {
      margin-bottom: 10px;
      text-align: center;
    }
    img{
      width: auto;
      max-height: 100%;

    }
    @include media_desktop(){
      width: 25%;
    }
    .ec-closeBtn--circle {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 999;
      .ec-icon img{
        width: 1em;
        height: 1em;
      }
    }
  }
  & &__itemThumb {
    display: block;
    height:auto;
    margin-bottom: 8px;
    img{
      border-radius: 10px;
      border: 5px solid #ffe2e2;
    }
  }
  & &__itemTitle{
    margin:0 auto 2px;
    text-align:center;
    color: #f5568b;
  }
  & &__itemPrice{
    font-weight: bold;
    margin:0 auto;
    text-align:center;
    color: #f5568b;
    font-size:13px;
    font-size:1.3rem;
  }

}
